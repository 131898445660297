import React, { createContext } from 'react';

import history from '../utils/history';
import { INIT_CACHE_LENGTH } from '../utils/map';

export const AppContext = createContext();

export const withAppContext = Component => props => (
  <AppContext.Consumer>{ctx => <Component {...props} appContext={ctx} />}</AppContext.Consumer>
);

export const reducers = ctx => {
  return {
    addAlert: newAlert => {
      // adds a fall alert to global state for consumption in other components
      const {
        room: { id: roomId },
      } = newAlert;
      const {
        state: {
          context: { alerts },
        },
      } = ctx;
      let newAlerts;

      const currentAlert = alerts.find(alert => alert.room.id === roomId);

      if (currentAlert) {
        // replace current alert with new
        newAlerts = alerts.filter(alert => alert.room.id !== roomId);
        newAlerts = newAlerts.concat(newAlert);
      } else {
        // no alert exists for this room, add to existing alerts
        newAlerts = alerts.concat(newAlert);
      }

      ctx.setState({
        context: {
          ...ctx.state.context,
          alerts: newAlerts,
        },
      });
    },
    dismissAlert: alertId => {
      const {
        state: {
          context: { alerts },
        },
      } = ctx;

      const newAlerts = alerts.filter(alert => alert.id !== alertId);

      ctx.setState({
        context: {
          ...ctx.state.context,
          alerts: newAlerts,
        },
      });
    },
    showFallRoom: (zoneId, room) => {
      // navigates to locate page, centers and zooms in on a room in an animated fashion
      if (!room || !room.id) return;

      const {
        location: { pathname },
      } = window;

      if (pathname !== '/locate') {
        history.push(`/locate/${zoneId}`);
      }

      ctx.setState({
        context: {
          ...ctx.state.context,
          showingFallRoom: room.id,
        },
      });
    },
    resetShowFallRoom: () => {
      ctx.setState({
        context: {
          ...ctx.state.context,
          showingFallRoom: false,
        },
      });
    },
    initializeSubscriptions: subscriptions => {
      ctx.setState({
        context: {
          ...ctx.state.context,
          subscriptions,
        },
      });
    },
    addSubscription: newSubscription => {
      const {
        state: { context },
      } = ctx;

      ctx.setState({
        context: {
          ...context,
          subscriptions: [...context.subscriptions, newSubscription],
        },
      });
    },
    removeSubscription: subscriptionId => {
      const {
        state: { context },
      } = ctx;

      ctx.setState({
        context: {
          ...context,
          subscriptions: context.subscriptions.filter(
            subscription => subscription.id !== subscriptionId,
          ),
        },
      });
    },
    setNetworkStatus: status => {
      const {
        state: { context },
      } = ctx;

      ctx.setState({
        context: {
          ...context,
          networkStatus: status,
        },
      });
    },
    resetQueryCacheExpire: () => {
      const {
        state: { context },
      } = ctx;
      const queryCacheExpire = new Date(new Date().getTime() + INIT_CACHE_LENGTH);
      ctx.setState({
        context: {
          ...context,
          queryCacheExpire,
        },
      });
    },
  };
};
