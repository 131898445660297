import React from 'react';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  container: {
    padding: '2rem 0',
  },
  track: {
    backgroundColor: 'gray',
  },
  thumb: {
    backgroundColor: '#FFB838',
  },
});

const Slider = props => (
  <div className="slider-container">
    <input type="range" {...props} />
  </div>
);

export default withStyles(styles)(Slider);
