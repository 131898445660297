import React from 'react';

import Can from '../../../components/Can/Can';
import PasswordPrompt from '../../../components/PasswordPrompt/PasswordPrompt';
import DeleteButton from '../DeleteButton/DeleteButton';

const BulkActionButtons = props => (
  <Can perform={`${props.object}:delete`}>
    <PasswordPrompt>
      {({ promptCheck }) => (
        <DeleteButton
          bulk
          modalHeader={props.deleteModalHeader}
          promptCheck={promptCheck}
          {...props}
        />
      )}
    </PasswordPrompt>
  </Can>
);

export default BulkActionButtons;
