import gql from 'graphql-tag';

export default function roomAreaQueries(raFetchType, params) {
  switch (raFetchType) {
    case 'CREATE': {
      // TODO: add vertices translation like in rooms after vertices edit is introduced
      return {
        query: gql`
          mutation addRoomArea(
            $room_id: ID!
            $vertices: [VertexInput]!
            $description: String
            $region_map_id: Int!
            $is_bed: Boolean
          ) {
            addRoomArea(
              room_id: $room_id
              vertices: $vertices
              description: $description
              region_map_id: $region_map_id
              is_bed: $is_bed
            ) {
              id
            }
          }
        `,
        variables: {
          ...params.data,
          vertices: [],
          room_id: params.data.room.id,
        },
        parseResponse: response => ({ data: response.data.addRoomArea }),
      };
    }

    case 'UPDATE': {
      // TODO: add vertices translation like in rooms after vertices edit is introduced
      return {
        query: gql`
          mutation updateRoomArea(
            $id: ID!
            $room_id: ID
            $vertices: [VertexInput]
            $description: String
            $region_map_id: Int
            $is_bed: Boolean
          ) {
            updateRoomArea(
              id: $id
              room_id: $room_id
              vertices: $vertices
              description: $description
              region_map_id: $region_map_id
              is_bed: $is_bed
            ) {
              id
            }
          }
        `,
        variables: {
          ...params.data,
          vertices: [],
          room_id: params.data.room.id,
        },
        parseResponse: response => ({ data: response.data.updateRoomArea }),
      };
    }

    case 'GET_ONE':
      return {
        query: gql`
          query getRoomAreas($id: ID) {
            room_areas(id: $id) {
              total
              data {
                ... on RoomArea {
                  id
                  description
                  region_map_id
                  is_bed
                  room {
                    id
                    name
                  }
                }
              }
            }
          }
        `,
        variables: params,
        parseResponse: response => ({
          data:
            response.data &&
            response.data.room_areas &&
            response.data.room_areas.data &&
            response.data.room_areas.data.length &&
            response.data.room_areas.data[0],
        }),
      };
    case 'GET_MANY':
    case 'GET_LIST':
      return {
        query: gql`
          query getRoomAreas($sort: Sort, $pagination: Pagination) {
            room_areas(sort: $sort, pagination: $pagination) {
              total
              data {
                ... on RoomArea {
                  id
                  description
                  region_map_id
                  is_bed
                  room {
                    id
                    name
                  }
                }
              }
            }
          }
        `,
        variables: params,
        parseResponse: response => ({
          data: response.data.room_areas.data,
          total: response.data.room_areas.total,
        }),
      };
    case 'DELETE':
      return {
        query: gql`
          mutation deleteRoomAreas($id: [ID!]!) {
            deleteRoomAreas(id: $id) {
              id
            }
          }
        `,
        variables: params,
        parseResponse: response => ({ data: response.data.deleteRoomAreas[0] }),
      };
    case 'DELETE_MANY':
      const variables = {
        id: params.ids,
      };

      return {
        query: gql`
          mutation deleteRoomAreas($id: [ID]!) {
            deleteRoomAreas(id: $id) {
              id
            }
          }
        `,
        variables,
        parseResponse: response => ({ data: response.data.deleteRoomAreas }),
      };
    default:
      throw new Error('Invalid fetch type');
  }
}
