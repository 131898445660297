import React, { Fragment, Component } from 'react';
import gql from 'graphql-tag';
import { withApollo } from 'react-apollo';
import { CREATE, DELETE } from 'react-admin';

import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';

import { dataProvider } from '../../dataProvider';
import { toast } from '../../../components/Toast/Toast';
import PasswordPrompt from '../../../components/PasswordPrompt/PasswordPrompt';

const GET_ORGANIZATIONS_AND_OBJECT_TYPES_TO_ORGANIZATION = objectType => gql`
  query getOrgsAndTypes($id: ID) {
    organizations(all: true) {
      total
      data {
        ... on Organization {
          id
          name
        }
      }
    }

    ${objectType}s_to_organizations(${objectType}_id: $id) {
      id
      organization {
        id
        name
      }
    }
  }
`;

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  noLabel: {
    marginTop: theme.spacing.unit * 3,
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(org, that) {
  return {
    fontWeight:
      that.state.allOrganizations.indexOf(org) === -1
        ? that.props.theme.typography.fontWeightRegular
        : that.props.theme.typography.fontWeightMedium,
  };
}

class OrganizationDropdown extends Component {
  constructor(props) {
    super(props);

    const { record, objectType } = props;

    this.state = {
      selectedOrganizations: [],
      allOrganizations: [],
      loading: true,
      rowsLoading: false,
    };

    const { client } = props;

    client
      .query({
        query: GET_ORGANIZATIONS_AND_OBJECT_TYPES_TO_ORGANIZATION(objectType),
        variables: { id: record.id },
        fetchPolicy: 'network-only',
      })
      .then(queryResult => {
        const {
          data: {
            organizations: { data: organizations },
            [`${objectType}s_to_organizations`]: orgObjectTypes,
          },
        } = queryResult;
        const orgsWithObjectTypes =
          orgObjectTypes && orgObjectTypes.map(at => at.organization.name);
        let selectedOrganizations =
          orgsWithObjectTypes &&
          organizations.filter(org => orgsWithObjectTypes.includes(org.name));

        if (selectedOrganizations && selectedOrganizations.length > 0) {
          selectedOrganizations = selectedOrganizations.map(org => {
            const typeToOrganization = Object.assign(
              orgObjectTypes &&
                orgObjectTypes.length > 0 && {
                  ...orgObjectTypes.find(at => at.organization.id === org.id),
                },
            );

            typeToOrganization && delete typeToOrganization.organization;

            return {
              ...org,
              typeToOrganization,
            };
          });
        }

        this.setState({
          allOrganizations: organizations,
          selectedOrganizations: selectedOrganizations || [],
          loading: false,
        });
      })
      .catch(error => {
        console.log('error: ', error);
        toast.error('Error getting object type to organization data');
      });
  }

  handleChange = event => {
    if (!this.state.rowsLoading) {
      this.setState({
        rowsLoading: true,
      });
    }

    const { selectedOrganizations, allOrganizations } = this.state;
    const {
      record: { id: objectTypeId },
      resourceName,
      objectType,
    } = this.props;
    const {
      target: { value },
    } = event;
    const operation =
      value.length > (selectedOrganizations ? selectedOrganizations.length : 0) ? 'add' : 'remove';
    let clickedOrganization;

    if (operation === 'add') {
      const selectedOrgNames = selectedOrganizations.map(sOrg => sOrg.name);
      const newValue = value.filter(org => !selectedOrgNames.includes(org))[0];

      clickedOrganization = allOrganizations.find(aOrg => aOrg.name === newValue);
    } else {
      clickedOrganization = selectedOrganizations.filter(org => !value.includes(org.name))[0];
    }

    dataProvider(
      operation === 'add' ? CREATE : DELETE,
      resourceName,
      operation === 'add'
        ? {
            data: {
              [objectType]: { id: objectTypeId },
              organization: { id: clickedOrganization.id },
            },
          }
        : { id: clickedOrganization.typeToOrganization.id },
    ).then(result => {
      let newSelectedOrganizations;

      if (operation === 'add') {
        // add result data to selectedOrganizations
        newSelectedOrganizations = [
          ...selectedOrganizations,
          {
            ...clickedOrganization,
            typeToOrganization: {
              ...result.data,
            },
          },
        ];
      } else {
        // remove from selectedOrganizations
        newSelectedOrganizations = selectedOrganizations.filter(
          org => org.id !== clickedOrganization.id,
        );
      }

      this.setState({
        selectedOrganizations: newSelectedOrganizations,
        rowsLoading: false,
      });
    });
  };

  render() {
    const { classes } = this.props;
    const { selectedOrganizations, allOrganizations, loading, rowsLoading } = this.state;

    return (
      !loading && (
        <Fragment>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="select-organizations">Organizations</InputLabel>
            <PasswordPrompt>
              {({ promptCheck }) => (
                <Select
                  multiple
                  value={
                    selectedOrganizations
                      ? selectedOrganizations.map(organization => organization.name)
                      : []
                  }
                  onChange={event => promptCheck(() => this.handleChange(event))}
                  input={<Input id="select-organizations" />}
                  renderValue={selected => (
                    <div className={classes.chips}>
                      {selected.map(value => (
                        <Chip key={value} label={value} className={classes.chip} />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {allOrganizations.map(organization => (
                    <MenuItem
                      key={organization.id}
                      value={organization.name}
                      style={getStyles(organization, this)}
                      disabled={rowsLoading}
                    >
                      <Checkbox
                        checked={
                          !!selectedOrganizations.find(org => org.name === organization.name)
                        }
                        color="primary"
                      />
                      {organization.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </PasswordPrompt>
          </FormControl>
        </Fragment>
      )
    );
  }
}

export default withStyles(styles, { withTheme: true })(withApollo(OrganizationDropdown));
