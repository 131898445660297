import React from 'react';
import { PiService, PiMap } from 'pointinside';
import { tryFor } from './js';
import ResizeDetector from '../components/ResizeDetector/ResizeDetector';
import moment from 'moment-timezone';

import log from './Log';

export const MARKER_COLOR_BASE = '#1f4b99';
export const MARKER_COLOR_HIGHLIGHT = '#DB3737';
export const MARKER_COLOR_INACTIVE = '#555555';
export const MARKER_HOVER = 'MARKER_HOVER';
export const MARKER_SELECTED = 'MARKER_SELECTED';
export const MARKER_Z_INDEX = '1090';
export const MARKER_Z_INDEX_HOVER = '1092';
export const MARKER_Z_INDEX_SELECTED = '1091';
export const GROUP_MARKER_RADIUS = 30;
export const MISSING_TAG_THRESHOLD_SECONDS = 300;
export const ROOM_PRESENCE_DETECTION_LOCAL_STORAGE_KEY = 'rh-show-room-presence';
export const MIN_MARKER_TRANSITION_SPEED_MS = 200;
export const MAX_MARKER_TRANSITION_SPEED_MS = 800;
export const DEFAULT_ASSET_UTILIZATION_RANGE_OPTION = '7d';
export const INIT_CACHE_LENGTH = 30 * 1000;

export const ALERT_OVERLAY_PATTERNS = [
  {
    componentId: 'fall_detected',
    color1: '#ffb838',
    color2: '#ffdb99',
  },
  {
    componentId: 'fall_confirmed',
    color1: '#cd3838',
    color2: '#ebadad',
  },
  {
    componentId: 'room_entry',
    color1: '#007bff',
    color2: '#99caff',
  },
];

export const piMapElement = (
  <ResizeDetector>
    <div className="mapSVG_wrapper">
      <div id="pi-map" style={{ height: '100%', width: '100%' }} />
    </div>
  </ResizeDetector>
);

export const svgPolygonPoints = {
  convertToArray: string =>
    string &&
    string.length &&
    string.split(' ').map(point => {
      const positions = point.split(',');
      return {
        x: positions[0],
        y: positions[1],
      };
    }),
  convertToString: array =>
    array &&
    Array.isArray(array) &&
    array.length &&
    array.map(item => `${item.x},${item.y}`).join(' '),
};

export function getBatteryIcon(batteryPercent) {
  let elClass;
  if (batteryPercent <= 5) {
    elClass = 'fa-battery-empty text-danger';
  } else if (batteryPercent <= 20) {
    elClass = 'fa-battery-quarter text-danger';
  } else if (batteryPercent <= 35) {
    elClass = 'fa-battery-quarter text-warning';
  } else if (batteryPercent <= 60) {
    elClass = 'fa-battery-half text-success';
  } else if (batteryPercent <= 85) {
    elClass = 'fa-battery-three-quarters text-success';
  } else {
    elClass = 'fa-battery-full text-success';
  }

  const cappedBatteryPercent = batteryPercent > 100 ? 100 : batteryPercent;

  const el = (
    <span className="d-flex align-items-center">
      <i className={`fas ${elClass}`} />
      &nbsp;<span className="asset__battery-percent">{cappedBatteryPercent}%</span>
    </span>
  );
  return el;
}

export function getDefaultZone(venue) {
  if (!venue) return;
  const { default_zone, zones } = venue;
  // Find the id that matches the default_zone id, if no default_zone exists, just get the first in the zone list
  const zone =
    zones &&
    (zones.find(zone => {
      const defaultZoneId = default_zone && default_zone.id;
      return zone.id === defaultZoneId;
    }) ||
      zones[0]);
  return zone;
}

export function getMarkerSVG({ type, color, assetType } = {}) {
  const markerColor = color || getMarkerColor(type);
  const size = getMarkerSize(type);
  const assetTypeStartsWith = assetType ? assetType.charAt(0) : '+';
  const textY = assetType ? '22' : '20';
  const textSize = assetType ? '16' : '18';
  return {
    content: `
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 40 40" xml:space="preserve">
      <g fill="${markerColor}" opacity="1">
        <path class="st0" d="M20,3.6C13.1,3.6,7.5,9,7.5,15.8c0,2.8,1,5.4,2.6,7.5L20,36.4l9.8-13.1c1.7-2.1,2.6-4.7,2.6-7.5 C32.5,9.1,26.9,3.6,20,3.6z"/>
        <text x="20" y=${textY} font-size='${textSize}' text-anchor='middle' font-family='helvetica' style='pointer-events: none; fill:white;'>${assetTypeStartsWith}</text>
      </g>
    </svg>`,
    width: size,
    height: size,
    centerX: size / 2,
    centerY: size - 2,
  };
}

export function getGroupMarkerSVG(type, count = '#TEXT#') {
  const color = getMarkerColor(type);
  const size = getMarkerSize(type);
  return {
    content: `<svg version='1.1' xmlns='http://www.w3.org/2000/svg'
      xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px'
      viewBox='0 0 32 32' xml:space='preserve'>
      <g fill='${color}'>
          <circle cx='16' cy='16' r='16'/>
          <circle opacity='0.9' cx='16' cy='16' r='10' fill='white'/>
          <text x='16' y='20' font-size='12' text-anchor='middle' font-family='helvetica'
           style='pointer-events: none;'>${count}</text>
      </g>
    </svg>`,
    width: size,
    height: size,
    centerX: size / 2,
    centerY: size / 2,
  };
}

export function getMarkerColor(type) {
  let color;
  switch (type) {
    case MARKER_HOVER:
    case MARKER_SELECTED:
      color = MARKER_COLOR_HIGHLIGHT;
      break;
    default:
      color = MARKER_COLOR_BASE;
  }
  return color;
}

export function getMarkerSize(type) {
  let size;
  switch (type) {
    case MARKER_HOVER:
      size = 40; // Needs to be an even number, we split it in half, can't have half pixels
      break;
    case MARKER_SELECTED:
      size = 46;
      break;
    default:
      size = 40;
  }
  return size;
}

export function initializePiMap(config, vendorMapId, vendorZoneId, overrides = {}, done) {
  const { piApiKey, piApiUrl } = config;
  const { piService: piServiceOverrides, piMap: piMapOverrides } = overrides;

  tryFor(() => document.getElementById('pi-map'), 5000)
    .then(element => {
      const piService = new PiService(
        Object.assign(
          {
            apiKey: piApiKey,
            apiUrl: piApiUrl,
            appName: 'repp health',
            appVersion: '1.0',
          },
          piServiceOverrides || {},
        ),
      );

      const piMap = new PiMap(
        Object.assign(
          {
            element: element,
            zoneChangerControls: 'top-right',
          },
          piMapOverrides || {},
        ),
      );

      piService
        .getVenueById({ venueId: vendorMapId })
        .then(venue => {
          piMap
            .setVenue({ venue })
            .then(() => {
              piMap
                .displayZone(vendorZoneId)
                .then(() => {
                  done({
                    element,
                    piService,
                    piMap,
                  });
                })
                .catch(({ message: displayZoneError }) => {
                  // the error emitted by PI is really ugly so we're not using it here
                  done({ error: 'Invalid zone' });
                });
            })
            .catch(({ message: setVenueError }) => {
              done({ error: setVenueError || 'Error setting venue' });
            });
        })
        .catch(({ message: getVenueError }) => {
          done({ error: getVenueError || 'Error getting venue' });
        });
    })
    .catch(({ message: mapElementError }) => {
      done({ error: mapElementError || 'Could not find map element' });
    });
}

export function cursorPositionToSVGPoint(cursorX, cursorY, svgElement, svgPoint) {
  svgPoint.x = cursorX; // might need to use map zoom/scale here if render method changes
  svgPoint.y = cursorY;

  return svgPoint.matrixTransform(svgElement.getScreenCTM().inverse());
}

export function meterPointToPixels(location, zone) {
  const {
    meter_to_pixel_scaler_x,
    meter_to_pixel_scaler_y,
    origin_offset_x, // meters
    origin_offset_y, // meters
  } = zone;

  return {
    x: parseFloat((location.x + origin_offset_x) / meter_to_pixel_scaler_x),
    y: parseFloat((location.y + origin_offset_y) / meter_to_pixel_scaler_y),
  };
}

export function pixelPointToMeters(location, zone) {
  const {
    meter_to_pixel_scaler_x,
    meter_to_pixel_scaler_y,
    origin_offset_x, // meters
    origin_offset_y, // meters
  } = zone;

  return {
    x: parseFloat((location.x * meter_to_pixel_scaler_x - origin_offset_x).toFixed(3)),
    y: parseFloat((location.y * meter_to_pixel_scaler_y - origin_offset_y).toFixed(3)),
  };
}

export function tagIsMissing(deviceEventTime) {
  if (
    deviceEventTime >=
    moment()
      .unix()
      .valueOf() -
      MISSING_TAG_THRESHOLD_SECONDS
  ) {
    return false;
  }

  return true;
}

export function deviceEventTimeFromPast({ previous, current }) {
  if (current - previous > 0) {
    return false;
  }

  return true;
}

/**
 * getOverlayFillColor - returns the appropriate color based on the current fall status string and hex color in ALERT_OVERLAY_PATTERNS
 * @access public
 *
 * @param componentId Current fall status: fall_detected, fall_confirmed, or fall_end
 */
export function getOverlayFillColor(componentId) {
  try {
    if (componentId !== 'fall_end') {
      const overlay = ALERT_OVERLAY_PATTERNS.find(overlay => overlay.componentId === componentId);
      return overlay.color1;
    }
  } catch (e) {
    log.warn(e);
  }
  return 'none';
}

export function getSVGPattern(componentId, vendorZoneId) {
  const overlay = ALERT_OVERLAY_PATTERNS.find(overlay => overlay.componentId === componentId);

  return `<pattern id="${componentId}_pattern_${vendorZoneId}" patternUnits="userSpaceOnUse" width="250" height="250">
    <animateTransform
      attributeType="xml"
      attributeName="patternTransform"
      type="translate"
      dur="1s"
      from="0"
      to="250"
      repeatCount="indefinite"
    />
    <rect width="250" height="250" fill="${overlay.color2}" />
    <path
      d="M -149 150 l 255 -255 M -4 255 l 255 -255 M 141 360 l 255 -255"
      style="stroke:${overlay.color1}; stroke-width:160"
    />
  </pattern>`;
}
