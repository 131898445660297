import React, { Fragment } from 'react';
import { Button as RSButton, Row, Col } from 'reactstrap';
import svgson from 'svgson';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import cx from 'classnames';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { LinearProgress } from 'react-admin';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import UpIcon from '@material-ui/icons/AddCircleOutline';
import DownIcon from '@material-ui/icons/RemoveCircleOutline';
import RemoveIcon from '@material-ui/icons/Delete';
import List from '@material-ui/core/List';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import ListItem from '@material-ui/core/ListItem';
import FormControl from '@material-ui/core/FormControl';

import PIMap from '../../components/PIMap/PIMap';
import { cursorPositionToSVGPoint, pixelPointToMeters, meterPointToPixels } from '../../utils/map';
import { toast } from '../../components/Toast/Toast';
import { generateShortId, getMin } from '../../utils/js';
import Sidebar from '../../components/Sidebar/Sidebar';
import GlobalPortal from '../../components/GlobalPortal/GlobalPortal';
import FormFeedback from '../../components/FormFeedback/FormFeedback';
import { defaultRoomVertices, DEFAULT_ANCHOR_RADIUS, DEFAULT_ANCHOR_STROKE } from './index.js';

const GET_ZONE = gql`
  query getZone($zone_id: ID!) {
    zones(id: $zone_id) {
      data {
        ... on Zone {
          id
          name
          vendor_zone_id
          meter_to_pixel_scaler_x
          meter_to_pixel_scaler_y
          origin_offset_x
          origin_offset_y

          venue {
            id
            vendor_map_id
          }

          rooms {
            id
            name
            vertices {
              x
              y
            }
          }
        }
      }
    }
  }
`;

const styles = theme => ({
  formControl: {
    width: '200px',
  },
  actionButton: {
    marginLeft: '1rem',
    backgroundColor: '#FFB838',
    color: 'black',
    '&:hover': {
      backgroundColor: '#ffa601',
    },
  },
  removeButton: {
    minWidth: '36px',
    width: '36px',
  },
});

class VerticeInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMap: false,
      vendorMapId: null,
      vendorZoneId: null,
      currentZone: null,
      polygonPointsInMeters: [],
      existingRoomPointsInMeters: [],
      formData: [], // track form data separately to allow string input for decimal points
      dragStartPosition: [],
      draggingPolygon: false,
      draggingPoint: false,
      showAnchorMenu: false, // updated to location object with x, y when displayed
      selectedAnchor: false, // updated to polygonPoints index when true
      selectedBoundary: false, // updated to polygonPoints index when true
      customLayer: null, // svg layer to draw on, returned from PIMap component
      changesMade: false,
      originalPoints: null,
      spinnerActive: false,
      piMapInitialized: false,
      loading: true,
      hoveredRoom: false,
      zoomLevel: 1, // pointinside map zoom level
    };

    // OPTIMIZE: necessary timeout here because react-admin triggers multiple
    // re-renders after selecting a zone without a callback method to know when it's done.
    // because of this, it is possible for the user to click 'edit vertices' while
    // react-admin is still loading, causing duplicate rendering of the same component
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 750);
  }

  componentWillUnmount() {
    const portalElement = document.querySelector('.app-layout--portal-root');

    if (portalElement) {
      portalElement.classList.remove('show');
    }
  }

  initializeMap = zoneId => {
    const {
      client,
      formType,
      input: { value: formValue },
    } = this.props;

    if (formType === 'create') {
      // creating new room
      client
        .query({ query: GET_ZONE, fetchPolicy: 'network-only', variables: { zone_id: zoneId } })
        .then(queryResult => {
          const {
            data: {
              zones: { data },
            },
          } = queryResult;
          const firstZone = data[0];
          const vendorZoneId = firstZone.vendor_zone_id;
          const polygonPointsInMeters =
            formValue && formValue.length
              ? formValue.map(point => ({
                  ...point,
                  id: generateShortId(),
                }))
              : defaultRoomVertices.map(point => ({
                  ...point,
                  id: generateShortId(),
                }));

          this.setState({
            vendorMapId: firstZone.venue.vendor_map_id,
            vendorZoneId,
            currentZone: firstZone,
            showMap: true,
            polygonPointsInMeters,
            existingRoomPointsInMeters: firstZone.rooms,
            formData: polygonPointsInMeters,
          });
        })
        .catch(({ message: queryError }) => {
          toast.error(queryError || 'Error getting zone data');
        });
    } else {
      // editing existing room
      const {
        input: { value: formValue },
        record: { id, zone },
      } = this.props;
      const vendorZoneId = zone.vendor_zone_id;
      const polygonPointsInMeters = formValue.map(point => ({
        x: parseFloat(point.x.toFixed(3)),
        y: parseFloat(point.y.toFixed(3)),
        id: generateShortId(),
      }));

      this.setState({
        vendorMapId: zone.venue.vendor_map_id,
        vendorZoneId,
        currentZone: zone,
        showMap: true,
        polygonPointsInMeters,
        existingRoomPointsInMeters: zone.rooms.filter(room => room.id !== id),
        formData: polygonPointsInMeters,
      });
    }
  };

  createPolygonFromMeterPoints = (meterPoints, options) => {
    // return SVG JSON to use for HTML injection into custom SVG layer
    const { selectedAnchor, selectedBoundary, zoomLevel = 1 } = Object.assign(this.state, options);
    const { currentZone, existingRoomPointsInMeters } = this.state;
    const pixelPoints = meterPoints.map(point => meterPointToPixels(point, currentZone));
    const existingRoomPixelPoints = existingRoomPointsInMeters.map(room => ({
      ...room,
      vertices: room.vertices.map(point => meterPointToPixels(point, currentZone)),
    }));

    const anchorRadius = getMin(DEFAULT_ANCHOR_RADIUS / zoomLevel, DEFAULT_ANCHOR_RADIUS);
    const anchorStroke = getMin(DEFAULT_ANCHOR_STROKE / zoomLevel, DEFAULT_ANCHOR_STROKE);
    const circle = {
      name: 'circle',
      type: 'element',
      attributes: {
        r: anchorRadius,
        stroke: 'black',
        'stroke-width': anchorStroke,
        fill: 'white',
      },
      children: [],
    };

    return {
      name: 'g',
      type: 'element',
      attributes: {
        id: 'injected',
      },
      children: [
        // existing room boundaries
        existingRoomPixelPoints.map((room, index) => ({
          name: 'polygon',
          type: 'element',
          attributes: {
            id: 'existing-room-fill-area',
            value: room.id,
            points: room.vertices.map(vertice => `${vertice.x},${vertice.y}`).join(' '),
            class: 'existing-room',
            style: `stroke-width: ${anchorRadius / 2};`,
          },
          children: [],
        })),
        {
          name: 'polygon',
          type: 'element',
          attributes: {
            id: 'polygon-fill-area',
            points: pixelPoints.map(vertice => `${vertice.x},${vertice.y}`).join(' '),
            class: 'body',
          },
          children: [],
        },
        // current room boundaries
        pixelPoints.map((point, index) => ({
          name: 'line',
          type: 'element',
          attributes: {
            id: `polygon-boundary-${index}`,
            value: index,
            x1: point.x,
            y1: point.y,
            x2: pixelPoints[index + 1] ? pixelPoints[index + 1].x : pixelPoints[0].x,
            y2: pixelPoints[index + 1] ? pixelPoints[index + 1].y : pixelPoints[0].y,
            'stroke-width': anchorRadius,
            class: 'line',
            stroke: selectedBoundary === index ? 'blue' : 'red',
            'stroke-opacity': selectedBoundary === index ? 1 : 0,
          },
          children: [],
        })),
        // anchors
        pixelPoints.map((point, index) => ({
          ...circle,
          attributes: Object.assign(
            {
              ...circle.attributes,
              // always store index in the id so we know its position in the
              // 'points' string inside the SVG
              id: `polygon-anchor-${index}`,
              value: index,
              cx: parseFloat(point.x),
              cy: parseFloat(point.y),
              class: 'anchor',
            },
            selectedAnchor === index && { fill: 'blue' },
          ),
        })),
      ],
    };
  };

  renderNewPolygon = (meterPoints = this.state.polygonPointsInMeters, options = {}, done) => {
    const customLayer = options.customLayer || this.state.customLayer;
    const { setState } = options;

    if (customLayer) {
      customLayer.innerHTML = svgson.stringify(
        this.createPolygonFromMeterPoints(meterPoints, options),
        {
          selfClose: false,
        },
      );

      this.setState(
        {
          polygonPointsInMeters: meterPoints,
          formData: meterPoints,
          ...setState,
        },
        () => done && done(),
      );
    }
  };

  handlePIMapCallback = piMapEvent => {
    const { customLayer, svgElement, mapContainer, piMap } = piMapEvent;

    // an SVG point is used for mathematical purposes only to transform cursor position
    // to transformed (zoomed/panned) SVG coordinates
    const svgPoint = svgElement.createSVGPoint();

    this.setState({
      customLayer,
      originalPoints: this.state.polygonPointsInMeters,
      piMapInitialized: true,
    });

    document.addEventListener('mouseup', event => {
      // disable click + hold feature on the vertice field spinners
      this.toggleFieldSpinner(false);
    });

    mapContainer.addEventListener('mousemove', event => {
      const {
        target: { id },
        clientX,
        clientY,
      } = event;
      const {
        draggingPolygon,
        draggingPoint,
        changesMade,
        selectedAnchor,
        showAnchorMenu,
      } = this.state;

      if (draggingPolygon || draggingPoint) {
        // custom layer is being dragged, update SVG layer
        const location = cursorPositionToSVGPoint(clientX, clientY, svgElement, svgPoint);

        this.handleDrag(customLayer, location);

        if (!changesMade) {
          this.setState({
            changesMade: true,
          });
        }
      } else {
        if (id.startsWith('polygon-anchor-')) {
          // update selected anchor on anchor hover
          const index = parseInt(event.target.getAttribute('value'), 10);

          if (selectedAnchor !== index) {
            this.setSelectedAnchor(index);
          }
        } else if (!showAnchorMenu && selectedAnchor !== false) {
          this.setSelectedAnchor(false);
        }
      }
    });

    mapContainer.addEventListener('mouseup', event => {
      this.setSelectedAnchor(false);
      this.setState({
        draggingPolygon: false,
        draggingPoint: false,
      });
    });

    customLayer.addEventListener('mousemove', event => {
      const { clientX, clientY, target } = event;

      if (target.id.startsWith('existing-room-fill-area')) {
        const roomId = parseInt(target.getAttribute('value'), 10);

        this.setHoveredRoom(roomId, { x: clientX, y: clientY });
      } else if (this.state.hoveredRoom) {
        this.setHoveredRoom(false);
      }
    });

    customLayer.addEventListener('mouseleave', event => {
      this.setHoveredRoom(false);
    });

    customLayer.addEventListener('mousedown', event => {
      if (event.buttons !== 1) {
        // we only care about left clicks
        return;
      }
      piMap.killTouchSessions(); // remove point inside map's 'panning' feature

      const {
        target: { id },
      } = event;
      const cursorPosition = cursorPositionToSVGPoint(
        event.clientX,
        event.clientY,
        svgElement,
        svgPoint,
      );

      if (id === 'polygon-fill-area') {
        const { polygonPointsInMeters, currentZone } = this.state;
        const pixelPoints = polygonPointsInMeters.map(point => ({
          ...point,
          ...meterPointToPixels(point, currentZone),
        }));
        const dragStartPosition = pixelPoints.map(point => ({
          ...point,
          x: cursorPosition.x - point.x,
          y: cursorPosition.y - point.y,
        }));

        this.setState({
          dragStartPosition,
          draggingPolygon: true,
        });
      } else if (id.startsWith('polygon-boundary-')) {
        this.addAnchor(parseInt(event.target.getAttribute('value'), 10));
      } else if (id.startsWith('polygon-anchor-')) {
        const index = parseInt(event.target.getAttribute('value'), 10);
        const { polygonPointsInMeters, currentZone } = this.state;
        const pixelPoints = polygonPointsInMeters.map(point => ({
          ...point,
          ...meterPointToPixels(point, currentZone),
        }));
        const dragStartPosition = pixelPoints.map(point => ({
          ...point,
          x: cursorPosition.x - point.x,
          y: cursorPosition.y - point.y,
        }));

        this.setSelectedAnchor(index);
        this.setState({
          dragStartPosition,
          draggingPoint: {
            index,
          },
        });
      }
    });

    customLayer.addEventListener('contextmenu', event => {
      if (event.target.id.startsWith('polygon-anchor-')) {
        event.preventDefault();

        const index = parseInt(event.target.getAttribute('value'), 10);

        this.setSelectedAnchor(index);
        this.setShowAnchorMenu(index, {
          x: event.clientX,
          y: event.clientY,
        });
      }
    });

    piMap.addListener('positionChange', newPosition => {
      const [newZoom] = newPosition;

      this.renderNewPolygon(undefined, { zoomLevel: parseInt(newZoom, 10) });
    });
  };

  handleDrag = (element, cursorLocation) => {
    const { draggingPolygon, draggingPoint, dragStartPosition, currentZone } = this.state;

    if (!draggingPolygon && !draggingPoint) {
      return;
    }

    const { x, y } = cursorLocation;

    if (draggingPolygon) {
      // reposition whole layer
      const newPoints = dragStartPosition.map(position => ({
        ...position,
        ...pixelPointToMeters({ x: x - position.x, y: y - position.y }, currentZone),
      }));

      this.renderNewPolygon(newPoints, { setState: { changesMade: true } });
    } else if (draggingPoint) {
      // reposition single point
      const {
        polygonPointsInMeters,
        draggingPoint: { index: draggingIndex },
      } = this.state;
      const newPoints = dragStartPosition.map((position, index) =>
        index === draggingIndex
          ? {
              // follow the cursor because this is the selected vertice
              ...position,
              ...pixelPointToMeters({ x: x - position.x, y: y - position.y }, currentZone),
            }
          : {
              // use the vertice's current position
              ...position,
              x: polygonPointsInMeters[index].x,
              y: polygonPointsInMeters[index].y,
            },
      );

      this.renderNewPolygon(newPoints, { setState: { changesMade: true } });
    }
  };

  setHoveredRoom = (value, labelLocation = {}) => {
    let currentRoom = false;

    if (value !== false) {
      const {
        currentZone: { rooms },
      } = this.state;
      const labelElement = document.querySelector(
        '.vertice-input-content .map-content .room-label',
      );

      currentRoom = rooms && rooms.length && rooms.find(room => parseInt(room.id, 10) === value);
      labelElement.style.left = `${labelLocation.x + 15}px`;
      labelElement.style.top = `${labelLocation.y + 15}px`;
    }

    this.setState({
      hoveredRoom: currentRoom,
    });
  };

  addAnchor = index => {
    const { polygonPointsInMeters } = this.state;
    const newPolygonPoints = Array.prototype.concat([], polygonPointsInMeters);
    const getMidpoint = (x1, y1, x2, y2) => {
      return [(x1 + x2) / 2, (y1 + y2) / 2];
    };
    const midpoint = getMidpoint(
      polygonPointsInMeters[index].x,
      polygonPointsInMeters[index].y,
      polygonPointsInMeters[index + 1]
        ? polygonPointsInMeters[index + 1].x
        : polygonPointsInMeters[0].x,
      polygonPointsInMeters[index + 1]
        ? polygonPointsInMeters[index + 1].y
        : polygonPointsInMeters[0].y,
    );

    newPolygonPoints.splice(index + 1, 0, {
      x: midpoint[0], // calculate midpoint between known points
      y: midpoint[1],
      id: generateShortId(),
    });

    this.renderNewPolygon(newPolygonPoints, { setState: { changesMade: true } });
  };

  deleteAnchor = (anchorIndex = this.state.selectedAnchor) => {
    const { polygonPointsInMeters } = this.state;

    if (polygonPointsInMeters && polygonPointsInMeters.length > 3) {
      const newPolygonPoints = Array.prototype.concat([], polygonPointsInMeters);
      const spliceIndex = anchorIndex;

      newPolygonPoints.splice(spliceIndex, 1);

      this.setState(
        {
          polygonPointsInMeters: newPolygonPoints,
          showAnchorMenu: false,
          selectedAnchor: false,
          changesMade: true,
        },
        () => this.renderNewPolygon(newPolygonPoints),
      );
    } else {
      toast.error('Error deleting: Three vertices are required');
      this.setState(
        {
          showAnchorMenu: false,
          selectedAnchor: false,
        },
        () => this.renderNewPolygon(),
      );
    }
  };

  setShowMap = value => {
    const { selectedZoneId } = this.props;

    if (value) {
      this.initializeMap(selectedZoneId);
    } else {
      this.setState({
        showMap: value,
      });
    }
  };

  setShowAnchorMenu = (index, location) => {
    if (index !== false) {
      // opening menu, move to cursor and update state
      const menuElement = document.querySelector('.map-wrapper .anchor-menu-wrapper .anchor-menu');

      menuElement.style.left = `${location.x}px`;
      menuElement.style.top = `${location.y}px`;
    }

    this.renderNewPolygon(undefined, {
      selectedAnchor: index,
      setState: { showAnchorMenu: location },
    });
  };

  submitChanges = () => {
    const {
      props: {
        input: { onChange },
      },
      state: { polygonPointsInMeters },
    } = this;

    this.setState({
      originalPoints: polygonPointsInMeters,
      changesMade: false,
    });

    onChange(polygonPointsInMeters);
  };

  revertChanges = () => {
    const { originalPoints } = this.state;

    this.renderNewPolygon(originalPoints, { setState: { changesMade: false } });
  };

  setSelectedAnchor = value => {
    this.renderNewPolygon(undefined, { selectedAnchor: value });
  };

  setSelectedBoundary = value => {
    this.renderNewPolygon(undefined, { selectedBoundary: value });
  };

  handleFormChange = (event, value, options = {}) => {
    const {
      target: { id, name, value: eventValue },
    } = event;
    let newValue = value !== undefined ? value : eventValue;

    // exit states
    if (!['x', 'y'].includes(name)) {
      return;
    }

    const { polygonPointsInMeters, formData } = this.state;
    const index = parseInt(id, 10);
    const characterWhitelist = ['-'];

    if (isNaN(newValue) && !characterWhitelist.some(char => char === newValue)) {
      newValue = 0;
    }

    const newPolygonPoints = polygonPointsInMeters.map((point, idx) =>
      idx === index
        ? { ...point, [name]: parseFloat(newValue === '-' || !newValue ? 0 : newValue) }
        : point,
    );
    const newFormData = formData.map((point, idx) =>
      idx === index ? { ...point, [name]: newValue } : point,
    );

    this.renderNewPolygon(newPolygonPoints, {
      // overriding default formData here to maintain string values
      setState: { changesMade: true, formData: newFormData },
    });
  };

  handleSpinnerClick = (event, value) => {
    const {
      target: { id, name },
    } = event;
    const { polygonPointsInMeters } = this.state;
    const index = parseInt(id, 10);
    const newPolygonPoints = polygonPointsInMeters.map((point, idx) =>
      idx === index ? { ...point, [name]: parseFloat((point[name] += value).toFixed(3)) } : point,
    );

    this.renderNewPolygon(newPolygonPoints, { setState: { changesMade: true } });
  };

  toggleFieldSpinner = (name, index, changeValue) => {
    if (name !== false && !this.state.spinnerActive) {
      this.setState({
        spinnerActive: true,
      });

      this.spinnerTimer = setTimeout(() => {
        if (this.state.spinnerActive) {
          this.spinnerInterval = setInterval(() => {
            const { polygonPointsInMeters } = this.state;
            const newPolygonPoints = polygonPointsInMeters.map((point, idx) =>
              idx === index
                ? { ...point, [name]: parseFloat((point[name] += changeValue).toFixed(3)) }
                : point,
            );

            this.renderNewPolygon(newPolygonPoints, { setState: { changesMade: true } });
          }, 25);
        } else {
          clearTimeout(this.spinnerTimer);
          clearInterval(this.spinnerInterval);
        }
      }, 400);
    } else {
      clearTimeout(this.spinnerTimer);
      clearInterval(this.spinnerInterval);
      this.setState({
        spinnerActive: false,
      });
    }
  };

  render() {
    const {
      state: {
        currentZone,
        showMap,
        vendorMapId,
        vendorZoneId,
        showAnchorMenu,
        changesMade,
        selectedAnchor,
        piMapInitialized,
        polygonPointsInMeters,
        formData,
        loading,
        hoveredRoom,
      },
      props: {
        classes,
        formType,
        input: { value: formValue },
        meta: { submitFailed, error: validationError },
      },
    } = this;

    return showMap ? (
      <GlobalPortal noOverlay>
        <div className="vertice-input-content">
          <Sidebar collapsable noPadding>
            <div className="vertices-list">
              <List>
                <TransitionGroup>
                  {polygonPointsInMeters && polygonPointsInMeters.length ? (
                    polygonPointsInMeters.map((point, index) => (
                      <CSSTransition
                        key={point.id}
                        timeout={300}
                        classNames={piMapInitialized ? 'vertice-item' : ''}
                      >
                        <div className="vertice-item">
                          <ListItem
                            id={index}
                            className={cx({
                              selected: selectedAnchor === index,
                            })}
                            onMouseEnter={() => this.setSelectedAnchor(index)}
                            onMouseLeave={() => this.setSelectedAnchor(false)}
                          >
                            <Row>
                              <Col className="text-right">
                                <FormControl className={classes.formControl}>
                                  <InputLabel htmlFor={index.toString()}>X</InputLabel>
                                  <Input
                                    variant="outlined"
                                    name="x"
                                    id={index.toString()}
                                    placeholder="X"
                                    onChange={this.handleFormChange}
                                    value={formData[index] && formData[index].x}
                                    endAdornment={
                                      <div className="up-down-wrapper text-nowrap d-flex">
                                        <span className="align-self-end mr-2 mb-2 unit-type">
                                          meters
                                        </span>
                                        <DownIcon
                                          className="hover-pointer"
                                          onMouseDown={() =>
                                            this.toggleFieldSpinner(
                                              'x',
                                              index,
                                              -currentZone.meter_to_pixel_scaler_x,
                                            )
                                          }
                                          onMouseUp={() => this.toggleFieldSpinner(false)}
                                          onClick={() => {
                                            this.toggleFieldSpinner(false);
                                            this.handleSpinnerClick(
                                              { target: { id: index, name: 'x' } },
                                              -currentZone.meter_to_pixel_scaler_x,
                                            );
                                          }}
                                        />
                                        <UpIcon
                                          className="hover-pointer"
                                          onMouseDown={() =>
                                            this.toggleFieldSpinner(
                                              'x',
                                              index,
                                              currentZone.meter_to_pixel_scaler_x,
                                            )
                                          }
                                          onMouseUp={() => this.toggleFieldSpinner(false)}
                                          onClick={() => {
                                            this.toggleFieldSpinner(false);
                                            this.handleSpinnerClick(
                                              { target: { id: index, name: 'x' } },
                                              currentZone.meter_to_pixel_scaler_x,
                                            );
                                          }}
                                        />
                                      </div>
                                    }
                                  />
                                </FormControl>
                                <FormControl className={classes.formControl}>
                                  <InputLabel htmlFor={index.toString()}>Y</InputLabel>
                                  <Input
                                    name="y"
                                    id={index.toString()}
                                    placeholder="Y"
                                    onChange={this.handleFormChange}
                                    value={formData[index] && formData[index].y}
                                    endAdornment={
                                      <div className="up-down-wrapper text-nowrap d-flex">
                                        <span className="align-self-end mr-2 mb-2 unit-type">
                                          meters
                                        </span>
                                        <DownIcon
                                          className="hover-pointer"
                                          onMouseDown={() =>
                                            this.toggleFieldSpinner(
                                              'y',
                                              index,
                                              -currentZone.meter_to_pixel_scaler_y,
                                            )
                                          }
                                          onMouseUp={() => this.toggleFieldSpinner(false)}
                                          onClick={() => {
                                            this.toggleFieldSpinner(false);
                                            this.handleSpinnerClick(
                                              { target: { id: index, name: 'y' } },
                                              -currentZone.meter_to_pixel_scaler_y,
                                            );
                                          }}
                                        />
                                        <UpIcon
                                          className="hover-pointer"
                                          onMouseDown={() =>
                                            this.toggleFieldSpinner(
                                              'y',
                                              index,
                                              currentZone.meter_to_pixel_scaler_y,
                                            )
                                          }
                                          onMouseUp={() => this.toggleFieldSpinner(false)}
                                          onClick={() => {
                                            this.toggleFieldSpinner(false);
                                            this.handleSpinnerClick(
                                              { target: { id: index, name: 'y' } },
                                              currentZone.meter_to_pixel_scaler_y,
                                            );
                                          }}
                                        />
                                      </div>
                                    }
                                  />
                                </FormControl>
                              </Col>
                              <Col xs={2}>
                                <Button
                                  className={classes.removeButton}
                                  onClick={() => this.deleteAnchor(index)}
                                >
                                  <RemoveIcon />
                                </Button>
                              </Col>
                            </Row>
                          </ListItem>
                          <Row className="text-center input-divider">
                            <Col className="d-flex justify-content-center">
                              <div className="divider" />
                              <Button
                                id={index}
                                onClick={() => this.addAnchor(index)}
                                onMouseEnter={() => this.setSelectedBoundary(index)}
                                onMouseLeave={() => this.setSelectedBoundary(false)}
                              >
                                Insert new
                              </Button>
                              <div className="divider" />
                            </Col>
                          </Row>
                        </div>
                      </CSSTransition>
                    ))
                  ) : (
                    <div>[No polygon points]</div>
                  )}
                </TransitionGroup>
              </List>
            </div>
          </Sidebar>
          <div className="map-content">
            <div className="actions mb-3">
              <RSButton className="link" onClick={() => this.setShowMap(false)}>
                <i className="fas fa-chevron-left fa-sm" /> Back to {formType} room
              </RSButton>
              <div className="zone-name text-center">{currentZone && currentZone.name}</div>
              <Button
                className={cx(classes.actionButton, 'shadow-none')}
                variant="contained"
                color="primary"
                onClick={this.revertChanges}
                disabled={!changesMade}
              >
                Revert
              </Button>
              <Button
                className={cx(classes.actionButton, 'shadow-none')}
                variant="contained"
                color="primary"
                onClick={this.submitChanges}
                disabled={!changesMade && !!formValue.length}
              >
                Save
              </Button>
            </div>
            <div className="map-wrapper">
              <div className="map">
                <PIMap
                  vendorMapId={vendorMapId}
                  vendorZoneId={vendorZoneId}
                  injectChild={svgson.stringify(
                    this.createPolygonFromMeterPoints(polygonPointsInMeters),
                    {
                      selfClose: false,
                    },
                  )}
                  overrides={{ piMap: { zoneChangerControls: false } }}
                  onInitialized={this.handlePIMapCallback}
                />
              </div>
              <div className="anchor-menu-wrapper">
                {showAnchorMenu && (
                  <div
                    className="anchor-menu-overlay"
                    onClick={() => this.setShowAnchorMenu(false)}
                  />
                )}
                <div className={cx('anchor-menu', { show: !!showAnchorMenu })}>
                  <div className="item" onClick={() => this.deleteAnchor()}>
                    Delete
                  </div>
                </div>
              </div>
            </div>
            <div className={cx('room-label', { show: !!hoveredRoom })}>
              {hoveredRoom && hoveredRoom.name}
            </div>
          </div>
        </div>
      </GlobalPortal>
    ) : (
      <Fragment>
        <GlobalPortal show={false} />
        {loading ? (
          <Fragment>
            <InputLabel shrink>Vertices</InputLabel>
            <LinearProgress />
          </Fragment>
        ) : (
          <Fragment>
            <Button
              variant="contained"
              onClick={() => this.setShowMap(true)}
              className="shadow-none mt-3"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="mr-2"
              >
                <path fill="none" d="M0 0h24v24H0V0z" />
                <g xmlns="http://www.w3.org/2000/svg" fill="#010101">
                  <line x1="7" y1="14" x2="11" y2="6" strokeWidth="1.5" stroke="black" />
                  <line x1="11" y1="6" x2="17" y2="18" strokeWidth="1.5" stroke="black" />
                  <line x1="17" y1="18" x2="7" y2="14" strokeWidth="1.5" stroke="black" />

                  <circle cx="7" cy="14" r="3" />
                  <circle cx="11" cy="6" r="3" />
                  <circle cx="17" cy="18" r="3" />
                </g>
              </svg>{' '}
              Edit vertices
            </Button>
            <FormFeedback visible={submitFailed && validationError} message={validationError} />
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default withStyles(styles)(withApollo(VerticeInput));
