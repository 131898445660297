import gql from 'graphql-tag';

export default function venueQueries(raFetchType, params) {
  switch (raFetchType) {
    case 'CREATE':
      return {
        query: gql`
          mutation addVenue(
            $name: String!
            $default_zone_id: ID
            $building: String
            $floor: String
            $description: String
            $vendor_map_id: String
          ) {
            addVenue(
              name: $name
              default_zone_id: $default_zone_id
              building: $building
              floor: $floor
              description: $description
              vendor_map_id: $vendor_map_id
            ) {
              id
            }
          }
        `,
        variables: {
          ...params.data,
          default_zone_id: params.data.default_zone && params.data.default_zone.id,
        },
        parseResponse: response => ({ data: response.data.addVenue }),
      };
    case 'UPDATE':
      return {
        query: gql`
          mutation updateVenue(
            $id: ID!
            $name: String!
            $default_zone_id: ID
            $building: String
            $floor: String
            $description: String
            $vendor_map_id: String
          ) {
            updateVenue(
              id: $id
              name: $name
              default_zone_id: $default_zone_id
              building: $building
              floor: $floor
              description: $description
              vendor_map_id: $vendor_map_id
            ) {
              id
            }
          }
        `,
        variables: {
          ...params.data,
          default_zone_id: params.data.default_zone && params.data.default_zone.id,
        },
        parseResponse: response => ({ data: response.data.updateVenue }),
      };
    case 'GET_ONE':
      return {
        query: gql`
          query getVenues($id: ID) {
            venues(id: $id) {
              total
              data {
                ... on Venue {
                  id
                  name
                  description
                  vendor_map_id
                  floor
                  building
                  organization {
                    id
                    name
                  }
                  default_zone {
                    id
                    name
                  }
                }
              }
            }
          }
        `,
        variables: params,
        parseResponse: response => ({
          data:
            response.data &&
            response.data.venues &&
            response.data.venues.data &&
            response.data.venues.data.length &&
            response.data.venues.data[0],
        }),
      };
    case 'GET_MANY':
    case 'GET_LIST':
      return {
        query: gql`
          query getVenues($sort: Sort, $pagination: Pagination) {
            venues(sort: $sort, pagination: $pagination) {
              total
              data {
                ... on Venue {
                  id
                  name
                  description
                  vendor_map_id
                  floor
                  building
                  organization {
                    id
                    name
                  }
                  default_zone {
                    id
                    name
                  }
                }
              }
            }
          }
        `,
        variables: params,
        parseResponse: response => ({
          data: response.data.venues.data,
          total: response.data.venues.total,
        }),
      };
    case 'DELETE':
      return {
        query: gql`
          mutation deleteVenues($id: [ID!]!) {
            deleteVenues(id: $id) {
              id
            }
          }
        `,
        variables: params,
        parseResponse: response => ({ data: response.data.deleteVenues[0] }),
      };
    case 'DELETE_MANY':
      const variables = {
        id: params.ids,
      };

      return {
        query: gql`
          mutation deleteVenues($id: [ID]!) {
            deleteVenues(id: $id) {
              id
            }
          }
        `,
        variables,
        parseResponse: response => ({ data: response.data.deleteVenues }),
      };
    default:
      throw new Error('Invalid fetch type');
  }
}
