import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  palette: {
    primary: {
      light: '#eceae8',
      main: '#80878f',
      dark: '#3b4d55',
      contrastText: '#fff',
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(
      ',',
    ),
    fontSize: '14px',
    body1: {
      fontSize: '14px',
      color: '#3b4d55',
    },
  },
  button: {
    text: {
      primary: '#337ab7',
    },
  },
  // .MuiTypography-body1-80
  overrides: {
    MuiCard: {
      root: {
        overflow: 'unset',
      },
    },
  },
});
