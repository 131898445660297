import React, { Fragment } from 'react';
import { withApollo } from 'react-apollo';
import { PiService, PiMap } from 'pointinside';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Button,
  ButtonToolbar,
  ButtonDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Formik } from 'formik';
import cx from 'classnames';
import moment from 'moment-timezone';
import gql from 'graphql-tag';
import axios from 'axios';

import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';

import Loader from '../../../../components/Loader/Loader';
import {
  tryFor,
  mmToPixels,
  getCurrentTimeFromTimezone,
  formatTimestamp,
} from '../../../../utils/js';
import { toast } from '../../../../components/Toast/Toast';
import Slider from '../../../../components/Slider/Slider';
import { timezoneOptions } from '../../../../utils/js';
import ResizeDetector from '../../../../components/ResizeDetector/ResizeDetector';
import DatePicker from '../../../../components/DatePicker/DatePicker';
import FormFeedback from '../../../../components/FormFeedback/FormFeedback';
import {
  GET_INITIAL_PLAYBACK_FORM_DATA,
  GET_INITIAL_LOCAL_DATA,
  playbackFormStyles,
  playbackFormSchema,
  playbackExportTypes,
  getPlaybackLengthFromDuration,
  durationOptions,
} from '../../../../utils/reports';
import { withAppContext } from '../../../../context/AppContext';

const GET_PLAYBACK = gql`
  query playback(
    $asset_id: [ID]!
    $zone_id: ID!
    $start_time: AWSDateTime!
    $duration_time_seconds: Int!
    $playback_length_seconds: Int!
    $raw: Boolean
    $export_file_type: EventStreamExportType
    $export_fields: [String]
  ) {
    event_stream_items(
      asset_id: $asset_id
      zone_id: $zone_id
      start_time: $start_time
      duration_time_seconds: $duration_time_seconds
      playback_length_seconds: $playback_length_seconds
      raw: $raw
      export_file_type: $export_file_type
      export_fields: $export_fields
    )
  }
`;

const speeds = [
  {
    id: 1,
    label: '1x',
    value: 1,
  },
  {
    id: 2,
    label: '2x',
    value: 2,
  },
  {
    id: 3,
    label: '3x',
    value: 3,
  },
  {
    id: 4,
    label: '4x',
    value: 4,
  },
];

const defaultExportTypes = [
  'asset_name',
  'room_name',
  'x',
  'y',
  'device_event_time',
  'quality',
  'sleep_mode',
];

class Playback extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      timezoneOpen: false,
      speedOpen: false,
      loading: true,
      initialized: false,
      mapElement: null,
      piService: null,
      piMap: null,
      assets: null,
      zones: null,
      selectedAssets: [],
      selectedZone: null,
      selectedStartDate: moment() // default to 12:00 AM of current day
        .startOf('day'),
      selectedTimezone: 'Eastern',
      selectedStartDateWithTimezone: null,
      selectedPresetDuration: 86400, // default to 24 hours (seconds)
      selectedCustomDuration: 24,
      selectedCustomDurationType: 'hours',
      showMap: false,
      useCustomDuration: false,
      frames: null,
      playing: false,
      playPosition: 0,
      frameRealTime: 0, // current frame's time relative to the selected period
      speed: 1,
      exportMenuOpen: false,
      exportModalOpen: false,
      exportType: null,
      exportError: false,
      exportDownloadUrl: false,
      selectedExportTypes: defaultExportTypes,
      exporting: false, // export loading state
      hiddenMarkerLabels: [], // holds tag serial numbers of hidden marker labels
    };
  }

  componentDidMount() {
    this.initialize();
  }

  initialize = () => {
    const {
      client,
      appContext: {
        config: { piApiKey, piApiUrl },
      },
    } = this.props;

    client
      .query({ query: GET_INITIAL_LOCAL_DATA })
      .then(localData => {
        const {
          data: {
            userData: { email },
          },
        } = localData;

        client
          .query({
            query: GET_INITIAL_PLAYBACK_FORM_DATA,
            variables: { site_user_email: email },
            fetchPolicy: 'network-only',
          })
          .then(remoteData => {
            const {
              data: {
                assets: { data: assets },
                zones: { data: zones },
                site_users,
              },
            } = remoteData;
            const timezone =
              site_users && site_users.data && site_users.data[0]
                ? site_users.data[0].timezone.replace('US/', '')
                : 'Mountain';

            tryFor(() => document.getElementById('pi-map'), 5000)
              .then(element => {
                const piService = new PiService({
                  apiKey: piApiKey,
                  apiUrl: piApiUrl,
                  appName: 'repp health',
                  appVersion: '1.0',
                });

                const piMap = new PiMap({
                  element: element,
                  zoneChangerControls: 'top-right',
                });

                this.setState({
                  initialized: true,
                  loading: false,
                  mapElement: element,
                  piService,
                  piMap,
                  assets,
                  zones,
                  userTimezone: timezone,
                  selectedTimezone: timezone,
                });
              })
              .catch(error => {
                toast.error('Could not find map element');

                this.setState({
                  loading: false,
                });
              });
          });
      })
      .catch(assetsAndZonesError => {
        toast.error('Error getting assets and zones');
      });
  };

  toggleTimezoneDropdown = () => {
    this.setState({
      timezoneOpen: !this.state.timezoneOpen,
    });
  };

  toggleSpeedDropdown = () => {
    this.setState({
      speedOpen: !this.state.speedOpen,
    });
  };

  handlePlaybackDataError = message => {
    this.setState({
      loading: false,
    });

    toast.error(message || 'Error getting playback data');
  };

  getPlaybackData = (values, done) => {
    // provides a callback (done) after querying the API for playback data
    const { client } = this.props;
    const { assets, zones, useCustomDuration, selectedAssets } = this.state;

    if (!assets || !zones) {
      toast.error('Cannot submit without assets and/or zones');
      return false;
    }

    const {
      zoneName,
      startDate,
      timezone,
      presetDuration,
      customDuration,
      customDurationType,
      fileType,
      exporting,
      exportFields,
      isRaw,
    } = values;
    const zone = zones.find(zone => zone.name === zoneName);
    const timezoneOption = timezoneOptions.find(tz => tz.name === timezone);

    if (!selectedAssets || !selectedAssets.length || !zone) {
      toast.error('Invalid zone or asset selection');
    }

    this.setState({
      loading: true,
    });

    // extract the raw numbers from the time picker so current timezone data is stripped
    const check = moment(startDate);
    const year = check.format('YYYY');
    const month = parseInt(check.format('M', 10) - 1); // months are zero based
    const day = check.format('D');
    const hour = check.format('HH');
    const minute = check.format('mm');

    // use the raw numbers to construct a new time using the provided timezone
    const startDateWithTimezone = moment
      .tz([year, month, day, hour, minute], timezoneOption.id)
      .format();

    let playbackLength;
    let duration;

    if (useCustomDuration) {
      // custom duration selected, calculate playback length
      const customDurationNumber = parseFloat(customDuration);
      const durationMinutes =
        customDurationType === 'minutes' ? customDurationNumber : customDurationNumber * 60;

      // convert to seconds for query
      duration =
        customDurationType === 'minutes'
          ? customDurationNumber * 60
          : customDurationNumber * 60 * 60;

      // calculate playback length using custom value from user
      playbackLength = getPlaybackLengthFromDuration(durationMinutes);
    } else {
      // pre-defined duration selected, use hardcoded playback length
      const presetDurationNumber = parseFloat(presetDuration);

      duration = presetDurationNumber;
      playbackLength = durationOptions.find(option => option.value === presetDurationNumber)
        .playbackLength;
    }

    const handlePlaybackDataError = (message, type) => {
      const retMessage = message || 'Error getting playback data';

      toast[type || 'error'](retMessage);
      this.setState({
        loading: false,
      });

      done({ error: retMessage });
    };

    const playbackLengthSeconds = parseInt(playbackLength, 10);
    const durationTimeSeconds = parseInt(duration, 10);

    client
      .query({
        query: GET_PLAYBACK,
        fetchPolicy: 'network-only',
        variables: {
          asset_id: selectedAssets.map(selected => selected.id),
          zone_id: zone.id,
          start_time: startDateWithTimezone,
          duration_time_seconds: durationTimeSeconds,
          playback_length_seconds: playbackLengthSeconds,
          raw: isRaw || false,
          export_file_type: fileType,
          export_fields: exportFields || defaultExportTypes,
        },
      })
      .then(queryResponse => {
        const {
          data: { event_stream_items: url },
        } = queryResponse;

        if (exporting) {
          // just return download URL
          done({ url });
        } else {
          // GET S3 URL, download and return response for playback
          axios
            .get(url)
            .then(playbackData => {
              if (
                !Object.prototype.hasOwnProperty.call(playbackData, 'data') ||
                !playbackData.data.length
              ) {
                handlePlaybackDataError('No event data', 'info');
              } else {
                done({
                  zone,
                  startDate,
                  playbackLengthSeconds,
                  durationTimeSeconds,
                  result: playbackData,
                });
              }
            })
            .catch(({ message: playbackDataError }) => {
              handlePlaybackDataError(playbackDataError);
            });
        }
      })
      .catch(({ message: playbackError }) => {
        handlePlaybackDataError(playbackError);
      });
  };

  handleSubmit = values => {
    const input = {
      ...values,
      fileType: 'JSON',
      exportFields: ['x', 'y', 'device_event_time', 'asset_id', 'asset_name', 'serial_number'],
    };

    this.getPlaybackData(input, result => {
      if (!result.error) {
        const { piService, piMap, selectedAssets } = this.state;
        const assetIds = selectedAssets.map(asset => asset.id);
        const {
          zone,
          startDate,
          playbackLengthSeconds,
          durationTimeSeconds,
          result: { data },
        } = result;

        // TODO: build frames array?
        const fps = 5;
        const frameCount = fps * playbackLengthSeconds;
        const realTimePerFrame = durationTimeSeconds / frameCount;
        const getFirstKnownEvent = id => data.find(row => row.asset_id === parseInt(id, 10));
        const unixStartTime = moment(startDate)
          .unix()
          .valueOf();
        const frames = [
          // initially define the first frame for each asset_id
          {
            frameRealTime: unixStartTime,
            locations: assetIds.map(id => getFirstKnownEvent(id)),
          },
        ];

        const getClosestKnownEvent = (assetId, frameRealTime) => {
          const filter = data.filter(row => row.asset_id === parseInt(assetId, 10));

          return (
            filter &&
            filter.length &&
            filter.reduce((prev, curr) => {
              const currentDiff = Math.abs(curr.device_event_time - frameRealTime);
              const previousDiff = Math.abs(prev.device_event_time - frameRealTime);

              return currentDiff < previousDiff ? curr : prev;
            })
          );
        };

        // build array of frames to return
        for (let i = 1; i < frameCount; i += 1) {
          // NOTE: skipping the first frame
          const frameRealTime = i * realTimePerFrame + unixStartTime;
          const frame = {
            frameRealTime,
            locations: assetIds.map(id => getClosestKnownEvent(id, frameRealTime)),
          };

          frames.push(frame);
        }

        piService.getVenueById({ venueId: zone.venue.vendor_map_id }).then(venue => {
          piMap.setVenue({ venue }).then(() => {
            this.setState(
              {
                showMap: true,
                frames,
                selectedZone: zone,
                selectedStartDate: moment(startDate),
                playing: false,
                playPosition: 0,
                frameRealTime: moment(startDate)
                  .unix()
                  .valueOf(),
                loading: false,
              },
              () => {
                window.dispatchEvent(new Event('resize'));
                this.updateMap(0);
              },
            );
          });
        });
      }
    });
  };

  handleExport = options => {
    const {
      selectedExportTypes,
      selectedZone,
      selectedStartDate,
      selectedTimezone,
      selectedPresetDuration,
      selectedCustomDuration,
      selectedCustomDurationType,
      exportType,
    } = this.state;
    const playbackParams = {
      zoneName: selectedZone.name,
      startDate: selectedStartDate,
      timezone: selectedTimezone,
      presetDuration: selectedPresetDuration,
      customDuration: selectedCustomDuration,
      customDurationType: selectedCustomDurationType,
      exporting: true,
      exportFields: selectedExportTypes,
      fileType: exportType,
      isRaw: true,
    };

    if (!selectedExportTypes || !selectedExportTypes.length) {
      this.setState({
        exportError: 'Please select at least one field',
      });

      return;
    }

    this.setState({
      exporting: true,
    });

    this.getPlaybackData(playbackParams, result => {
      const { error, url } = result;

      if (!error) {
        this.setState({
          exportError: false,
          loading: false,
          exportDownloadUrl: url,
          exporting: false,
        });
      } else {
        const { message } = error;

        this.setState({
          loading: false,
          exportError: message,
          exportDownloadUrl: false,
          exporting: false,
        });
      }
    });
  };

  hideMap = () => {
    if (this.state.playing) {
      this.togglePlay();
    }

    const { piMap } = this.state;

    piMap.removeMarkers();
    this.setState({
      showMap: false,
      speed: 1,
      playPosition: 0,
    });
  };

  resizeWindow = () => {
    try {
      window.dispatchEvent(new Event('resize'));
    } catch (e) {
      var resizeEvent = window.document.createEvent('UIEvents');
      resizeEvent.initUIEvent('resize', true, false, window, 0);
      window.dispatchEvent(resizeEvent);
    }
  };

  restartPlay = () => {
    this.setState({
      playPosition: 0,
    });
  };

  togglePlay = () => {
    const { timer } = this;
    const fps = 5;
    const playing = !this.state.playing;

    if (!timer) {
      // begin playback
      this.timer = setInterval(() => {
        const { speed, playPosition, frames } = this.state;
        const maxLength = frames.length - 1;
        let newPlayPosition = playPosition + speed;

        if (newPlayPosition > maxLength) {
          // last frame.. update map, clear timer and return
          this.updateMap(maxLength);
          this.clearTimer();
          this.setState({
            playing: false,
          });

          return;
        }

        this.updateMap(newPlayPosition);
      }, 1000 / fps);
    } else {
      // pause playback
      this.clearTimer();
    }

    this.setState({
      playing,
    });
  };

  clearTimer = () => {
    clearInterval(this.timer);
    this.timer = null;
  };

  toggleExportMenu = () => {
    this.setState({
      exportMenuOpen: !this.state.exportMenuOpen,
    });
  };

  showExportModal = ({ type }) => {
    this.setState({
      exportModalOpen: true,
      exportType: type,
      exportError: false,
    });
  };

  closeExportModal = () => {
    this.setState({
      exportModalOpen: false,
      selectedExportTypes: defaultExportTypes,
    });
  };

  handleAssetSelectChange = event => {
    const { assets } = this.state;
    const {
      target: { value },
    } = event;

    this.setState({
      selectedAssets: value.map(assetId => assets.find(asset => asset.id === assetId)),
    });
  };

  handleExportTypeChange = event => {
    const { selectedExportTypes } = this.state;
    const {
      target: { id },
    } = event;
    const operation = selectedExportTypes.includes(id) ? 'remove' : 'add';

    if (operation === 'add') {
      // add to selectedExportTypes
      this.setState({
        selectedExportTypes: Array.prototype.concat(selectedExportTypes, id),
      });
    } else {
      // remove from selectedExportTypes
      this.setState({
        selectedExportTypes: selectedExportTypes.filter(type => type !== id),
      });
    }
  };

  handlePresetDurationChange = event => {
    const {
      target: { value },
    } = event;

    this.setState({
      selectedPresetDuration: value,
      useCustomDuration: value === -1,
    });
  };

  handlCustomDurationChange = event => {
    const {
      target: { value },
    } = event;

    this.setState({
      selectedCustomDuration: value,
    });
  };

  handleCustomDurationTypeChange = event => {
    this.setState({
      selectedCustomDurationType: event.target.value,
    });
  };

  updateSpeed = value => {
    this.setState({
      speed: value,
    });
  };

  updateMap = playPosition => {
    // draw markers at new position
    const { frames, selectedZone, piMap, hiddenMarkerLabels } = this.state;
    const frame = frames[playPosition];

    if (!selectedZone) {
      toast.error('Error updating map: no zone selected');
      return;
    }

    if (!frame) {
      return;
    }

    const { locations, frameRealTime } = frame;
    const {
      origin_offset_x,
      origin_offset_y,
      meter_to_pixel_scaler_x,
      meter_to_pixel_scaler_y,
      vendor_zone_id,
    } = selectedZone;

    try {
      piMap.removeMarkers();

      locations.forEach(tag => {
        if (!tag) return;
        const location = {
          x: mmToPixels(tag.x, meter_to_pixel_scaler_x, origin_offset_x),
          y: mmToPixels(tag.y, meter_to_pixel_scaler_y, origin_offset_y),
          zone: vendor_zone_id,
        };

        piMap
          .addMarker({
            labelHtml: `<div class="rhMapLabel compact">${tag.asset_name}</div>`,
            maxWidth: 300,
            context: { serialNumber: tag.serial_number },
            location,
            animated: false,
          })
          .then(marker => {
            marker.addListener('click', (event, context) => {
              // add or remove serial number from hidden marker labels list
              const { serialNumber } = context;
              const { hiddenMarkerLabels } = this.state;

              if (!hiddenMarkerLabels.includes(serialNumber)) {
                // add
                this.setState({
                  hiddenMarkerLabels: Array.prototype.concat(hiddenMarkerLabels, serialNumber),
                });
              } else {
                // remove
                this.setState({
                  hiddenMarkerLabels: hiddenMarkerLabels.filter(sn => sn !== serialNumber),
                });
              }
            });

            if (!hiddenMarkerLabels.includes(tag.serial_number)) {
              marker.showLabel();
            }
          });
      });

      this.setState({
        playPosition,
        frameRealTime,
      });
    } catch (e) {
      toast.error('Error adding markers');
    }
  };

  render() {
    const {
      props: { classes },
      state: {
        timezoneOpen,
        speedOpen,
        speed,
        loading,
        initialized,
        showMap,
        playing,
        playPosition,
        frameRealTime,
        assets,
        zones,
        frames,
        userTimezone,
        selectedAssets,
        selectedZone,
        selectedPresetDuration,
        selectedCustomDuration,
        selectedCustomDurationType,
        selectedStartDate,
        selectedTimezone,
        exportMenuOpen,
        exportModalOpen,
        exportType,
        exportError,
        exportDownloadUrl,
        selectedExportTypes,
        useCustomDuration,
        exporting,
      },
    } = this;
    const durationLabel = useCustomDuration
      ? `${selectedCustomDuration} ${selectedCustomDurationType}`
      : durationOptions.find(o => o.value === selectedPresetDuration).label;

    return (
      <div className={cx('report-page--content__container', { 'overflow-hidden': !showMap })}>
        {loading && <Loader fullscreen transparent />}
        <div className="playback-form">
          {!showMap && <h1>Playback</h1>}
          {initialized && !showMap && (
            <Formik
              onSubmit={this.handleSubmit}
              validationSchema={playbackFormSchema}
              initialValues={{
                zoneName: selectedZone ? selectedZone.name : zones && zones[0] && zones[0].name,
                assetIds:
                  selectedAssets && selectedAssets.length
                    ? selectedAssets.map(asset => asset.id)
                    : [],
                startDate: selectedStartDate,
                timezone: selectedTimezone || userTimezone || 'Eastern',
                presetDuration: selectedPresetDuration,
                customDuration: selectedCustomDuration,
                customDurationType: selectedCustomDurationType,
              }}
            >
              {({ handleSubmit, handleChange, setFieldValue, values, errors }) => (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col className="mb-3 align-self-end">
                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="asset-name">Assets</InputLabel>
                        <Select
                          multiple
                          value={values.assetIds}
                          name="assetIds"
                          id="asset-name"
                          onChange={event => {
                            this.handleAssetSelectChange(event);
                            handleChange(event);
                          }}
                          renderValue={selected => (
                            <div className={classes.chips}>
                              {selectedAssets.map(value => (
                                <Chip key={value.id} label={value.name} className={classes.chip} />
                              ))}
                            </div>
                          )}
                        >
                          {assets &&
                            assets.map(asset => (
                              <MenuItem key={asset.id} value={asset.id}>
                                <Checkbox
                                  checked={
                                    !!selectedAssets.find(selected => selected.id === asset.id)
                                  }
                                  color="primary"
                                />
                                {asset.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      <FormFeedback visible={errors.assetIds} message={errors.assetIds} />
                    </Col>
                    <Col className="mb-3 align-self-end">
                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="zone-name">Zone</InputLabel>
                        <Select
                          value={values.zoneName}
                          name="zoneName"
                          id="zone-name"
                          onChange={handleChange}
                        >
                          {zones &&
                            zones.map(zone => (
                              <MenuItem key={zone.id} value={zone.name}>
                                {zone.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      <FormFeedback visible={errors.zoneName} message={errors.zoneName} />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-3 align-self-end">
                      <InputLabel shrink htmlFor="startDate">
                        Start
                      </InputLabel>
                      <div className="flex-box start-date-wrapper">
                        <DatePicker
                          defaultValue={new Date(values.startDate)}
                          name="startDate"
                          onChange={value => setFieldValue('startDate', value)}
                          onKeyUp={({ target: { value } }) => setFieldValue('startDate', value)}
                          max={new Date()}
                          min={
                            new Date(
                              moment()
                                .subtract('year', 1)
                                .format(),
                            )
                          }
                        />
                        <Dropdown isOpen={timezoneOpen} toggle={this.toggleTimezoneDropdown}>
                          <DropdownToggle className="toggle-button">
                            {values.timezone}
                          </DropdownToggle>
                          <DropdownMenu>
                            {timezoneOptions.map(timezone => (
                              <DropdownItem
                                key={timezone.id}
                                onClick={() => {
                                  this.setState({ selectedTimezone: timezone.name });
                                  setFieldValue('timezone', timezone.name);
                                }}
                              >
                                {timezone.name} (Currently {getCurrentTimeFromTimezone(timezone.id)}
                                )
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                      <FormFeedback visible={errors.startDate} message={errors.startDate} />
                    </Col>
                    <Col className="mb-3 align-self-end">
                      <FormControl
                        className={cx(
                          classes.formControl,
                          useCustomDuration && classes.durationControl,
                        )}
                      >
                        <InputLabel htmlFor="presetDuration">Duration</InputLabel>
                        <Select
                          value={values.presetDuration}
                          name="presetDuration"
                          id="presetDuration"
                          className={classes.durationSelect}
                          onChange={event => {
                            this.handlePresetDurationChange(event);
                            handleChange(event);
                          }}
                        >
                          {durationOptions.map(option => (
                            <MenuItem key={option.id} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                        {useCustomDuration && (
                          <Fragment>
                            <FormControl className={classes.customDuration}>
                              <InputLabel htmlFor="customDuration" hidden>
                                Duration
                              </InputLabel>
                              <TextField
                                name="customDuration"
                                id="customDuration"
                                placeholder="Duration"
                                onChange={event => {
                                  this.handlCustomDurationChange(event);
                                  handleChange(event);
                                }}
                                value={values.customDuration}
                                inputProps={{ style: { textAlign: 'right', marginRight: '1rem' } }}
                              />
                            </FormControl>
                            <FormControl>
                              <InputLabel htmlFor="customDurationType" hidden>
                                Type
                              </InputLabel>
                              <Select
                                value={values.customDurationType}
                                name="customDurationType"
                                id="customDurationType"
                                onChange={event => {
                                  this.handleCustomDurationTypeChange(event);
                                  handleChange(event);
                                }}
                                className={classes.customDurationType}
                              >
                                {['minutes', 'hours'].map(type => (
                                  <MenuItem key={type} value={type}>
                                    {type}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Fragment>
                        )}
                      </FormControl>
                      <FormFeedback
                        visible={errors.customDuration}
                        message={errors.customDuration}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-3 align-self-end">
                      <Button type="submit" color="primary">
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          )}
        </div>
        <div className="map-wrapper">
          <div className="actions mb-3">
            {showMap && (
              <Fragment>
                <Button onClick={this.hideMap}>Go back</Button>
                <ButtonToolbar className="float-right">
                  <ButtonDropdown
                    id="export"
                    title="Export raw"
                    isOpen={exportMenuOpen}
                    toggle={this.toggleExportMenu}
                  >
                    <DropdownToggle caret color="primary">
                      Export raw
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={() => this.showExportModal({ type: 'JSON' })}
                        className="hover-pointer"
                      >
                        as JSON
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => this.showExportModal({ type: 'CSV' })}
                        className="hover-pointer"
                      >
                        as CSV
                      </DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>
                </ButtonToolbar>
              </Fragment>
            )}
          </div>
          <ResizeDetector>
            <div className="mapSVG_wrapper">
              {!showMap && <div className="pi-map-overlay" />}
              <div id="pi-map" style={{ height: '100%', width: '100%' }} />
            </div>
          </ResizeDetector>
        </div>
        <div className="map-controls">
          {showMap && (
            <Fragment>
              <div className="control-wrapper">
                <Slider
                  min={0}
                  max={frames ? frames.length - 1 : 0}
                  step={1}
                  value={playPosition}
                  onChange={event => this.updateMap(parseInt(event.target.value, 10))}
                />
                <div className="buttons">
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => (playPosition === 0 ? false : this.restartPlay())}
                  >
                    <i className="fas fa-step-backward" />
                  </Button>
                  <Button color="primary" size="sm" onClick={this.togglePlay}>
                    <i className={cx('fas', { 'fa-play': !playing }, { 'fa-pause': playing })} />
                  </Button>
                  <Dropdown isOpen={speedOpen} toggle={this.toggleSpeedDropdown}>
                    <DropdownToggle
                      className="toggle-button"
                      color="primary"
                      size="sm"
                    >{`${speed}x`}</DropdownToggle>
                    <DropdownMenu>
                      {speeds.map(speed => (
                        <DropdownItem key={speed.id} onClick={() => this.updateSpeed(speed.value)}>
                          {speed.label}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
              <div className="report-details">
                <ul>
                  <li>
                    Assets:{' '}
                    {selectedAssets && selectedAssets.length
                      ? selectedAssets.map(selected => selected.name).join(', ')
                      : '[none]'}
                  </li>
                  <li>Zone: {selectedZone && selectedZone.name}</li>
                  <li>
                    Start: {selectedStartDate && selectedStartDate.format('MM/DD/YYYY h:mm A')}
                  </li>
                  <li>Duration: {durationLabel}</li>
                </ul>
                <span className="frame-real-time">
                  {formatTimestamp(frameRealTime, { seconds: true, isUnix: true })}
                </span>
              </div>
            </Fragment>
          )}
        </div>
        {exportModalOpen && (
          <div className="export-modal">
            <div
              className="wrapper"
              id="wrapper"
              onClick={event => event.target.id === 'wrapper' && this.closeExportModal()}
            >
              <div className="content">
                {exporting ? (
                  <div className="loading-wrapper">
                    <Loader fullscreen />
                  </div>
                ) : (
                  <Fragment>
                    {exportDownloadUrl ? (
                      <Fragment>
                        <div className="heading mb-3">
                          Export complete
                          <hr />
                        </div>
                        <div className="body mb-3">
                          <p>Your export is available for download.</p>
                        </div>
                        <div className="actions">
                          <Button
                            color="secondary"
                            onClick={() => this.setState({ exportDownloadUrl: false })}
                          >
                            Back
                          </Button>
                          <Button className="float-right" color="primary" href={exportDownloadUrl}>
                            <i className="fas fa-download" /> Download {exportType.toUpperCase()}
                          </Button>
                        </div>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <div className="heading mb-3">
                          Exporting {exportType.toUpperCase()}
                          <hr />
                        </div>
                        <div className="body mb-3">
                          <p>Which columns do you want to export?</p>
                          <div className="options">
                            {playbackExportTypes.map(type =>
                              type.visible ? (
                                <FormGroup check key={type.value}>
                                  <Label check>
                                    <Input
                                      type="checkbox"
                                      id={type.value}
                                      checked={selectedExportTypes.includes(type.value)}
                                      onChange={this.handleExportTypeChange}
                                    />{' '}
                                    {type.label}
                                  </Label>
                                </FormGroup>
                              ) : (
                                false
                              ),
                            )}
                          </div>
                          <FormFeedback visible={exportError} message={exportError} />
                        </div>
                        <div className="actions">
                          <Button color="secondary" onClick={this.closeExportModal}>
                            Close
                          </Button>
                          <Button
                            className="float-right"
                            color="primary"
                            onClick={() => this.handleExport({ type: exportType })}
                          >
                            Export
                          </Button>
                        </div>
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(playbackFormStyles)(withApollo(withAppContext(Playback)));
