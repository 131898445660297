import React from 'react';
import { Redirect } from 'react-router-dom';
import { List, Datagrid, TextField, EditButton } from 'react-admin';

import Can from '../../components/Can/Can';
import PasswordPrompt from '../../components/PasswordPrompt/PasswordPrompt';
import BulkActionButtons from '../common/BulkActionButtons/BulkActionButtons';
import ListActions from '../common/ListActions/ListActions';
import Pagination from '../common/Pagination/Pagination';
import { redirectUrl } from './index.js';

export const RoomList = props => (
  <Can perform="rooms:list" no={<Redirect to={redirectUrl} />}>
    <PasswordPrompt>
      {({ promptCheck }) => (
        <List
          {...props}
          perPage={15}
          pagination={<Pagination />}
          actions={
            <ListActions
              title="Rooms"
              object="rooms"
              modifyExports={items =>
                items.map(item => ({
                  ...item,
                  vertices:
                    item.vertices &&
                    item.vertices.length &&
                    `(${item.vertices.map(point => `(${point.x},${point.y})`).join(',')})`,
                  zone: item.zone && item.zone.name,
                  room_type: item.room_type.name,
                }))
              }
            />
          }
          bulkActionButtons={
            <BulkActionButtons
              object="rooms"
              deleteModalHeader="Are you sure you want to delete the selected Rooms?"
            />
          }
          sort={{ field: 'name', order: 'ASC' }}
        >
          <Datagrid>
            <TextField source="name" />
            <TextField source="zone.name" label="Zone" />
            <TextField source="room_type.name" label="Room Type" />
            <Can perform="rooms:update">
              <EditButton basePath="/rooms" />
            </Can>
          </Datagrid>
        </List>
      )}
    </PasswordPrompt>
  </Can>
);
