import React, { Fragment } from 'react';
import { Button as RSButton } from 'reactstrap';
import svgson from 'svgson';
import cx from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ZoneIcon from '@material-ui/icons/Dashboard';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import PIMap from '../../components/PIMap/PIMap';
import { MANAGE_ROUTE_WITH_REACT_ADMIN_ROUTE } from '../../utils/routes';
import { toast } from '../../components/Toast/Toast';
import { baseName } from '../../utils/history';
import GlobalPortal from '../../components/GlobalPortal/GlobalPortal';

const styles = theme => ({
  formControlLabel: {
    margin: 0,
    float: 'right',
  },
});

class MapView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMap: false,
      vendorMapId: this.props.record.venue.vendor_map_id,
      currentVendorZoneId: null,
      showRooms: true,
      roomPolygons: this.convertPolygonsToSVGLayer(),
      piMapInitialized: false,
      selectedRoom: false,
    };
  }

  setShowMap = value => {
    if (value) {
      // showing map, fetch map by current vendorZoneId
      const { vendorZoneId } = this.props;

      this.setState({
        showMap: true,
        showRooms: true,
        currentVendorZoneId: vendorZoneId,
        piMapInitialized: true,
      });
    } else {
      this.setState({
        showMap: false,
        showRooms: true,
        piMapInitialized: false,
      });
    }
  };

  setShowRooms = value => {
    const elements = document.querySelectorAll('svg[id^="zone"] g#room_overlay g#injected polygon');

    if (elements && elements.length) {
      elements.forEach(element => {
        if (value) {
          element.classList.add('show');
        } else {
          element.classList.remove('show');
        }
      });
    }

    this.setState({
      showRooms: value,
    });
  };

  convertPolygonsToSVGLayer = () => {
    const {
      record: {
        rooms,
        meter_to_pixel_scaler_x,
        meter_to_pixel_scaler_y,
        origin_offset_x,
        origin_offset_y,
      },
    } = this.props;

    if (!rooms || !rooms.length) {
      return false;
    }

    const pixelPolygons = rooms.map(room => ({
      ...room,
      vertices: room.vertices.map(point => ({
        x: (point.x + origin_offset_x) / meter_to_pixel_scaler_x,
        y: (point.y + origin_offset_y) / meter_to_pixel_scaler_y,
      })),
    }));

    let returnObj = {
      name: 'g',
      type: 'element',
      attributes: {
        id: 'injected',
      },
      children: pixelPolygons.map(room => ({
        name: 'polygon',
        type: 'element',
        attributes: {
          id: `polygon-fill-area-${room.id}`,
          value: room.id,
          name: room.name,
          points: room.vertices.map(point => `${point.x},${point.y}`).join(' '),
          class: 'show',
        },
        children: [],
      })),
    };

    return returnObj;
  };

  handlePIMapCallback = event => {
    const { customLayer } = event;

    this.setState({
      piMapInitialized: true,
    });

    customLayer.addEventListener('mousemove', event => {
      const { clientX, clientY, target } = event;

      if (target.id.startsWith('polygon-fill-area-')) {
        const roomId = parseInt(target.getAttribute('value'), 10);

        this.setSelectedRoom(roomId, { x: clientX, y: clientY });
      }
    });

    customLayer.addEventListener('mouseleave', event => {
      this.setSelectedRoom(false);
    });

    customLayer.addEventListener('click', event => {
      const { target } = event;
      const roomId = parseInt(target.getAttribute('value'), 10);

      window.location.assign(`${baseName}${MANAGE_ROUTE_WITH_REACT_ADMIN_ROUTE}/rooms/${roomId}`);
    });
  };

  handlePIMapError = event => {
    toast.error(event.message);
    this.setShowMap(false);
  };

  setSelectedRoom = (value, labelLocation = {}) => {
    let currentRoom = false;

    if (value !== false) {
      // update label position and selectedRoom
      const {
        record: { rooms },
      } = this.props;
      const labelElement = document.querySelector('.map-view-container .map-wrapper .room-label');

      currentRoom = rooms && rooms.length && rooms.find(room => parseInt(room.id, 10) === value);
      labelElement.style.left = `${labelLocation.x + 15}px`;
      labelElement.style.top = `${labelLocation.y + 15}px`;
    }

    this.setState({
      selectedRoom: currentRoom,
    });
  };

  render() {
    const {
      state: { showMap, showRooms, roomPolygons, currentVendorZoneId, vendorMapId, selectedRoom },
      props: { classes },
    } = this;

    return showMap ? (
      <GlobalPortal noOverlay>
        <div className="map-view-container">
          <div className="content">
            <div className="actions">
              <RSButton className="link mt-2" onClick={() => this.setShowMap(false)}>
                <i className="fas fa-chevron-left fa-sm" /> Back to edit zone
              </RSButton>
              <FormControlLabel
                className={classes.formControlLabel}
                control={
                  <Switch
                    checked={!!showRooms}
                    onChange={() => this.setShowRooms(!showRooms)}
                    color="primary"
                  />
                }
                label="Show rooms"
                labelPlacement="start"
              />
            </div>
            <div className="map-wrapper">
              <div className="map">
                {currentVendorZoneId && (
                  <PIMap
                    vendorMapId={vendorMapId}
                    vendorZoneId={currentVendorZoneId}
                    injectChild={svgson.stringify(roomPolygons, { selfClose: false })}
                    overrides={{ piMap: { zoneChangerControls: false } }}
                    onInitialized={this.handlePIMapCallback}
                    onError={this.handlePIMapError}
                  />
                )}
              </div>
              <div className={cx('room-label', { show: !!selectedRoom })}>
                {selectedRoom && selectedRoom.name}
              </div>
            </div>
          </div>
        </div>
      </GlobalPortal>
    ) : (
      <Fragment>
        <GlobalPortal show={false} />
        <Button variant="contained" onClick={() => this.setShowMap(true)} className="shadow-none">
          <ZoneIcon className="mr-3" /> View map
        </Button>
      </Fragment>
    );
  }
}

export default withStyles(styles)(MapView);
