import gql from 'graphql-tag';

export default function zoneQueries(raFetchType, params) {
  switch (raFetchType) {
    case 'CREATE':
      return {
        query: gql`
          mutation addZone(
            $name: String!
            $venue_id: ID!
            $origin_offset_x: Float!
            $origin_offset_y: Float!
            $meter_to_pixel_scaler_x: Float!
            $meter_to_pixel_scaler_y: Float!
            $floor: String
            $description: String
            $vendor_zone_id: String
          ) {
            addZone(
              name: $name
              venue_id: $venue_id
              origin_offset_x: $origin_offset_x
              origin_offset_y: $origin_offset_y
              meter_to_pixel_scaler_x: $meter_to_pixel_scaler_x
              meter_to_pixel_scaler_y: $meter_to_pixel_scaler_y
              floor: $floor
              description: $description
              vendor_zone_id: $vendor_zone_id
            ) {
              id
            }
          }
        `,
        variables: {
          ...params.data,
          venue_id: params.data.venue.id,
        },
        parseResponse: response => ({ data: response.data.addZone }),
      };
    case 'UPDATE':
      return {
        query: gql`
          mutation updateZone(
            $id: ID!
            $name: String
            $venue_id: ID
            $origin_offset_x: Float
            $origin_offset_y: Float
            $meter_to_pixel_scaler_x: Float
            $meter_to_pixel_scaler_y: Float
            $floor: String
            $description: String
            $vendor_zone_id: String
          ) {
            updateZone(
              id: $id
              name: $name
              venue_id: $venue_id
              origin_offset_x: $origin_offset_x
              origin_offset_y: $origin_offset_y
              meter_to_pixel_scaler_x: $meter_to_pixel_scaler_x
              meter_to_pixel_scaler_y: $meter_to_pixel_scaler_y
              floor: $floor
              description: $description
              vendor_zone_id: $vendor_zone_id
            ) {
              id
            }
          }
        `,
        variables: {
          ...params.data,
          venue_id: params.data.venue.id,
        },
        parseResponse: response => ({ data: response.data.updateZone }),
      };
    case 'GET_ONE':
      return {
        query: gql`
          query getZones($id: ID) {
            zones(id: $id) {
              total
              data {
                ... on Zone {
                  id
                  name
                  floor
                  description
                  origin_offset_x
                  origin_offset_y
                  meter_to_pixel_scaler_x
                  meter_to_pixel_scaler_y
                  vendor_zone_id
                  venue {
                    id
                    name
                    vendor_map_id
                  }
                  rooms {
                    id
                    name
                    vertices {
                      x
                      y
                    }
                  }
                }
              }
            }
          }
        `,
        variables: params,
        parseResponse: response => ({
          data:
            response.data &&
            response.data.zones &&
            response.data.zones.data &&
            response.data.zones.data.length &&
            response.data.zones.data[0],
        }),
      };
    case 'GET_MANY':
    case 'GET_LIST':
      return {
        query: gql`
          query getZones($sort: Sort, $pagination: Pagination) {
            zones(sort: $sort, pagination: $pagination) {
              total
              data {
                ... on Zone {
                  id
                  name
                  floor
                }
              }
            }
          }
        `,
        variables: params,
        parseResponse: response => ({
          data: response.data.zones.data,
          total: response.data.zones.total,
        }),
      };
    case 'DELETE':
      return {
        query: gql`
          mutation deleteZones($id: [ID!]!) {
            deleteZones(id: $id) {
              id
              name
            }
          }
        `,
        variables: params,
        parseResponse: response => ({ data: response.data.deleteZones[0] }),
      };
    case 'DELETE_MANY':
      const variables = {
        id: params.ids,
      };

      return {
        query: gql`
          mutation deleteZones($id: [ID]!) {
            deleteZones(id: $id) {
              id
            }
          }
        `,
        variables,
        parseResponse: response => ({ data: response.data.deleteZones }),
      };
    default:
      throw new Error('Invalid fetch type');
  }
}
