import gql from 'graphql-tag';

export default function organizationQueries(raFetchType, params) {
  switch (raFetchType) {
    case 'CREATE':
      return {
        query: gql`
          mutation addOrganization($name: String!, $parent_id: ID!) {
            addOrganization(name: $name, parent_id: $parent_id) {
              id
            }
          }
        `,
        variables: { ...params.data, parent_id: params.data.parent.id },
        parseResponse: response => ({ data: response.data.addOrganization }),
      };
    case 'UPDATE':
      return {
        query: gql`
          mutation updateOrganization($id: ID!, $name: String, $parent_id: ID) {
            updateOrganization(id: $id, name: $name, parent_id: $parent_id) {
              id
            }
          }
        `,
        variables: { ...params.data, parent_id: params.data.parent.id },
        parseResponse: response => ({ data: response.data.updateOrganization }),
      };
    case 'GET_ONE':
      return {
        query: gql`
          query getOrganizations($id: ID) {
            organizations(id: $id) {
              total
              data {
                ... on Organization {
                  id
                  name
                  parent {
                    id
                    name
                  }
                }
              }
            }
          }
        `,
        variables: params,
        parseResponse: response => ({
          data:
            response.data &&
            response.data.organizations &&
            response.data.organizations.data &&
            response.data.organizations.data.length &&
            response.data.organizations.data[0],
        }),
      };
    case 'GET_MANY':
    case 'GET_LIST':
      return {
        query: gql`
          query getOrganizations($sort: Sort, $pagination: Pagination) {
            organizations(sort: $sort, pagination: $pagination, all: true) {
              total
              data {
                ... on Organization {
                  id
                  name
                  parent {
                    id
                    name
                  }
                }
              }
            }
          }
        `,
        variables: params,
        parseResponse: response => {
          return {
            data: response.data.organizations.data,
            total: response.data.organizations.total,
          };
        },
      };
    case 'DELETE':
      return {
        query: gql`
          mutation deleteOrganizations($id: [ID!]!) {
            deleteOrganizations(id: $id) {
              id
              name
            }
          }
        `,
        variables: params,
        parseResponse: response => ({ data: response.data.deleteOrganizations[0] }),
      };
    case 'DELETE_MANY':
      const variables = {
        id: params.ids,
      };

      return {
        query: gql`
          mutation deleteOrganizations($id: [ID]!) {
            deleteOrganizations(id: $id) {
              id
            }
          }
        `,
        variables,
        parseResponse: response => ({ data: response.data.deleteOrganizations }),
      };
    default:
      throw new Error('Invalid fetch type');
  }
}
