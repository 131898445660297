import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { Auth } from 'aws-amplify';
import gql from 'graphql-tag';
import cx from 'classnames';
import { withApollo, Mutation } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { Col } from 'reactstrap';

import { Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';

import Can from '../Can/Can';
import logo from './logo.svg';
import logo2 from './logo2.svg';
import { getDefaultGroupName } from '../../utils/auth';
import { toast } from '../Toast/Toast';
import Loader from '../Loader/Loader';
import { REPORT_ROUTE, LOGIN_ROUTE } from '../../utils/routes';
import Breakpoint from '../Breakpoint/Breakpoint';
import history, { baseName } from '../../utils/history';
import { GET_LOCAL_USER_DATA } from '../../client/queries/app';

const DEIMPERSONATE = gql`
  mutation deimpersonate {
    deimpersonate
  }
`;

class Header extends Component {
  constructor(props) {
    super(props);
    const { client } = props;

    this.state = {
      dropdownOpen: false,
      initialized: false,
      loading: false,
      userData: null,
      mobileNavOpen: false,
    };
    //TODO: Consider moving userData to context api, which should serve as the single source of truth instead of componenet-based state
    client
      .query({
        query: GET_LOCAL_USER_DATA,
      })
      .then(data => {
        this.populateUserData(data);
      });
  }

  toggleDropdown = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  signOut = () => {
    Auth.signOut().then(() => {
      window.location.assign(`${baseName}${LOGIN_ROUTE}`);
    });
  };

  handleDeimpersonate = fn => {
    this.setState({
      loading: true,
    });

    fn()
      .then(res => {
        Auth.currentAuthenticatedUser().then(user => {
          user.refreshSession(user.signInUserSession.refreshToken, (err, session) => {
            this.setState({
              loading: false,
            });

            window.location.reload();
          });
        });
      })
      .catch(err => {
        this.setState({
          loading: false,
        });

        toast.error('Error deimpersonating:', err);
      });
  };

  populateUserData = payload => {
    if (!payload.data.userData) {
      return false;
    }

    const { initialized } = this.state;

    if (!initialized) {
      this.setState({
        userData: payload.data.userData,
        initialized: true,
      });
    }
  };

  toggleMobileMenu = () => {
    this.setState({
      mobileNavOpen: !this.state.mobileNavOpen,
    });
  };

  handleMobileNavItemClick = to => {
    history.push(to);

    this.setState({
      mobileNavOpen: false,
    });
  };

  render() {
    const { initialized, loading } = this.state;
    const { pathname } = new URL(window.location);
    let ImpersonationComponent = () => false;

    if (initialized) {
      ImpersonationComponent = props => {
        const {
          userData: { impersonating, groups, organizationName },
        } = this.state;

        return (
          impersonating && (
            <Can perform="deimpersonate:*" data={{ impersonating }}>
              <Mutation mutation={DEIMPERSONATE}>
                {deimpersonate => (
                  <div className={`impersonate ${props.desktop ? 'd-none d-lg-block' : ''}`}>
                    You are logged in as <strong>{getDefaultGroupName(groups)}</strong> from{' '}
                    <strong>{organizationName}</strong>. <div className="w-100 d-lg-none" />
                    <span
                      className="link underline"
                      onKeyPress={() => this.handleDeimpersonate(deimpersonate)}
                      onClick={() => this.handleDeimpersonate(deimpersonate)}
                    >
                      Switch back
                      <i className="pl-1 fas fa-arrow-circle-right" />
                    </span>
                  </div>
                )}
              </Mutation>
            </Can>
          )
        );
      };
    }

    try {
      const { userData, mobileNavOpen } = this.state;
      const verified = userData ? !!userData.emailVerified : true;

      return loading ? (
        <Loader fullscreen transparent />
      ) : (
        <Fragment>
          <header
            className={cx('header', 'zindex-layout', {
              impersonating: userData && userData.impersonating,
            })}
          >
            <div className="header__brand">
              <Link to="/locate" className="logo">
                <img
                  src={userData && userData.impersonating ? logo2 : logo}
                  alt="Repp Health Logo"
                />
              </Link>
            </div>
            <Col xs="auto" lg="3" className="header__hospital-name">
              <span>{userData && userData.organizationName}</span>
            </Col>
            <Col>
              <ImpersonationComponent desktop />
            </Col>
            <Breakpoint size={['xs', 'md']}>
              <div className="header__mobile-nav__toggle" onClick={this.toggleMobileMenu}>
                <i className="fas fa-bars" />
              </div>
            </Breakpoint>
            <Breakpoint size={['lg', 'xl']}>
              <div className="header__nav">
                <Can perform="locate-page:visit">
                  <div
                    className={cx('nav-item', {
                      active: pathname.includes('/locate'),
                    })}
                  >
                    <Link to="/locate">Locate</Link>
                  </div>
                </Can>
                <Can perform="report-page:visit">
                  <div
                    className={cx('nav-item', {
                      active: pathname.includes('/report'),
                    })}
                  >
                    <Link to={REPORT_ROUTE}>Report</Link>
                  </div>
                </Can>
                <Can perform="manage-page:visit">
                  <div
                    className={cx('nav-item', {
                      active: pathname.includes('/manage'),
                    })}
                  >
                    <Link to="/manage#/organizations">Manage</Link>
                  </div>
                </Can>
                <div className="nav-item">
                  <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
                    <DropdownToggle
                      className={cx('account-dropdown', {
                        'email-not-verified': !verified,
                      })}
                      id="account-dropdown"
                    >
                      <i className="fas fa-user-circle" />
                    </DropdownToggle>
                    <DropdownMenu right>
                      <LinkContainer to="/settings/account">
                        <DropdownItem>
                          <i className="fas fa-cog" /> Settings
                          {!verified && (
                            <i className="fas fa-exclamation-circle email-not-verified" />
                          )}
                        </DropdownItem>
                      </LinkContainer>
                      <DropdownItem divider />
                      <DropdownItem onClick={this.signOut} className="hover-pointer">
                        <i className="fas fa-sign-out-alt" /> Sign Out
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
            </Breakpoint>
          </header>
          {mobileNavOpen && (
            <Breakpoint size={['xs', 'md']}>
              <div className="header__mobile-nav">
                <div className="header__mobile-nav__content">
                  <ImpersonationComponent />
                  <hr />
                  <Can perform="locate-page:visit">
                    <div
                      className={cx('mobile-nav-item', {
                        active: pathname.includes('/locate'),
                      })}
                      onClick={() => this.handleMobileNavItemClick('/locate')}
                    >
                      Locate
                    </div>
                    <hr />
                  </Can>
                  <Can perform="report-page:visit">
                    <div
                      className={cx('mobile-nav-item', {
                        active: pathname.includes('/report'),
                      })}
                      onClick={() => this.handleMobileNavItemClick('/report')}
                    >
                      Report
                    </div>
                    <hr />
                  </Can>
                  <Can perform="manage-page:visit">
                    <div
                      className={cx('mobile-nav-item', {
                        active: pathname.includes('/manage'),
                      })}
                      onClick={() => this.handleMobileNavItemClick('/manage')}
                    >
                      Manage
                    </div>
                    <hr />
                  </Can>
                  <div
                    className={cx('mobile-nav-item', {
                      active: pathname.includes('/settings'),
                    })}
                    onClick={() => this.handleMobileNavItemClick('/settings/account')}
                  >
                    Settings
                    {!verified && <i className="fas fa-exclamation-circle email-not-verified" />}
                  </div>
                  <hr />
                  <div className="mobile-nav-item" onClick={this.signOut}>
                    <i className="fas fa-sign-out-alt" /> Sign Out
                  </div>
                  <hr />
                </div>
              </div>
            </Breakpoint>
          )}
        </Fragment>
      );
    } catch (e) {
      console.log('e: ', e);
      return false;
    }
  }
}

export default withApollo(withRouter(Header));
