import gql from 'graphql-tag';

export default function assetTypeToOrganizationQueries(raFetchType, params) {
  switch (raFetchType) {
    case 'CREATE':
      return {
        query: gql`
          mutation addAssetTypeToOrganization($asset_type_id: ID!, $organization_id: ID!) {
            addAssetTypeToOrganization(
              asset_type_id: $asset_type_id
              organization_id: $organization_id
            ) {
              id
            }
          }
        `,
        variables: {
          ...params.data,
          asset_type_id: params.data.asset_type.id,
          organization_id: params.data.organization.id,
        },
        parseResponse: response => ({
          data: response.data.addAssetTypeToOrganization,
        }),
      };
    case 'DELETE':
      return {
        query: gql`
          mutation deleteAssetTypesToOrganizations($id: [ID!]!) {
            deleteAssetTypesToOrganizations(id: $id) {
              id
            }
          }
        `,
        variables: params,
        parseResponse: response => ({
          data: response.data.deleteAssetTypesToOrganizations[0],
        }),
      };
    default:
      throw new Error('Invalid fetch type');
  }
}
