import React from 'react';
import { ToastContainer as DefaultToastContainer, toast as defaultToast } from 'react-toastify';
import { Button } from 'reactstrap';
import 'react-toastify/dist/ReactToastify.css';

const CloseButton = ({ closeToast, ...rest }) => (
  <Button className="toast-container__close-button" onClick={closeToast}>
    <i className="fas fa-times" />
  </Button>
);

export const toast = defaultToast;
export const ToastContainer = props => (
  <DefaultToastContainer
    {...props}
    className="toast-container"
    bodyClassName="toast-container__body"
    progressClassName="toast-container__progress"
    position="bottom-right"
    closeButton={<CloseButton />}
  />
);
