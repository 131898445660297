import React from 'react';
import { Redirect } from 'react-router-dom';
import { List, Datagrid, TextField, EditButton } from 'react-admin';

import Can from '../../components/Can/Can';
import PasswordPrompt from '../../components/PasswordPrompt/PasswordPrompt';
import ListActions from '../common/ListActions/ListActions';
import Pagination from '../common/Pagination/Pagination';
import { redirectUrl } from './index.js';

export const ParentList = props => {
  return (
    <Can perform="parents:list" no={<Redirect to={redirectUrl} />}>
      <PasswordPrompt>
        {({ promptCheck }) => (
          <List
            {...props}
            perPage={15}
            pagination={<Pagination />}
            actions={<ListActions title="Parents" object="parents" />}
            bulkActionButtons={false}
            sort={{ field: 'name', order: 'ASC' }}
          >
            <Datagrid>
              <TextField source="name" />
              <Can perform="parents:update">
                <EditButton basePath="/parents" />
              </Can>
            </Datagrid>
          </List>
        )}
      </PasswordPrompt>
    </Can>
  );
};
