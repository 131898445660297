import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardActions';
import { withStyles } from '@material-ui/core/styles';
import { Button } from 'reactstrap';

import logo from '../Header/logo.svg';
import noItem from './no-item.svg';
import Footer from '../../components/Footer/Footer';

const styles = {
  card: {
    marginBottom: '200px',
    padding: '2rem 1rem',
  },
  cardContent: {
    flexDirection: 'column',
  },
};

const Maintenance = ({ message, withHeader, withFooter, classes }) => {
  return (
    <>
      {withHeader && (
        <header className="header">
          <div className="header__brand">
            <a href="/locate" className="logo">
              <img src={logo} alt="Repp Health Logo" />
            </a>
          </div>
        </header>
      )}
      <section className="maintenance-page">
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <img src={noItem} alt="Warning" />
            {message ? (
              <p className="maintenance-page__title">{message}</p>
            ) : (
              <>
                <p className="maintenance-page__title">We're experiencing technical difficulties</p>
                <p>Repp Health has been notified of this issue.</p>
                <Button className="btn-primary" onClick={() => (window.location.href = '/')}>
                  Click Here to Try Again
                </Button>
              </>
            )}
          </CardContent>
        </Card>
      </section>

      {withFooter && <Footer />}
    </>
  );
};

Maintenance.propTypes = {
  withHeader: PropTypes.bool,
  withFooter: PropTypes.bool,
  message: PropTypes.string,
};

Maintenance.defaultProps = {
  withHeader: false,
  withFooter: false,
  message: null,
};

export default withStyles(styles)(Maintenance);
