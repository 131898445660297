import gql from 'graphql-tag';

export default function assetTypeQueries(raFetchType, params) {
  switch (raFetchType) {
    case 'CREATE':
      return {
        query: gql`
          mutation addAssetType(
            $name: String!
            $plural: String!
            $description: String
            $trigger_entry_alerts: Boolean
          ) {
            addAssetType(
              name: $name
              plural: $plural
              description: $description
              trigger_entry_alerts: $trigger_entry_alerts
            ) {
              id
            }
          }
        `,
        variables: params.data,
        parseResponse: response => ({ data: response.data.addAssetType }),
      };
    case 'UPDATE':
      return {
        query: gql`
          mutation updateAssetType(
            $id: ID!
            $name: String
            $plural: String
            $description: String
            $trigger_entry_alerts: Boolean
          ) {
            updateAssetType(
              id: $id
              name: $name
              plural: $plural
              description: $description
              trigger_entry_alerts: $trigger_entry_alerts
            ) {
              id
            }
          }
        `,
        variables: params.data,
        parseResponse: response => ({ data: response.data.updateAssetType }),
      };
    case 'GET_ONE':
      return {
        query: gql`
          query getAssetTypes($id: ID, $filter: TypesFilter) {
            asset_types(id: $id, filter: $filter) {
              total
              data {
                ... on AssetType {
                  id
                  name
                  plural
                  description
                  trigger_entry_alerts
                }
              }
            }
          }
        `,
        variables: {
          filter: {
            allOrganizations: true,
          },
          ...params,
        },
        parseResponse: response => ({
          data:
            response.data &&
            response.data.asset_types &&
            response.data.asset_types.data &&
            response.data.asset_types.data.length &&
            response.data.asset_types.data[0],
        }),
      };
    case 'GET_MANY':
    case 'GET_LIST':
      return {
        query: gql`
          query getAssetTypes($sort: Sort, $pagination: Pagination, $filter: TypesFilter) {
            asset_types(sort: $sort, pagination: $pagination, filter: $filter) {
              total
              data {
                ... on AssetType {
                  id
                  name
                  plural
                  description
                  trigger_entry_alerts
                }
              }
            }
          }
        `,
        variables: params,
        parseResponse: response => ({
          data: response.data.asset_types.data,
          total: response.data.asset_types.total,
        }),
      };
    case 'DELETE':
      return {
        query: gql`
          mutation deleteAssetTypes($id: [ID!]!) {
            deleteAssetTypes(id: $id) {
              id
            }
          }
        `,
        variables: params,
        parseResponse: response => ({ data: response.data.deleteAssetTypes[0] }),
      };
    case 'DELETE_MANY':
      const variables = {
        id: params.ids,
      };

      return {
        query: gql`
          mutation deleteAssetTypes($id: [ID!]!) {
            deleteAssetTypes(id: $id) {
              id
            }
          }
        `,
        variables,
        parseResponse: response => ({ data: response.data.deleteAssetTypes }),
      };
    default:
      throw new Error('Invalid fetch type');
  }
}
