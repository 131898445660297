import React, { Fragment } from 'react';
import { LongTextInput as RALongTextInput, FormDataConsumer } from 'react-admin';
import cx from 'classnames';

import { DESCRIPTION_MAX_LENGTH } from '../../../utils/validations';

const LongTextInput = props => {
  const { showCounter, ...rest } = props;
  const ele = <RALongTextInput {...rest} />;

  return (
    <div className="long-text-input">
      {showCounter ? (
        <FormDataConsumer>
          {({ formData }) => {
            const source = formData && formData[props.source];
            const length = source ? source.length : 0;
            const limit = DESCRIPTION_MAX_LENGTH.value;

            return (
              <Fragment>
                {ele}
                <div className={cx('long-text-input__counter', { over: length > limit })}>
                  {length} / {limit}
                </div>
              </Fragment>
            );
          }}
        </FormDataConsumer>
      ) : (
        ele
      )}
    </div>
  );
};

export default LongTextInput;
