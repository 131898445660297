import buildGraphQLProvider from 'ra-data-graphql';
import buildQuery from '../queries/queries';

export let dataProvider;

// NOTE: the following init function must be called before the dataProvider
// can be used. it's currently called in the main Manage component.
export async function initDataProvider(client) {
  if (!dataProvider) {
    dataProvider = await buildGraphQLProvider({ client, buildQuery });
  }

  return dataProvider;
}

export default dataProvider;
