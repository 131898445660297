class Log {
  constructor() {
    this.levels = [{ name: 'ERROR' }, { name: 'WARN' }];
    this.level = 0;
  }

  setLevel = level => {
    this.level = level;
  };

  pill = message => {
    console.log('💊 %c!!!', 'font-weight: bold; color: orange;', message || '');
  };

  lemon = message => {
    console.log('🍋 %c!!!', 'font-weight: bold; color: yellow;', message || '');
  };

  avacado = message => {
    console.log('🥑 %c!!!', 'font-weight: bold; color: green;', message || '');
  };

  chili = message => {
    console.log('🌶️ %c!!!', 'font-weight: bold; color: red;', message || '');
  };

  warn = message => {
    console.log('🌀 %cWARN:', 'font-weight: bold; color: blue;', message || '');
  };

  error = message => {
    console.log('🌶️ %cERROR: ', 'font-weight: bold; color: red;', message || '');
  };
}

export default new Log();
