import React from 'react';

const Landing = props => (
  <div className="report-page--content__container">
    <h1>Reports</h1>
    <p>Repp Health has a variety of reporting options available. Please talk to your account administrator to determine what is best for you.
    </p>
  </div>
);

export default Landing;
