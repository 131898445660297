import React from 'react';
import { Toolbar } from 'react-admin';

import Can from '../../../components/Can/Can';
import PasswordPrompt from '../../../components/PasswordPrompt/PasswordPrompt';
import DeleteButton from '../DeleteButton/DeleteButton';
import SaveButton from '../SaveButton/SaveButton';

const EditToolbar = props => {
  const {
    object,
    deleteModalHeader,
    readOnly,
    hideDelete,
    additionalSubmitValues,
    ...rest
  } = props;

  return (
    <Toolbar {...rest} style={{ padding: '1rem' }}>
      {readOnly ? (
        false
      ) : (
        <PasswordPrompt>
          {({ promptCheck }) => <SaveButton update promptCheck={promptCheck} {...props} />}
        </PasswordPrompt>
      )}
      {!hideDelete && !readOnly && (
        <Can perform={`${object}:delete`}>
          <PasswordPrompt>
            {({ promptCheck }) => (
              <DeleteButton promptCheck={promptCheck} modalHeader={deleteModalHeader} {...rest} />
            )}
          </PasswordPrompt>
        </Can>
      )}
    </Toolbar>
  );
};

export default EditToolbar;
