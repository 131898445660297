import React, { createContext } from 'react';

import { ROOM_PRESENCE_DETECTION_LOCAL_STORAGE_KEY } from '../utils/map';

export const LocateContext = createContext();

export const withLocateContext = Component => props => (
  <LocateContext.Consumer>
    {ctx => <Component {...props} locateContext={ctx} />}
  </LocateContext.Consumer>
);

export const reducers = ctx => ({
  updatePresenceSubscription: value => {
    // called when presence detection data is updated
    const {
      state: { context },
    } = ctx;

    ctx.setState({
      context: {
        ...context,
        showRoomPresence: value,
      },
    });
  },
  initializePresenceData: () => {
    // called when presence detection is enabled
    ctx.initializeRoomPresenceData();
    localStorage.setItem(ROOM_PRESENCE_DETECTION_LOCAL_STORAGE_KEY, true);
  },
  resetPresenceData: () => {
    // called when presence detection is disabled
    localStorage.setItem(ROOM_PRESENCE_DETECTION_LOCAL_STORAGE_KEY, false);
    const {
      state: { context },
    } = ctx;

    ctx.setState({
      context: {
        ...context,
        roomPresenceData: [],
        showRoomPresence: false,
      },
    });
  },
});
