import gql from 'graphql-tag';

export const LOCATION_SUBSCRIPTION = gql`
  subscription locationSubscription($selectedZoneId: ID!) {
    updatedLocationData(ZoneId: $selectedZoneId) {
      X
      Y
      TagSerialNumber
      BatteryPercent
      DeviceEventTime
      ZoneName
      RoomName
    }
  }
`;

export const ROOM_PRESENCE_SUBSCRIPTION = gql`
  subscription presenceSubscription($selectedZoneId: ID!) {
    updatedVisionRoomData(ZoneId: $selectedZoneId) {
      ZoneId
      RoomId
      PresenceDetected
      DeviceEventTime
    }
  }
`;
