import React, { Fragment } from 'react';
import { withApollo } from 'react-apollo';
import {
  Form,
  Row,
  Col,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Formik } from 'formik';
import cx from 'classnames';
import moment from 'moment-timezone';
import gql from 'graphql-tag';
import * as yup from 'yup';

import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';

import Loader from '../../../../components/Loader/Loader';
import { getCurrentTimeFromTimezone } from '../../../../utils/js';
import { toast } from '../../../../components/Toast/Toast';
import { timezoneOptions } from '../../../../utils/js';
import DatePicker from '../../../../components/DatePicker/DatePicker';
import FormFeedback from '../../../../components/FormFeedback/FormFeedback';

import { REQUIRED_FIELD, DATE, NUMBER } from '../../../../utils/validations';
import {
  GET_INITIAL_PLAYBACK_FORM_DATA,
  GET_INITIAL_LOCAL_DATA,
  playbackFormStyles,
  getPlaybackLengthFromDuration,
  durationOptions,
} from '../../../../utils/reports';

const formSchema = yup.object().shape({
  assetIds: yup
    .array()
    .of(yup.string().min(1))
    .required(REQUIRED_FIELD.message),
  zoneName: yup
    .string()
    .min(1)
    .required(REQUIRED_FIELD.message),
  startDate: yup
    .date()
    .required(REQUIRED_FIELD.message)
    .typeError(DATE.message),
  customDuration: yup.string().when('presetDuration', {
    is: -1,
    then: yup
      .string()
      .matches(NUMBER.value, NUMBER.message)
      .required(REQUIRED_FIELD.message)
      .test('duration-check', 'Must be at least 1 minute and less than 24 hours', function(value) {
        if (this.parent.customDurationType === 'minutes') {
          return value >= 1 && value <= 1440;
        }

        return value >= 1 && value <= 24;
      }),
  }),
  heatmapType: yup
    .string()
    .required(REQUIRED_FIELD.message)
    .test('heatmap-type-test', 'Path type only accepts one asset', function(value) {
      const {
        parent: { assetIds },
      } = this;

      return !(value === 'PATH' && assetIds && assetIds.length && assetIds.length > 1);
    }),
});

const GET_HEATMAP = gql`
  query heatmap(
    $asset_id: [ID]!
    $zone_id: ID!
    $start_time: AWSDateTime!
    $duration_time_seconds: Int!
    $playback_length_seconds: Int!
    $heatmap_type: HeatMapType
  ) {
    heatmap(
      asset_id: $asset_id
      zone_id: $zone_id
      start_time: $start_time
      duration_time_seconds: $duration_time_seconds
      playback_length_seconds: $playback_length_seconds
      heatmap_type: $heatmap_type
    )
  }
`;

const heatmapTypes = [
  {
    label: 'Generic',
    value: 'GENERIC',
  },
  {
    label: 'Path',
    value: 'PATH',
  },
];

class Heatmap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      timezoneOpen: false,
      loading: true,
      initialized: false,
      assets: null,
      zones: null,
      selectedAssets: [],
      selectedZone: null,
      selectedStartDate: moment() // default to 12:00 AM of current day
        .startOf('day'),
      selectedTimezone: 'Eastern',
      selectedStartDateWithTimezone: null,
      selectedPresetDuration: 86400, // default to 24 hours (seconds)
      selectedCustomDuration: 24,
      selectedCustomDurationType: 'hours',
      selectedHeatmapType: 'Generic',
      showMap: false,
      useCustomDuration: false,
      mapImageLocation: null,
    };
  }

  componentDidMount() {
    this.initialize();
  }

  initialize = () => {
    const { client } = this.props;

    client
      .query({ query: GET_INITIAL_LOCAL_DATA })
      .then(localData => {
        const {
          data: {
            userData: { email },
          },
        } = localData;

        client
          .query({
            query: GET_INITIAL_PLAYBACK_FORM_DATA,
            variables: { site_user_email: email },
            fetchPolicy: 'network-only',
          })
          .then(remoteData => {
            const {
              data: {
                assets: { data: assets },
                zones: { data: zones },
                site_users,
              },
            } = remoteData;
            const timezone =
              site_users && site_users.data && site_users.data[0]
                ? site_users.data[0].timezone.replace('US/', '')
                : 'Mountain';

            this.setState({
              initialized: true,
              loading: false,
              assets,
              zones,
              userTimezone: timezone,
              selectedTimezone: timezone,
            });
          });
      })
      .catch(assetsAndZonesError => {
        toast.error('Error getting assets and zones');
      });
  };

  toggleTimezoneDropdown = () => {
    this.setState({
      timezoneOpen: !this.state.timezoneOpen,
    });
  };

  handlePlaybackDataError = message => {
    this.setState({
      loading: false,
    });

    toast.error(message || 'Error getting playback data');
  };

  getPlaybackData = (values, done) => {
    // provides a callback (done) after querying the API for playback data
    const { client } = this.props;
    const { assets, zones, useCustomDuration, selectedAssets, selectedHeatmapType } = this.state;

    if (!assets || !zones) {
      toast.error('Cannot submit without assets and/or zones');
      return false;
    }

    const {
      zoneName,
      startDate,
      timezone,
      presetDuration,
      customDuration,
      customDurationType,
    } = values;
    const zone = zones.find(zone => zone.name === zoneName);
    const timezoneOption = timezoneOptions.find(tz => tz.name === timezone);

    if (!selectedAssets || !selectedAssets.length || !zone) {
      toast.error('Invalid zone or asset selection');
    }

    this.setState({
      loading: true,
    });

    // extract the raw numbers from the time picker so current timezone data is stripped
    const check = moment(startDate);
    const year = check.format('YYYY');
    const month = parseInt(check.format('M', 10) - 1); // months are zero based
    const day = check.format('D');
    const hour = check.format('HH');
    const minute = check.format('mm');

    // use the raw numbers to construct a new time using the provided timezone
    const startDateWithTimezone = moment
      .tz([year, month, day, hour, minute], timezoneOption.id)
      .format();

    let playbackLength;
    let duration;

    if (useCustomDuration) {
      // custom duration selected, calculate playback length
      const customDurationNumber = parseFloat(customDuration);
      const durationMinutes =
        customDurationType === 'minutes' ? customDurationNumber : customDurationNumber * 60;

      // convert to seconds for query
      duration =
        customDurationType === 'minutes'
          ? customDurationNumber * 60
          : customDurationNumber * 60 * 60;

      // calculate playback length using custom value from user
      playbackLength = getPlaybackLengthFromDuration(durationMinutes);
    } else {
      // pre-defined duration selected, use hardcoded playback length
      const presetDurationNumber = parseFloat(presetDuration);

      duration = presetDurationNumber;
      playbackLength = durationOptions.find(option => option.value === presetDurationNumber)
        .playbackLength;
    }

    const handlePlaybackDataError = (message, type) => {
      const retMessage = message || 'Error getting playback data';

      toast[type || 'error'](retMessage);
      this.setState({
        loading: false,
      });

      done({ error: retMessage });
    };

    const playbackLengthSeconds = parseInt(playbackLength, 10);
    const durationTimeSeconds = parseInt(duration, 10);

    client
      .query({
        query: GET_HEATMAP,
        fetchPolicy: 'network-only',
        variables: {
          asset_id: selectedAssets.map(selected => selected.id),
          zone_id: zone.id,
          start_time: startDateWithTimezone,
          duration_time_seconds: durationTimeSeconds,
          playback_length_seconds: playbackLengthSeconds,
          heatmap_type: selectedHeatmapType ? selectedHeatmapType.toUpperCase() : 'GENERIC',
        },
      })
      .then(result => {
        const {
          data: { heatmap: url },
        } = result;

        if (url) {
          done({ url, zone });
        } else {
          handlePlaybackDataError('No event data', 'info');
        }
      })
      .catch(({ message: queryError }) => {
        handlePlaybackDataError(queryError);
      });
  };

  handleSubmit = values => {
    const input = {
      ...values,
      fileType: 'JSON',
      exportFields: ['x', 'y', 'device_event_time', 'asset_id', 'asset_name'],
    };

    this.getPlaybackData(input, result => {
      if (!result.error) {
        const { url, zone } = result;

        this.setState({
          showMap: true,
          loading: false,
          mapImageLocation: url,
          selectedZone: zone,
        });
      }
    });
  };

  hideMap = () => {
    this.setState({
      showMap: false,
    });
  };

  handleAssetSelectChange = event => {
    const { assets } = this.state;
    const {
      target: { value },
    } = event;

    this.setState({
      selectedAssets: value.map(assetId => assets.find(asset => asset.id === assetId)),
    });
  };

  handlePresetDurationChange = event => {
    const {
      target: { value },
    } = event;

    this.setState({
      selectedPresetDuration: value,
      useCustomDuration: value === -1,
    });
  };

  handlCustomDurationChange = event => {
    const {
      target: { value },
    } = event;

    this.setState({
      selectedCustomDuration: value,
    });
  };

  handleCustomDurationTypeChange = event => {
    this.setState({
      selectedCustomDurationType: event.target.value,
    });
  };

  handleHeatmapTypeChange = event => {
    this.setState({
      selectedHeatmapType: event.target.value,
    });
  };

  render() {
    const {
      props: { classes },
      state: {
        timezoneOpen,
        loading,
        initialized,
        showMap,
        assets,
        zones,
        userTimezone,
        selectedAssets,
        selectedZone,
        selectedPresetDuration,
        selectedCustomDuration,
        selectedCustomDurationType,
        selectedStartDate,
        selectedTimezone,
        selectedHeatmapType,
        useCustomDuration,
        mapImageLocation,
      },
    } = this;
    const durationLabel = useCustomDuration
      ? `${selectedCustomDuration} ${selectedCustomDurationType}`
      : durationOptions.find(o => o.value === selectedPresetDuration).label;

    return (
      <div
        className={cx('report-page--content__container heatmap-container', {
          'overflow-hidden': !showMap,
        })}
      >
        {loading && <Loader fullscreen transparent />}
        <div className="playback-form">
          {!showMap && <h1>Heatmap</h1>}
          {initialized && !showMap && (
            <Formik
              onSubmit={this.handleSubmit}
              validationSchema={formSchema}
              initialValues={{
                zoneName: selectedZone ? selectedZone.name : zones && zones[0] && zones[0].name,
                assetIds:
                  selectedAssets && selectedAssets.length
                    ? selectedAssets.map(asset => asset.id)
                    : [],
                startDate: selectedStartDate,
                timezone: selectedTimezone || userTimezone || 'Eastern',
                presetDuration: selectedPresetDuration,
                customDuration: selectedCustomDuration,
                customDurationType: selectedCustomDurationType,
                heatmapType: selectedHeatmapType || heatmapTypes[0].value,
              }}
            >
              {({ handleSubmit, handleChange, setFieldValue, values, errors }) => (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col className="mb-3 align-self-end">
                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="asset-name">Assets</InputLabel>
                        <Select
                          multiple
                          value={values.assetIds}
                          name="assetIds"
                          id="asset-name"
                          onChange={event => {
                            this.handleAssetSelectChange(event);
                            handleChange(event);
                          }}
                          renderValue={selected => (
                            <div className={classes.chips}>
                              {selectedAssets.map(value => (
                                <Chip key={value.id} label={value.name} className={classes.chip} />
                              ))}
                            </div>
                          )}
                        >
                          {assets &&
                            assets.map(asset => (
                              <MenuItem key={asset.id} value={asset.id}>
                                <Checkbox
                                  checked={
                                    !!selectedAssets.find(selected => selected.id === asset.id)
                                  }
                                  color="primary"
                                />
                                {asset.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      <FormFeedback visible={errors.assetIds} message={errors.assetIds} />
                    </Col>
                    <Col className="mb-3 align-self-end">
                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="zone-name">Zone</InputLabel>
                        <Select
                          value={values.zoneName}
                          name="zoneName"
                          id="zone-name"
                          onChange={handleChange}
                        >
                          {zones &&
                            zones.map(zone => (
                              <MenuItem key={zone.id} value={zone.name}>
                                {zone.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      <FormFeedback visible={errors.zoneName} message={errors.zoneName} />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-3 align-self-end">
                      <InputLabel shrink htmlFor="startDate">
                        Start
                      </InputLabel>
                      <div className="flex-box start-date-wrapper">
                        <DatePicker
                          defaultValue={new Date(values.startDate)}
                          name="startDate"
                          onChange={value => {
                            setFieldValue('startDate', value);
                            this.setState({ selectedStartDate: moment(value) });
                          }}
                          onKeyUp={({ target: { value } }) => setFieldValue('startDate', value)}
                          max={new Date()}
                          min={
                            new Date(
                              moment()
                                .subtract('year', 1)
                                .format(),
                            )
                          }
                        />
                        <Dropdown isOpen={timezoneOpen} toggle={this.toggleTimezoneDropdown}>
                          <DropdownToggle className="toggle-button">
                            {values.timezone}
                          </DropdownToggle>
                          <DropdownMenu>
                            {timezoneOptions.map(timezone => (
                              <DropdownItem
                                key={timezone.id}
                                onClick={() => {
                                  this.setState({ selectedTimezone: timezone.name });
                                  setFieldValue('timezone', timezone.name);
                                }}
                              >
                                {timezone.name} (Currently {getCurrentTimeFromTimezone(timezone.id)}
                                )
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                      <FormFeedback visible={errors.startDate} message={errors.startDate} />
                    </Col>
                    <Col className="mb-3 align-self-end">
                      <FormControl
                        className={cx(
                          classes.formControl,
                          useCustomDuration && classes.durationControl,
                        )}
                      >
                        <InputLabel htmlFor="presetDuration">Duration</InputLabel>
                        <Select
                          value={values.presetDuration}
                          name="presetDuration"
                          id="presetDuration"
                          className={classes.durationSelect}
                          onChange={event => {
                            this.handlePresetDurationChange(event);
                            handleChange(event);
                          }}
                        >
                          {durationOptions.map(option => (
                            <MenuItem key={option.id} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                        {useCustomDuration && (
                          <Fragment>
                            <FormControl className={classes.customDuration}>
                              <InputLabel htmlFor="customDuration" hidden>
                                Duration
                              </InputLabel>
                              <TextField
                                name="customDuration"
                                id="customDuration"
                                placeholder="Duration"
                                onChange={event => {
                                  this.handlCustomDurationChange(event);
                                  handleChange(event);
                                }}
                                value={values.customDuration}
                                inputProps={{ style: { textAlign: 'right', marginRight: '1rem' } }}
                              />
                            </FormControl>
                            <FormControl>
                              <InputLabel htmlFor="customDurationType" hidden>
                                Type
                              </InputLabel>
                              <Select
                                value={values.customDurationType}
                                name="customDurationType"
                                id="customDurationType"
                                onChange={event => {
                                  this.handleCustomDurationTypeChange(event);
                                  handleChange(event);
                                }}
                                className={classes.customDurationType}
                              >
                                {['minutes', 'hours'].map(type => (
                                  <MenuItem key={type} value={type}>
                                    {type}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Fragment>
                        )}
                      </FormControl>
                      <FormFeedback
                        visible={errors.customDuration}
                        message={errors.customDuration}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-3" xs={6}>
                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="heatmapType">Heatmap Type</InputLabel>
                        <Select
                          value={values.heatmapType}
                          name="heatmapType"
                          id="heatmapType"
                          onChange={event => {
                            this.handleHeatmapTypeChange(event);
                            handleChange(event);
                          }}
                        >
                          {heatmapTypes.map(type => (
                            <MenuItem key={type.value} value={type.value}>
                              {type.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormFeedback visible={errors.heatmapType} message={errors.heatmapType} />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-3 align-self-end">
                      <Button type="submit" color="primary">
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          )}
        </div>
        {showMap && (
          <div className="map-wrapper">
            <div className="actions mb-3">
              <Button onClick={this.hideMap}>Go back</Button>
              <Button color="primary" className="float-right" href={mapImageLocation}>
                Download
              </Button>
            </div>
            <div
              className="image-wrapper"
              style={{ backgroundImage: `url(${mapImageLocation})` }}
            />
          </div>
        )}
        {showMap && (
          <div className="report-details">
            <ul>
              <li>
                Assets:{' '}
                {selectedAssets && selectedAssets.length
                  ? selectedAssets.map(selected => selected.name).join(', ')
                  : '[none]'}
              </li>
              <li>Zone: {selectedZone && selectedZone.name}</li>
              <li>Start: {selectedStartDate && selectedStartDate.format('MM/DD/YYYY h:mm A')}</li>
              <li>Duration: {durationLabel}</li>
            </ul>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(playbackFormStyles)(withApollo(Heatmap));
