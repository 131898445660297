import * as yup from 'yup';

import { REQUIRED_FIELD, NAME_MIN_LENGTH, NAME_MAX_LENGTH } from '../../utils/validations';

export { ParentCreate } from './ParentCreate';
export { ParentEdit } from './ParentEdit';
export { ParentList } from './ParentList';

export const redirectUrl = '/manage/users';

export const formSchema = yup.object().shape({
  name: yup
    .string()
    .required(REQUIRED_FIELD.message)
    .min(NAME_MIN_LENGTH.value, NAME_MIN_LENGTH.message)
    .max(NAME_MAX_LENGTH.value, NAME_MAX_LENGTH.message),
});
