import React, { Component } from 'react';
import { Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';

import history from '../../../utils/history';

class Overview extends Component {
  handleClick = zoneId => {
    const { selectedZone, updateSelectedZone, currentVenue } = this.props;

    if (selectedZone && selectedZone.id !== zoneId) {
      const zone = currentVenue.zones.find(zone => zone.id === zoneId);
      updateSelectedZone(zone);
      history.push(`/locate/${zoneId}`);
    } else {
      history.push(`/locate/${zoneId}`);
    }
  };

  render() {
    const { venues } = this.props;

    return (
      <div className="locate__overview">
        <div className="cards-list">
          {venues &&
            venues.map(
              venue =>
                venue.zones &&
                venue.zones.length > 0 &&
                venue.zones.map(zone => (
                  <Card
                    key={zone.id}
                    className="card--cobalt"
                    onClick={() => this.handleClick(zone.id)}
                  >
                    <CardBody>
                      <div className="asset-title">{zone.name}</div>
                      {zone.assets && (
                        <div>
                          {zone.assets.length} asset
                          {zone.assets.length === 1 ? '' : 's'}
                        </div>
                      )}
                    </CardBody>
                  </Card>
                )),
            )}
        </div>
      </div>
    );
  }
}

Overview.propTypes = {
  history: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({}).isRequired,
  venues: PropTypes.array.isRequired,
};

export default Overview;
