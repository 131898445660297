import React, { Fragment } from 'react';
import { CardActions, CreateButton, ExportButton, RefreshButton, downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import moment from 'moment';

import Can from '../../../components/Can/Can';
import PasswordPrompt from '../../../components/PasswordPrompt/PasswordPrompt';
import FormHeader from '../FormHeader/FormHeader';

const exporter = (items, object, modifyExports) => {
  if (!items || !items[0]) {
    return false;
  }

  const itemsForExport = modifyExports ? modifyExports(items) : items;
  const time = moment().format('MM_DD_YYYY');
  const fileName = `${object}_${time}`;

  itemsForExport.forEach(item => {
    if (item.__typename) {
      delete item.__typename;
    }
  });

  const csv = convertToCSV({
    data: itemsForExport,
    fields: Object.keys(itemsForExport[0]),
  });

  downloadCSV(csv, fileName);
};

const ListActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  object,
  modifyExports,
  total,
  title,
}) => (
  <Fragment>
    <FormHeader text={title} />
    <PasswordPrompt>
      {({ promptCheck }) => (
        <CardActions style={{ padding: '1rem 0' }}>
          {bulkActions &&
            React.cloneElement(bulkActions, {
              basePath,
              filterValues,
              resource,
              selectedIds,
              onUnselectItems,
            })}
          {filters &&
            React.cloneElement(filters, {
              resource,
              showFilter,
              displayedFilters,
              filterValues,
              context: 'button',
            })}
          {total > 0 && (
            <ExportButton
              resource={resource}
              sort={currentSort}
              filter={filterValues}
              exporter={items => exporter(items, object, modifyExports)}
            />
          )}
          <Can perform={`${object}:create`}>
            <CreateButton basePath={basePath} />
          </Can>
          <RefreshButton />
        </CardActions>
      )}
    </PasswordPrompt>
  </Fragment>
);

export default ListActions;
