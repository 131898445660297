// TODO: Consider fetching ids from the DB at app init
export const SYSTEM_ADMINISTRATOR_ID = 1;
export const ORGANIZATION_ADMIN_ID = 2;
export const ORGANIZATION_USER_ID = 3;

// TODO: Consider two things: placing the rules in a separate file that
// is fetched at app init, and/or creating a new DB column on site_users
// that holds permissions on an individual user basis
export const rbacRules = [
  {
    role: 'system-administrator',
    permissions: {
      static: [
        'locate-page:visit',
        'report-page:visit',
        'manage-page:visit',
        'parents:*',
        'organizations:*',
        'venues:*',
        'zones:*',
        'rooms:*',
        'roomAreas:*',
        'roomTypes:*',
        'tags:*',
        'assets:*',
        'assetTypes:*',
        'siteUsers:*',
        'alertTypes:*',
        'reportTypes:*',
        'visionDevices:*',
      ],
      dynamic: {
        'impersonate:*': ({ impersonating }) => !impersonating,
        'deimpersonate:*': ({ impersonating }) => impersonating,
      },
    },
  },
  {
    role: 'organization-administrator',
    permissions: {
      static: [
        'locate-page:visit',
        'report-page:visit',
        'manage-page:visit',
        'tags:*',
        'assets:*',
        'rooms:*',
        'roomAreas:*',
        'siteUsers:list',
        'siteUsers:create',
        'siteUsers:create:organizationAdministrator',
        'siteUsers:create:organizationUser',
        'siteUsers:update',
        'siteUsers:update:organizationAdministrator',
        'siteUsers:update:organizationUser',
        'siteUsers:delete',
        'zones:list',
        'visionDevices:*',
      ],
      dynamic: {
        'deimpersonate:*': ({ impersonating }) => impersonating,
      },
    },
  },
  {
    role: 'organization-user',
    permissions: {
      static: ['locate-page:visit'],
      dynamic: { 'deimpersonate:*': ({ impersonating }) => impersonating },
    },
  },
];

export function convertTokenToUserData(token) {
  try {
    const { payload } = token;

    return {
      __typename: 'UserData',
      groups: payload['cognito:groups'],
      impersonating: payload.impersonating === 'true',
      organizationId: payload.organization_id,
      email: payload.email,
      emailVerified: payload.email_verified,
      authTime: payload.auth_time,
      colorBlindMode: false,
    };
  } catch (err) {
    return `Error converting token: ${err}`;
  }
}

export function getDefaultGroupName(groups) {
  if (!groups || !groups.length || groups.length < 1) {
    return false;
  }

  switch (groups[0]) {
    case 'system-administrator':
      return 'System Administrator';
    case 'organization-administrator':
      return 'Organization Administrator';
    case 'organization-user':
      return 'Organization User';
    default:
      return false;
  }
}

export function convertRoleTypeToId(roleType) {
  let roleId;

  switch (roleType) {
    case 'system-administrator':
      roleId = SYSTEM_ADMINISTRATOR_ID;
      break;
    case 'organization-administrator':
      roleId = ORGANIZATION_ADMIN_ID;
      break;
    case 'organization-user':
      roleId = ORGANIZATION_USER_ID;
      break;
    default:
      roleId = false;
  }

  return roleId;
}
