import gql from 'graphql-tag';

export default function alertTypeQueries(raFetchType, params) {
  switch (raFetchType) {
    case 'CREATE':
      return {
        query: gql`
          mutation addAlertType(
            $name: String!
            $plural: String!
            $sns_topic: String!
            $description: String
          ) {
            addAlertType(
              name: $name
              plural: $plural
              sns_topic: $sns_topic
              description: $description
            ) {
              id
            }
          }
        `,
        variables: params.data,
        parseResponse: response => ({ data: response.data.addAlertType }),
      };
    case 'UPDATE':
      return {
        query: gql`
          mutation updateAlertType(
            $id: ID!
            $name: String
            $plural: String
            $sns_topic: String
            $description: String
          ) {
            updateAlertType(
              id: $id
              name: $name
              plural: $plural
              sns_topic: $sns_topic
              description: $description
            ) {
              id
            }
          }
        `,
        variables: params.data,
        parseResponse: response => ({ data: response.data.updateAlertType }),
      };
    case 'GET_ONE':
      return {
        query: gql`
          query getAlertTypes($id: ID, $filter: TypesFilter) {
            alert_types(id: $id, filter: $filter) {
              total
              data {
                ... on AlertType {
                  id
                  name
                  plural
                  sns_topic
                  description
                }
              }
            }
          }
        `,
        variables: {
          filter: {
            allOrganizations: true,
          },
          ...params,
        },
        parseResponse: response => ({
          data:
            response.data &&
            response.data.alert_types &&
            response.data.alert_types.data &&
            response.data.alert_types.data.length &&
            response.data.alert_types.data[0],
        }),
      };
    case 'GET_MANY':
    case 'GET_LIST':
      return {
        query: gql`
          query getAlertTypes($sort: Sort, $pagination: Pagination, $filter: TypesFilter) {
            alert_types(sort: $sort, pagination: $pagination, filter: $filter) {
              total
              data {
                ... on AlertType {
                  id
                  name
                  plural
                  sns_topic
                  description
                }
              }
            }
          }
        `,
        variables: params,
        parseResponse: response => ({
          data: response.data.alert_types.data,
          total: response.data.alert_types.total,
        }),
      };
    case 'DELETE':
      return {
        query: gql`
          mutation deleteAlertTypes($id: [ID!]!) {
            deleteAlertTypes(id: $id) {
              id
            }
          }
        `,
        variables: params,
        parseResponse: response => ({ data: response.data.deleteAlertTypes[0] }),
      };
    case 'DELETE_MANY':
      const variables = {
        id: params.ids,
      };

      return {
        query: gql`
          mutation deleteAlertTypes($id: [ID!]!) {
            deleteAlertTypes(id: $id) {
              id
            }
          }
        `,
        variables,
        parseResponse: response => ({ data: response.data.deleteAlertTypes }),
      };
    default:
      throw new Error('Invalid fetch type');
  }
}
