import React, { Component } from 'react';
import gql from 'graphql-tag';
import { withApollo } from 'react-apollo';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Form, Col, Row, Button } from 'reactstrap';
import moment from 'moment-timezone';

import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { toast } from '../../../../components/Toast/Toast';
import { REQUIRED_FIELD, DATE } from '../../../../utils/validations';
import { humanizeSeconds, formatTimestamp } from '../../../../utils/js';
import DatePicker from '../../../../components/DatePicker/DatePicker';
import Loader from '../../../../components/Loader/Loader';
import FormFeedback from '../../../../components/FormFeedback/FormFeedback';
import PaginatedTable from '../../../../components/PaginatedTable/PaginatedTable';
import { getAWSDateTime } from '../../../../utils/js';

const formSchema = yup.object().shape({
  zoneId: yup
    .number()
    .required(REQUIRED_FIELD.message)
    .typeError('Invalid zone'),
  startTime: yup
    .date()
    .required(REQUIRED_FIELD.message)
    .typeError(DATE.message),
  endTime: yup
    .date()
    .required(REQUIRED_FIELD.message)
    .typeError(DATE.message)
    .test('end-date-check', 'End date must be after start date', function(value) {
      return value > this.parent.startTime;
    }),
});

const GET_INITIAL_FALL_HISTORY_DATA = gql`
  query getInitialFallHistoryData {
    zones {
      total
      data {
        ... on Zone {
          id
          name
        }
      }
    }
  }
`;

const GET_FALL_EVENTS = gql`
  query fall_events($zone_id: [ID]!, $start_time: AWSDateTime!, $end_time: AWSDateTime!) {
    fall_events(zone_id: $zone_id, start_time: $start_time, end_time: $end_time) {
      total
      data {
        ... on Event {
          event_type
          event_time
          duration

          room {
            id
            name
          }
        }
      }
    }
  }
`;

const styles = theme => ({
  formControl: {
    width: '100%',
  },
});

const formatData = events => {
  if (!events || !events[0]) {
    return [];
  }

  return events.map((event, index) => {
    const { event_time, event_type, duration, room } = event;
    const roomName = room ? room.name : '-';

    return {
      eventTime: {
        sortValue: new Date(event_time).getTime(),
        label: formatTimestamp(event_time),
      },
      eventType: event_type,
      duration: {
        sortValue: duration || 0,
        label: duration && duration > 0 ? humanizeSeconds(duration / 1000) : '[unknown]',
      },
      room: roomName,
    };
  });
};

class FallHistory extends Component {
  state = {
    initialized: false,
    loading: false,
    zones: null,
    selectedZone: null,
    selectedStartTime: moment(moment().subtract('day', 7)).startOf('day'),
    selectedEndTime: moment(),
    showTable: false,
    tableData: null,
  };

  componentDidMount() {
    const { client } = this.props;
    const chartElement = document.querySelector('canvas#chart');

    client
      .query({
        query: GET_INITIAL_FALL_HISTORY_DATA,
        fetchPolicy: 'network-only',
      })
      .then(res => {
        this.setState({
          zones: res.data.zones.data,
          initialized: true,
          chartElement,
        });
      })
      .catch(({ message: initUtilizationError }) => {
        toast.error(initUtilizationError || 'Error getting initial fall history data');
      });
  }

  handleSubmit = values => {
    const { client } = this.props;
    const { zones } = this.state;

    if (!zones) {
      toast.error('Please select a zone and time period');
      return false;
    }

    const { zoneId, startTime, endTime } = values;
    const zone = zones.find(zone => zone.id === zoneId);

    if (!zone) {
      toast.error('Could not find zone');
      return false;
    }

    this.setState({
      loading: true,
    });

    // use selected start/end dates
    const startTimeQueryVariable = getAWSDateTime(startTime);
    const endTimeQueryVariable = getAWSDateTime(endTime);

    const query = {
      query: GET_FALL_EVENTS,
      variables: {
        zone_id: [zone.id],
        start_time: startTimeQueryVariable,
        end_time: endTimeQueryVariable,
      },
    };

    client
      .query(query)
      .then(res => {
        const {
          data: {
            fall_events: { data: fall_events },
          },
        } = res;

        if (fall_events && fall_events.length) {
          this.setState({
            loading: false,
            showTable: true,
            tableData: formatData(fall_events),
          });
        } else {
          toast.info('No fall history data found');
          this.setState({
            loading: false,
            showTable: false,
            tableData: [],
          });
        }
      })
      .catch(({ message: queryError }) => {
        toast.error(queryError || 'Error getting fall history data');
        this.setState({
          loading: false,
        });
      });
  };

  render() {
    const {
      props: { classes },
      state: {
        initialized,
        selectedStartTime,
        selectedEndTime,
        selectedZone,
        zones,
        loading,
        tableData,
        showTable,
      },
    } = this;

    return (
      <div className="report-page--content__container">
        {loading && <Loader fullscreen transparent />}
        <h1>Fall History</h1>
        {initialized && (
          <div className="fall-history-form mb-3">
            <Formik
              onSubmit={this.handleSubmit}
              validationSchema={formSchema}
              initialValues={{
                zoneId: selectedZone ? selectedZone.id : zones && zones[0] && zones[0].id,
                startTime: selectedStartTime,
                endTime: selectedEndTime,
              }}
            >
              {({ handleSubmit, handleChange, setFieldValue, values, errors }) => (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col className="mb-3 align-self-end" lg={6}>
                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="asset-type-name">Zone</InputLabel>
                        <Select
                          value={values.zoneId}
                          name="zoneId"
                          id="zone-id"
                          onChange={handleChange}
                        >
                          {zones &&
                            zones.map(zone => (
                              <MenuItem key={zone.id} value={zone.id}>
                                {zone.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      <FormFeedback visible={errors.zoneId} message={errors.zoneId} />
                    </Col>
                  </Row>
                  <Row className="date-range-wrapper">
                    <Col className="mb-3">
                      <InputLabel shrink htmlFor="startTime">
                        Start Time
                      </InputLabel>
                      <div className="flex-box start-date-wrapper">
                        <DatePicker
                          defaultValue={new Date(values.startTime)}
                          name="startTime"
                          onChange={value => setFieldValue('startTime', value)}
                          onKeyUp={({ target: { value } }) => setFieldValue('startTime', value)}
                          max={new Date()}
                          min={
                            new Date(
                              moment()
                                .subtract('months', 6)
                                .format(),
                            )
                          }
                        />
                      </div>
                      <FormFeedback visible={errors.startTime} message={errors.startTime} />
                    </Col>
                    <Col className="mb-3">
                      <InputLabel shrink htmlFor="endTime">
                        End Time
                      </InputLabel>
                      <div className="flex-box start-date-wrapper">
                        <DatePicker
                          defaultValue={new Date(values.endTime)}
                          name="endTime"
                          onChange={value => setFieldValue('endTime', value)}
                          max={new Date()}
                          min={
                            new Date(
                              moment()
                                .subtract('months', 6)
                                .format(),
                            )
                          }
                        />
                      </div>
                      <FormFeedback visible={errors.endTime} message={errors.endTime} />
                    </Col>
                  </Row>
                  <Button type="submit" color="primary">
                    Submit
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        )}
        {showTable && (
          // <Paper>
          <div className="fall-history-table">
            {loading ? (
              <Loader />
            ) : (
              <PaginatedTable
                columns={[
                  { id: 'eventTime', label: 'Time of Fall' },
                  { id: 'room', label: 'Room' },
                  { id: 'duration', label: 'Duration' },
                ]}
                data={tableData}
                orderBy="eventTime"
              />
            )}
          </div>
          // </Paper>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(withApollo(FallHistory));
