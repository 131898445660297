import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import gql from 'graphql-tag';
import { withApollo } from 'react-apollo';
import { Edit, SimpleForm, TextInput, CREATE, UPDATE, DELETE } from 'react-admin';

import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Switch from '@material-ui/core/Switch';
import Divider from '@material-ui/core/Divider';

import Can from '../../components/Can/Can';
import EditToolbar from '../common/EditToolbar/EditToolbar';
import FormHeader from '../common/FormHeader/FormHeader';
import { validate } from '../../utils/admin';
import { formSchema, redirectUrl } from './index.js';
import { dataProvider } from '../dataProvider';
import { toast } from '../../components/Toast/Toast';
import PasswordPrompt from '../../components/PasswordPrompt/PasswordPrompt';
import { mapToArray } from '../../utils/js';
import * as auth from '../../utils/auth';

const GET_ORGANIZATIONS_AND_REPORT_TYPES_TO_ORGANIZATION = gql`
  query getOrgsAndAlertTypes($report_type_id: ID) {
    organizations(all: true) {
      total
      data {
        ... on Organization {
          id
          name
        }
      }
    }

    report_types_to_organizations_to_roles(report_type_id: $report_type_id) {
      id
      sort_order
      organization {
        id
        name
      }
      role {
        id
        type
      }
    }
  }
`;

const styles = theme => ({
  root: {
    display: 'block',
  },
  listItem: {
    display: 'block',
    padding: '1rem 0 0 0',
  },
  formGroup: {
    display: 'block',
  },
  formControl: {
    width: '100%',
  },
  sortOrderFormGroup: {
    minWidth: '100px',
    display: 'inline-block',
  },
  sortOrderSelect: {
    width: '100%',
  },
  newOrgSelect: {
    width: '256px',
    marginTop: '1rem',
  },
});

const sortOrderOptions = (() => {
  const max = 10;
  const options = [];

  for (let i = 1; i <= max; i++) {
    options.push(i);
  }

  return options;
})();

function getStyles(org, that) {
  return {
    fontWeight:
      that.state.allOrganizations.indexOf(org) === -1
        ? that.props.theme.typography.fontWeightRegular
        : that.props.theme.typography.fontWeightMedium,
  };
}

const RoleInput = ({
  row,
  roleType,
  label,
  onToggleChange,
  onSortOrderChange,
  classes,
  loading,
  promptCheck,
}) => {
  const currentRow = row.roles.find(row => row.type === roleType);

  return (
    <FormGroup className={classes.formGroup}>
      <FormControlLabel
        control={
          <Switch
            checked={!!currentRow}
            onChange={() =>
              promptCheck(() => onToggleChange(row.organizationId, roleType, !currentRow))
            }
            value={roleType}
            disabled={loading}
            color="primary"
          />
        }
        label={label}
      />
      {currentRow && (
        <FormControl className={classes.sortOrderFormGroup}>
          <InputLabel htmlFor="select-sort-order">Sort Order</InputLabel>
          <Select
            className={classes.sortOrderSelect}
            id="select-sort-order"
            value={currentRow ? currentRow.sortOrder || '' : ''}
            onChange={event =>
              promptCheck(() => onSortOrderChange(row.organizationId, roleType, event.target.value))
            }
            disabled={loading}
          >
            {sortOrderOptions.map(option => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </FormGroup>
  );
};

class OrganizationReferenceInput extends React.Component {
  constructor(props) {
    super(props);

    const { record } = props;

    this.state = {
      rows: [],
      allOrganizations: [],
      loading: true,
      rowsLoading: false,
    };

    const { client } = props;

    client
      .query({
        query: GET_ORGANIZATIONS_AND_REPORT_TYPES_TO_ORGANIZATION,
        variables: { report_type_id: record.id },
        fetchPolicy: 'network-only',
      })
      .then(queryResult => {
        const {
          data: {
            organizations: { data: organizations },
            report_types_to_organizations_to_roles: mappings,
          },
        } = queryResult;
        let rows = new Map();

        mappings.forEach(mapping => {
          const {
            id: mappingId,
            sort_order: sortOrder,
            organization: { id: orgId, name: orgName },
            role: { type: roleType },
          } = mapping;
          const rowItem = rows.get(orgName);

          if (rowItem) {
            // org already in list, update roles
            rows.set(orgName, {
              ...rowItem,
              roles: [
                ...rowItem.roles,
                {
                  id: mappingId,
                  type: roleType,
                  sortOrder,
                },
              ],
            });
          } else {
            // add org to list
            rows.set(orgName, {
              organizationId: orgId,
              organizationName: orgName,
              roles: [
                {
                  id: mappingId,
                  type: roleType,
                  sortOrder,
                },
              ],
            });
          }
        });

        this.setState({
          allOrganizations: organizations,
          rows: rows && mapToArray(rows),
          loading: false,
        });
      })
      .catch(error => {
        toast.error('Error getting organizational report type data');
      });
  }

  handleRoleToggle = (organizationId, roleType, newValue) => {
    this.setState({
      rowsLoading: true,
    });

    const operation = newValue === true ? 'add' : 'delete';
    const {
      record: { id: reportTypeId },
    } = this.props;
    const { rows } = this.state;
    const currentRow = rows.find(row => row.organizationId === organizationId);
    let mappingId;

    if (operation === 'delete' && currentRow && currentRow.roles && currentRow.roles.length) {
      mappingId = currentRow.roles.find(mapping => mapping.type === roleType).id;
    }

    const roleId = auth.convertRoleTypeToId(roleType);

    if (!roleId) {
      toast.error('Invalid role type');
      return;
    }

    dataProvider(
      operation === 'add' ? CREATE : DELETE,
      'reportTypesToOrganizationsToRoles',
      operation === 'add'
        ? {
            report_type_id: reportTypeId,
            organization_id: organizationId,
            role_id: roleId,
          }
        : {
            id: mappingId,
          },
    )
      .then(result => {
        if (operation === 'add') {
          // add new mapping id to role
          currentRow.roles = [
            ...currentRow.roles.filter(role => role.type !== roleType),
            {
              id: result.data.id,
              type: roleType,
              sortOrder: '',
            },
          ];
        } else {
          // remove role from roles
          currentRow.roles = [...currentRow.roles.filter(role => role.type !== roleType)];
        }

        // update the current row in state rows
        rows.forEach((row, index) => {
          if (row.organizationId === currentRow.organizationId) {
            rows[index] = currentRow;
          }
        });

        this.setState({
          rowsLoading: false,
          rows,
        });
      })
      .catch(error => {
        console.log('error: ', error);
        toast.error('Error updating mapping');
      });
  };

  handleSortOrderChange = (organizationId, roleType, newValue) => {
    const roleId = auth.convertRoleTypeToId(roleType);

    if (!roleId) {
      toast.error('Invalid role type');
      return;
    }

    this.setState({
      rowsLoading: true,
    });

    const { rows } = this.state;
    const currentRow = rows.find(row => row.organizationId === organizationId);
    let mappingId;

    if (currentRow && currentRow.roles) {
      mappingId = currentRow.roles.find(mapping => mapping.type === roleType).id;
    }

    if (!mappingId) {
      toast.error('Invalid mapping id');
    }

    dataProvider(UPDATE, 'reportTypesToOrganizationsToRoles', {
      id: mappingId,
      sort_order: newValue,
    }).then(result => {
      const { rows } = this.state;
      currentRow.roles = [
        ...currentRow.roles.filter(role => role.type !== roleType),
        {
          id: mappingId,
          type: roleType,
          sortOrder: newValue,
        },
      ];

      this.setState({
        rowsLoading: false,
        rows: [...rows.filter(row => row.organizationId !== organizationId), currentRow],
      });
    });
  };

  handleNewOrgChange = event => {
    const { allOrganizations } = this.state;
    const {
      target: { value },
    } = event;

    const clickedOrganization = allOrganizations.find(org => org.name === value);

    if (!clickedOrganization) {
      return;
    }

    this.setState({
      rows: Array.prototype.concat(this.state.rows, {
        organizationId: clickedOrganization.id,
        organizationName: clickedOrganization.name,
        roles: [],
      }),
    });
  };

  render() {
    const { classes } = this.props;
    const { rows, allOrganizations, loading, rowsLoading } = this.state;

    return loading ? (
      <div>Loading organizational data...</div>
    ) : (
      <Fragment>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="select-organizations">Organizations</InputLabel>
          <PasswordPrompt onCancel={this.stopConfirmingDelete}>
            {({ promptCheck }) => (
              <Fragment>
                <List className={classes.root}>
                  {rows.map((row, index) => (
                    <Fragment key={index}>
                      <ListItem className={classes.listItem}>
                        <div>{row.organizationName}</div>
                        <RoleInput
                          row={row}
                          roleType="system-administrator"
                          label="System Administrator"
                          onToggleChange={this.handleRoleToggle}
                          onSortOrderChange={this.handleSortOrderChange}
                          classes={classes}
                          loading={rowsLoading}
                          promptCheck={promptCheck}
                        />
                        <RoleInput
                          row={row}
                          roleType="organization-administrator"
                          label="Organization Administrator"
                          onToggleChange={this.handleRoleToggle}
                          onSortOrderChange={this.handleSortOrderChange}
                          classes={classes}
                          loading={rowsLoading}
                          promptCheck={promptCheck}
                        />
                        <RoleInput
                          row={row}
                          roleType="organization-user"
                          label="Organization User"
                          onToggleChange={this.handleRoleToggle}
                          onSortOrderChange={this.handleSortOrderChange}
                          classes={classes}
                          loading={rowsLoading}
                          promptCheck={promptCheck}
                        />
                      </ListItem>
                      <Divider />
                    </Fragment>
                  ))}
                  <ListItem className={classes.listItem}>
                    <Select
                      value={'Add new organization'}
                      onChange={this.handleNewOrgChange}
                      className={classes.newOrgSelect}
                    >
                      <MenuItem value="Add new organization">Add new organization</MenuItem>
                      {allOrganizations.map(
                        organization =>
                          !rows.find(row => row.organizationName === organization.name) && (
                            <MenuItem
                              key={organization.id}
                              value={organization.name}
                              style={getStyles(organization, this)}
                            >
                              {organization.name}
                            </MenuItem>
                          ),
                      )}
                    </Select>
                  </ListItem>
                </List>
              </Fragment>
            )}
          </PasswordPrompt>
        </FormControl>
      </Fragment>
    );
  }
}

const OrganizationInput = withStyles(styles, { withTheme: true })(
  withApollo(OrganizationReferenceInput),
);

export const ReportTypeEdit = props => (
  <Can perform="reportTypes:update" no={<Redirect to={redirectUrl} />}>
    <Edit {...props}>
      <SimpleForm
        validate={values => validate(formSchema, values)}
        toolbar={
          <EditToolbar
            object="reportTypes"
            deleteModalHeader="Are you sure you want to delete this Report Type?"
          />
        }
      >
        <FormHeader text="Report Type - Edit" />
        <TextInput source="display_name" required />
        <TextInput source="component_id" required />
        <OrganizationInput />
      </SimpleForm>
    </Edit>
  </Can>
);
