import * as yup from 'yup';

import {
  REQUIRED_FIELD,
  NAME_MIN_LENGTH,
  NAME_MAX_LENGTH,
  DESCRIPTION_MAX_LENGTH,
  NUMBER,
} from '../../utils/validations';

export { ZoneCreate } from './ZoneCreate';
export { ZoneEdit } from './ZoneEdit';
export { ZoneList } from './ZoneList';

export const redirectUrl = '/manage/users';

export const formSchema = yup.object().shape({
  name: yup
    .string()
    .required(REQUIRED_FIELD.message)
    .min(NAME_MIN_LENGTH.value, NAME_MIN_LENGTH.message)
    .max(NAME_MAX_LENGTH.value, NAME_MAX_LENGTH.message),
  description: yup
    .string()
    .nullable()
    .max(DESCRIPTION_MAX_LENGTH.value, DESCRIPTION_MAX_LENGTH.message),
  vendor_zone_id: yup
    .string()
    .nullable()
    .max(NAME_MAX_LENGTH.value, NAME_MAX_LENGTH.message),
  floor: yup
    .string()
    .nullable()
    .max(NAME_MAX_LENGTH.value, NAME_MAX_LENGTH.message),
  origin_offset_x: yup
    .number()
    .typeError(NUMBER.message)
    .required(REQUIRED_FIELD.message),
  origin_offset_y: yup
    .number()
    .typeError(NUMBER.message)
    .required(REQUIRED_FIELD.message),
  meter_to_pixel_scaler_x: yup
    .number()
    .typeError(NUMBER.message)
    .required(REQUIRED_FIELD.message),
  meter_to_pixel_scaler_y: yup
    .number()
    .typeError(NUMBER.message)
    .required(REQUIRED_FIELD.message),
});
