const moment = require('moment-timezone');

export function tryFor(method, duration, every = 100) {
  return new Promise((resolve, reject) => {
    const time = moment.duration(duration);
    const stop = moment().add(time);

    const check = setInterval(() => {
      const now = moment();

      if (now > stop) {
        clearInterval(check);
        return reject('Past duration');
      }

      const result = method();
      if (result) {
        clearInterval(check);
        return resolve(result);
      }
    }, every);
  });
}

export function searchLocalStorage(input = /.+/g) {
  let result;

  if (typeof input === 'object') {
    Object.keys(localStorage).forEach(key => {
      if (input.test(key)) {
        result = key;
      }
    });
  } else {
    Object.keys(localStorage).forEach(key => {
      if (key === input) {
        result = key;
      }
    });
  }

  if (result) {
    return localStorage.getItem(result);
  }

  return false;
}

export function mapToArray(map) {
  const res = [];
  map.forEach(value => res.push(value));

  return res;
}

export function mmToPixels(input, conversionFactor, offset) {
  const mmToM = input / 1000;
  const mToPixels = mmToM / conversionFactor;
  const offsetPixels = offset / conversionFactor;

  return Math.round(mToPixels + offsetPixels);
}

export function getCurrentTimeFromTimezone(timezone) {
  return moment()
    .tz(timezone)
    .format('HH:mm');
}

export function formatUnixTime(time, options = {}) {
  const { seconds } = options;

  return moment.unix(time).format('MM/DD/YYYY HH:mm' + (seconds ? ':ss' : ''));
}

export function formatTimestamp(time, options = {}) {
  const { seconds, isUnix } = options;
  const format = 'MM/DD/YYYY @ HH:mm' + (seconds ? ':ss' : '');

  if (isUnix) {
    return moment.unix(time).format(format);
  }

  return moment(time).format(format);
}

export function generateShortId() {
  return (
    Math.random()
      .toString(36)
      .substring(2, 6) +
    Math.random()
      .toString(36)
      .substring(8, 12)
  );
}

export function pluralize(count, singular, plural, pluralCheck = false) {
  /*
    Description: Takes singular and plural versions of a string and returns an appropriate
      value depending on the provided count. Defaults to integers greater than 1 when
      returning plural but can be overridden.
    Params:
      count - Numeric value used to compare
      singular - Singular version of the string to return
      plural - Plural version of the string to return
      pluralCheck [optional] - Function to override the default comparison used to return the plural version
  */

  if (pluralCheck && typeof pluralCheck === 'function') {
    return pluralCheck() ? plural : singular;
  }

  return `${count > 1 ? plural : singular}`;
}

export function capitalize(string) {
  /*
    Description: Takes a string, capitalizes the first letter and lowercases everything else.
      Useful for taking an all-caps string and turning it into something less loud.
    Params:
      string - Any string value
  */

  if (!string || !string.length || string.length <= 0) {
    return '';
  }

  return `${string.charAt(0).toUpperCase()}${string.substring(1, string.length).toLowerCase()}`;
}

export function humanizeSeconds(seconds) {
  /*
    Description: Converts a number of seconds to a readable format. Examples: 75 -> 1m 15s,
      5689 -> 1h 34m 49s, 5640 -> 1h 34m.
    Params:
      seconds - Any numeric value
  */

  if (!seconds || isNaN(seconds) || seconds < 0) {
    return '0s';
  }

  const secondsParsed = parseInt(seconds, 10);

  if (secondsParsed < 60) {
    return `${secondsParsed}s`;
  }

  if (secondsParsed < 60 * 60) {
    const minutes = Math.floor(secondsParsed / 60);
    const secondsReturn = Math.floor(secondsParsed % 60);

    return `${minutes}m${secondsReturn > 0 ? ` ${secondsReturn}s` : ''}`;
  }

  const minutes = Math.floor(secondsParsed / 60) % 60;
  const hours = Math.floor(secondsParsed / 60 / 60);
  const secondsReturn = Math.floor(secondsParsed % 60);

  return `${hours}h${minutes > 0 ? ` ${minutes}m` : ''}${
    secondsReturn > 0 ? ` ${secondsReturn}s` : ''
  }`;
}

export function getMin() {
  /*
    Description: Returns the lowest value from a set of arguments
    Params: any, only numeric values will be considered during evaluation
    Example:
      getMin(3, 10 / 5, 7); // returns 2
  */

  const argLength = arguments.length;

  if (argLength <= 0) {
    return 0;
  }

  let max = arguments[0];

  for (let i = 0; i < argLength; i += 1) {
    const arg = arguments[i];

    if (!isNaN(arg) && arg < max) {
      max = arg;
    }
  }

  return max;
}

export const timezoneOptions = [
  {
    id: 'US/Eastern',
    name: 'Eastern',
  },
  {
    id: 'US/Central',
    name: 'Central',
  },
  {
    id: 'US/Mountain',
    name: 'Mountain',
  },
  {
    id: 'US/Pacific',
    name: 'Pacific',
  },
];

export function getHumanizedTimestamp(timestamp, notUnix) {
  const time = notUnix ? moment(timestamp) : moment.unix(timestamp);
  return time.fromNow();
}

// returns an AWS date string (eg "2019-06-24") from any timestamp. call without
// any arguments to return today's date
export function getAWSDate(timestamp) {
  const time = moment(
    moment
      .utc(timestamp)
      .startOf('day')
      .format('LL'),
  ).startOf('day');
  const returnValue = time.format('YYYY-MM-DD');

  return returnValue;
}

// returns an AWS date string (eg "2019-06-24T04:25:32-07:00") from any timestamp. call without
// any arguments to return now
export function getAWSDateTime(timestamp) {
  return moment(timestamp).format();
}
