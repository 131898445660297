import React from 'react';
import { Redirect } from 'react-router-dom';
import { Edit, SimpleForm, TextInput, BooleanInput } from 'react-admin';

import Can from '../../components/Can/Can';
import EditToolbar from '../common/EditToolbar/EditToolbar';
import FormHeader from '../common/FormHeader/FormHeader';
import LongTextInput from '../common/LongTextInput/LongTextInput';
import TypeToOrganization from '../common/TypeToOrganization/TypeToOrganization';
import { validate } from '../../utils/admin';
import { formSchema, redirectUrl } from './index.js';

export const AssetTypeEdit = props => (
  <Can perform="assetTypes:update" no={<Redirect to={redirectUrl} />}>
    <Edit {...props}>
      <SimpleForm
        validate={values => validate(formSchema, values)}
        toolbar={
          <EditToolbar
            object="assetTypes"
            deleteModalHeader="Are you sure you want to delete this Asset Type?"
          />
        }
      >
        <FormHeader text="Asset Type - Edit" />
        <TextInput source="name" required />
        <TextInput source="plural" required />
        <LongTextInput source="description" showCounter />
        <BooleanInput source="trigger_entry_alerts" label="Trigger room entry alerts" />
        <TypeToOrganization resourceName="assetTypesToOrganization" objectType="asset_type" />
      </SimpleForm>
    </Edit>
  </Can>
);
