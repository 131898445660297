import * as yup from 'yup';

import { REQUIRED_FIELD, TAG_SERIAL_NUMBER_REGEX } from '../../utils/validations';

export { TagCreate } from './TagCreate';
export { TagEdit } from './TagEdit';
export { TagList } from './TagList';

export const redirectUrl = '/locate';

export const formSchema = yup.object().shape({
  serial_number: yup
    .string()
    .required(REQUIRED_FIELD.message)
    .matches(TAG_SERIAL_NUMBER_REGEX.value, TAG_SERIAL_NUMBER_REGEX.message),
});
