import React from 'react';
import { Query } from 'react-apollo';

const State = ({ query, render }) => (
  <Query query={query}>
    {({ data, error, loading }) => {
      if (loading) {
        return <div>Loading</div>;
      }

      if (error) {
        return <div>Query error: {JSON.stringify(error)}</div>
      }

      try {
        return render(data);
      } catch (err) {
        return JSON.stringify(err);
      }
    }}
  </Query>
);

export default State;
