import React, { Component } from 'react';
import queryString from 'query-string';

import { Button, Form, Input, FormGroup, InputGroup } from 'reactstrap';

import history from '../../../utils/history';

class SearchField extends Component {
  constructor(props) {
    super(props);

    const { search } = props.location;
    const { q } = queryString.parse(search.replace('?', ''));

    this.state = {
      searchText: q || '',
    };
  }

  componentWillReceiveProps(nextProps) {
    const { search } = nextProps.location;

    if (search !== this.props.location.search) {
      const { q } = queryString.parse(search.replace('?', ''));
      this.setState({
        searchText: q || '',
      });
    }
  }

  updateSearch = e => {
    this.setState({
      searchText: e.target.value,
    });
  };

  handleSearch = e => {
    e.preventDefault();
    const { searchText } = this.state;
    const newUrl = `/locate/search?q=${searchText || ''}`;

    history.push(newUrl);
    this.props.onSearch();
  };

  render() {
    const {
      state: { searchText },
      props: { toggleSearchFieldMenu },
    } = this;

    return (
      <div className="search-container">
        <Form onSubmit={this.handleSearch}>
          <FormGroup>
            <InputGroup>
              <span className="input-group-prepend">
                <Button
                  className="locate__overview__search-field-menu-button"
                  type="button"
                  onClick={toggleSearchFieldMenu}
                >
                  <i className="fas fa-bars" />
                </Button>
              </span>
              <Input
                className="locate__overview__search-field"
                placeholder="Search"
                value={searchText}
                onChange={this.updateSearch}
              />
              <span className="input-group-append">
                <Button
                  className="locate__overview__search-field-search-button btn-primary"
                  type="submit"
                >
                  <i className="fas fa-search" />
                </Button>
              </span>
            </InputGroup>
          </FormGroup>
        </Form>
      </div>
    );
  }
}

export default SearchField;
