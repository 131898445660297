// ****************************************************
// Common
// ****************************************************

export const REQUIRED_FIELD = {
  message: 'This field is required',
};

export const EMAIL = {
  message: 'Invalid email',
};

export const DEFAULT_MIN_LENGTH = {
  message: 'Too short',
};

export const DEFAULT_MAX_LENGTH = {
  message: 'Too long',
};

export const NUMBER = {
  value: /^[0-9]+.?[0-9]*$/,
  message: 'Invalid number',
};

export const DESCRIPTION_MIN_LENGTH = {
  value: 1,
  message: 'Too short',
};

export const DESCRIPTION_MAX_LENGTH = {
  value: 500,
  message: 'Too long',
};

export const DATE = {
  message: 'Invalid date',
};

// ****************************************************
// Passwords
// ****************************************************

export const PASSWORD_MIN_LENGTH = {
  value: 8,
  message: 'Too short',
};

export const PASSWORD_MAX_LENGTH = {
  value: 100,
  message: 'Too long',
};

export const PASSWORD_REGEX = {
  value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`+=])[A-Za-z\d^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`+=]{8,}$/,
  message:
    'Password must have: 1 uppercase letter, 1 lowercase letter, 1 number, 1 special character',
};

export const PASSWORD_MATCH = {
  message: 'Passwords do not match',
};

// ****************************************************
// User Settings
// ****************************************************

export const NAME_REGEX = {
  value: /^[a-z ,.'-]+$/i,
  message: 'Invalid name',
};

export const NAME_MIN_LENGTH = {
  value: 1,
  message: 'Too short',
};

export const NAME_MAX_LENGTH = {
  value: 100,
  message: 'Too long',
};

export const PHONE_NUMBER_REGEX = {
  value: /^([0-9]{10})$|^([0-9]{3}-[0-9]{3}-[0-9]{4})$/, // unchanged value from db or valid new number
  message: 'Invalid phone number',
};

export const TIMEZONE_NAME_REGEX = {
  value: /^US\/(Eastern|Central|Mountain|Pacific)+$/, // must match momentJS timezone names
  message: 'Invalid timezone',
};

// ****************************************************
// Object-specific
// ****************************************************

export const TAG_SERIAL_NUMBER_REGEX = {
  value: /^[A-a0-9]{2}:[A-a0-9]{2}:[A-a0-9]{4}$/,
  message: 'Invalid serial number',
};

export const ROOM_VERTICES_MIN_LENGTH = {
  value: 3,
  message: 'Three vertices required',
};
