import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => (
  <div className="not-found">
    <h1>Page not found</h1>
    <p>Uh oh! We couldn't find the page you're looking for.</p>
    <p>
      <Link to="/">Return to home</Link>
    </p>
  </div>
);

export default NotFound;
