import React from 'react';
import ReactDOM from 'react-dom';

export default ({ show = true, noOverlay = false, children }) => {
  const element = document.querySelector('.app-layout--portal-root');

  if (element) {
    element.classList[show ? 'add' : 'remove']('show');
  }

  return ReactDOM.createPortal(
    <div className="global-portal-wrapper">
      {!noOverlay && <div className="global-portal-overlay" />}
      {children}
    </div>,
    document.querySelector('.app-layout--portal-root'),
  );
};
