export const updateImpersonating = (_, { impersonating }, { cache }) => {
  try {
    const data = { impersonating };

    cache.writeData({ data });
    return null;
  } catch (e) {
    console.log('e: ', e);
    return null;
  }
}
