import React, { Fragment } from 'react';

const PasswordRules = () => (
  <Fragment>
    New password must be at least 8 characters and contain:
    <ul>
      <li>1 uppercase letter</li>
      <li>1 lowercase letter</li>
      <li>1 number</li>
      <li>1 special character</li>
    </ul>
  </Fragment>
);

export default PasswordRules;
