import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import AssetCard from './AssetCard';
import { Alert } from 'reactstrap';
import { tagIsMissing } from '../../../utils/map';

const missingHeading = 'Missing';

class AssetsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true,
      listItems: this.generateList(props), // set initial list state so items don't jump around on subscription updates
    };
  }

  componentWillReceiveProps(nextProps) {
    const { filter: currentFilter } = this.props;
    const { filter: nextFilter } = nextProps;

    const equal = !Array.from(nextFilter).some(item => Array.from(currentFilter).includes(item));

    if (!equal) {
      this.setState({
        listItems: this.generateList(nextProps),
      });
    }
  }

  generateList = props => {
    const { selectedZone, filter } = props;
    const { assets: zoneAssets } = selectedZone;
    const assets =
      filter && filter.size
        ? Array.from(new Set(zoneAssets.filter(asset => filter.has(asset.id))))
        : zoneAssets;
    const types = Array.from(new Set(assets.map(asset => asset.asset_type.name))).sort(
      (a, b) => a + b,
    );

    // check for missing tags, add to `types` if exists
    if (
      assets.some(asset =>
        tagIsMissing(asset.tag && asset.tag.location && asset.tag.location.DeviceEventTime),
      )
    ) {
      types.push(missingHeading);
    }

    // construct list of assets to use during render
    const listItems = types.map(type => {
      return {
        heading: type,
        assets: assets.filter(asset => {
          const missing =
            (type === missingHeading && tagIsMissing(asset.tag.location.DeviceEventTime)) ||
            (asset.asset_type.name === type && !tagIsMissing(asset.tag.location.DeviceEventTime));

          return filter && filter.size
            ? filter.has(asset.id) && missing // this.props.filter is array of asset IDs, we filter the assets by matching ids
            : missing;
        }),
      };
    });

    return listItems;
  };

  onDismiss = () => {
    const { visible } = this.state;

    this.setState({
      visible: !visible,
    });
  };

  render() {
    const { listItems } = this.state;
    const { selectedZone, filter, selectOneAsset, clearHoverAsset, hoverOverAsset } = this.props;

    return (
      <div className="locate__assets-list">
        {filter.size > 0 && (
          <Alert color="info" isOpen={this.state.visible} toggle={this.onDismiss}>
            You are seeing filtered assets based on your selections from the map.
          </Alert>
        )}
        <div className="cards-list">
          {listItems && listItems.length > 0 ? (
            listItems.map(type => {
              const { assets } = type;
              const firstAsset = assets && assets.length > 0 && assets[0];

              return (
                firstAsset && (
                  <Fragment key={type.heading}>
                    <h4>
                      {type.assets.length}{' '}
                      {type.heading === missingHeading ? (
                        missingHeading
                      ) : (
                        <span>
                          {type.assets.length > 1
                            ? firstAsset.asset_type.plural
                            : firstAsset.asset_type.name}{' '}
                          in {firstAsset.tag.location.ZoneName || firstAsset.tag.location.zone.name}
                        </span>
                      )}
                    </h4>
                    {type.assets.map(asset => (
                      <AssetCard
                        key={`asset-card-${asset.id}`}
                        asset={asset}
                        zoneId={selectedZone.id}
                        clearHoverAsset={clearHoverAsset}
                        hoverOverAsset={hoverOverAsset}
                        selectOneAsset={selectOneAsset}
                      />
                    ))}
                  </Fragment>
                )
              );
            })
          ) : (
            <Alert color="info">No assets in this zone</Alert>
          )}
        </div>
      </div>
    );
  }
}

AssetsList.propTypes = {
  history: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({}).isRequired,
  currentVenue: PropTypes.shape({}).isRequired,
};

export default AssetsList;
