import React from 'react';
import ReactResizeDetector from 'react-resize-detector';

class ResizeDetector extends React.Component {
  resizeWindow = () => {
    try {
      window.dispatchEvent(new Event('resize'));
    } catch (e) {
      const resizeEvent = window.document.createEvent('UIEvents');
      resizeEvent.initUIEvent('resize', true, false, window, 0);
      window.dispatchEvent(resizeEvent);
    }
  };

  render() {
    return (
      <ReactResizeDetector handleWidth handleHeight onResize={this.resizeWindow}>
        {this.props.children}
      </ReactResizeDetector>
    );
  }
}

export default ResizeDetector;
