import * as yup from 'yup';

import { REQUIRED_FIELD } from '../../utils/validations';

export { VisionDeviceCreate } from './VisionDeviceCreate';
export { VisionDeviceEdit } from './VisionDeviceEdit';
export { VisionDeviceList } from './VisionDeviceList';

export const redirectUrl = '/locate';

export const formSchema = yup.object().shape({
  device_id: yup.string().required(REQUIRED_FIELD.message),
});
