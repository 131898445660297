import React from 'react';
import { Redirect } from 'react-router-dom';
import { List, Datagrid, TextField, EditButton } from 'react-admin';

import Can from '../../components/Can/Can';
import PasswordPrompt from '../../components/PasswordPrompt/PasswordPrompt';
import BulkActionButtons from '../common/BulkActionButtons/BulkActionButtons';
import ListActions from '../common/ListActions/ListActions';
import Pagination from '../common/Pagination/Pagination';
import { redirectUrl } from './index.js';

export const SiteUserList = props => (
  <Can perform="siteUsers:list" no={<Redirect to={redirectUrl} />}>
    <PasswordPrompt>
      {({ promptCheck }) => (
        <List
          {...props}
          perPage={15}
          pagination={<Pagination />}
          actions={<ListActions title="Users" object="siteUsers" />}
          bulkActionButtons={
            <BulkActionButtons
              object="siteUsers"
              deleteModalHeader="Are you sure you want to delete the selected Users?"
            />
          }
          sort={{ field: 'last_name', order: 'ASC' }}
        >
          <Datagrid>
            <TextField source="first_name" />
            <TextField source="last_name" />
            <TextField source="email" />
            <TextField source="role" />
            <Can perform="siteUsers:update">
              <EditButton basePath="/users" />
            </Can>
          </Datagrid>
        </List>
      )}
    </PasswordPrompt>
  </Can>
);
