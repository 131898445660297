import React, { useState } from 'react';
import { Edit, SimpleForm, TextInput, ReferenceInput, SelectInput } from 'react-admin';

import Can from '../../components/Can/Can';
import EditToolbar from '../common/EditToolbar/EditToolbar';
import FormHeader from '../common/FormHeader/FormHeader';
import LongTextInput from '../common/LongTextInput/LongTextInput';
import { validate } from '../../utils/admin';
import { formSchema } from './index.js';
import { REQUIRED_FIELD } from '../../utils/validations';
import MapView from './MapView';
import InputWrapper from '../common/InputWrapper/InputWrapper';

export const ZoneEdit = props => {
  const [vendorZoneId, setVendorZoneId] = useState();

  return (
    <div className="zone-edit-form">
      <Can perform="zones:list">
        {({ check }) => {
          const canUpdate = check('zones:update');
          const canUpdateCoordinateVars = check('zones:updateCoordinateVars');

          return (
            <Edit {...props}>
              <SimpleForm
                validate={values => validate(formSchema, values)}
                toolbar={
                  <EditToolbar
                    object="zones"
                    deleteModalHeader="Are you sure you want to delete this Zone?"
                    readOnly={!canUpdate}
                  />
                }
              >
                <FormHeader text="Zone - Edit" />
                <TextInput source="name" required disabled={!canUpdate} />
                <LongTextInput source="description" showCounter disabled={!canUpdate} />
                <ReferenceInput
                  label="Venue"
                  source="venue.id"
                  reference="venues"
                  validate={value => !value && REQUIRED_FIELD.message}
                  required
                  disabled={!canUpdate}
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
                <InputWrapper
                  components={Array.prototype.concat(
                    [
                      <TextInput
                        source="vendor_zone_id"
                        validate={values => setVendorZoneId(values)}
                        disabled={!canUpdate}
                      />,
                    ],
                    vendorZoneId ? <MapView vendorZoneId={vendorZoneId} /> : [],
                  )}
                />
                <TextInput source="floor" disabled={!canUpdate} />
                <TextInput source="origin_offset_x" disabled={!canUpdateCoordinateVars} />
                <TextInput source="origin_offset_y" disabled={!canUpdateCoordinateVars} />
                <TextInput source="meter_to_pixel_scaler_x" disabled={!canUpdateCoordinateVars} />
                <TextInput source="meter_to_pixel_scaler_y" disabled={!canUpdateCoordinateVars} />
              </SimpleForm>
            </Edit>
          );
        }}
      </Can>
    </div>
  );
};
