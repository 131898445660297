export const toggleSidebar = (_, { open }, { cache }) => {
  try {
    const data = {
      sidebar: {
        __typename: 'Sidebar',
        open,
      },
    };

    cache.writeData({ data });
    return null;
  } catch (e) {
    console.log('e: ', e);
    return null;
  }
};

export const toggleView = (_, { view }, { cache }) => {
  try {
    const data = {
      sidebar: {
        __typename: 'Sidebar',
        view,
      },
    };

    cache.writeData({ data });
    return null;
  } catch (e) {
    console.log('e: ', e);
    return null;
  }
};
