import React, { Component, Fragment } from 'react';
import { Auth } from 'aws-amplify';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Button, Form, Row, Col } from 'reactstrap';

import logo from '../../components/Header/logo.svg';
import { toast } from '../../components/Toast/Toast';
import TextInput from '../../components/TextInput/TextInput';
import { LOCATE_ROUTE, MANAGE_ROUTE } from '../../utils/routes';
import PasswordRules from '../../components/PasswordRules/PasswordRules';
import {
  REQUIRED_FIELD,
  EMAIL,
  PASSWORD_MIN_LENGTH,
  PASSWORD_MATCH,
} from '../../utils/validations';
import { baseName } from '../../utils/history';

const loginFormSchema = yup.object().shape({
  email: yup
    .string()
    .required(REQUIRED_FIELD.message)
    .email(EMAIL.message),
  password: yup.string().required(REQUIRED_FIELD.message),
});

const newPasswordFormSchema = yup.object().shape({
  email: yup
    .string()
    .required(REQUIRED_FIELD.message)
    .email(EMAIL.message),
  newPassword: yup
    .string()
    .required(REQUIRED_FIELD.message)
    .min(PASSWORD_MIN_LENGTH.value, PASSWORD_MIN_LENGTH.message),
  confirmPassword: yup
    .string()
    .required(REQUIRED_FIELD.message)
    .min(PASSWORD_MIN_LENGTH.value, PASSWORD_MIN_LENGTH.message)
    .oneOf([yup.ref('newPassword')], PASSWORD_MATCH.message),
});

const forgotPasswordFormSchema = yup.object().shape({
  email: yup
    .string()
    .required(REQUIRED_FIELD.message)
    .email(EMAIL.message),
});

const resetPasswordFormSchema = yup.object().shape({
  code: yup
    .number()
    .required(REQUIRED_FIELD.message)
    .typeError('Invalid code format')
    .integer('Invalid code format'),
  newPassword: yup
    .string()
    .required(REQUIRED_FIELD.message)
    .min(PASSWORD_MIN_LENGTH.value, PASSWORD_MIN_LENGTH.message),
  confirmPassword: yup
    .string()
    .required(REQUIRED_FIELD.message)
    .min(PASSWORD_MIN_LENGTH.value, PASSWORD_MIN_LENGTH.message)
    .oneOf([yup.ref('newPassword')], PASSWORD_MATCH.message),
});

class Login extends Component {
  state = {
    loading: false,
    showLogin: true,
    showNewPassword: false,
    showForgot: false,
    forgotSubmitted: false,
    forgotEmail: null,
    data: null,
  };

  handleSubmitLogin = event => {
    this.setState({
      loading: true,
    });

    const { email, password } = event;

    Auth.signIn(email, password)
      .then(async user => {
        const { challengeName } = user;

        if (challengeName && challengeName === 'NEW_PASSWORD_REQUIRED') {
          this.setState({
            showLogin: false,
            showNewPassword: true,
            loading: false,
            data: {
              email,
              password,
            },
          });
        } else {
          this.handleLoginSuccess(user);
        }
      })
      .catch(error => {
        toast.error('Error signing in');
        this.setState({
          loading: false,
        });
      });
  };

  handleSubmitNewPassword = event => {
    const {
      data: { email, password },
    } = this.state;
    const { newPassword } = event;
    const genericError = 'Error submitting new password';

    Auth.signIn(email, password).then(user => {
      Auth.completeNewPassword(user, newPassword)
        .then(result => {
          Auth.signOut()
            .then(signOutResult => {
              // need to reload the page after sign out
              // TODO: Feature branches will land on a 404 page during this flow
              window.location.reload();
            })
            .catch(({ message: signOutError }) => {
              toast.error(signOutError || genericError);
            });
        })
        .catch(({ message: newPasswordError }) => {
          toast.error(newPasswordError || genericError);
        });
    });
  };

  handleLoginSuccess = user => {
    const {
      signInUserSession: { idToken },
    } = user;

    if (idToken) {
      const groups = idToken.payload['cognito:groups'];
      if (groups.includes('system-administrator')) {
        window.location.assign(`${baseName}${MANAGE_ROUTE}`);
      } else if (groups.includes('organization-administrator')) {
        window.location.assign(`${baseName}${MANAGE_ROUTE}`);
      } else {
        window.location.assign(`${baseName}${LOCATE_ROUTE}`);
      }
    }
  };

  handleSubmitForgot = event => {
    this.setState({
      loading: true,
    });

    const { email, resend } = event;
    const success = () =>
      this.setState({
        loading: false,
        forgotSubmitted: true,
        forgotEmail: email,
      });

    Auth.forgotPassword(email)
      .then(result => {
        success();

        if (resend) {
          toast.success('Resend successful');
        }
      })
      .catch(error => {
        success();
      });
  };

  resendForgotEmail = () => {
    const { forgotEmail: email } = this.state;
    this.handleSubmitForgot({ email, resend: true });
  };

  handleSubmitReset = event => {
    const { forgotEmail: email } = this.state;
    const { code, newPassword } = event;

    Auth.forgotPasswordSubmit(email, code, newPassword)
      .then(result => {
        toast.success('Password reset successful');
        this.setState({
          showLogin: true,
          showForgot: false,
          forgotSubmitted: false,
        });
      })
      .catch(error => {
        toast.error(error && error.message ? error.message : 'Error submitting forgot password');
      });
  };

  toggleForgot = () => {
    const newValue = !this.state.showForgot;
    const newState = {
      showForgot: newValue,
    };

    if (newValue === false) {
      newState.forgotSubmitted = false;
      newState.showLogin = true;
    } else {
      newState.showLogin = false;
    }

    this.setState(newState);
  };

  render() {
    const {
      loading,
      showLogin,
      showNewPassword,
      showForgot,
      forgotSubmitted,
      forgotEmail,
    } = this.state;

    return (
      <div className="login-page">
        <div className="overlay" />
        <div className="content mt-5">
          <div className="form-header">
            <img className="logo" src={logo} alt="Repp Health logo" />
          </div>
          <div className="body">
            {showLogin && (
              <Fragment>
                <div className="message mb-3">Sign in with your email and password</div>
                <div className="form">
                  <Formik
                    onSubmit={this.handleSubmitLogin}
                    validationSchema={loginFormSchema}
                    initialValues={{
                      email: '',
                      password: '',
                    }}
                  >
                    {({ handleSubmit, handleChange, values, isValid, touched, errors }) => (
                      <Form
                        noValidate
                        onSubmit={handleSubmit}
                        className="login-form"
                        autoComplete="off"
                      >
                        <Row>
                          <Col>
                            <TextInput
                              required
                              id="email"
                              label="Email"
                              type="email"
                              name="email"
                              placeholder="Email"
                              value={values.email}
                              onChange={handleChange}
                              error={touched.email && !!errors.email}
                              errorText={errors.email}
                              autoOff
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <TextInput
                              required
                              id="password"
                              label="Password"
                              type="password"
                              name="password"
                              placeholder="Password"
                              value={values.password}
                              onChange={handleChange}
                              error={touched.password && !!errors.password}
                              errorText={errors.password}
                            />
                            <Button className="link" onClick={this.toggleForgot}>
                              Forgot your password?
                            </Button>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col>
                            <Button
                              name="signin-submit-button"
                              className="submit-button"
                              type="submit"
                              disabled={loading}
                              color="primary"
                            >
                              Sign in
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Fragment>
            )}
            {showNewPassword && (
              <Fragment>
                <div className="message mb-3">Enter a new password below</div>
                <div className="form">
                  <Formik
                    onSubmit={this.handleSubmitNewPassword}
                    validationSchema={newPasswordFormSchema}
                    initialValues={{
                      email: '',
                      newPassword: '',
                      confirmPassword: '',
                    }}
                  >
                    {({ handleSubmit, handleChange, values, isValid, touched, errors }) => (
                      <Form
                        noValidate
                        onSubmit={handleSubmit}
                        className="first-login-form"
                        autoComplete="off"
                      >
                        <Row>
                          <Col>
                            <TextInput
                              required
                              id="email"
                              label="Email"
                              type="email"
                              name="email"
                              placeholder="Email"
                              value={values.email}
                              onChange={handleChange}
                              error={touched.email && !!errors.email}
                              errorText={errors.email}
                              autoOff
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <PasswordRules />
                            <TextInput
                              required
                              id="newPassword"
                              label="New Password"
                              type="password"
                              name="newPassword"
                              placeholder="New Password"
                              value={values.newPassword}
                              onChange={handleChange}
                              error={touched.newPassword && !!errors.newPassword}
                              errorText={errors.newPassword}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <TextInput
                              required
                              id="confirmPassword"
                              label="Confirm Password"
                              type="password"
                              name="confirmPassword"
                              placeholder="Confirm Password"
                              value={values.confirmPassword}
                              onChange={handleChange}
                              error={touched.confirmPassword && !!errors.confirmPassword}
                              errorText={errors.confirmPassword}
                            />
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col>
                            <Button
                              name="new-password-submit-button"
                              className="submit-button"
                              type="submit"
                              disabled={loading}
                              color="primary"
                            >
                              Submit
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Fragment>
            )}
            {showForgot && !forgotSubmitted && (
              <Fragment>
                <Button className="link mb-3" onClick={this.toggleForgot}>
                  <i className="fas fa-chevron-left fa-sm" /> Back to login
                </Button>
                <div className="message mb-3">
                  Enter your email below and we will send a message to reset your password
                </div>
                <div className="form">
                  <Formik
                    onSubmit={this.handleSubmitForgot}
                    validationSchema={forgotPasswordFormSchema}
                    initialValues={{
                      email: '',
                    }}
                  >
                    {({ handleSubmit, handleChange, values, isValid, touched, errors }) => (
                      <Form
                        noValidate
                        onSubmit={handleSubmit}
                        className="forgot-password-form"
                        autoComplete="off"
                      >
                        <Row>
                          <Col>
                            <TextInput
                              required
                              id="email"
                              label="Email"
                              type="email"
                              name="email"
                              placeholder="Email"
                              value={values.email}
                              onChange={handleChange}
                              error={touched.email && !!errors.email}
                              errorText={errors.email}
                              autoOff
                            />
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col>
                            <Button
                              name="forgot-password-submit-button"
                              className="submit-button"
                              type="submit"
                              disabled={loading}
                              color="primary"
                            >
                              Reset my password
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Fragment>
            )}
            {showForgot && forgotSubmitted && (
              <Fragment>
                <div className="message mb-3">
                  We have sent a password reset code by email to {forgotEmail}. Enter it below to
                  reset your password.{' '}
                  <Button className="link" onClick={this.resendForgotEmail}>
                    Resend
                  </Button>
                </div>
                <div className="form">
                  <Formik
                    onSubmit={this.handleSubmitReset}
                    validationSchema={resetPasswordFormSchema}
                    initialValues={{
                      code: '',
                      newPassword: '',
                      confirmPassword: '',
                    }}
                  >
                    {({ handleSubmit, handleChange, values, isValid, touched, errors }) => (
                      <Form
                        noValidate
                        onSubmit={handleSubmit}
                        className="reset-password-form"
                        autoComplete="off"
                      >
                        <Row>
                          <Col>
                            <TextInput
                              required
                              id="code"
                              label="Code"
                              type="text"
                              name="code"
                              placeholder="Code"
                              value={values.code}
                              onChange={handleChange}
                              error={touched.code && !!errors.code}
                              errorText={errors.code}
                              autoOff
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <PasswordRules />
                            <TextInput
                              required
                              id="newPassword"
                              label="New Password"
                              type="password"
                              name="newPassword"
                              placeholder="New Password"
                              value={values.newPassword}
                              onChange={handleChange}
                              error={touched.newPassword && !!errors.newPassword}
                              errorText={errors.newPassword}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <TextInput
                              required
                              id="confirmPassword"
                              label="Confirm Password"
                              type="password"
                              name="confirmPassword"
                              placeholder="Confirm Password"
                              value={values.confirmPassword}
                              onChange={handleChange}
                              error={touched.confirmPassword && !!errors.confirmPassword}
                              errorText={errors.confirmPassword}
                            />
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col>
                            <Button
                              name="reset-password-submit-button"
                              className="submit-button"
                              type="submit"
                              disabled={loading}
                              color="primary"
                            >
                              Reset my password
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
