import * as yup from 'yup';

import {
  REQUIRED_FIELD,
  NAME_MIN_LENGTH,
  NAME_MAX_LENGTH,
  DESCRIPTION_MAX_LENGTH,
  ROOM_VERTICES_MIN_LENGTH,
} from '../../utils/validations';

export { RoomCreate } from './RoomCreate';
export { RoomEdit } from './RoomEdit';
export { RoomList } from './RoomList';

export const redirectUrl = '/manage/users';

export const formSchema = yup.object().shape({
  name: yup
    .string()
    .required(REQUIRED_FIELD.message)
    .min(NAME_MIN_LENGTH.value, NAME_MIN_LENGTH.message)
    .max(NAME_MAX_LENGTH.value, NAME_MAX_LENGTH.message),
  description: yup
    .string()
    .nullable()
    .max(DESCRIPTION_MAX_LENGTH.value, DESCRIPTION_MAX_LENGTH.message),
  vertices: yup
    .array()
    .of(
      yup.object().shape({
        x: yup.number().required(),
        y: yup.number().required(),
      }),
    )
    .required(REQUIRED_FIELD.message)
    .min(ROOM_VERTICES_MIN_LENGTH.value, ROOM_VERTICES_MIN_LENGTH.message),
});

// vertices to use when creating a room without explicitly defining vertices
export const defaultRoomVertices = [
  {
    x: 1, // meters
    y: 1,
  },
  {
    x: 3,
    y: 1,
  },
  {
    x: 3,
    y: 3,
  },
  {
    x: 1,
    y: 3,
  },
];

export const DEFAULT_ANCHOR_RADIUS = 40; // px
export const DEFAULT_ANCHOR_STROKE = 3; // px
