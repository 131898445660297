import React, { Fragment } from 'react';

const MapKey = ({ showRoomPresence }) => (
  <div className="pi-map-key">
    {showRoomPresence && (
      <Fragment>
        <div className="pi-map-key-item">
          <div className="color room-presence--occupied" /> Occupied
        </div>
        <div className="pi-map-key-item">
          <div className="color room-presence--unoccupied" /> Unoccupied
        </div>
      </Fragment>
    )}
  </div>
);

export default MapKey;
