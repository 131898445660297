import gql from 'graphql-tag';

export default function tagQueries(raFetchType, params) {
  switch (raFetchType) {
    case 'CREATE':
      return {
        query: gql`
          mutation addVisionDevice($device_id: String!, $room_id: ID, $description: String) {
            addVisionDevice(room_id: $room_id, device_id: $device_id, description: $description) {
              id
            }
          }
        `,
        variables: {
          ...params.data,
          room_id: params.data.room && params.data.room.id,
        },
        parseResponse: response => ({ data: response.data.addVisionDevice }),
      };
    case 'UPDATE':
      return {
        query: gql`
          mutation updateVisionDevice(
            $id: ID!
            $room_id: ID
            $device_id: String
            $description: String
          ) {
            updateVisionDevice(
              id: $id
              room_id: $room_id
              device_id: $device_id
              description: $description
            ) {
              id
            }
          }
        `,
        variables: {
          ...params.data,
          room_id:
            params.data.room && params.data.room.id === ''
              ? null
              : params.data.room && params.data.room.id,
        },
        parseResponse: response => ({ data: response.data.updateVisionDevice }),
      };
    case 'GET_ONE':
      return {
        query: gql`
          query getVisionDevices($id: ID) {
            vision_devices(id: $id) {
              total
              data {
                ... on VisionDevice {
                  id
                  device_id
                  room {
                    id
                    name
                  }
                }
              }
            }
          }
        `,
        variables: params,
        parseResponse: response => ({
          data:
            response.data &&
            response.data.vision_devices &&
            response.data.vision_devices.data &&
            response.data.vision_devices.data.length &&
            response.data.vision_devices.data[0],
        }),
      };
    case 'GET_MANY':
    case 'GET_LIST':
      return {
        query: gql`
          query getVisionDevices($sort: Sort, $pagination: Pagination) {
            vision_devices(sort: $sort, pagination: $pagination) {
              total
              data {
                ... on VisionDevice {
                  id
                  device_id
                  room {
                    id
                    name
                  }
                }
              }
            }
          }
        `,
        variables: params,
        parseResponse: response => ({
          data: response.data.vision_devices ? response.data.vision_devices.data : [],
          total: response.data.vision_devices ? response.data.vision_devices.total : 0,
        }),
      };
    case 'DELETE':
      return {
        query: gql`
          mutation deleteVisionDevices($id: [ID!]!) {
            deleteVisionDevices(id: $id) {
              id
            }
          }
        `,
        variables: params,
        parseResponse: response => ({ data: response.data.deleteVisionDevices[0] }),
      };
    case 'DELETE_MANY':
      const variables = {
        id: params.ids,
      };

      return {
        query: gql`
          mutation deleteVisionDevices($id: [ID]!) {
            deleteVisionDevices(id: $id) {
              id
            }
          }
        `,
        variables,
        parseResponse: response => ({ data: response.data.deleteVisionDevices }),
      };
    default:
      throw new Error('Invalid fetch type');
  }
}
