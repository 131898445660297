import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { withApollo } from 'react-apollo';

import Admin from './Admin';
import Can from '../../components/Can/Can';
import { initDataProvider } from '../../admin/dataProvider';
import Loader from '../../components/Loader/Loader';

class Manage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      dataProvider: null,
    };

    initDataProvider(props.client).then(dataProvider => {
      this.setState({
        loading: false,
        dataProvider,
      });
    });
  }

  render() {
    const { dataProvider, loading } = this.state;

    return (
      !loading && (
        <Can perform="manage-page:visit" no={<Redirect to="/locate" />}>
          {({ check }) =>
            dataProvider ? <Admin check={check} dataProvider={dataProvider} /> : <Loader />
          }
        </Can>
      )
    );
  }
}

export default withApollo(Manage);
