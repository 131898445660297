import gql from 'graphql-tag';

export default function parentQueries(raFetchType, params) {
  switch (raFetchType) {
    case 'CREATE':
      return {
        query: gql`
          mutation addParent($name: String!) {
            addParent(name: $name) {
              id
            }
          }
        `,
        variables: params.data,
        parseResponse: response => ({ data: response.data.addParent }),
      };
    case 'UPDATE':
      return {
        query: gql`
          mutation updateParent($id: ID!, $name: String) {
            updateParent(id: $id, name: $name) {
              id
              name
            }
          }
        `,
        variables: params.data,
        parseResponse: response => ({ data: response.data.updateParent }),
      };
    case 'GET_ONE':
      return {
        query: gql`
          query getParents($id: ID) {
            parents(id: $id) {
              total
              data {
                ... on Parent {
                  id
                  name
                }
              }
            }
          }
        `,
        variables: params,
        parseResponse: response => ({
          data:
            response.data &&
            response.data.parents &&
            response.data.parents.data &&
            response.data.parents.data.length &&
            response.data.parents.data[0],
        }),
      };
    case 'GET_MANY':
    case 'GET_LIST':
      return {
        query: gql`
          query getAllParents($sort: Sort, $pagination: Pagination) {
            parents(sort: $sort, pagination: $pagination) {
              total
              data {
                ... on Parent {
                  id
                  name
                }
              }
            }
          }
        `,
        variables: params,
        parseResponse: response => ({
          data: response.data.parents.data,
          total: response.data.parents.total,
        }),
      };
    case 'DELETE':
      return {
        query: gql`
          mutation deleteParents($id: [ID!]!) {
            deleteParents(id: $id) {
              id
              name
            }
          }
        `,
        variables: params,
        parseResponse: response => ({ data: response.data.deleteParents[0] }),
      };
    case 'DELETE_MANY':
      const variables = {
        id: params.ids,
      };

      return {
        query: gql`
          mutation deleteParents($id: [ID]!) {
            deleteParents(id: $id) {
              id
            }
          }
        `,
        variables,
        parseResponse: response => ({ data: response.data.deleteParents }),
      };
    default:
      throw new Error('Invalid fetch type');
  }
}
