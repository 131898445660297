import gql from 'graphql-tag';

export default function reportTypesToOrganizationsToRolesQueries(raFetchType, params) {
  switch (raFetchType) {
    case 'CREATE':
      return {
        query: gql`
          mutation addReportTypeToOrganizationToRole(
            $report_type_id: ID!
            $organization_id: ID!
            $role_id: ID!
            $sort_order: Int
          ) {
            addReportTypeToOrganizationToRole(
              report_type_id: $report_type_id
              organization_id: $organization_id
              role_id: $role_id
              sort_order: $sort_order
            ) {
              id
            }
          }
        `,
        variables: params,
        parseResponse: response => ({ data: response.data.addReportTypeToOrganizationToRole }),
      };
    case 'UPDATE':
      return {
        query: gql`
          mutation updateReportTypeToOrganizationToRole($id: ID!, $sort_order: Int!) {
            updateReportTypeToOrganizationToRole(id: $id, sort_order: $sort_order) {
              id
            }
          }
        `,
        variables: params,
        parseResponse: response => ({ data: response.data.updateReportTypeToOrganizationToRole }),
      };
    case 'DELETE':
      return {
        query: gql`
          mutation deleteReportTypesToOrganizationsToRoles($id: [ID!]!) {
            deleteReportTypesToOrganizationsToRoles(id: $id) {
              id
            }
          }
        `,
        variables: params,
        parseResponse: response => ({
          data: response.data.deleteReportTypesToOrganizationsToRoles[0],
        }),
      };
    default:
      throw new Error('Invalid fetch type');
  }
}
