import React from 'react';
import { withApollo } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import { Alert, Button, Col, Form, Row } from 'reactstrap';
import gql from 'graphql-tag';
import { Formik } from 'formik';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';

import Loader from '../../../../components/Loader/Loader';
import { getHumanizedTimestamp } from '../../../../utils/js';
import Table from './Table';
import { toast } from '../../../../components/Toast/Toast';

const GET_MISSING_TAGS = gql`
  query missingTags($last_seen_more_than_seconds: Int!) {
    last_seen_report(last_seen_more_than_seconds: $last_seen_more_than_seconds) {
      id
      serial_number
      asset {
        id
        name
      }
      location {
        DeviceEventTime
        zone {
          id
          name
          venue {
            id
            name
          }
        }
      }
    }
  }
`;

const styles = theme => ({
  formControl: {
    width: '100%',
  },
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
});

const formatData = tags => {
  const arr = [];

  if (!tags || !tags[0]) {
    return [];
  }

  tags.forEach((tag, index) => {
    const { location, asset, id, serial_number } = tag;

    const obj = Object.assign(
      {
        id,
        serialNumber: serial_number,
      },
      asset
        ? {
            asset: asset.name,
          }
        : {
            asset: '-',
          },
      location
        ? Object.assign(
            {
              lastSeen: {
                sortValue: location.DeviceEventTime,
                label: getHumanizedTimestamp(location.DeviceEventTime),
              },
            },
            location.zone
              ? {
                  zone: location.zone.name,
                  venue: location.zone.venue.name,
                }
              : {
                  zone: '-',
                  venue: '-',
                },
          )
        : {
            lastSeen: '',
            zone: '-',
            venue: '-',
          },
    );

    arr.push(obj);
  });

  return arr;
};

const options = [
  {
    id: 'second',
    label: '1 second',
    value: 1,
  },
  {
    id: '1',
    label: '1 hour',
    value: 3600,
  },
  {
    id: '6',
    label: '6 hours',
    value: 21600,
  },
  {
    id: '24',
    label: '24 hours',
    value: 86400,
  },
];

class MissingTags extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showTable: false,
      tableData: null,
    };
  }

  handleSubmit = event => {
    const { lastSeenSeconds } = event;
    const {
      props: { client },
    } = this;

    this.setState({
      loading: true,
    });

    client
      .query({
        query: GET_MISSING_TAGS,
        variables: { last_seen_more_than_seconds: lastSeenSeconds },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        this.setState({
          loading: false,
          showTable: true,
          tableData: data && data.last_seen_report,
        });
      })
      .catch(({ message }) => {
        toast.error(message || 'Error getting missing tags');
      });
  };

  render() {
    const {
      props: { classes },
      state: { tableData, loading, showTable },
    } = this;

    return (
      <div className="report-page--content__container">
        <h1>Last Seen</h1>
        <Formik
          onSubmit={this.handleSubmit}
          initialValues={{
            lastSeenSeconds: 1,
          }}
        >
          {({ handleSubmit, handleChange, values, errors }) => (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col className="mb-3" xs="12" md="6" lg="4">
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="last-seen-seconds">Last seen more than</InputLabel>
                    <Select
                      value={values.lastSeenSeconds}
                      name="lastSeenSeconds"
                      id="last-seen-seconds"
                      onChange={handleChange}
                    >
                      {options.map(option => (
                        <MenuItem key={option.id} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Col>
              </Row>
              <Row>
                <Col className="mb-3">
                  <Button type="submit" color="primary">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
        {loading ? (
          <Loader />
        ) : tableData && tableData.length > 0 ? (
          <Paper className={classes.root}>
            <Table
              columns={[
                { id: 'asset', label: 'Asset' },
                { id: 'serialNumber', label: 'Tag Serial Number' },
                { id: 'lastSeen', label: 'Last Seen' },
                { id: 'venue', label: 'Venue' },
                { id: 'zone', label: 'Zone' },
              ]}
              data={formatData(tableData)}
            />
          </Paper>
        ) : (
          showTable && <Alert color="info">No missing tags found with the given time period</Alert>
        )}
      </div>
    );
  }
}

export default withApollo(withStyles(styles)(MissingTags));
