export const LOGIN_ROUTE = '/login';

export const LOCATE_ROUTE = '/locate';

export const REPORT_ROUTE = '/report';
export const LAST_SEEN_REPORT_ROUTE = '/report/lastSeen';
export const PLAYBACK_REPORT_ROUTE = '/report/playback';
export const ASSET_UTILIZATION_REPORT_ROUTE = '/report/assetUtilization';
export const FALL_HISTORY_REPORT_ROUTE = '/report/fallHistory';
export const HEATMAP_REPORT_ROUTE = '/report/heatmap';
export const ROOM_OCCUPANCY_REPORT_ROUTE = '/report/roomOccupancy';
export const TIME_IN_BED_REPORT_ROUTE = '/report/timeInBed';

export const SETTINGS_ROUTE = '/settings';
export const ACCOUNT_SETTINGS_ROUTE = '/settings/account';
export const ALERT_SETTINGS_ROUTE = '/settings/alerts';
export const ACCESSIBILITY_SETTINGS_ROUTE = '/settings/accessibility';

export const MANAGE_ROUTE = '/manage';
export const MANAGE_ROUTE_WITH_REACT_ADMIN_ROUTE = '/manage#';
