import React, { Fragment } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';

import AssetLastSeen from './AssetLastSeen';
import AssetBatteryPercent from './AssetBatteryPercent';
import { getHumanizedTimestamp } from '../../../utils/js';

const AssetCard = props => {
  const { asset, zoneId, clearHoverAsset, hoverOverAsset } = props;
  const initialTimestamp =
    asset &&
    asset.tag &&
    asset.tag.location &&
    asset.tag.location.DeviceEventTime &&
    getHumanizedTimestamp(asset.tag.location.DeviceEventTime);

  const zoneName =
    asset &&
    asset.tag &&
    asset.tag.location &&
    asset.tag.location.ZoneName &&
    asset.tag.location.ZoneName !== 'false'
      ? asset.tag.location.ZoneName
      : asset &&
        asset.tag &&
        asset.tag.location &&
        asset.tag.location.zone &&
        asset.tag.location.zone.name;

  const roomName =
    asset &&
    asset.tag &&
    asset.tag.location &&
    asset.tag.location.RoomName &&
    asset.tag.location.RoomName !== 'false'
      ? asset.tag.location.RoomName
      : asset &&
        asset.tag &&
        asset.tag.location &&
        asset.tag.location.room &&
        asset.tag.location.room.name;

  const locationLabel = (zoneName || roomName) && `${roomName ? `${roomName}, ` : ''}${zoneName}`;

  return (
    <Fragment>
      {asset && asset.tag ? (
        <LinkContainer to={`/locate/${zoneId}/${asset.id}`}>
          <Card
            className="card--cobalt"
            onMouseEnter={() => hoverOverAsset(asset)}
            onMouseLeave={() => clearHoverAsset()}
          >
            <CardBody className="pt-2 pb-2">
              <Row>
                <Col className="pl-4">
                  <Row>
                    <span className="asset-title">{asset.name}</span>
                  </Row>
                  {initialTimestamp && (
                    <Row>
                      <span className="asset__info">
                        <AssetLastSeen id={asset.id} initialTimestamp={initialTimestamp} />
                      </span>
                    </Row>
                  )}
                  {locationLabel && (
                    <Row>
                      <span className="asset__info">
                        <strong>Location:</strong> {locationLabel}
                      </span>
                    </Row>
                  )}
                </Col>
              </Row>
              <div className="asset__battery-container">
                {asset && asset.tag && (
                  <AssetBatteryPercent
                    serialNumber={asset.tag.serial_number}
                    initialBatteryPercent={
                      asset && asset.tag && asset.tag.location && asset.tag.location.BatteryPercent
                    }
                  />
                )}
              </div>
            </CardBody>
          </Card>
        </LinkContainer>
      ) : (
        <Card className="card--cobalt card--disabled">
          <CardBody className="pt-2 pb-2">
            <Row>
              <Col className="pl-4">
                <Row>
                  <span className="asset-title">{asset.name}</span>
                </Row>
                <Row>
                  <span className="asset__info">Asset not tagged</span>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      )}
    </Fragment>
  );
};

export default AssetCard;
