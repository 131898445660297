import React, { useState, useEffect } from 'react';
import { withAppContext } from '../../context/AppContext';
import log from '../../utils/Log';
import moment from 'moment';

const NetworkStatus = ({ appContext: { setNetworkStatus, networkStatus } }) => {
  const [listenersRegistered, setListenersRegistered] = useState(false);

  useEffect(() => {
    const registerListeners = () => {
      window.addEventListener('online', handleConnectionChange);
      window.addEventListener('offline', handleConnectionChange);
      setListenersRegistered(true);
    };
    const handleConnectionChange = event => {
      try {
        const { type } = event;

        const timestamp = moment().format('h:mm:ssa');
        console.log(`Network went ${type} at ${timestamp} local time.`);
        setNetworkStatus(type);
      } catch (e) {
        log.warn('Failed to set networkStatus with error: ', e);
      }
    };
    if (!listenersRegistered) {
      registerListeners();
    }
  }, [listenersRegistered, setNetworkStatus]);

  return (
    <>
      {networkStatus === 'offline' && (
        <div className="network-status">
          <p>
            <i className="fas fa-exclamation-triangle" />{' '}
            <span className="ml-2">{`You are currently ${networkStatus}.`}</span>
          </p>
        </div>
      )}
    </>
  );
};

export default withAppContext(NetworkStatus);
