import React from 'react';
import { Redirect } from 'react-router-dom';
import { Edit, SimpleForm, TextInput } from 'react-admin';

import Can from '../../components/Can/Can';
import EditToolbar from '../common/EditToolbar/EditToolbar';
import FormHeader from '../common/FormHeader/FormHeader';
import TypeToOrganization from '../common/TypeToOrganization/TypeToOrganization';
import { validate } from '../../utils/admin';
import { formSchema, redirectUrl } from './index.js';

export const RoomTypeEdit = props => (
  <Can perform="roomTypes:update" no={<Redirect to={redirectUrl} />}>
    <Edit {...props}>
      <SimpleForm
        validate={values => validate(formSchema, values)}
        toolbar={
          <EditToolbar
            object="roomTypes"
            deleteModalHeader="Are you sure you want to delete this Room Type?"
          />
        }
      >
        <FormHeader text="Room Type - Edit" />
        <TextInput source="name" required />
        <TextInput source="plural" required />
        <TypeToOrganization resourceName="roomTypesToOrganization" objectType="room_type" />
      </SimpleForm>
    </Edit>
  </Can>
);
