import React from 'react';
import { Redirect } from 'react-router-dom';
import { Create, SimpleForm, TextInput } from 'react-admin';

import Can from '../../components/Can/Can';
import CreateToolbar from '../common/CreateToolbar/CreateToolbar';
import FormHeader from '../common/FormHeader/FormHeader';
import { validate } from '../../utils/admin';
import { formSchema, redirectUrl } from './index.js';

export const ReportTypeCreate = props => (
  <Can perform="reportTypes:create" no={<Redirect to={redirectUrl} />}>
    <Create {...props}>
      <SimpleForm toolbar={<CreateToolbar />} validate={values => validate(formSchema, values)}>
        <FormHeader text="Report Type - Create" />
        <TextInput source="display_name" required />
        <TextInput source="component_id" required />
      </SimpleForm>
    </Create>
  </Can>
);
