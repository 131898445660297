import React from 'react';
import { Redirect } from 'react-router-dom';
import { Edit, SimpleForm, TextInput, ReferenceInput, SelectInput } from 'react-admin';

import Can from '../../components/Can/Can';
import EditToolbar from '../common/EditToolbar/EditToolbar';
import FormHeader from '../common/FormHeader/FormHeader';
import LongTextInput from '../common/LongTextInput/LongTextInput';
import { validate } from '../../utils/admin';
import { formSchema, redirectUrl } from './index.js';
import { REQUIRED_FIELD } from '../../utils/validations';

export const AssetEdit = props => (
  <Can perform="assets:update" no={<Redirect to={redirectUrl} />}>
    <Edit {...props}>
      <SimpleForm
        validate={values => validate(formSchema, values)}
        toolbar={
          <EditToolbar
            object="assets"
            deleteModalHeader="Are you sure you want to delete this Asset?"
          />
        }
      >
        <FormHeader text="Asset - Edit" />
        <TextInput source="name" required />
        <LongTextInput source="description" showCounter />
        <ReferenceInput
          label="Asset Type"
          source="asset_type.id"
          reference="assetTypes"
          validate={value => !value && REQUIRED_FIELD.message}
          required
          filter={{ allOrganizations: false }}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  </Can>
);
