// renders two or more react-admin input components inline while maintaining the
// top-level props necessary to remain functional in their out-of-the-box state

import React, { Fragment } from 'react';

const InputWrapper = props => {
  const { components, ...rest } = props;

  return components && components.length ? (
    <Fragment>
      {components.map((component, index) => {
        const stripDefault = component.props['no-default'];

        return React.cloneElement(
          component,
          Object.assign(
            {
              key: index,
              style: { marginRight: '1rem' },
            },
            !stripDefault && { ...rest },
          ),
        );
      })}
    </Fragment>
  ) : (
    false
  );
};

export default InputWrapper;
