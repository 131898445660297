import { Auth, Hub } from 'aws-amplify';
import { convertTokenToUserData } from '../utils/auth';

const init = client => {
  Hub.listen('auth', data => {
    const {
      payload: { event },
    } = data;
    switch (event) {
      case 'signIn':
        console.log('SIGN IN');
        Auth.currentSession()
          .then(user => {
            try {
              const { idToken } = user;

              client.writeData({
                data: {
                  initialized: new Date().toString(),
                  userData: {
                    ...convertTokenToUserData(idToken),
                    loggedIn: true,
                  },
                },
              });
            } catch (err) {
              console.log('Sign In error: ', err);
            }
          })
          .catch(e => {
            // console.log(e);
          });
        break;
      case 'signOut':
        console.log('SIGN OUT');
        client.resetStore();
        break;
      case 'signIn_failure':
        console.log('SIGN IN FAILURE: ', event);
        // client.resetStore();
        break;
      default:
        console.log('event: ', event);
    }
  });
};

export default init;
