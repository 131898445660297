import React from 'react';
import cx from 'classnames';

const Loader = props => {
  const { large, small, fullscreen, transparent, unsetLeft, inline, ...rest } = props;

  if (large) {
    return <div className="loader--large" />;
  }

  if (small) {
    return <div className="loader--small" />;
  }

  if (fullscreen) {
    return (
      <div
        className={cx('loader--fullscreen', {
          transparent: transparent,
          unsetLeft: unsetLeft,
        })}
        {...rest}
      />
    );
  }

  return <div className={cx('loader', { 'loader--inline': inline })} {...rest} />;
};

export default Loader;
