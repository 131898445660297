import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import { rbacRules } from '../../utils/auth';

let globalGroups;
const GET_GROUPS = gql`
  query {
    userData @client {
      groups
    }
  }
`;

const check = (permission, groups = globalGroups, data = {}) => {
  const rules = rbacRules.filter(rule => groups.includes(rule.role));

  if (!rules || rules.length < 1) {
    return false;
  }

  // static permissions is an array
  const staticPermissions = new Set(
    rules && rules.length > 1
      ? rules.reduce((acc, val) =>
          Array.prototype.concat(acc.permissions.static, val.permissions.static),
        )
      : rules[0].permissions.static,
  );

  // dynamic permissions is an object
  const dynamicPermissions =
    rules && rules.length > 1
      ? rules.reduce((acc, val) => Object.assign(acc.permissions.dynamic, val.permissions.dynamic))
      : rules[0].permissions.dynamic;

  const object = permission.split(':')[0];
  const action = permission.split(':')[1];
  if (
    staticPermissions &&
    staticPermissions.size > 0 &&
    (staticPermissions.has(permission) ||
      staticPermissions.has(`${object}:*`) ||
      staticPermissions.has(`${object}:${action}:*`))
  ) {
    return true;
  }

  if (dynamicPermissions) {
    const condition =
      dynamicPermissions[permission] ||
      dynamicPermissions[`${object}:*`] ||
      dynamicPermissions[`${object}:${action}:*`];

    const result = condition && condition(data);
    if (result) {
      return result;
    }
  }

  return false;
};
//TODO: Consider moving userData to context api, which should serve as the single source of truth instead of componenet-based state
const Can = ({ perform, children, no = false, data, ...rest }) => (
  <Query query={GET_GROUPS}>
    {({ data: queryData, loading: queryLoading, error: queryError }) => {
      if (queryLoading) {
        return false;
      }

      if (queryError) {
        return false;
      }

      if (!queryData || !queryData.userData || !queryData.userData.groups) {
        return false;
      }

      const {
        userData: { groups },
      } = queryData;

      globalGroups = groups;
      const can = check(perform, groups, data);

      if (can) {
        if (typeof children === 'object') {
          const childrenWithProps = React.Children.map(children, child =>
            React.cloneElement(child, rest),
          );

          const ret =
            childrenWithProps && childrenWithProps.length === 1
              ? childrenWithProps[0]
              : childrenWithProps;
          return ret;
        } else {
          return children({ check });
        }
      }

      if (!no) {
        return false;
      }

      if (typeof no === 'function') {
        const noValue = no();
        return noValue !== undefined ? noValue : false;
      }

      return React.cloneElement(no, rest);
    }}
  </Query>
);

export default Can;
