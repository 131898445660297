import React from 'react';
import { Redirect } from 'react-router-dom';
import { List, Datagrid, TextField, EditButton } from 'react-admin';

import Can from '../../components/Can/Can';
import PasswordPrompt from '../../components/PasswordPrompt/PasswordPrompt';
import BulkActionButtons from '../common/BulkActionButtons/BulkActionButtons';
import ListActions from '../common/ListActions/ListActions';
import Pagination from '../common/Pagination/Pagination';
import { redirectUrl } from './index.js';

export const VisionDeviceList = props => (
  <Can perform="visionDevices:list" no={<Redirect to={redirectUrl} />}>
    <PasswordPrompt>
      {({ promptCheck }) => (
        <List
          {...props}
          perPage={15}
          pagination={<Pagination />}
          actions={
            <ListActions
              title="Vision Devices"
              object="visionDevices"
              modifyExports={items =>
                items.map(item => ({
                  ...item,
                  room: item.room && item.room.name,
                }))
              }
            />
          }
          bulkActionButtons={
            <BulkActionButtons
              object="visionDevices"
              deleteModalHeader="Are you sure you want to delete the selected Vision Devices?"
            />
          }
          sort={{ field: 'device_id', order: 'ASC' }}
        >
          <Datagrid>
            <TextField source="device_id" />
            <TextField source="room.name" label="Room" />
            <Can perform="visionDevices:update">
              <EditButton basePath="/visionDevices" />
            </Can>
          </Datagrid>
        </List>
      )}
    </PasswordPrompt>
  </Can>
);
