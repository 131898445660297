import * as yup from 'yup';

import { REQUIRED_FIELD, NAME_MIN_LENGTH, NAME_MAX_LENGTH } from '../../utils/validations';

export { ReportTypeCreate } from './ReportTypeCreate';
export { ReportTypeEdit } from './ReportTypeEdit';
export { ReportTypeList } from './ReportTypeList';

export const redirectUrl = '/manage/users';

export const formSchema = yup.object().shape({
  display_name: yup
    .string()
    .required(REQUIRED_FIELD.message)
    .min(NAME_MIN_LENGTH.value, NAME_MIN_LENGTH.message)
    .max(NAME_MAX_LENGTH.value, NAME_MAX_LENGTH.message),
  component_id: yup
    .string()
    .required(REQUIRED_FIELD.message)
    .min(NAME_MIN_LENGTH.value, NAME_MIN_LENGTH.message)
    .max(NAME_MAX_LENGTH.value, NAME_MAX_LENGTH.message),
});
