import React, { Component } from 'react';
import { Switch, Link } from 'react-router-dom';
import cx from 'classnames';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccountIcon from '@material-ui/icons/AccountCircle';

import Account from './Account';
import Alerts from './Alerts';
import PrivateRoute from '../../components/PrivateRoute/PrivateRoute';
import Sidebar from '../../components/Sidebar/Sidebar';
import NotFound from '../../components/NotFound/NotFound';
import { toast } from '../../components/Toast/Toast';
import {
  ACCOUNT_SETTINGS_ROUTE,
  ALERT_SETTINGS_ROUTE,
  // ACCESSIBILITY_SETTINGS_ROUTE,
} from '../../utils/routes';

const GET_ALERT_TYPES = gql`
  query getAlertTypes {
    alert_types {
      data {
        ... on AlertType {
          id
        }
      }
    }
  }
`;

class Settings extends Component {
  constructor(props) {
    super(props);

    const { client } = props;

    this.state = {
      showAlerts: false,
    };

    client
      .query({ query: GET_ALERT_TYPES, fetchPolicy: 'network-only' })
      .then(alertTypesResult => {
        const alertTypes =
          alertTypesResult &&
          alertTypesResult.data &&
          alertTypesResult.data.alert_types &&
          alertTypesResult.data.alert_types.data;

        if (alertTypes && alertTypes.length > 0) {
          this.setState({
            showAlerts: true,
          });
        }
      })
      .catch(err => {
        toast.error('Error getting organization alert types');
      });
  }
  render() {
    const { showAlerts } = this.state;
    const { pathname } = new URL(window.location);

    return (
      <div className="settings-page">
        <Sidebar noPadding>
          <List>
            <Link to={ACCOUNT_SETTINGS_ROUTE}>
              <ListItem
                button
                className={cx('sidebar-nav-item', {
                  selected: pathname.includes('/settings/account'),
                })}
              >
                <ListItemIcon>
                  <AccountIcon />
                </ListItemIcon>
                <ListItemText>Account</ListItemText>
              </ListItem>
            </Link>
            {showAlerts && (
              <Link to={ALERT_SETTINGS_ROUTE}>
                <ListItem
                  button
                  className={cx('sidebar-nav-item', {
                    selected: pathname.includes('/settings/alerts'),
                  })}
                >
                  <ListItemIcon>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="rgba(0, 0, 0, 0.54)"
                        d="M18 16v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2zm-5 0h-2v-2h2v2zm0-4h-2V8h2v4zm-1 10c1.1 0 2-.9 2-2h-4c0 1.1.89 2 2 2z"
                      />
                      <path fill="none" d="M0 0h24v24H0V0z" />
                    </svg>
                  </ListItemIcon>
                  <ListItemText>Alerts</ListItemText>
                </ListItem>
              </Link>
            )}
            {/* <Link to={ACCESSIBILITY_SETTINGS_ROUTE}>
              <ListItem
                button
                className={cx('sidebar-nav-item', {
                  selected: pathname.includes('/settings/accessibility'),
                })}
              >
                <ListItemIcon>
                  <i className="fas fa-low-vision" />
                </ListItemIcon>
                <ListItemText>Accessibility</ListItemText>
              </ListItem>
            </Link> */}
          </List>
        </Sidebar>
        <div className="settings-page--content">
          <Switch>
            <PrivateRoute exact path={ACCOUNT_SETTINGS_ROUTE} component={Account} />
            <PrivateRoute exact path={ALERT_SETTINGS_ROUTE} component={Alerts} />
            <PrivateRoute component={NotFound} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default withApollo(Settings);
