export const DEFAULT_PAGINATION_PER_PAGE = 25;

export const validate = (schema, values) => {
  const newValues = Object.assign({}, values);
  const errors = {};

  // trim values
  Object.keys(newValues).forEach(key => {
    if (typeof newValues[key] === 'string') {
      newValues[key] = newValues[key].trim();
    }
  });

  try {
    schema.validateSync(newValues, { abortEarly: false });
  } catch (err) {
    const { inner } = err;

    if (inner && inner.length > 0) {
      inner.forEach(error => {
        errors[error.path] = error.errors[0];
      });
    }
  }

  return errors;
};
