import gql from 'graphql-tag';
import * as yup from 'yup';

import { REQUIRED_FIELD, DATE, NUMBER } from './validations';

export const GET_INITIAL_LOCAL_DATA = gql`
  query getAppConfig {
    userData @client {
      email
    }
  }
`;

export const GET_INITIAL_PLAYBACK_FORM_DATA = gql`
  query getInitialPlaybackData($site_user_email: AWSEmail!) {
    assets {
      total
      data {
        ... on Asset {
          id
          name
        }
      }
    }

    zones {
      total
      data {
        ... on Zone {
          id
          name
          vendor_zone_id
          origin_offset_x
          origin_offset_y
          meter_to_pixel_scaler_x
          meter_to_pixel_scaler_y
          venue {
            id
            name
            vendor_map_id
          }
        }
      }
    }

    site_users(email: $site_user_email) {
      data {
        ... on SiteUser {
          timezone
        }
      }
    }
  }
`;

export const playbackFormStyles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    width: '100%',
  },
  durationSelect: {
    flex: 1,
  },
  durationControl: {
    flexDirection: 'row',
  },
  customDuration: {
    width: '100px',
    textAlign: 'right',
    alignSelf: 'end',
    marginLeft: '1rem',
  },
  customDurationType: {
    width: '100px',
  },
  adornment: {
    marginTop: '14px',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
});

export const playbackFormSchema = yup.object().shape({
  assetIds: yup
    .array()
    .of(yup.string().min(1))
    .required(REQUIRED_FIELD.message),
  zoneName: yup
    .string()
    .min(1)
    .required(REQUIRED_FIELD.message),
  startDate: yup
    .date()
    .required(REQUIRED_FIELD.message)
    .typeError(DATE.message),
  customDuration: yup.string().when('presetDuration', {
    is: -1,
    then: yup
      .string()
      .matches(NUMBER.value, NUMBER.message)
      .required(REQUIRED_FIELD.message)
      .test('duration-check', 'Must be at least 1 minute and less than 24 hours', function(value) {
        if (this.parent.customDurationType === 'minutes') {
          return value >= 1 && value <= 1440;
        }

        return value >= 1 && value <= 24;
      }),
  }),
});

export const durationOptions = [
  {
    id: '15min',
    label: '15 minutes',
    value: 900, // seconds
    playbackLength: 30, // seconds
  },
  {
    id: '30min',
    label: '30 minutes',
    value: 1800,
    playbackLength: 45,
  },
  {
    id: '1hr',
    label: '1 hour',
    value: 3600,
    playbackLength: 60, // 1 minute
  },
  {
    id: '3hr',
    label: '3 hours',
    value: 10800,
    playbackLength: 120, // 2 minutes
  },
  {
    id: '6hr',
    label: '6 hours',
    value: 21600,
    playbackLength: 240, // 4 minutes
  },
  {
    id: '12hr',
    label: '12 hours',
    value: 43200,
    playbackLength: 480, // 8 minutes
  },
  {
    id: '24hr',
    label: '24 hours',
    value: 86400,
    playbackLength: 720, // 12 minutes
  },
  {
    id: 'custom',
    label: 'Custom',
    value: -1,
    playbackLength: null, // default null, is calculated on submit using getPlaybackLengthFromDuration()
  },
];

export const playbackExportTypes = [
  {
    label: 'Asset',
    value: 'asset_name',
    visible: true,
  },
  {
    label: 'Room',
    value: 'room_name',
    visible: true,
  },
  {
    label: 'X',
    value: 'x',
    visible: true,
  },
  {
    label: 'Y',
    value: 'y',
    visible: true,
  },
  {
    label: 'Z',
    value: 'z',
    visible: true,
  },
  {
    label: 'Device event time',
    value: 'device_event_time',
    visible: true,
  },
  {
    label: 'Frame real time',
    value: 'frame_real_time',
    visible: false,
  },
  {
    label: 'Serial number',
    value: 'serial_number',
    visible: true,
  },
  {
    label: 'Quality',
    value: 'quality',
    visible: true,
  },
  {
    label: 'Smoothing',
    value: 'smoothing',
    visible: true,
  },
  {
    label: 'Zone',
    value: 'zone_name',
    visible: true,
  },
  {
    label: 'Sleep mode',
    value: 'sleep_mode',
    visible: true,
  },
];

// solve for the elliptical curve created by defining a min/max on playback length and duration.
// expects: desired duration in minutes (ex: 5, 15, 1440)
export const getPlaybackLengthFromDuration = input => {
  const validDurations = durationOptions.filter(o => o.value > 0);
  const maxPlaybackLength = validDurations.reduce((a, b) =>
    b.playbackLength > a.playbackLength ? b : a,
  ).playbackLength;
  const minPlaybackLength = validDurations.reduce((a, b) =>
    b.playbackLength < a.playbackLength ? b : a,
  ).playbackLength;
  const maxDuration = validDurations.reduce((a, b) => (b.value > a.value ? b : a)).value / 60;
  const minDuration = validDurations.reduce((a, b) => (b.value < a.value ? b : a)).value / 60;

  if (input < minDuration) {
    // exit because the result will be NaN, just return the minimum
    return minPlaybackLength;
  } else if (input > maxDuration) {
    // exit because the result will not be correct, return the maximum
    return maxPlaybackLength;
  }

  const fn = duration =>
    Math.sqrt(
      Math.pow(maxPlaybackLength - minPlaybackLength, 2) *
        (1 - Math.pow(maxDuration - duration, 2) / Math.pow(maxDuration - minDuration, 2)),
    ) + minPlaybackLength;
  const result = fn(input);

  return parseInt(result, 10);
};
