import * as yup from 'yup';

import {
  REQUIRED_FIELD,
  NAME_MIN_LENGTH,
  NAME_MAX_LENGTH,
  DESCRIPTION_MAX_LENGTH,
} from '../../utils/validations';

export { RoomAreaCreate } from './RoomAreaCreate';
export { RoomAreaEdit } from './RoomAreaEdit';
export { RoomAreaList } from './RoomAreaList';

export const redirectUrl = '/manage/users';

export const formSchema = yup.object().shape({
  region_map_id: yup
    .string()
    .required(REQUIRED_FIELD.message)
    .min(NAME_MIN_LENGTH.value, NAME_MIN_LENGTH.message)
    .max(NAME_MAX_LENGTH.value, NAME_MAX_LENGTH.message),
  description: yup
    .string()
    .nullable()
    .max(DESCRIPTION_MAX_LENGTH.value, DESCRIPTION_MAX_LENGTH.message),
  // TODO: add vertices validation here after room area vertices input is ready
});
