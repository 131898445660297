import React, { Component } from 'react';
import cx from 'classnames';
import { Button } from 'reactstrap';
import _ from 'lodash';

class BackButton extends Component {
  state = {
    previousLocation: null,
  };

  componentWillReceiveProps() {
    const { location } = this.props;
    this.setState({
      previousLocation: location,
    });
  }

  goBack = () => {
    const {
      history,
      clearSelectedAssets,
      clearHoverAsset,
      clearFilter,
      match: {
        params: { zoneSlug, assetSlug },
      },
    } = this.props;
    const { previousLocation } = this.state;
    clearHoverAsset();
    clearSelectedAssets();
    clearFilter();
    if (
      zoneSlug === 'search' ||
      (previousLocation && previousLocation.pathname.includes('search'))
    ) {
      history.goBack();
      return;
    } else if (zoneSlug && assetSlug) {
      history.push(`/locate/${zoneSlug}`);
    } else {
      history.push('/locate');
    }
  };

  render() {
    const { match } = this.props;
    const invisible = _.isEmpty(match.params);
    const backButtonClasses = cx('back-btn btn-link', { invisible });
    return (
      <Button className={backButtonClasses} onClick={this.goBack}>
        <i className="fas fa-angle-left" /> Go Back
      </Button>
    );
  }
}

export default BackButton;
