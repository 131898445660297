import * as yup from 'yup';

import {
  REQUIRED_FIELD,
  NAME_MIN_LENGTH,
  NAME_MAX_LENGTH,
  DESCRIPTION_MAX_LENGTH,
} from '../../utils/validations';

export { AssetCreate } from './AssetCreate';
export { AssetEdit } from './AssetEdit';
export { AssetList } from './AssetList';

export const redirectUrl = '/locate';

export const formSchema = yup.object().shape({
  name: yup
    .string()
    .required(REQUIRED_FIELD.message)
    .min(NAME_MIN_LENGTH.value, NAME_MIN_LENGTH.message)
    .max(NAME_MAX_LENGTH.value, NAME_MAX_LENGTH.message),
  description: yup
    .string()
    .nullable()
    .max(DESCRIPTION_MAX_LENGTH.value, DESCRIPTION_MAX_LENGTH.message),
});
