import alertTypeQueries from './alertTypes';
import alertTypesToOrganizationQueries from './alertTypesToOrganization';
import parentQueries from './parents';
import organizationQueries from './organizations';
import roomQueries from './rooms';
import roomAreaQueries from './roomAreas';
import roomTypeQueries from './roomTypes';
import roomTypesToOrganizationQueries from './roomTypesToOrganization';
import assetQueries from './assets';
import assetTypeQueries from './assetTypes';
import assetTypesToOrganizationQueries from './assetTypesToOrganization';
import tagQueries from './tags';
import venueQueries from './venues';
import visionDeviceQueries from './visionDevices';
import zoneQueries from './zones';
import siteUserQueries from './siteUsers';
import reportTypeQueries from './reportTypes';
import reportTypesToOrganizationsToRolesQueries from './reportTypesToOrganizationsToRoles';

export default () => (raFetchType, resourceName, params) => {
  switch (resourceName) {
    case 'alertTypes':
      return alertTypeQueries(raFetchType, params);
    case 'alertTypesToOrganization':
      return alertTypesToOrganizationQueries(raFetchType, params);
    case 'parents':
      return parentQueries(raFetchType, params);
    case 'organizations':
      return organizationQueries(raFetchType, params);
    case 'rooms':
      return roomQueries(raFetchType, params);
    case 'roomAreas':
      return roomAreaQueries(raFetchType, params);
    case 'roomTypes':
      return roomTypeQueries(raFetchType, params);
    case 'roomTypesToOrganization':
      return roomTypesToOrganizationQueries(raFetchType, params);
    case 'assets':
      return assetQueries(raFetchType, params);
    case 'assetTypes':
      return assetTypeQueries(raFetchType, params);
    case 'assetTypesToOrganization':
      return assetTypesToOrganizationQueries(raFetchType, params);
    case 'tags':
      return tagQueries(raFetchType, params);
    case 'venues':
      return venueQueries(raFetchType, params);
    case 'visionDevices':
      return visionDeviceQueries(raFetchType, params);
    case 'zones':
      return zoneQueries(raFetchType, params);
    case 'users':
      return siteUserQueries(raFetchType, params);
    case 'reportTypes':
      return reportTypeQueries(raFetchType, params);
    case 'reportTypesToOrganizationsToRoles':
      return reportTypesToOrganizationsToRolesQueries(raFetchType, params);
    default:
      throw new Error('Bad resource');
  }
};
