import { createBrowserHistory } from 'history';

const MATCH_PATTERN = /^\/(development|staging|production|((\w|\d)+-((\w|\d)+-?)+))(\/.*)$/;
const VERSION_MATCH_PATTERN = /^\/version((\/(api|app)\/((\w|-)+))+)(\/.*)$/;

const basecheck = MATCH_PATTERN.exec(window.location.pathname);
const versionBasecheck = VERSION_MATCH_PATTERN.exec(window.location.pathname);
const basePath = versionBasecheck
  ? `/version${versionBasecheck[1]}`
  : basecheck
  ? `/${basecheck[1]}`
  : '/';

export const baseName = basePath === '/' ? '' : basePath;

const history = createBrowserHistory({
  basename: basePath,
});

export default history;
