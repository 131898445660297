import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { Card, CardBody, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import moment from 'moment';
import Chart from 'chart.js';
import cx from 'classnames';

import Loader from '../../../components/Loader/Loader';
import { getHumanizedTimestamp, getAWSDate } from '../../../utils/js';
import {
  GET_ASSET_BY_ID,
  GET_ASSET_DWELL_TIME,
  GET_ASSET_DISTANCE_TRAVELED,
  GET_CHECKIN_EVENTS,
} from '../../../client/queries/map';
import log from '../../../utils/Log';
import {
  DEFAULT_CHART_OPTIONS,
  COLOR_SCHEME,
  PIE_CHART_RANGE_OPTIONS,
  parseUtilizationChartData,
} from '../../../utils/chart';
import { toast } from '../../../components/Toast/Toast';
import { tagIsMissing, DEFAULT_ASSET_UTILIZATION_RANGE_OPTION } from '../../../utils/map';
import { capitalize, humanizeSeconds } from '../../../utils/js';
import AssetLastSeen from './AssetLastSeen';
import AssetBatteryPercent from './AssetBatteryPercent';

const unknownLabel = 'unknown';
const tabPrefix = 'asset-detail-tab-';
const tabs = [
  {
    id: `${tabPrefix}-utilization`,
    label: 'Utilization',
  },
  {
    id: `${tabPrefix}-distance-traveled`,
    label: 'Distance Traveled',
  },
  {
    id: `${tabPrefix}-checkin-events`,
    label: 'Checkins',
  },
];

class AssetDetail extends Component {
  state = {
    loadingChart: false,
    asset: null,
    activeTab: `${tabPrefix}-utilization`,
    utilizationChart: null,
    utilizationChartElement: null,
    utilizationRangeOption: DEFAULT_ASSET_UTILIZATION_RANGE_OPTION,
    utilizationQueryData: null,
    distanceTraveledChart: null,
    distanceTraveledChartElement: null,
    distanceTraveledQueryData: null,
    checkinEventsQueryData: null,
  };

  componentDidMount() {
    this.getUtilization();
  }

  getUtilization = () => {
    const {
      client,
      selectOneAsset,
      match: {
        params: { assetSlug },
      },
    } = this.props;

    if (!this.state.utilizationQueryData) {
      this.setState({
        loadingChart: true,
      });
    }

    client
      .query({
        query: GET_ASSET_BY_ID,
        variables: {
          id: assetSlug,
          start_date: getAWSDate(moment().subtract(7, 'day')),
          end_date: getAWSDate(),
        },
      })
      .then(res => {
        const {
          assets: { data: assetData },
          dwell_time_by_room_type,
        } = res.data;

        if (!assetData || !assetData[0]) {
          return;
        }

        const asset = assetData[0];
        const utilizationChartElement = document.querySelector('canvas#utilization-chart');

        this.setState(
          Object.assign(
            {
              asset,
              utilizationQueryData: dwell_time_by_room_type,
              loadingChart: false,
              utilizationRangeOption: DEFAULT_ASSET_UTILIZATION_RANGE_OPTION,
            },
            utilizationChartElement && {
              utilizationChart: new Chart(utilizationChartElement, {
                type: 'pie',
                data: parseUtilizationChartData(dwell_time_by_room_type, 'pie'),
                options: DEFAULT_CHART_OPTIONS.utilization,
              }),
              utilizationChartElement,
            },
          ),
          () => {
            selectOneAsset(asset);
          },
        );
      })
      .catch(error => {
        log.warn(error);
      });
  };

  getDistanceTraveled = () => {
    const {
      client,
      match: {
        params: { assetSlug },
      },
    } = this.props;

    if (!this.state.distanceTraveledQueryData) {
      this.setState({
        loadingChart: true,
      });
    }

    client
      .query({
        query: GET_ASSET_DISTANCE_TRAVELED,
        variables: { asset_id: assetSlug },
      })
      .then(res => {
        const {
          data: { distance_traveled },
        } = res;

        if (!distance_traveled || !distance_traveled[0]) {
          toast.error('Error getting distance traveled');
          return;
        }

        const distanceTraveledChartElement = document.querySelector(
          'canvas#distance-traveled-chart',
        );

        const dataset = distance_traveled.map(t => ({
          x: t.day,
          y: t.distance_traveled / 1000, // convert to meters
        }));

        this.setState(
          Object.assign(
            {
              distanceTraveledQueryData: distance_traveled,
              loadingChart: false,
            },
            distanceTraveledChartElement && {
              distanceTraveledChart: new Chart(distanceTraveledChartElement, {
                type: 'bar',
                data: {
                  labels: dataset.map(t => t.x),
                  datasets: [
                    {
                      label: null,
                      data: dataset,
                      backgroundColor: COLOR_SCHEME[0],
                    },
                  ],
                },
                options: DEFAULT_CHART_OPTIONS.distanceTraveled,
              }),
              distanceTraveledChartElement,
            },
          ),
        );
      })
      .catch(error => {
        log.warn(error);
      });
  };

  getCheckins = () => {
    const {
      client,
      match: {
        params: { assetSlug },
      },
    } = this.props;

    if (!this.state.checkinEventsQueryData) {
      this.setState({
        loadingChart: true,
      });
    }

    client
      .query({
        query: GET_CHECKIN_EVENTS,
        variables: { asset_id: assetSlug },
      })
      .then(res => {
        const {
          data: { events },
        } = res;

        if (!events) {
          toast.error('Error getting checkin events');
          return;
        }

        this.setState({
          checkinEventsQueryData: events, // sort so latest day is first
          loadingChart: false,
        });
      })
      .catch(error => {
        log.warn(error);
      });
  };

  toggleTab = newId => {
    switch (newId) {
      case `${tabPrefix}-utilization`:
        this.getUtilization();
        break;
      case `${tabPrefix}-distance-traveled`:
        this.getDistanceTraveled();
        break;
      case `${tabPrefix}-checkin-events`:
        this.getCheckins();
        break;
      default:
        return;
    }

    this.setState({
      activeTab: newId,
    });
  };

  updateRangeOption = input => {
    const {
      props: { client },
      state: {
        asset: { id },
        utilizationChart,
        utilizationChartElement,
      },
    } = this;
    const rangeValue = PIE_CHART_RANGE_OPTIONS.find(option => option.id === input).value;
    const startDate = moment().subtract(rangeValue, 'day');

    client
      .query({
        query: GET_ASSET_DWELL_TIME,
        variables: { start_date: getAWSDate(startDate), end_date: getAWSDate(), asset_id: id },
      })
      .then(result => {
        const {
          data: { dwell_time_by_room_type },
        } = result;

        utilizationChart && utilizationChart.destroy();
        this.setState({
          utilizationRangeOption: input,
          utilizationQueryData: dwell_time_by_room_type,
          utilizationChart: new Chart(utilizationChartElement, {
            type: 'pie',
            data: parseUtilizationChartData(dwell_time_by_room_type, 'pie'),
            options: DEFAULT_CHART_OPTIONS.utilization,
          }),
        });
      })
      .catch(({ message: queryError }) => {
        toast.error(queryError || 'Error getting asset dwell time');
      });
  };

  render() {
    const {
      asset,
      utilizationRangeOption,
      utilizationQueryData,
      activeTab,
      loadingChart,
      distanceTraveledQueryData,
      checkinEventsQueryData,
    } = this.state;
    const tag = asset && asset.tag;
    const location = tag && asset.tag.location;
    const zoneName =
      location && location.ZoneName
        ? asset.tag.location.ZoneName
        : location && location.zone && location.zone.name;
    const roomName = location && asset.tag.location.room && asset.tag.location.room.name;
    const locationLabel = `${roomName ? `${roomName}, ` : ''}${zoneName}`;
    const isMissing = asset && tagIsMissing(location && location.DeviceEventTime);

    const initialTimestamp =
      asset &&
      asset.tag &&
      asset.tag.location &&
      asset.tag.location.DeviceEventTime &&
      getHumanizedTimestamp(asset.tag.location.DeviceEventTime);

    return (
      <div className="asset-details cards-list">
        <Fragment>
          <Card className="card--cobalt">
            <CardBody>
              <Row>
                <Col className="pl-4">
                  {asset ? (
                    <Fragment>
                      <Row>
                        <span className="asset-title">
                          <strong>
                            <h2>{asset.name}</h2>
                          </strong>
                        </span>
                      </Row>
                      <Row>
                        <span className="asset__info">
                          <AssetLastSeen id={asset.id} initialTimestamp={initialTimestamp} />
                        </span>
                      </Row>
                      <Row>
                        <span className="asset__info">
                          <strong>Asset Type:</strong> {asset.asset_type.name}
                        </span>
                      </Row>
                      <Row>
                        <span className="asset__info">
                          <strong>Serial Number:</strong> {tag && tag.serial_number}
                        </span>
                      </Row>
                      <div className="asset__battery-container">
                        <AssetBatteryPercent
                          serialNumber={asset.tag.serial_number}
                          initialBatteryPercent={
                            asset &&
                            asset.tag &&
                            asset.tag.location &&
                            asset.tag.location.BatteryPercent
                          }
                        />
                      </div>
                      <Row>
                        <span className="asset__info">
                          <strong>Location:</strong> {isMissing ? unknownLabel : locationLabel}
                        </span>
                      </Row>
                    </Fragment>
                  ) : (
                    <Loader inline />
                  )}
                </Col>
              </Row>
              <hr />

              {/* the chart target elements must exist in the DOM at all times but only sometimes displayed */}
              <div className="asset-details-tabs">
                <Nav tabs>
                  {tabs.map(tab => (
                    <NavItem key={tab.id}>
                      <NavLink
                        className={cx({ active: activeTab === tab.id })}
                        onClick={() => this.toggleTab(tab.id)}
                      >
                        {tab.label}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId={`${tabPrefix}-utilization`}>
                    <Row className={cx(asset ? 'd-block' : 'd-none')}>
                      <Col className="utilization-wrapper">
                        <div className="chart-container">
                          {(!utilizationQueryData || !utilizationQueryData.length) && (
                            <div className="zero-state-overlay">
                              <div className="content asset__info">[no data]</div>
                            </div>
                          )}
                          <canvas id="utilization-chart" className="utilization-chart" />
                        </div>
                        <div className="actions asset__info">
                          Last:
                          <ul>
                            {PIE_CHART_RANGE_OPTIONS.slice(0, 3).map(({ id }) => (
                              <li
                                key={id}
                                className={cx({ active: utilizationRangeOption === id })}
                                onClick={() => this.updateRangeOption(id)}
                              >
                                {id}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId={`${tabPrefix}-distance-traveled`}>
                    {(!distanceTraveledQueryData || loadingChart) && (
                      <Row>
                        <Col>
                          <Loader inline />
                        </Col>
                      </Row>
                    )}
                    <Row className={cx(distanceTraveledQueryData ? 'd-block' : 'd-none')}>
                      <Col className="distance-traveled-wrapper">
                        <div className="chart-container">
                          <canvas
                            id="distance-traveled-chart"
                            className="distance-traveled-chart"
                          />
                        </div>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId={`${tabPrefix}-checkin-events`}>
                    {(!checkinEventsQueryData || loadingChart) && (
                      <Row className="p-relative">
                        <Col>
                          <Loader inline />
                        </Col>
                      </Row>
                    )}
                    <Row className={cx(checkinEventsQueryData ? 'd-block' : 'd-none')}>
                      <Col className="checkin-events-wrapper">
                        {checkinEventsQueryData && (
                          <table>
                            <tr>
                              <th>Type</th>
                              <th>When</th>
                              <th>Room</th>
                              <th>Dwell Time</th>
                            </tr>
                            {checkinEventsQueryData.length > 0 ? (
                              checkinEventsQueryData.map((item, index) => (
                                <tr key={index}>
                                  <td className="checkin-tooltip">
                                    <i
                                      className={cx(
                                        'fas',
                                        item.event_type === 'CHECKIN'
                                          ? 'fa-sign-in-alt'
                                          : 'fa-sign-out-alt',
                                      )}
                                    />
                                    <span class="tooltip-text">{capitalize(item.event_type)}</span>
                                  </td>
                                  <td>{moment(item.event_time).format('M/D H:mm')}</td>
                                  <td>{item.room ? item.room.name : '-'}</td>
                                  <td>{`${
                                    item.dwell_time ? humanizeSeconds(item.dwell_time) : '-'
                                  }`}</td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="4">No checkin events in the last 24 hours</td>
                              </tr>
                            )}
                          </table>
                        )}
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </div>
            </CardBody>
          </Card>
        </Fragment>
      </div>
    );
  }
}

AssetDetail.propTypes = {
  match: PropTypes.shape({}).isRequired,
};

export default withApollo(AssetDetail);
