import * as yup from 'yup';

import {
  REQUIRED_FIELD,
  NAME_REGEX,
  NAME_MIN_LENGTH,
  NAME_MAX_LENGTH,
  EMAIL,
  PHONE_NUMBER_REGEX,
  TIMEZONE_NAME_REGEX,
} from '../../utils/validations';

export { SiteUserCreate } from './SiteUserCreate';
export { SiteUserEdit } from './SiteUserEdit';
export { SiteUserList } from './SiteUserList';

export const redirectUrl = '/locate';

export const formSchema = yup.object().shape({
  first_name: yup
    .string()
    .required(REQUIRED_FIELD.message)
    .min(NAME_MIN_LENGTH.value, NAME_MIN_LENGTH.message)
    .max(NAME_MAX_LENGTH.value, NAME_MAX_LENGTH.message)
    .matches(NAME_REGEX.value, NAME_REGEX.message),
  last_name: yup
    .string()
    .required(REQUIRED_FIELD.message)
    .min(NAME_MIN_LENGTH.value, NAME_MIN_LENGTH.message)
    .max(NAME_MAX_LENGTH.value, NAME_MAX_LENGTH.message)
    .matches(NAME_REGEX.value, NAME_REGEX.message),
  email: yup
    .string()
    .required(REQUIRED_FIELD.message)
    .email(EMAIL.message),
  phone_number: yup
    .string()
    .nullable()
    .matches(PHONE_NUMBER_REGEX.value, {
      message: PHONE_NUMBER_REGEX.message,
      excludeEmptyString: true,
    }),
  role: yup
    .string()
    .required(REQUIRED_FIELD.message)
    .min(NAME_MIN_LENGTH.value, NAME_MIN_LENGTH.message)
    .max(NAME_MAX_LENGTH.value, NAME_MAX_LENGTH.message),
  timezone: yup
    .string()
    .required(REQUIRED_FIELD.message)
    .matches(TIMEZONE_NAME_REGEX.value, TIMEZONE_NAME_REGEX.message),
});
