import React, { Component } from 'react';
import { withRouter, Route } from 'react-router-dom';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { Auth } from 'aws-amplify';

const GET_USER_DATA = gql`
  query {
    userData @client {
      loggedIn
    }
  }
`;

class PrivateRoute extends Component {
  state = {
    sessionActive: false,
  };

  componentWillMount() {
    const { history } = this.props;
    Auth.currentSession()
      .then(data => {
        // this.setState({ sessionActive: true });
      })
      .catch(error => {
        // console.log('Session:', error);
        // this.setState({ sessionActive: false });
        history.push('/login');
      });
  }

  render() {
    // const { sessionActive } = this.state;
    const { component: Component, ...rest } = this.props;

    return (
      <Query query={GET_USER_DATA}>
        {({ loading, error, data }) => {
          try {
            if (loading) {
              return null;
            }

            if (error) {
              return error;
            }

            const { userData } = data;

            if (!userData) {
              return false;
            }

            if (userData.loggedIn) {
              return <Route {...rest} render={props => <Component {...props} />} />;
            }
            return false;
          } catch (e) {
            console.log('e: ', e);
            return false;
          }
        }}
      </Query>
    );
  }
}

export default withRouter(PrivateRoute);
