import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';

import State from '../State/State';
import { GET_SIDEBAR } from '../../client/queries/sidebar';

const TOGGLE_SIDEBAR = gql`
  mutation toggleSidebar($open: Boolean) {
    toggleSidebar(open: $open) @client {
      open
    }
  }
`;

class Sidebar extends Component {
  render() {
    const { noPadding, collapsable, children } = this.props;
    return (
      <State
        query={GET_SIDEBAR}
        render={data => {
          const {
            sidebar: { open },
          } = data;
          const sidebarClasses = cx('app-content__sidebar', {
            'app-content__sidebar--collapsed': !open,
          });

          const sidebarContentClasses = cx('sidebar-content', {
            'sidebar-content--no-padding': noPadding,
          });

          return (
            <div className={sidebarClasses}>
              <div className={sidebarContentClasses}>
                {collapsable && (
                  <Mutation mutation={TOGGLE_SIDEBAR} variables={{ open: !open }}>
                    {toggleSidebar => (
                      <button type="button" className="collapse" onClick={toggleSidebar}>
                        <i className={`fas fa-angle-${!open ? 'right' : 'left'}`} />
                      </button>
                    )}
                  </Mutation>
                )}

                {children}
              </div>
            </div>
          );
        }}
      />
    );
  }
}

Sidebar.propTypes = {
  noPadding: PropTypes.bool,
  collapsable: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Sidebar.defaultProps = {
  noPadding: false,
  collapsable: false,
};

export default Sidebar;
