import React from 'react';
import { Redirect } from 'react-router-dom';
import { Edit, SimpleForm, TextInput } from 'react-admin';

import Can from '../../components/Can/Can';
import EditToolbar from '../common/EditToolbar/EditToolbar';
import FormHeader from '../common/FormHeader/FormHeader';
import LongTextInput from '../common/LongTextInput/LongTextInput';
import TypeToOrganization from '../common/TypeToOrganization/TypeToOrganization';
import { validate } from '../../utils/admin';
import { formSchema, redirectUrl } from './index.js';

export const AlertTypeEdit = props => (
  <Can perform="alertTypes:update" no={<Redirect to={redirectUrl} />}>
    <Edit {...props}>
      <SimpleForm
        validate={values => validate(formSchema, values)}
        toolbar={
          <EditToolbar
            object="alertTypes"
            deleteModalHeader="Are you sure you want to delete this Alert Type?"
          />
        }
      >
        <FormHeader text="Alert Type - Edit" />
        <TextInput source="name" required />
        <TextInput source="plural" required />
        <TextInput source="sns_topic" label="SNS Topic" required />
        <LongTextInput source="description" showCounter />
        <TypeToOrganization resourceName="alertTypesToOrganization" objectType="alert_type" />
      </SimpleForm>
    </Edit>
  </Can>
);
