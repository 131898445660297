import React, { Fragment } from 'react';
import cx from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { withLocateContext } from '../../../context/LocateContext';

const styles = theme => ({
  formControlLabel: {
    margin: 0,
  },
});

const SearchFieldMenu = ({
  classes,
  toggleSearchFieldMenu,
  searchFieldMenuOpen,
  locateContext: {
    updatePresenceSubscription,
    showRoomPresence,
    initializePresenceData,
    resetPresenceData,
  },
}) => {
  const handlePresenceDetectionChange = (event, value) => {
    updatePresenceSubscription(value);

    if (value === true) {
      initializePresenceData();
    } else {
      resetPresenceData();
    }
  };

  return (
    <Fragment>
      <div
        onClick={toggleSearchFieldMenu}
        className={cx(
          'search-menu-container__overlay',
          !searchFieldMenuOpen && 'search-menu-container__overlay--collapsed',
        )}
      />
      <div
        className={cx(
          'search-menu-container',
          !searchFieldMenuOpen && 'search-menu-container--collapsed',
        )}
      >
        <div className="collapse-button" onClick={toggleSearchFieldMenu}>
          <i className="fas fa-angle-double-left" />
        </div>
        <div className="search-menu-container__content">
          <FormControlLabel
            className={classes.formControlLabel}
            label="Show occupied rooms"
            labelPlacement="end"
            control={
              <Switch
                onChange={handlePresenceDetectionChange}
                checked={showRoomPresence}
                color="primary"
              />
            }
          />
        </div>
      </div>
    </Fragment>
  );
};

export default withStyles(styles)(withLocateContext(SearchFieldMenu));
