// in src/AppLayout.js
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Notification, Sidebar, setSidebarVisibility } from 'react-admin';
import cx from 'classnames';

import AdminMenu from './AdminMenu';
import defaultTheme from '../../admin/theme';

const componentPropType = PropTypes.oneOfType([PropTypes.func, PropTypes.string]);

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1,
    backgroundColor: theme.palette.background.default,
    position: 'relative',
    height: '100%',
  },
  appFrame: {
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'auto',
  },
  contentWithSidebar: {
    display: 'flex',
    flexGrow: 1,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 2,
    padding: theme.spacing.unit * 3,
    marginTop: '4em',
    marginLeft: '1rem',
    paddingLeft: 5,
  },
});

class AppLayout extends Component {
  componentWillMount() {
    this.props.setSidebarVisibility(true);
  }

  render() {
    const { children, classes, dashboard, logout } = this.props;

    return (
      <div className={cx(classes.root, 'admin-layout-root')}>
        <div className={classes.appFrame}>
          {/* <AppBar title={title} open={open} logout={logout} /> */}
          <main className={classes.contentWithSidebar}>
            <Sidebar>
              <AdminMenu logout={logout} hasDashboard={!!dashboard} />
            </Sidebar>
            <div className={cx(classes.content, 'admin-layout-root__content')}>{children}</div>
          </main>
          <div className="ra-notification-override">
            <Notification autoHideDuration={1} />
          </div>
        </div>
      </div>
    );
  }
}

AppLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  dashboard: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  isLoading: PropTypes.bool.isRequired,
  logout: componentPropType,
  setSidebarVisibility: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({ isLoading: state.admin.loading > 0 });
export default connect(
  mapStateToProps,
  { setSidebarVisibility },
)(withStyles(styles(defaultTheme))(AppLayout));
