import gql from 'graphql-tag';

export default function roomTypeToOrganizationQueries(raFetchType, params) {
  switch (raFetchType) {
    case 'CREATE':
      return {
        query: gql`
          mutation addRoomTypeToOrganization($room_type_id: ID!, $organization_id: ID!) {
            addRoomTypeToOrganization(
              room_type_id: $room_type_id
              organization_id: $organization_id
            ) {
              id
            }
          }
        `,
        variables: {
          ...params.data,
          room_type_id: params.data.room_type.id,
          organization_id: params.data.organization.id,
        },
        parseResponse: response => ({ data: response.data.addRoomTypeToOrganization }),
      };
    case 'DELETE':
      return {
        query: gql`
          mutation deleteRoomTypesToOrganizations($id: [ID!]!) {
            deleteRoomTypesToOrganizations(id: $id) {
              id
            }
          }
        `,
        variables: params,
        parseResponse: response => ({ data: response.data.deleteRoomTypesToOrganizations[0] }),
      };
    default:
      throw new Error('Invalid fetch type');
  }
}
