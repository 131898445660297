import React, { Fragment, useState, useEffect } from 'react';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import cx from 'classnames';
import { Alert } from 'reactstrap';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import InputLabel from '@material-ui/core/InputLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import InputWrapper from '../InputWrapper/InputWrapper';

const styles = {
  inputLabel: {
    display: 'block',
  },
  list: {
    flex: 1,
    overflow: 'auto',
  },
  paginationButton: {
    '&.prev': {
      borderTopLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    '&.next': {
      borderTopRightRadius: 0,
      borderBottomLeftRadius: 0,
      float: 'right',
    },
  },
  paper: {
    width: '256px',
    height: '260px',
    display: 'flex',
    flexDirection: 'column',
  },
  textField: {
    width: '256px',
  },
};

const PaginatedList = props => {
  const {
    classes,
    choices,
    label,
    optionText,
    pagination: { page, perPage },
    setPagination,
    input: { value, onChange },
  } = props;
  const [loading, setLoading] = useState(false);
  const [currentChoices, setCurrentChoices] = useState([]);
  const [showList, setShowList] = useState(false);

  useEffect(() => {
    if (!isEqual(sortBy(choices), sortBy(currentChoices))) {
      setCurrentChoices(choices);
      setLoading(false);
    }
  }, [choices]);

  const handlePrevClick = () => event => {
    setLoading(true);
    setPagination({ page: page - 1, perPage });
  };

  const handleNextClick = () => event => {
    setLoading(true);
    setPagination({ page: page + 1, perPage });
  };

  const handleChange = id => event => {
    onChange(id);
    setShowList(false);
  };

  return (
    <div className="paginated-list">
      <InputLabel shrink className={classes.inputLabel}>
        {label}
      </InputLabel>
      <InputWrapper
        components={Array.prototype.concat(
          [
            <TextField
              className={classes.textField}
              value={
                optionText !== undefined && value && value.length > 0
                  ? choices.find(choice => choice.id === value)[optionText]
                  : value
              }
              disabled
            />,
            <Button onClick={() => setShowList(!showList)}>{showList ? 'Cancel' : 'Edit'}</Button>,
          ],
          value && value.length > 0 ? <Button onClick={handleChange('')}>Clear</Button> : [],
        )}
      />
      {showList && (
        <Paper className={classes.paper}>
          <List dense className={classes.list}>
            {choices && choices.length && choices.length > 0 ? (
              choices.map(choice => (
                <ListItem
                  className={cx(value === choice.id && 'selected')}
                  button
                  onClick={handleChange(choice.id)}
                  disabled={loading}
                  key={choice.id}
                >
                  <ListItemText
                    primary={optionText !== undefined ? choice[optionText] : choice.id}
                  />
                </ListItem>
              ))
            ) : (
              <div className="no-results-text">
                <Alert color="info">Empty page</Alert>
              </div>
            )}
          </List>
          <div className="actions">
            {loading ? (
              <div className="loading-container">
                <LinearProgress />
              </div>
            ) : (
              <Fragment>
                {page > 1 && (
                  <Button
                    className={cx(classes.paginationButton, 'prev')}
                    onClick={handlePrevClick()}
                  >
                    <ChevronLeft />
                  </Button>
                )}
                {choices && choices.length >= perPage && (
                  <Button
                    className={cx(classes.paginationButton, 'next')}
                    onClick={handleNextClick()}
                  >
                    <ChevronRight />
                  </Button>
                )}
              </Fragment>
            )}
          </div>
        </Paper>
      )}
    </div>
  );
};

export default withStyles(styles)(PaginatedList);
