import React from 'react';
import { Auth } from 'aws-amplify';
import gql from 'graphql-tag';

import {
  ButtonToolbar,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Mutation, withApollo } from 'react-apollo';

import Can from '../Can/Can';
import * as auth from '../../utils/auth';
import Loader from '../Loader/Loader';
import { toast } from '../Toast/Toast';

const IMPERSONATE = gql`
  mutation impersonate($role_id: ID!, $organization_id: ID!) {
    impersonate(role_id: $role_id, organization_id: $organization_id)
  }
`;

class ImpersonateControl extends React.Component {
  state = {
    selectedRole: null,
    open: false,
    loading: false,
  };

  handleClick = (role, fn) => {
    this.setState({ selectedRole: role, loading: true }, () => {
      fn()
        .then(res => {
          Auth.currentAuthenticatedUser().then(user => {
            user.refreshSession(user.signInUserSession.refreshToken, (err, session) => {
              window.location.reload();
            });
          });
        })
        .catch(err => {
          this.setState({
            loading: false,
          });
          console.log('impersonation error: ', err);
          toast.error('Error impersonating');
        });
    });
  };

  toggle = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  render() {
    const { selectedRole, loading } = this.state;
    const {
      record: { id },
    } = this.props;

    return (
      <div className="impersonate-control">
        <Can perform="impersonate:*">
          <Mutation
            mutation={IMPERSONATE}
            variables={{ role_id: selectedRole, organization_id: id }}
          >
            {impersonate =>
              loading ? (
                <Loader fullscreen />
              ) : (
                <ButtonToolbar id={id}>
                  <ButtonDropdown
                    id="takeover"
                    title="Takeover"
                    isOpen={this.state.open}
                    toggle={this.toggle}
                  >
                    <DropdownToggle caret color="primary">
                      Takeover
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={() => this.handleClick(auth.SYSTEM_ADMINISTRATOR_ID, impersonate)}
                        className="hover-pointer"
                      >
                        as System Admin
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => this.handleClick(auth.ORGANIZATION_ADMIN_ID, impersonate)}
                        className="hover-pointer"
                      >
                        as Organization Admin
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => this.handleClick(auth.ORGANIZATION_USER_ID, impersonate)}
                        className="hover-pointer"
                      >
                        as Organization User
                      </DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>
                </ButtonToolbar>
              )
            }
          </Mutation>
        </Can>
      </div>
    );
  }
}

export default withApollo(ImpersonateControl);
