import React from 'react';
import { Redirect } from 'react-router-dom';
import { List, Datagrid, TextField, EditButton } from 'react-admin';

import Can from '../../components/Can/Can';
import PasswordPrompt from '../../components/PasswordPrompt/PasswordPrompt';
import BulkActionButtons from '../common/BulkActionButtons/BulkActionButtons';
import ListActions from '../common/ListActions/ListActions';
import Pagination from '../common/Pagination/Pagination';
import { redirectUrl } from './index.js';

export const ReportTypeList = props => (
  <Can perform="reportTypes:list" no={<Redirect to={redirectUrl} />}>
    <PasswordPrompt>
      {({ promptCheck }) => (
        <List
          {...props}
          perPage={15}
          pagination={<Pagination />}
          actions={
            <ListActions
              title="Report Types"
              object="reportTypes"
              filterValues={{ allOrganizations: true }}
            />
          }
          bulkActionButtons={
            <BulkActionButtons
              object="reportTypes"
              deleteModalHeader="Are you sure you want to delete the selected Report Types?"
            />
          }
          sort={{ field: 'display_name', order: 'ASC' }}
          filter={{ allOrganizations: true }}
        >
          <Datagrid>
            <TextField source="display_name" />
            <Can perform="reportTypes:update">
              <EditButton basePath="/reportTypes" />
            </Can>
          </Datagrid>
        </List>
      )}
    </PasswordPrompt>
  </Can>
);
