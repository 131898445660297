import gql from 'graphql-tag';
import Fragments from './fragments';

export const GET_SIDEBAR_ASSETS = gql`
  query getSidebarAssets {
    sidebar @client {
      selectedAsset
      hoverAsset
    }
  }
`;

export const GET_ASSET_BY_ID = gql`
  query getSelectedAsset($id: ID, $start_date: AWSDate!, $end_date: AWSDate!) {
    assets(id: $id) {
      data {
        ... on Asset {
          ...AssetFields
        }
      }
    }

    dwell_time_by_room_type(asset_id: $id, start_date: $start_date, end_date: $end_date) {
      room_type {
        name
      }
      dwell_time_sum
    }
  }
  ${Fragments.assetFields}
`;

export const SEARCH_ASSETS = gql`
  query searchAssets($search_query: String) {
    assets(search_string: $search_query) {
      data {
        ... on Asset {
          ...AssetFields
        }
      }
    }
  }
  ${Fragments.assetFields}
`;

export const GET_ASSET_DWELL_TIME = gql`
  query assetDwellTime($asset_id: ID, $start_date: AWSDate!, $end_date: AWSDate!) {
    dwell_time_by_room_type(asset_id: $asset_id, start_date: $start_date, end_date: $end_date) {
      room_type {
        name
      }
      dwell_time_sum
    }
  }
`;

export const GET_ASSET_DISTANCE_TRAVELED = gql`
  query distanceTraveled($asset_id: ID!) {
    distance_traveled(asset_id: $asset_id) {
      day
      distance_traveled
    }
  }
`;

export const GET_CHECKIN_EVENTS = gql`
  query checkinEvents($asset_id: ID!) {
    events(asset_id: $asset_id, event_types: [CHECKIN, CHECKOUT]) {
      event_type
      event_time
      dwell_time
      room {
        id
        name
      }
    }
  }
`;

export const ZONE_ROOM_PRESENCE_QUERY = gql`
  query zonePresence($zone_id: ID!) {
    zone_presence(zone_id: $zone_id) {
      ZoneId
      RoomId
      PresenceDetected
    }
  }
`;

export const GET_LOCATIONS_BY_ZONE = gql`
  query locations($zone_id: ID!) {
    locations(zone_id: $zone_id) {
      X
      Y
      TagSerialNumber
      BatteryPercent
      DeviceEventTime
      room {
        name
      }
    }
  }
`;
