import React from 'react';
import { Redirect } from 'react-router-dom';
import { Edit, SimpleForm, TextField, ReferenceInput } from 'react-admin';

import Can from '../../components/Can/Can';
import EditToolbar from '../common/EditToolbar/EditToolbar';
import FormHeader from '../common/FormHeader/FormHeader';
import PaginatedList from '../common/PaginatedList/PaginatedList';
import { validate } from '../../utils/admin';
import { formSchema, redirectUrl } from './index.js';
import { DEFAULT_PAGINATION_PER_PAGE } from '../../utils/admin';

export const TagEdit = props => (
  <Can perform="tags:update" no={<Redirect to={redirectUrl} />}>
    <Edit {...props}>
      <SimpleForm
        validate={values => validate(formSchema, values)}
        toolbar={
          <EditToolbar
            object="tags"
            deleteModalHeader="Are you sure you want to delete this Tag?"
          />
        }
      >
        <FormHeader text="Tag - Edit" />
        <TextField source="serial_number" />
        <ReferenceInput
          label="Asset"
          source="asset.id"
          perPage={DEFAULT_PAGINATION_PER_PAGE}
          reference="assets"
          filter={{ noTag: true, tagId: props.id }}
          allowEmpty
        >
          <PaginatedList optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  </Can>
);
