import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';

const styles = theme => ({
  formControl: {
    width: '100%',
  },
});

function offObj(autoOff) {
  const returnObj = {};

  const applyOff = input => {
    switch (input) {
      case 'complete':
        Object.assign(returnObj, {
          autoComplete: 'off',
          list: 'autocompleteOff',
        });
        break;

      case 'correct':
        returnObj.autoCorrect = 'off';
        break;

      case 'capitalize':
        returnObj.autoCapitalize = 'off';
        break;

      case 'spellCheck':
        returnObj.spellCheck = false;
        break;

      default:
        Object.assign(returnObj, {
          autoComplete: 'off',
          list: 'autocompleteOff',
          autoCorrect: 'off',
          autoCapitalize: 'off',
          spellCheck: false,
        });
        break;
    }
  };

  if (autoOff && typeof autoOff === 'string') {
    applyOff(autoOff);
  } else if (autoOff === true) {
    applyOff();
  } else if (autoOff) {
    autoOff.forEach(off => applyOff(off));
  }

  return returnObj;
}

const TextInput = props => {
  const { autoOff, errorText, ...rest } = props;

  return (
    <div className="mb-3">
      <FormControl className={props.classes.formControl} error={!!props.error}>
        <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
        <Input fullWidth type="text" {...autoOff && offObj(autoOff)} {...rest} />
        {props.error && <FormHelperText id={props.id}>{errorText || 'Error'}</FormHelperText>}
      </FormControl>
    </div>
  );
};

TextInput.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default withStyles(styles)(TextInput);
