import gql from 'graphql-tag';

export default function reportTypeQueries(raFetchType, params) {
  switch (raFetchType) {
    case 'CREATE':
      return {
        query: gql`
          mutation addReportType($display_name: String!, $component_id: String!) {
            addReportType(display_name: $display_name, component_id: $component_id) {
              id
            }
          }
        `,
        variables: params.data,
        parseResponse: response => ({ data: response.data.addReportType }),
      };
    case 'UPDATE':
      return {
        query: gql`
          mutation updateReportType($id: ID!, $display_name: String, $component_id: String) {
            updateReportType(id: $id, display_name: $display_name, component_id: $component_id) {
              id
            }
          }
        `,
        variables: params.data,
        parseResponse: response => ({ data: response.data.updateReportType }),
      };
    case 'GET_ONE':
      return {
        query: gql`
          query getReportTypes($id: ID, $filter: TypesFilter) {
            report_types(id: $id, filter: $filter) {
              total
              data {
                ... on ReportType {
                  id
                  display_name
                  component_id
                }
              }
            }
          }
        `,
        variables: {
          filter: {
            allOrganizations: true,
          },
          ...params,
        },
        parseResponse: response => ({
          data:
            response.data &&
            response.data.report_types &&
            response.data.report_types.data &&
            response.data.report_types.data.length &&
            response.data.report_types.data[0],
        }),
      };
    case 'GET_MANY':
    case 'GET_LIST':
      return {
        query: gql`
          query getReportTypes($sort: Sort, $pagination: Pagination, $filter: TypesFilter) {
            report_types(sort: $sort, pagination: $pagination, filter: $filter) {
              total
              data {
                ... on ReportType {
                  id
                  display_name
                  component_id
                }
              }
            }
          }
        `,
        variables: params,
        parseResponse: response => ({
          data: response.data.report_types.data,
          total: response.data.report_types.total,
        }),
      };
    case 'DELETE':
      return {
        query: gql`
          mutation deleteReportTypes($id: [ID!]!) {
            deleteReportTypes(id: $id) {
              id
            }
          }
        `,
        variables: params,
        parseResponse: response => ({ data: response.data.deleteReportTypes[0] }),
      };
    case 'DELETE_MANY':
      const variables = {
        id: params.ids,
      };

      return {
        query: gql`
          mutation deleteReportTypes($id: [ID!]!) {
            deleteReportTypes(id: $id) {
              id
            }
          }
        `,
        variables,
        parseResponse: response => ({ data: response.data.deleteReportTypes }),
      };
    default:
      throw new Error('Invalid fetch type');
  }
}
