import React from 'react';
import { Alert, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

// TODO: Might consider abstracting this further, about 8 different files use Alert, might be a way forward to formalize look and feel of the component in the future
const SearchResultsAlert = props => (
  <Alert {...props}>
    <div className="alert__content">
      <Row className="alert__body">
        <Col sm="auto" className="d-flex align-items-center">
          <i className="fas fa-exclamation-circle" />{' '}
        </Col>
        <Col className="d-flex align-items-center pl-0">
          <p className="mb-0">{props.message}</p>
        </Col>
      </Row>
    </div>
  </Alert>
);

SearchResultsAlert.propTypes = {
  message: PropTypes.string.isRequired,
};

export default SearchResultsAlert;
