import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Field } from 'redux-form';
import {
  BooleanInput,
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

import Can from '../../components/Can/Can';
import EditToolbar from '../common/EditToolbar/EditToolbar';
import FormHeader from '../common/FormHeader/FormHeader';
import LongTextInput from '../common/LongTextInput/LongTextInput';
import { validate } from '../../utils/admin';
import { formSchema, redirectUrl } from './index.js';
import { REQUIRED_FIELD } from '../../utils/validations';
import VerticesInput from './VerticesInput';

export const RoomEdit = props => {
  const [selectedZoneId, setSelectedZoneId] = useState(false);

  return (
    <Can perform="rooms:update" no={<Redirect to={redirectUrl} />}>
      <Edit {...props}>
        <SimpleForm
          validate={values => validate(formSchema, values)}
          toolbar={
            <EditToolbar
              object="rooms"
              deleteModalHeader="Are you sure you want to delete this Room?"
            />
          }
        >
          <FormHeader text="Room - Edit" />
          <TextInput source="name" required />
          <LongTextInput source="description" showCounter />
          <ReferenceInput
            label="Room Type"
            source="room_type.id"
            reference="roomTypes"
            validate={values => !values && REQUIRED_FIELD.message}
            required
            filter={{ allOrganizations: false }}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <BooleanInput source="trigger_entry_alerts" label="Trigger entry alerts" />
          <ReferenceInput
            label="Zone"
            source="zone.id"
            reference="zones"
            validate={values => {
              setSelectedZoneId(values);
              return !values && REQUIRED_FIELD.message;
            }}
            required
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          {selectedZoneId && (
            <Field
              name="vertices"
              component={fieldProps => (
                <VerticesInput formType="edit" selectedZoneId={selectedZoneId} {...fieldProps} />
              )}
            />
          )}
        </SimpleForm>
      </Edit>
    </Can>
  );
};
