import React from 'react';
import { Auth } from 'aws-amplify';
import { withApollo } from 'react-apollo';
import { Formik } from 'formik';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  FormFeedback,
  Button,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { Query } from 'react-apollo';
import * as yup from 'yup';
import gql from 'graphql-tag';

import logo from '../Header/logo.svg';
import { toast } from '../../components/Toast/Toast';
import Loader from '../../components/Loader/Loader';

import { REQUIRED_FIELD } from '../../utils/validations';

const GET_LOCAL_USER_DATA = gql`
  query getLocalUserData {
    userData @client {
      email
    }
  }
`;

const GET_USER_AUTHENTICATION_STATUS = gql`
  query getUserAuthenticationStatus {
    user_authentication_status
  }
`;

const schema = yup.object().shape({
  password: yup.string().required(REQUIRED_FIELD.message),
});

class PasswordPrompt extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      show: false,
      cancel: false,
      callback: null,
    };
  }

  promptCheck = callback => {
    const { forceCurrent } = this.props;
    const { client } = this.props;

    const done = values => {
      this.setState({
        ...values,
        loading: false,
      });
    };

    client
      .query({ query: GET_USER_AUTHENTICATION_STATUS, fetchPolicy: 'network-only' })
      .then(res => {
        const {
          data: { user_authentication_status: isAuthorized },
        } = res;

        if (forceCurrent || !isAuthorized) {
          done({
            show: true,
            callback,
          });
        } else {
          Auth.currentAuthenticatedUser().then(user => {
            user.refreshSession(user.signInUserSession.refreshToken, (err, session) => {
              callback();
            });
          });
        }
      });
  };

  handleCancel = () => {
    this.setState({
      show: false,
      cancel: true,
    });
  };

  handleSubmit = event => {
    const { password } = event;
    const { callback: stateCallback } = this.state;

    Auth.currentSession().then(currentUser => {
      try {
        const {
          idToken: {
            payload: { email },
          },
        } = currentUser;

        Auth.signIn({
          username: email,
          password,
        })
          .then(res => {
            this.setState({
              show: false,
            });

            stateCallback({ password });
          })
          .catch(err => {
            toast.error('Error authenticating. Please check the password and try again.');
          });
      } catch (err) {
        toast.error('Error getting current session. Please refresh the page and try again.');
      }
    });
  };

  render() {
    const { loading, show, cancel } = this.state;
    const { children, onCancel } = this.props;
    const { promptCheck } = this;

    if (loading) {
      return <Loader />;
    }

    if (onCancel && cancel) {
      return onCancel;
    }

    return show ? (
      <Query query={GET_LOCAL_USER_DATA}>
        {({ data, loading, error }) => {
          if (loading || error) {
            return <Loader />;
          }

          const {
            userData: { email },
          } = data;

          return (
            <Modal isOpen toggle={onCancel} centered size="md" className="password-prompt-modal">
              <ModalHeader>
                <img src={logo} alt="Repp Health Logo" className="logo" />
                Please enter your password to continue
              </ModalHeader>
              <ModalBody>
                <p>You are logged in as: {email}</p>
                <Formik
                  onSubmit={this.handleSubmit}
                  validationSchema={schema}
                  initialValues={{
                    password: '',
                  }}
                >
                  {({ handleSubmit, handleChange, values, isValid, touched, errors }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Col>
                        <Row>
                          <FormGroup style={{ width: '100%' }}>
                            <Label for="password" hidden>
                              Password
                            </Label>
                            <Input
                              required
                              type="password"
                              name="password"
                              id="password"
                              placeholder="Password"
                              value={values.password}
                              onChange={handleChange}
                              valid={touched.password && !errors.password}
                              invalid={touched.password && !!errors.password}
                            />
                            <FormFeedback type="invalid">{errors.password}</FormFeedback>
                          </FormGroup>
                        </Row>
                        <Row>
                          <FormGroup style={{ width: '100%' }}>
                            <Button color="secondary" onClick={this.handleCancel}>
                              Cancel
                            </Button>
                            <Button
                              color="primary"
                              type="submit"
                              disabled={loading}
                              style={{ float: 'right' }}
                            >
                              {loading ? 'Submitting...' : 'Submit'}
                            </Button>
                          </FormGroup>
                        </Row>
                      </Col>
                    </Form>
                  )}
                </Formik>
              </ModalBody>
            </Modal>
          );
        }}
      </Query>
    ) : (
      children({ promptCheck })
    );
  }
}

export default withApollo(PasswordPrompt);
