import React, { Component } from 'react';
import gql from 'graphql-tag';
import { withApollo } from 'react-apollo';
import { Formik } from 'formik';
import * as yup from 'yup';
import { ButtonToolbar, ButtonGroup, Form, Col, Row, Button } from 'reactstrap';
import moment from 'moment-timezone';
import Chart from 'chart.js';

import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import { toast } from '../../../../components/Toast/Toast';
import { parseTimeInBedChartData } from '../../../../utils/chart';
import { REQUIRED_FIELD, DATE } from '../../../../utils/validations';
import DatePicker from '../../../../components/DatePicker/DatePicker';
import Loader from '../../../../components/Loader/Loader';
import FormFeedback from '../../../../components/FormFeedback/FormFeedback';
import Autocomplete from '../../../../components/Autocomplete/Autocomplete';
import { getAWSDate } from '../../../../utils/js';

const formSchema = yup.object().shape({
  roomId: yup
    .number()
    .test('is-valid', 'Invalid room', value => value && value > 0)
    .typeError(REQUIRED_FIELD.message),
  startDate: yup
    .date()
    .required(REQUIRED_FIELD.message)
    .typeError(DATE.message),
  endDate: yup
    .date()
    .required(REQUIRED_FIELD.message)
    .typeError(DATE.message)
    .test('end-date-check', 'End date must be after start date', function(value) {
      return value > this.parent.startDate;
    }),
});

const GET_INITIAL_FORM_DATA = gql`
  query getInitialFormData {
    rooms {
      total
      data {
        ... on Room {
          id
          name
        }
      }
    }
  }
`;

const GET_TIME_IN_BED = gql`
  query time_in_bed($room_id: [ID!]!, $start_date: AWSDate!, $end_date: AWSDate!) {
    time_in_bed(room_id: $room_id, start_date: $start_date, end_date: $end_date) {
      date
      percentage
      room {
        id
        name
      }
    }
  }
`;

const timeRangeOptions = [
  {
    id: '7d',
    label: '7 days',
    value: 7,
  },
  {
    id: '30d',
    label: '30 days',
    value: 30,
  },
  {
    id: 'custom',
    label: 'Custom',
    value: null,
  },
];

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    width: '100%',
  },
});

class TimeInBed extends Component {
  state = {
    initialized: false,
    loading: false,
    rooms: null,
    selectedStartDate: moment(moment().subtract(7, 'days')).startOf('day'),
    selectedEndDate: moment().startOf('day'),
    selectedRangeOption: '7d',
    chart: null,
    chartElement: null,
  };

  componentDidMount() {
    const { client } = this.props;
    const chartElement = document.querySelector('canvas#chart');

    client
      .query({
        query: GET_INITIAL_FORM_DATA,
        fetchPolicy: 'network-only',
      })
      .then(res => {
        this.setState({
          rooms: res.data.rooms.data,
          initialized: true,
          chartElement,
        });
      })
      .catch(({ message: initUtilizationError }) => {
        toast.error(initUtilizationError || 'Error getting initial utilization data');
      });
  }

  handleSubmit = values => {
    const { client } = this.props;
    const { rooms, chartElement, selectedRangeOption, chart } = this.state;

    if (!rooms) {
      toast.error('Please select an asset type and time period');
      return false;
    }

    const { roomId, startDate, endDate } = values;
    const room = rooms.find(room => room.id === roomId);

    if (!room) {
      toast.error('Could not find room');
      return false;
    }

    this.setState({
      loading: true,
    });

    let startDateQueryVariable, endDateQueryVariable;
    if (selectedRangeOption === 'custom') {
      // use selected start/end dates
      startDateQueryVariable = getAWSDate(startDate);
      endDateQueryVariable = getAWSDate(endDate);
    } else {
      // use value from selected range option with today as end date
      startDateQueryVariable = getAWSDate(
        moment().subtract('day', timeRangeOptions.find(i => i.id === selectedRangeOption).value),
      );
      endDateQueryVariable = getAWSDate();
    }

    const query = {
      query: GET_TIME_IN_BED,
      fetchPolicy: 'network-only',
      variables: Object.assign(
        {
          room_id: room.id,
          start_date: startDateQueryVariable,
          end_date: endDateQueryVariable,
        },
        // chartTypeQueryVariable === 'line' && { period: 'daily' },
      ),
    };

    client
      .query(query)
      .then(res => {
        const parsedData =
          res.data &&
          res.data.time_in_bed &&
          res.data.time_in_bed.length &&
          parseTimeInBedChartData(res.data.time_in_bed);

        if (parsedData) {
          chart && chart.destroy();
          this.setState({
            loading: false,
            selectedStartDate: startDateQueryVariable,
            selectedEndDate: endDateQueryVariable,
            chart: new Chart(chartElement, {
              type: 'line',
              data: parsedData,
              options: {
                responsive: true,
                legend: {
                  onClick: e => e.stopPropagation(),
                },
                maintainAspectRatio: false,
                tooltips: {
                  callbacks: {
                    label: (item, data) => {
                      const { datasets } = data;

                      return datasets && datasets.length && datasets.length > 0
                        ? `${item.value}%`
                        : '';
                    },
                  },
                },
                scales: {
                  yAxes: [
                    {
                      display: true,
                      ticks: {
                        suggestedMin: 0,
                        suggestedMax: 100,
                      },
                    },
                  ],
                },
              },
            }),
          });
        } else {
          toast.info('No utilization data found');
          this.setState({
            loading: false,
          });
        }
      })
      .catch(({ message: queryError }) => {
        toast.error(queryError || 'Error get utilization data');
        this.setState({
          loading: false,
        });
      });
  };

  updateRangeOption = event => {
    const {
      target: { id },
    } = event;

    this.setState({
      selectedRangeOption: id,
    });
  };

  render() {
    const {
      props: { classes },
      state: {
        initialized,
        selectedStartDate,
        selectedEndDate,
        rooms,
        loading,
        selectedRangeOption,
      },
    } = this;

    return (
      <div className="report-page--content__container">
        {loading && <Loader fullscreen transparent />}
        <h1>Time in Bed</h1>
        {initialized && (
          <div className="time-in-bed-form mb-3">
            <Formik
              onSubmit={this.handleSubmit}
              validationSchema={formSchema}
              initialValues={{
                roomId: null,
                startDate: selectedStartDate,
                endDate: selectedEndDate,
              }}
            >
              {({ handleSubmit, handleChange, getFieldValue, setFieldValue, values, errors }) => (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col className="mb-3 align-self-end" lg={6}>
                      <FormControl className={classes.formControl}>
                        <Autocomplete
                          label="Room"
                          choices={rooms}
                          renderLabel={option => option.name}
                          onChange={value => setFieldValue('roomId', value)}
                          fluid
                          required
                          meta={{ touched: true, error: errors.roomId }}
                        />
                      </FormControl>
                    </Col>
                    <Col className="mb-3" lg={6}>
                      <InputLabel shrink>Time Range</InputLabel>
                      <ButtonToolbar>
                        <ButtonGroup>
                          {timeRangeOptions.map(option => (
                            <Button
                              key={option.id}
                              id={option.id}
                              active={selectedRangeOption === option.id}
                              onClick={this.updateRangeOption}
                            >
                              {option.label}
                            </Button>
                          ))}
                        </ButtonGroup>
                      </ButtonToolbar>
                    </Col>
                  </Row>
                  {selectedRangeOption === 'custom' && (
                    <Row>
                      <Col className="mb-3" lg={6}>
                        <InputLabel shrink htmlFor="startDate">
                          Start Date
                        </InputLabel>
                        <div className="flex-box start-date-wrapper">
                          <DatePicker
                            time={false}
                            defaultValue={new Date(values.startDate)}
                            name="startDate"
                            onChange={value => setFieldValue('startDate', value)}
                            onKeyUp={({ target: { value } }) => setFieldValue('startDate', value)}
                            max={new Date()}
                            min={
                              new Date(
                                moment()
                                  .subtract('months', 6)
                                  .format(),
                              )
                            }
                          />
                        </div>
                        <FormFeedback visible={errors.startDate} message={errors.startDate} />
                      </Col>
                      <Col className="mb-3" lg={6}>
                        <InputLabel shrink htmlFor="endDate">
                          End Date
                        </InputLabel>
                        <div className="flex-box start-date-wrapper">
                          <DatePicker
                            time={false}
                            defaultValue={new Date(values.endDate)}
                            name="endDate"
                            onChange={value => setFieldValue('endDate', value)}
                            max={new Date()}
                            min={
                              new Date(
                                moment()
                                  .subtract('year', 1)
                                  .format(),
                              )
                            }
                          />
                        </div>
                        <FormFeedback visible={errors.endDate} message={errors.endDate} />
                      </Col>
                    </Row>
                  )}
                  <Button type="submit" color="primary">
                    Submit
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        )}
        <div className="chart-wrapper">
          <div className="chart-container">
            <canvas id="chart" width="400" height="400" />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(withApollo(TimeInBed));
