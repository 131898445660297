import gql from 'graphql-tag';

export default function tagQueries(raFetchType, params) {
  switch (raFetchType) {
    case 'CREATE':
      return {
        query: gql`
          mutation addTag($serial_number: String!, $asset_id: ID) {
            addTag(asset_id: $asset_id, serial_number: $serial_number) {
              id
            }
          }
        `,
        variables: {
          ...params.data,
          asset_id: params.data.asset && params.data.asset.id,
        },
        parseResponse: response => ({ data: response.data.addTag }),
      };
    case 'UPDATE':
      return {
        query: gql`
          mutation updateTag($id: ID!, $asset_id: ID) {
            updateTag(id: $id, asset_id: $asset_id) {
              id
            }
          }
        `,
        variables: {
          ...params.data,
          asset_id:
            params.data.asset && params.data.asset.id === ''
              ? null
              : params.data.asset && params.data.asset.id,
        },
        parseResponse: response => ({ data: response.data.updateTag }),
      };
    case 'GET_ONE':
      return {
        query: gql`
          query getTags($id: ID) {
            tags(id: $id) {
              total
              data {
                ... on Tag {
                  id
                  serial_number
                  asset {
                    id
                    name
                  }
                }
              }
            }
          }
        `,
        variables: params,
        parseResponse: response => ({
          data:
            response.data &&
            response.data.tags &&
            response.data.tags.data &&
            response.data.tags.data.length &&
            response.data.tags.data[0],
        }),
      };
    case 'GET_MANY':
    case 'GET_LIST':
      return {
        query: gql`
          query getTags($sort: Sort, $pagination: Pagination) {
            tags(sort: $sort, pagination: $pagination) {
              total
              data {
                ... on Tag {
                  id
                  serial_number
                  asset {
                    id
                    name
                  }
                }
              }
            }
          }
        `,
        variables: params,
        parseResponse: response => ({
          data: response.data.tags ? response.data.tags.data : [],
          total: response.data.tags ? response.data.tags.total : 0,
        }),
      };
    case 'DELETE':
      return {
        query: gql`
          mutation deleteTags($id: [ID!]!) {
            deleteTags(id: $id) {
              id
            }
          }
        `,
        variables: params,
        parseResponse: response => ({ data: response.data.deleteTags[0] }),
      };
    case 'DELETE_MANY':
      const variables = {
        id: params.ids,
      };

      return {
        query: gql`
          mutation deleteTags($id: [ID]!) {
            deleteTags(id: $id) {
              id
            }
          }
        `,
        variables,
        parseResponse: response => ({ data: response.data.deleteTags }),
      };
    default:
      throw new Error('Invalid fetch type');
  }
}
