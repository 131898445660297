import React from 'react';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import history from '../../utils/history';
import { getHumanizedTimestamp } from '../../utils/js';

const styles = theme => ({
  button: {
    backgroundColor: '#FFB838',
    '&:hover': {
      backgroundColor: '#ffa601',
    },
  },
  dismissButton: {
    float: 'right',
  },
});

class NotificationToast extends React.Component {
  state = {
    agedMessage: this.props.message && this.props.message.replace('%t', 'a few seconds ago'),
  };

  componentDidMount() {
    const { createdAt, message } = this.props;

    this.agedTimestampTimer = setInterval(() => {
      const newTimestampString = getHumanizedTimestamp(createdAt, true);

      this.setState({
        agedMessage: message.replace('%t', newTimestampString),
      });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.agedTimestampTimer);
  }

  render() {
    const {
      type,
      button,
      classes,
      onShowRoom,
      showDismiss,
      onDismiss,
      title,
      closeToast,
    } = this.props;
    const { agedMessage } = this.state;

    return (
      <div className={`notification--web notification--${type}`}>
        <div>
          <p>{title}</p>
          <p>{agedMessage}</p>
        </div>
        <div>
          {button && (
            <Button
              size="small"
              variant="contained"
              className={classes.button}
              onClick={() => (button.route ? history.push(`${button.route}`) : onShowRoom())}
            >
              {button.copy}
            </Button>
          )}
          {showDismiss && (
            <Button
              size="small"
              variant="contained"
              className={classes.dismissButton}
              onClick={() => {
                closeToast();
                onDismiss();
              }}
            >
              Dismiss
            </Button>
          )}
        </div>
      </div>
    );
  }
}

NotificationToast.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  createdAt: PropTypes.string,
};

export default withStyles(styles)(NotificationToast);
