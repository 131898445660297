import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Notification from '../../components/Notification/Notification';
import NetworkStatus from '../../components/Network/NetworkStatus';

const Layout = ({ children }) => (
  <div className="app-layout">
    <NetworkStatus />
    <Notification />
    <Header />

    <div className="app-layout--portal-root" />
    <div className="app-layout--content">{children}</div>

    <Footer />
  </div>
);

export default Layout;
