import React from 'react';
import axios from 'axios';

import log from '../../utils/Log';

class VersionChecker extends React.Component {
  state = {
    version: null,
    changedCount: 0,
  };

  componentDidMount() {
    this.check();
    this.beginPoll();
  }

  beginPoll = () => {
    const interval = 60; // seconds

    setInterval(() => {
      this.check();
    }, interval * 1000);
  };

  check = async () => {
    const json = await axios.get(`${process.env.PUBLIC_URL}/awsmobile.json`, {
      headers: {
        'Cache-Control': 'no-cache, no-store, max-age=0, private',
      },
    });

    if (json && json.data) {
      const { version: stateVersion, changedCount } = this.state;
      const {
        data: { version },
      } = json;
      if (stateVersion !== version) {
        const { pathname } = window.location;

        if (changedCount >= 1 && pathname.includes('/locate')) {
          // only reload the page if the endpoint changes more than once to account
          // for component initialization
          window.location.reload();
        }

        this.setState({
          version,
          changedCount: changedCount + 1,
        });
      }
    } else {
      log.error('Failed to get awsmobile.json from version change');
    }
  };

  render() {
    return false;
  }
}

export default VersionChecker;
