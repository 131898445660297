import React, { useState } from 'react';
import cx from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import FormFeedback from '../FormFeedback/FormFeedback';

const styles = theme => ({
  textField: {
    zIndex: 1101,
    width: '256px',
    '&.fluid': {
      width: '100%',
    },
  },
  suggestionsOverlay: {
    position: 'fixed',
    zIndex: 1100, // sits directly under header and on top of everything else
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  },
  suggestionsWrapper: {
    position: 'absolute',
    maxHeight: '257px',
    overflowY: 'auto',
    zIndex: 1101,
  },
});

const Autocomplete = ({
  label,
  choices,
  renderLabel,
  classes,
  onChange,
  fluid,
  required,
  meta,
  ...rest
}) => {
  const getOptionLabel = option => {
    return renderLabel !== undefined ? renderLabel(option) : option.label;
  };
  const defaultSuggestions = choices.map(option => ({
    id: option.id,
    label: getOptionLabel(option),
  }));

  const initialOption = (() => {
    if (Object.prototype.hasOwnProperty.call(rest, 'input')) {
      // this component is being used inside react-admin and may have an initial value
      const {
        input: { value },
      } = rest;

      return choices.find(choice => parseInt(choice.id, 10) === parseInt(value, 10));
    }

    return null;
  })();

  const defaultTextValue = initialOption && getOptionLabel(initialOption);
  const [textValue, setTextValue] = useState(defaultTextValue || '');
  const [suggestions, setSuggestions] = useState(defaultSuggestions);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const handleValueChange = value => {
    // called when the selected value changes
    if (Object.prototype.hasOwnProperty.call(rest, 'input')) {
      // this component is being used inside react-admin and receives a different handler
      rest.input.onChange(value);
    } else {
      onChange(value);
    }
  };

  const handleTextChange = ({ target: { value } }) => {
    setTextValue(value);

    if (!value.length) {
      setSuggestions(defaultSuggestions);
      handleValueChange(null); // has to be null here to support react-admin
      return;
    }

    // get suggestions from current input
    const newSuggestions = getSuggestions(value);

    if (newSuggestions && newSuggestions.length) {
      // suggestions exist
      setShowSuggestions(true);
      setSuggestions(newSuggestions);
    } else {
      // no suggestions
      setShowSuggestions(false);
      setSuggestions([]);
    }

    if (
      newSuggestions.length === 1 &&
      newSuggestions[0].label.toLowerCase() === value.toLowerCase()
    ) {
      // user input exactly matches a single suggestion, handle value changes
      handleValueChange(newSuggestions[0].id);
      setShowSuggestions(false);
    } else {
      handleValueChange(-1); // set to an invalid id since input does not match a suggestion
    }
  };

  const handleSelectionClick = ({ currentTarget: { id } }) => {
    const option = choices.find(option => option.id === id);

    handleValueChange(id);
    setTextValue(getOptionLabel(option));
    setShowSuggestions(false);
  };

  const handleFocus = value => {
    if (!value || !value.length) {
      // text field is empty, show default suggestions
      setSuggestions(defaultSuggestions);
      setShowSuggestions(true);
    } else {
      // field is not empty, show suggestions based on input
      const newSuggestions = getSuggestions(value);
      setSuggestions(newSuggestions);

      if (newSuggestions && newSuggestions.length) {
        // only show suggestions if they exist
        setShowSuggestions(true);
      }
    }
  };

  const getSuggestions = value => {
    if (!value || !value.length) {
      return defaultSuggestions;
    }

    const labels = choices.map(option => getOptionLabel(option));
    const searchRegex = new RegExp(`${value}`, 'i');
    const suggestionLabels = labels.filter(label => searchRegex.test(label));

    return choices
      .filter(option => suggestionLabels.includes(getOptionLabel(option)))
      .map(suggestion => ({
        id: suggestion.id,
        label: getOptionLabel(suggestion),
      }));
  };

  return (
    <div className="autocomplete-wrapper">
      {showSuggestions && (
        <div className={classes.suggestionsOverlay} onClick={() => setShowSuggestions(false)} />
      )}
      <TextField
        label={`${label}${required ? ' *' : ''}`}
        className={cx(classes.textField, { fluid })}
        value={textValue}
        onChange={handleTextChange}
        onKeyDown={({ keyCode }) => keyCode === 9 && setShowSuggestions(false)} // hide suggestions on tab press
        onFocus={() => handleFocus(textValue)}
      />
      {showSuggestions && (
        <Paper className={classes.suggestionsWrapper}>
          <List dense>
            {suggestions.map(suggestion => (
              <ListItem
                key={suggestion.id}
                id={suggestion.id}
                button
                onClick={handleSelectionClick}
              >
                <ListItemText primary={suggestion.label} />
              </ListItem>
            ))}
          </List>
        </Paper>
      )}
      {meta !== undefined && meta.touched === true && meta.error && (
        <FormFeedback visible={true} message={meta.error} />
      )}
    </div>
  );
};

export default withStyles(styles)(Autocomplete);
