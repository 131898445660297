import React, { Component } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import { Auth } from 'aws-amplify';
import gql from 'graphql-tag';
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import Root from './containers/Root/Root';
import Login from './containers/Login/Login';
import Locate from './containers/Locate/Locate';
import Report from './containers/Report/Report';
import Settings from './containers/Settings/Settings';
import Manage from './containers/Manage/Manage';
import Layout from './containers/Layout/Layout';

import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import NotFound from './components/NotFound/NotFound';
import Maintenance from './components/Maintenance/Maintenance';

import Client from './client/Client';
import history from './utils/history';
import { INIT_CACHE_LENGTH } from './utils/map';
import { convertTokenToUserData } from './utils/auth';
import Loader from './components/Loader/Loader';
import { LOGIN_ROUTE, SETTINGS_ROUTE } from './utils/routes';
import { ToastContainer, toast } from './components/Toast/Toast';
import VersionChecker from './components/VersionChecker/VersionChecker';
import { tryFor, searchLocalStorage } from './utils/js';
import { AppContext, reducers as appReducers } from './context/AppContext';

import { APP_INIT_QUERY } from './client/queries/app';

import log from './utils/Log';
import './styles/App.scss';
import 'react-widgets/dist/css/react-widgets.css';

moment.locale('en');
momentLocalizer();

const GET_LOCAL_USER_DATA = gql`
  query {
    userData @client {
      impersonating
      groups
      organizationName
      emailVerified
    }
  }
`;

const INITIALIZE_APP = gql`
  mutation initializeApp($userData: UserData!, $config: Config!, $organizations: Organizations!) {
    initializeApp(userData: $userData, config: $config, organizations: $organizations) @client
  }
`;

const getSession = done => {
  Auth.currentSession()
    .then(user => {
      const { idToken } = user;

      const userData = {
        ...convertTokenToUserData(idToken),
        loggedIn: true,
      };

      if (done) {
        done(userData);
      }

      return userData;
    })
    .catch(err => {
      if (done) {
        done(false);
      }

      return false;
    });
};

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      initializeMutationCalled: false,
      user: null,
      client: null,
      showLogin: false,
      context: null,
      showMaintenance: false,
    };

    new Client()
      .initialize()
      .then(awsAppSyncClient => {
        getSession(userData => {
          tryFor(() => searchLocalStorage(/^(Cognito)[A-Za-z]+\.\w+\..+\.\w+(Token)$/g), 5000)
            .then(() => {
              this.initialize(awsAppSyncClient, userData);
            })
            .catch(() => this.initialize(awsAppSyncClient, userData));
        });
      })
      .catch(err => {
        console.log('Client init error: ', err);
        this.setState({
          showMaintenance: true,
        });
      });
  }

  initialize = (client, userData) => {
    if (userData) {
      const finish = () => {
        const { mutate } = client;

        client
          .query({
            query: APP_INIT_QUERY,
            fetchPolicy: 'network-only',
          })
          .then(appInitResult => {
            console.log(appInitResult);
            const {
              data: {
                get_config: config,
                vision_alerts_for_site_user,
                organizations,
                venues: orgVenues,
              },
            } = appInitResult;
            const activeAlerts = vision_alerts_for_site_user || [];

            // Seems like an opportunity to do away with the apollo cache here and rely on context api for userData
            // The only thing this mutation does is set the organizationName for the user and saves userData to @client
            // After the mutation, we're saving userData to state, which context would hold instead if we make this change
            // <withUserData> (would just go away), <Can>, <Header>, <Account>, <App> would need to be updated to move away from userData queries and use the context api, along with adding userData to context (maybe a userContext?)
            // On a more generalized theory note, I think we should be using context to store local state and use Apollo to get remote data,
            // writing mutations and relying on soon to be deprecated Apollo components with side-effects for local state has never been my favorite thing
            mutate({
              mutation: INITIALIZE_APP,
              fetchPolicy: 'no-cache',
              variables: { userData, organizations },
            })
              .then(initResult => {
                const organization = organizations && organizations.data && organizations.data[0];
                const venues = orgVenues && orgVenues.data;
                const piApiKey = config.find(obj => obj.key === 'piApiKey').value;
                const piApiUrl = config.find(obj => obj.key === 'piApiUrl').value;
                const queryCacheExpire = new Date(new Date().getTime() + INIT_CACHE_LENGTH);
                this.setState({
                  initializeMutationCalled: true,
                  loading: false,
                  user: userData,
                  client,
                  context: {
                    queryCacheExpire,
                    alerts: activeAlerts,
                    showingFallRoom: false,
                    subscriptions: [],
                    organization: {
                      ...organization,
                      venues,
                    },
                    config: {
                      piApiKey,
                      piApiUrl,
                    },
                  },
                });
              })
              .catch(initError => {
                toast('Error initializing app');
                this.setState({
                  loading: false,
                  showMaintenance: true,
                });
              });
          })
          .catch(({ message: appConfigError }) => {
            // Display Maintenance Page
            const message = appConfigError || 'Error configuring application';

            toast.error(message);
            log.error(`App config error: ${message}`);
            this.setState({
              showMaintenance: true,
            });
          });

        return;
      };

      //TODO: Consider moving userData to context api, which should serve as the single source of truth instead of componenet-based state
      // see line 130ish for more info
      // check for existing local user data, reset store if it exists
      client
        .query({ query: GET_LOCAL_USER_DATA })
        .then(res => {
          if (res.data && res.data.userData) {
            // existing user data exists in the cache, erase before proceeding
            client.resetStore().then(() => {
              finish();
            });
          } else {
            // no user data found in cache, initialize immediately
            finish();
          }
        })
        .catch(err => {
          console.log(err);
          this.setState({
            showMaintenance: true,
          });
        });
    } else {
      this.setState(
        {
          showLogin: true,
          loading: false,
          client,
        },
        () => {
          history.push(LOGIN_ROUTE);
        },
      );
    }
  };

  render() {
    const {
      client,
      loading: stateLoading,
      initializeMutationCalled,
      showLogin,
      showMaintenance,
    } = this.state;
    if (showMaintenance) {
      return <Maintenance withHeader withFooter />;
    }
    return stateLoading ? (
      <Loader fullscreen />
    ) : (
      <AppContext.Provider
        value={{
          ...this.state.context,
          ...appReducers(this),
        }}
      >
        <div id="app">
          <ToastContainer />
          {showLogin && <Login />}
          <VersionChecker />

          <ApolloProvider client={client}>
            {initializeMutationCalled && (
              <Router history={history}>
                <Layout>
                  <Switch>
                    <Route exact path="/" component={Root} />
                    <PrivateRoute path="/locate" component={Locate} />
                    <PrivateRoute path="/report" component={Report} />
                    <PrivateRoute path={SETTINGS_ROUTE} component={Settings} />
                    <PrivateRoute path="/manage" component={Manage} />
                    <PrivateRoute component={NotFound} />
                  </Switch>
                </Layout>
              </Router>
            )}
          </ApolloProvider>
        </div>
      </AppContext.Provider>
    );
  }
}

export default App;
