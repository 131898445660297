import gql from 'graphql-tag';

export default function siteUserQueries(raFetchType, params) {
  switch (raFetchType) {
    case 'CREATE':
      return {
        query: gql`
          mutation addSiteUser(
            $first_name: String!
            $last_name: String!
            $email: AWSEmail!
            $role: String!
            $phone_number: String
            $timezone: String!
          ) {
            addSiteUser(
              first_name: $first_name
              last_name: $last_name
              email: $email
              role: $role
              phone_number: $phone_number
              timezone: $timezone
            ) {
              id
            }
          }
        `,
        variables: params.data,
        parseResponse: response => ({ data: response.data.addSiteUser }),
      };
    case 'UPDATE':
      return {
        query: gql`
          mutation updateSiteUser(
            $id: ID
            $email_id: AWSEmail
            $first_name: String
            $last_name: String
            $email: AWSEmail
            $role: String
            $phone_number: String
            $timezone: String
          ) {
            updateSiteUser(
              id: $id
              email_id: $email_id
              first_name: $first_name
              last_name: $last_name
              email: $email
              role: $role
              phone_number: $phone_number
              timezone: $timezone
            ) {
              id
            }
          }
        `,
        variables: params.data,
        parseResponse: response => ({ data: response.data.updateSiteUser }),
      };
    case 'GET_ONE':
      return {
        query: gql`
          query getSiteUsers($id: ID, $email: AWSEmail) {
            site_users(id: $id, email: $email) {
              total
              data {
                ... on SiteUser {
                  id
                  first_name
                  last_name
                  email
                  role
                  phone_number
                  timezone
                }
              }
            }
          }
        `,
        variables: params,
        parseResponse: response => ({
          data:
            response.data &&
            response.data.site_users &&
            response.data.site_users.data &&
            response.data.site_users.data.length &&
            response.data.site_users.data[0],
        }),
      };
    case 'GET_MANY':
    case 'GET_LIST':
      return {
        query: gql`
          query getSiteUsers($sort: Sort, $pagination: Pagination) {
            site_users(sort: $sort, pagination: $pagination) {
              total
              data {
                ... on SiteUser {
                  id
                  first_name
                  last_name
                  email
                  role
                  phone_number
                  timezone
                }
              }
            }
          }
        `,
        variables: params,
        parseResponse: response => ({
          data: response.data.site_users.data,
          total: response.data.site_users.total,
        }),
      };
    case 'DELETE':
      return {
        query: gql`
          mutation deleteSiteUsers($id: [ID!]!) {
            deleteSiteUsers(id: $id) {
              id
            }
          }
        `,
        variables: params,
        parseResponse: response => ({ data: response.data.deleteSiteUsers[0] }),
      };
    case 'DELETE_MANY':
      const variables = {
        id: params.ids,
      };

      return {
        query: gql`
          mutation deleteSiteUsers($id: [ID]!) {
            deleteSiteUsers(id: $id) {
              id
            }
          }
        `,
        variables,
        parseResponse: response => ({ data: response.data.deleteSiteUsers }),
      };
    default:
      throw new Error('Invalid fetch type');
  }
}
