import React from 'react';
import { connect } from 'react-redux';
import { crudUpdate, crudCreate, SaveButton as RASaveButton } from 'react-admin';
import { startUndoable as startUndoableAction } from 'ra-core';

class SaveButton extends React.Component {
  handleClick = () => {
    const {
      record,
      startUndoable,
      basePath,
      resource,
      promptCheck,
      handleSubmit,
      update,
      additionalSubmitValues,
    } = this.props;

    return handleSubmit(values => {
      const submitValues = Object.assign(values, additionalSubmitValues);

      promptCheck(() =>
        startUndoable(
          update
            ? crudUpdate(resource, record.id, submitValues, null, basePath, basePath)
            : crudCreate(resource, submitValues, basePath, basePath),
        ),
      );
    });
  };

  render() {
    const {
      handleSubmitWithRedirect,
      basePath,
      update,
      promptCheck,
      startUndoable,
      deleteModalHeader,
      additionalSubmitValues,
      ...rest
    } = this.props;

    return (
      <div className="ra-save-button-override">
        <RASaveButton handleSubmitWithRedirect={this.handleClick} redirect={basePath} {...rest} />
      </div>
    );
  }
}

export default connect(
  null,
  { startUndoable: startUndoableAction },
)(SaveButton);
