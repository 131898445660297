import gql from 'graphql-tag';

export default function roomQueries(raFetchType, params) {
  switch (raFetchType) {
    case 'CREATE': {
      // need to strip out the '__typename' field from vertices
      const vertices =
        params.data.vertices &&
        params.data.vertices.map(
          vertice =>
            vertice &&
            vertice.x !== undefined &&
            vertice.y !== undefined && { x: vertice.x, y: vertice.y },
        );

      return {
        query: gql`
          mutation addRoom(
            $name: String!
            $zone_id: ID!
            $room_type_id: ID!
            $vertices: [VertexInput]!
            $description: String
            $trigger_entry_alerts: Boolean
          ) {
            addRoom(
              name: $name
              zone_id: $zone_id
              room_type_id: $room_type_id
              vertices: $vertices
              description: $description
              trigger_entry_alerts: $trigger_entry_alerts
            ) {
              id
            }
          }
        `,
        variables: {
          ...params.data,
          vertices,
          zone_id: params.data.zone.id,
          room_type_id: params.data.room_type.id,
        },
        parseResponse: response => ({ data: response.data.addRoom }),
      };
    }

    case 'UPDATE': {
      // need to strip out the '__typename' field from vertices
      const vertices =
        params.data.vertices &&
        params.data.vertices.map(vertice => ({ x: vertice.x, y: vertice.y }));

      return {
        query: gql`
          mutation updateRoom(
            $id: ID!
            $name: String
            $zone_id: ID
            $room_type_id: ID
            $vertices: [VertexInput]
            $description: String
            $trigger_entry_alerts: Boolean
          ) {
            updateRoom(
              id: $id
              name: $name
              zone_id: $zone_id
              room_type_id: $room_type_id
              vertices: $vertices
              description: $description
              trigger_entry_alerts: $trigger_entry_alerts
            ) {
              id
            }
          }
        `,
        variables: {
          ...params.data,
          vertices,
          zone_id: params.data.zone.id,
          room_type_id: params.data.room_type.id,
        },
        parseResponse: response => ({ data: response.data.updateRoom }),
      };
    }

    case 'GET_ONE':
      return {
        query: gql`
          query getRooms($id: ID) {
            rooms(id: $id) {
              total
              data {
                ... on Room {
                  id
                  name
                  description
                  trigger_entry_alerts
                  vertices {
                    x
                    y
                  }
                  zone {
                    id
                    name
                    vendor_zone_id
                    meter_to_pixel_scaler_x
                    meter_to_pixel_scaler_y
                    origin_offset_x
                    origin_offset_y
                    venue {
                      id
                      vendor_map_id
                      name
                    }
                    rooms {
                      id
                      name
                      vertices {
                        x
                        y
                      }
                    }
                  }
                  room_type {
                    id
                    name
                  }
                }
              }
            }
          }
        `,
        variables: params,
        parseResponse: response => ({
          data:
            response.data &&
            response.data.rooms &&
            response.data.rooms.data &&
            response.data.rooms.data.length &&
            response.data.rooms.data[0],
        }),
      };
    case 'GET_MANY':
    case 'GET_LIST':
      return {
        query: gql`
          query getRooms($sort: Sort, $pagination: Pagination) {
            rooms(sort: $sort, pagination: $pagination) {
              total
              data {
                ... on Room {
                  id
                  name
                  vertices {
                    x
                    y
                  }
                  zone {
                    id
                    name
                  }
                  room_type {
                    id
                    name
                  }
                }
              }
            }
          }
        `,
        variables: params,
        parseResponse: response => ({
          data: response.data.rooms.data,
          total: response.data.rooms.total,
        }),
      };
    case 'DELETE':
      return {
        query: gql`
          mutation deleteRooms($id: [ID!]!) {
            deleteRooms(id: $id) {
              id
              name
            }
          }
        `,
        variables: params,
        parseResponse: response => ({ data: response.data.deleteRooms[0] }),
      };
    case 'DELETE_MANY':
      const variables = {
        id: params.ids,
      };

      return {
        query: gql`
          mutation deleteRooms($id: [ID]!) {
            deleteRooms(id: $id) {
              id
            }
          }
        `,
        variables,
        parseResponse: response => ({ data: response.data.deleteRooms }),
      };
    default:
      throw new Error('Invalid fetch type');
  }
}
