import log from '../../utils/Log';

//TODO: Consider moving userData to context api, which should serve as the single source of truth instead of componenet-based state

export const initializeApp = (_, { userData, organizations }, { cache }) => {
  try {
    if (userData) {
      const firstOrg = organizations && organizations.data && organizations.data[0];
      if (firstOrg) {
        const data = {
          initialized: new Date().toISOString(),
          userData: {
            __typename: 'UserData',
            organizationName: firstOrg.name,
            ...userData,
          },
        };

        cache.writeData({ data });
        return data;
      }
      return null;
    } else {
      const data = {
        initialized: new Date().toISOString(),
        userData: {
          __typename: 'UserData',
          loggedIn: false,
          groups: [],
        },
      };

      cache.writeData({ data });
      return data;
    }
  } catch (err) {
    console.log(err);
    log.error(`Error initializing app: ${JSON.stringify(err)}`);
  }

  return null;
};

export const toggleColorBlindMode = (_, { colorBlindMode }, { cache }) => {
  try {
    const data = {
      userData: {
        __typename: 'UserData',
        colorBlindMode,
      },
    };

    cache.writeData({ data });
    return data;
  } catch (e) {
    console.log('e: ', e);
    return null;
  }
};
