import React, { Component, Fragment } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { withApollo, Subscription } from 'react-apollo';
import { Button } from 'reactstrap';
import _ from 'lodash';

import Sidebar from '../../components/Sidebar/Sidebar';
import Overview from './Sidebar/Overview';
import SearchResults from './Sidebar/SearchResults';
import BackButton from './Sidebar/BackButton';
import AssetsList from './Sidebar/AssetsList';
import AssetDetail from './Sidebar/AssetDetail';
import SearchField from './Sidebar/SearchField';
import SearchFieldMenu from './Sidebar/SearchFieldMenu';
import Can from '../../components/Can/Can';
import { ZONE_ROOM_PRESENCE_QUERY, GET_LOCATIONS_BY_ZONE } from '../../client/queries/map';
import { LOCATION_SUBSCRIPTION, ROOM_PRESENCE_SUBSCRIPTION } from '../../client/subscriptions/map';
import MapContainer from './Map/MapContainer';
import PropsRoute from '../../components/PropsRoute/PropsRoute';
import { ROOM_PRESENCE_DETECTION_LOCAL_STORAGE_KEY, getDefaultZone } from '../../utils/map';
import history from '../../utils/history';
import Breakpoint from '../../components/Breakpoint/Breakpoint';
import Loader from '../../components/Loader/Loader';
import { toast } from '../../components/Toast/Toast';
import { LocateContext, reducers as locateReducers } from '../../context/LocateContext';
import log from '../../utils/Log';
import { AppContext, withAppContext } from '../../context/AppContext';
import Maintenance from '../../components/Maintenance/Maintenance';

export const getZoneIdFromUrl = props => {
  let zoneId = null;
  const {
    location: { pathname },
    match: { path, isExact },
  } = props;

  if (path === '/locate' && !isExact) {
    // user is on an internal locate route, try to find current zone in URL
    const paths = pathname.split('/');
    zoneId = paths[2];
  }

  return zoneId;
};

class Locate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedZone: null,
      selectedAssets: null,
      previouslyClearedSelectedAssets: null,
      hoverAsset: null,
      loading: true,
      piSvc: null,
      piMap: null,
      filter: new Set(),
      bottomBarOpen: false,
      searchFieldMenuOpen: false,
      mapInitialized: false,
      showMaintenance: false,
      maintenanceMessage: 'Something went wrong.',
      context: {
        latestLocationData: null,
        roomPresenceData: [],
        showRoomPresence: false,
      },
    };
  }

  componentDidMount() {
    this.initialize();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      // URL has changed, check for different zone
      const { defaultVenue, selectedZone: stateSelectedZone } = this.state;
      const urlZone = getZoneIdFromUrl(nextProps);

      if (!stateSelectedZone || urlZone !== stateSelectedZone.id) {
        const defaultZone = getDefaultZone(defaultVenue);
        const selectedZone =
          defaultVenue.zones.find(zone => zone.id === urlZone) || stateSelectedZone || defaultZone;

        this.setState({
          selectedZone,
        });
      }
    }
  }

  updateSelectedZone = zone => {
    this.setState(
      {
        selectedZone: zone,
      },
      () => {
        // conditionally update URL
        const {
          props: {
            location: { pathname },
            match: { isExact },
          },
          state: {
            context: { showRoomPresence },
          },
        } = this;

        if (!isExact) {
          // user is on an internal /locate page
          const paths = pathname.split('/');

          if (paths[3]) {
            // user is on asset detail page, kick them back to asset list
            history.push(`/locate/${zone.id}`);
          }
        }

        if (showRoomPresence) {
          this.initializeRoomPresenceData();
        }
      },
    );
  };

  addOneAssetToFilter = assetId => {
    const { filter } = this.state;
    filter.add(assetId);
    this.setState({
      filter,
    });
  };

  addManyAssetsToFilter = assetIds => {
    const { filter } = this.state;
    assetIds.forEach(assetId => {
      filter.add(assetId);
    });
    this.setState({
      filter,
    });
  };

  clearOneAssetFromFilter = assetId => {
    const { filter } = this.state;
    filter.delete(assetId);
    this.setState({
      filter,
    });
  };

  clearManyAssetsFromFilter = assetIds => {
    const { filter } = this.state;
    assetIds.forEach(assetId => {
      filter.delete(assetId);
    });
    this.setState({
      filter,
    });
  };

  clearFilter = () => {
    const { filter } = this.state;
    filter.clear();
    this.setState({
      filter,
    });
  };

  clearSelectedAssets = () => {
    const { selectedAssets } = this.state;
    const previouslyClearedSelectedAssets = new Map(selectedAssets);
    selectedAssets.clear();
    this.setState({
      selectedAssets,
      previouslyClearedSelectedAssets,
    });
  };

  clearOneSelectedAsset = id => {
    const { selectedAssets } = this.state;
    selectedAssets.delete(id);
    this.setState({
      selectedAssets,
    });
  };

  clearManySelectedAssets = ids => {
    const { selectedAssets } = this.state;
    const clearedAssets = new Map();
    ids.forEach(id => {
      const assetToAdd = selectedAssets.get(id);
      clearedAssets.set(id, assetToAdd);
      selectedAssets.delete(id);
    });
    this.setState({
      selectedAssets,
      previouslyClearedSelectedAssets: clearedAssets,
    });
  };

  selectOneAsset = asset => {
    const { selectedAssets } = this.state;
    selectedAssets.set(asset && asset.id, asset);
    this.setState({
      selectedAssets,
    });
  };

  selectManyAssets = assets => {
    const { selectedAssets } = this.state;
    assets.forEach(asset => {
      if (asset) {
        selectedAssets.set(asset.id, asset);
      }
    });
    this.setState({
      selectedAssets,
    });
  };

  clearHoverAsset = () => {
    this.setState({
      hoverAsset: null,
    });
  };

  hoverOverAsset = asset => {
    this.setState({
      hoverAsset: asset,
    });
  };

  initialize = async () => {
    const {
      appContext: {
        resetQueryCacheExpire,
        queryCacheExpire,
        organization,
        organization: { venues },
      },
      client,
    } = this.props;

    const defaultVenue =
      venues &&
      venues.length > 0 &&
      (venues.find(
        venue =>
          venue.id === organization && organization.default_venue && organization.default_venue.id,
      ) ||
        venues[0]);

    if (!defaultVenue) {
      toast.error('Venue not found.');
      this.setState({
        loading: false,
        showMaintenance: true,
        maintenanceMessage: 'Venue not found.',
      });
      return;
    }

    const { zones, default_zone } = defaultVenue;

    const urlZone = getZoneIdFromUrl(this.props);
    const defaultZoneId = (default_zone && default_zone.id) || getDefaultZone(defaultVenue);

    if (!defaultZoneId) {
      toast.error('No zones found.');
      this.setState({
        loading: false,
        showMaintenance: true,
        maintenanceMessage: 'No zones found.',
      });
      return;
    }

    const selectedZone =
      zones.find(zone => zone.id === urlZone) || zones.find(zone => zone.id === defaultZoneId);

    // check the query cache
    // if the cache is older than 30 seconds, requery the selected zone's locations
    let selectedZoneWithUpdatedLocations;
    if (queryCacheExpire) {
      if (new Date().getTime() > queryCacheExpire) {
        await client
          .query({
            query: GET_LOCATIONS_BY_ZONE,
            fetchPolicy: 'network-only',
            variables: { zone_id: selectedZone.id },
          })
          .then(res => {
            // Update the selected zone with updated locations
            try {
              const updatedZone = {
                assets: selectedZone.assets.map(asset => {
                  if (asset && asset.tag && asset.tag.location) {
                    const updatedLocation =
                      res &&
                      res.data &&
                      res.data.locations &&
                      res.data.locations.find(
                        loc => loc.TagSerialNumber === asset.tag.location.TagSerialNumber,
                      );
                    Object.assign(asset.tag.location, updatedLocation);
                    return asset;
                  }
                  return asset;
                }),
              };
              selectedZoneWithUpdatedLocations = Object.assign(selectedZone, updatedZone);
              resetQueryCacheExpire();
            } catch (e) {
              log.warn(e);
            }
          })
          .catch(e => {
            log.warn(e);
          });
      }
    }
    this.setState(
      {
        defaultVenue,
        venues,
        selectedZone: selectedZoneWithUpdatedLocations || selectedZone,
        loading: false,
        selectedAssets: new Map(),
        previouslyClearedSelectedAssets: new Map(),
      },
      () => {
        // check local storage for additional configuration
        const showRoomPresence =
          localStorage.getItem(ROOM_PRESENCE_DETECTION_LOCAL_STORAGE_KEY) === 'true' || false;

        if (showRoomPresence) {
          this.initializeRoomPresenceData();
        }
      },
    );
  };

  toggleBottomBar = () => {
    this.setState({
      bottomBarOpen: !this.state.bottomBarOpen,
    });
  };

  handleSearch = () => {
    this.setState({
      bottomBarOpen: true,
    });
  };

  toggleSearchFieldMenu = () => {
    this.setState({
      searchFieldMenuOpen: !this.state.searchFieldMenuOpen,
    });
  };

  updateLocationDataFromSubscription = ({ updatedLocationData, asset }) => {
    this.setState({
      context: {
        ...this.state.context,
        latestLocationData: {
          asset,
          updatedLocationData,
        },
      },
    });
  };

  handlePresenceData = event => {
    const {
      state: {
        context: { roomPresenceData },
      },
    } = this;
    const presenceMap = new Map(roomPresenceData.map(item => [item.RoomId, item]));
    const {
      subscriptionData: {
        data: { updatedVisionRoomData },
      },
    } = event;
    presenceMap.set(updatedVisionRoomData.RoomId, updatedVisionRoomData);
    const newPresenceData = Array.from(presenceMap.entries()).map(entry => entry[1]);

    this.setState({
      context: {
        ...this.state.context,
        roomPresenceData: newPresenceData,
      },
    });
  };

  initializeRoomPresenceData = () => {
    const {
      state: { selectedZone },
      props: { client },
    } = this;

    client
      .query({
        query: ZONE_ROOM_PRESENCE_QUERY,
        variables: { zone_id: selectedZone.id },
        fetchPolicy: 'network-only',
      })
      .then(({ data: queryData }) => {
        const { zone_presence } = queryData;

        this.setState({
          context: {
            ...this.state.context,
            roomPresenceData: zone_presence || [],
            showRoomPresence: true,
          },
        });
      })
      .catch(error => {
        const message = error.message || 'Error getting zone presence';

        toast.error(message);
        log.error(error);
      });
  };

  render() {
    const {
      state: {
        selectedZone,
        filter,
        selectedAssets,
        previouslyClearedSelectedAssets,
        hoverAsset,
        loading,
        bottomBarOpen,
        defaultVenue,
        venues,
        latestLocationData,
        searchFieldMenuOpen,
        mapInitialized,
        context: { showRoomPresence },
        showMaintenance,
        maintenanceMessage,
      },
      props: { location },
      updateSelectedZone,
      clearSelectedAssets,
      clearOneSelectedAsset,
      clearManySelectedAssets,
      selectOneAsset,
      selectManyAssets,
      clearHoverAsset,
      hoverOverAsset,
      addOneAssetToFilter,
      addManyAssetsToFilter,
      clearOneAssetFromFilter,
      clearManyAssetsFromFilter,
      clearFilter,
      handleSearch,
      toggleSearchFieldMenu,
      handlePresenceData,
    } = this;

    if (showMaintenance) {
      return <Maintenance message={maintenanceMessage} />;
    }
    const searchFieldComponent = (
      <SearchField
        location={location}
        onSearch={handleSearch}
        toggleSearchFieldMenu={toggleSearchFieldMenu}
      />
    );
    const searchFieldMenuComponent = (
      <SearchFieldMenu
        toggleSearchFieldMenu={toggleSearchFieldMenu}
        searchFieldMenuOpen={searchFieldMenuOpen}
      />
    );
    const backButtonSwitch = (
      <Switch>
        <PropsRoute
          exact
          path="/locate"
          component={BackButton}
          clearSelectedAssets={clearSelectedAssets}
          clearHoverAsset={clearHoverAsset}
          clearFilter={clearFilter}
        />
        <PropsRoute
          exact
          path="/locate/:zoneSlug"
          component={BackButton}
          clearSelectedAssets={clearSelectedAssets}
          clearHoverAsset={clearHoverAsset}
          clearFilter={clearFilter}
        />
        <PropsRoute
          exact
          path="/locate/:zoneSlug/:assetSlug"
          component={BackButton}
          clearSelectedAssets={clearSelectedAssets}
          clearOneSelectedAsset={clearOneSelectedAsset}
          clearHoverAsset={clearHoverAsset}
          clearFilter={clearFilter}
        />
      </Switch>
    );

    const sidebarContentSwitch = (
      <Switch>
        <PropsRoute
          exact
          path="/locate"
          venues={venues}
          currentVenue={defaultVenue}
          component={Overview}
          selectedZone={selectedZone}
          updateSelectedZone={updateSelectedZone}
        />
        <PropsRoute
          exact
          path="/locate/search"
          component={SearchResults}
          selectedZone={selectedZone}
          hoverOverAsset={hoverOverAsset}
          clearHoverAsset={clearHoverAsset}
          selectOneAsset={selectOneAsset}
        />
        <PropsRoute
          exact
          path="/locate/:zoneSlug"
          filter={filter}
          venues={venues}
          component={AssetsList}
          currentVenue={defaultVenue}
          selectedZone={selectedZone}
          clearHoverAsset={clearHoverAsset}
          hoverOverAsset={hoverOverAsset}
          selectOneAsset={selectOneAsset}
        />
        <PropsRoute
          exact
          path="/locate/:zoneSlug/:assetSlug"
          venues={venues}
          component={AssetDetail}
          latestLocationData={this.state.latestLocationData}
          clearSelectedAssets={clearSelectedAssets}
          selectOneAsset={selectOneAsset}
        />
      </Switch>
    );

    return (
      <AppContext.Consumer>
        {appContext => (
          <LocateContext.Provider
            value={{
              ...this.state.context,
              ...locateReducers(this),
            }}
          >
            {!loading ? (
              <div className="locate-content">
                <Can perform="locate-page:visit" no={<Redirect to="/" />}>
                  {venues ? (
                    <Fragment>
                      <Breakpoint size={['xs', 'md']}>
                        <Fragment>
                          <div className="top-bar">
                            <div className="search-wrapper">
                              {searchFieldComponent}
                              <Button
                                color="secondary"
                                className="collapse show"
                                onClick={this.toggleBottomBar}
                              >
                                <i className={`fas fa-angle-${bottomBarOpen ? 'up' : 'down'}`} />
                              </Button>
                            </div>
                            {searchFieldMenuComponent}
                          </div>
                          {bottomBarOpen && (
                            <div className="bottom-bar">
                              <div className="bottom-bar--content">
                                {backButtonSwitch}
                                {sidebarContentSwitch}
                              </div>
                              <div className="bottom-bar--background" />
                            </div>
                          )}
                        </Fragment>
                      </Breakpoint>
                      <Breakpoint size={['lg', 'xl']}>
                        <Sidebar collapsable fixedPosition>
                          {backButtonSwitch}
                          {searchFieldComponent}
                          <hr />
                          {sidebarContentSwitch}
                        </Sidebar>
                        {searchFieldMenuComponent}
                      </Breakpoint>
                      <div className="app-content__main">
                        <MapContainer
                          mapInitComplete={() => this.setState({ mapInitialized: true })}
                          currentVenue={defaultVenue}
                          selectedZone={selectedZone}
                          clearFilter={clearFilter}
                          updateSelectedZone={updateSelectedZone}
                          clearSelectedAssets={clearSelectedAssets}
                          clearOneSelectedAsset={clearOneSelectedAsset}
                          clearManySelectedAssets={clearManySelectedAssets}
                          selectOneAsset={selectOneAsset}
                          selectManyAssets={selectManyAssets}
                          clearHoverAsset={clearHoverAsset}
                          hoverOverAsset={hoverOverAsset}
                          selectedAssets={selectedAssets}
                          previouslyClearedSelectedAssets={previouslyClearedSelectedAssets}
                          hoverAsset={hoverAsset}
                          addOneAssetToFilter={addOneAssetToFilter}
                          addManyAssetsToFilter={addManyAssetsToFilter}
                          clearOneAssetFromFilter={clearOneAssetFromFilter}
                          clearManyAssetsFromFilter={clearManyAssetsFromFilter}
                          latestLocationData={latestLocationData}
                          appContext={appContext}
                          {...this.props}
                        />
                        <Subscription
                          subscription={LOCATION_SUBSCRIPTION}
                          variables={{ selectedZoneId: selectedZone.id }}
                          shouldResubscribe={true}
                          onSubscriptionData={({
                            subscriptionData: {
                              data: { updatedLocationData },
                            },
                          }) => {
                            // If the map isn't initialized yet, do not update
                            if (!mapInitialized) {
                              return;
                            }

                            const asset = _.find(
                              selectedZone.assets,
                              asset =>
                                asset.tag.serial_number === updatedLocationData.TagSerialNumber,
                            );
                            try {
                              if (asset) {
                                Object.assign(asset.tag.location, updatedLocationData);
                              }
                              this.updateLocationDataFromSubscription({
                                updatedLocationData,
                                asset,
                              });
                            } catch (e) {
                              log.warn(e);
                            }
                          }}
                        />
                        {showRoomPresence && (
                          <Subscription
                            subscription={ROOM_PRESENCE_SUBSCRIPTION}
                            variables={{ selectedZoneId: selectedZone.id }}
                            shouldResubscribe={true}
                            onSubscriptionData={handlePresenceData}
                          />
                        )}
                      </div>
                    </Fragment>
                  ) : (
                    <Maintenance message="Venues not found." />
                  )}
                </Can>
              </div>
            ) : (
              <Loader fullscreen />
            )}
          </LocateContext.Provider>
        )}
      </AppContext.Consumer>
    );
  }
}

Locate.contextType = LocateContext;

export default withApollo(withAppContext(Locate));
