import React from 'react';
import { Redirect } from 'react-router-dom';
import { List, Datagrid, TextField, EditButton } from 'react-admin';

import Can from '../../components/Can/Can';
import PasswordPrompt from '../../components/PasswordPrompt/PasswordPrompt';
import BulkActionButtons from '../common/BulkActionButtons/BulkActionButtons';
import ListActions from '../common/ListActions/ListActions';
import Pagination from '../common/Pagination/Pagination';
import { redirectUrl } from './index.js';

export const ZoneList = props => (
  <Can perform="zones:list" no={<Redirect to={redirectUrl} />}>
    <PasswordPrompt>
      {({ promptCheck }) => (
        <List
          {...props}
          perPage={15}
          pagination={<Pagination />}
          actions={<ListActions title="Zones" object="zones" />}
          bulkActionButtons={
            <BulkActionButtons
              object="zones"
              deleteModalHeader="Are you sure you want to delete the selected Zones?"
            />
          }
          sort={{ field: 'name', order: 'ASC' }}
        >
          <Datagrid>
            <TextField source="name" />
            <TextField source="floor" />
            <Can perform="zones:list">
              <EditButton basePath="/zones" />
            </Can>
          </Datagrid>
        </List>
      )}
    </PasswordPrompt>
  </Can>
);
