import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { withApollo, Subscription } from 'react-apollo';

import { withUserData } from '../HOC/withUserData';
import { toast } from '../../components/Toast/Toast';
import NotificationToast from './NotificationToast';
import log from '../../utils/Log';
import { withAppContext } from '../../context/AppContext';
import { getMessageByComponentId, getAlertOptions } from '../../utils/notifications';
import { generateShortId } from '../../utils/js';

const GET_ALERT_TYPES_TO_SITE_USER = gql`
  query getAlertTypesToSiteUser($site_user_email: AWSEmail!) {
    alert_types_to_site_users {
      id
      notify_web

      alert_type {
        id
        sns_topic
        component_id
        name
      }
    }

    site_users(email: $site_user_email) {
      data {
        ... on SiteUser {
          timezone
        }
      }
    }
  }
`;

const ADD_ALERT_SUBSCRIPTION = gql`
  subscription($alertTypeId: ID, $orgId: ID) {
    updatedAlertData(alert_type_id: $alertTypeId, organization_id: $orgId) {
      id

      room {
        id
        name
      }
    }
  }
`;

const Notification = props => {
  const [initialized, updatedInitialized] = useState(false);
  const [timezone, updateTimezone] = useState('US/Mountain');
  const {
    appContext: { subscriptions, initializeSubscriptions },
    organizationId,
    client,
    email,
  } = props;

  useEffect(() => {
    client
      .query({
        query: GET_ALERT_TYPES_TO_SITE_USER,
        variables: { site_user_email: email },
        fetchPolicy: 'network-only',
      })
      .then(result => {
        const {
          data: { alert_types_to_site_users, site_users },
        } = result;
        const webSubscriptions =
          alert_types_to_site_users &&
          alert_types_to_site_users.filter(type => type.notify_web === true);

        updatedInitialized(true);
        updateTimezone(
          site_users && site_users.data && site_users.data.length && site_users.data[0].timezone,
        );
        initializeSubscriptions(webSubscriptions);
      })
      .catch(error => {
        log.error(error);
        toast.error('Error getting alert types for site user');
      });
  }, []);

  const handleSubscriptionData = event => {
    const {
      subscriptionData: {
        data: { updatedAlertData },
      },
    } = event;
    const {
      appContext: { addAlert, dismissAlert, showFallRoom },
    } = props;
    const subscription = subscriptions.find(
      subscription => subscription.alert_type.id === updatedAlertData.alert_type_id,
    );

    if (!subscription) {
      return;
    }

    const {
      alert_type: { id: alertTypeId, sns_topic, component_id, ...alertTypeRest },
    } = subscription;

    if (
      updatedAlertData &&
      updatedAlertData.message &&
      (updatedAlertData.updated_at || updatedAlertData.device_event_time)
    ) {
      const alertId = `${Math.round(new Date().getTime())}_${generateShortId()}`;
      const snsTopic = sns_topic.toLowerCase();
      const alertOptions = getAlertOptions(snsTopic);

      if (['fall_detected', 'fall_confirmed', 'fall_end', 'room_entry'].includes(component_id)) {
        // add to global state
        addAlert({
          id: alertId,
          ...updatedAlertData,
          alert_type: {
            id: alertTypeId,
            component_id,
            ...alertTypeRest,
          },
        });
      }

      // do not show fall_end alert toast messages
      if (component_id !== 'fall_end') {
        toast(
          ({ closeToast }) => (
            <NotificationToast
              type={snsTopic}
              title={alertTypeRest.name}
              createdAt={updatedAlertData.device_event_time}
              message={getMessageByComponentId(component_id, updatedAlertData, timezone)}
              button={alertOptions.button}
              showDismiss={alertOptions.showDismiss}
              closeToast={closeToast}
              onShowRoom={() => showFallRoom(updatedAlertData.zone_id, updatedAlertData.room)}
              onDismiss={() => dismissAlert(alertId)}
            />
          ),
          {
            ...alertOptions,
            onClose: () => alertOptions.dismissAlertOnClose && dismissAlert(alertId),
          },
        );
      }
    }
  };

  return (
    initialized &&
    subscriptions.map((subscription, index) => (
      <Subscription
        key={index}
        subscription={ADD_ALERT_SUBSCRIPTION}
        variables={{ alertTypeId: subscription.alert_type.id, orgId: organizationId }}
        onSubscriptionData={handleSubscriptionData}
      />
    ))
  );
};

export default withApollo(withAppContext(withUserData(Notification)));
