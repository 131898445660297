import React from 'react';
import { Redirect } from 'react-router-dom';
import { Create, SimpleForm, TextInput, BooleanInput } from 'react-admin';

import Can from '../../components/Can/Can';
import CreateToolbar from '../common/CreateToolbar/CreateToolbar';
import FormHeader from '../common/FormHeader/FormHeader';
import LongTextInput from '../common/LongTextInput/LongTextInput';
import { validate } from '../../utils/admin';
import { formSchema, redirectUrl } from './index.js';

export const AssetTypeCreate = props => (
  <Can perform="assetTypes:create" no={<Redirect to={redirectUrl} />}>
    <Create {...props}>
      <SimpleForm toolbar={<CreateToolbar />} validate={values => validate(formSchema, values)}>
        <FormHeader text="Asset Type - Create" />
        <TextInput source="name" required />
        <TextInput source="plural" required />
        <LongTextInput source="description" showCounter />
        <BooleanInput source="trigger_entry_alerts" label="Trigger room entry alerts" />
      </SimpleForm>
    </Create>
  </Can>
);
