import gql from 'graphql-tag';

export default function assetQueries(raFetchType, params) {
  switch (raFetchType) {
    case 'CREATE':
      return {
        query: gql`
          mutation addAsset($name: String!, $asset_type_id: ID!, $description: String) {
            addAsset(name: $name, asset_type_id: $asset_type_id, description: $description) {
              id
            }
          }
        `,
        variables: {
          ...params.data,
          asset_type_id: params.data.asset_type.id,
        },
        parseResponse: response => ({ data: response.data.addAsset }),
      };
    case 'UPDATE':
      return {
        query: gql`
          mutation updateAsset($id: ID!, $name: String, $asset_type_id: ID, $description: String) {
            updateAsset(
              id: $id
              name: $name
              asset_type_id: $asset_type_id
              description: $description
            ) {
              id
            }
          }
        `,
        variables: {
          ...params.data,
          asset_type_id: params.data.asset_type.id,
        },
        parseResponse: response => ({ data: response.data.updateAsset }),
      };
    case 'GET_ONE':
      return {
        query: gql`
          query getAssets($id: ID) {
            assets(id: $id) {
              total
              data {
                ... on Asset {
                  id
                  name
                  description
                  asset_type {
                    id
                    name
                  }
                  tag {
                    id
                    serial_number
                  }
                }
              }
            }
          }
        `,
        variables: params,
        parseResponse: response => ({
          data:
            response.data &&
            response.data.assets &&
            response.data.assets.data &&
            response.data.assets.data.length &&
            response.data.assets.data[0],
        }),
      };
    case 'GET_MANY':
    case 'GET_LIST':
      return {
        query: gql`
          query getAssets($sort: Sort, $pagination: Pagination, $filter: AssetsFilter) {
            assets(sort: $sort, pagination: $pagination, filter: $filter) {
              total
              data {
                ... on Asset {
                  id
                  name
                  description
                  asset_type {
                    id
                    name
                  }
                  tag {
                    id
                    serial_number
                  }
                }
              }
            }
          }
        `,
        variables: params,
        parseResponse: response => ({
          data: response.data.assets.data,
          total: response.data.assets.total,
        }),
      };
    case 'DELETE':
      return {
        query: gql`
          mutation deleteAssets($id: [ID!]!) {
            deleteAssets(id: $id) {
              id
              name
            }
          }
        `,
        variables: params,
        parseResponse: response => ({ data: response.data.deleteAssets[0] }),
      };
    case 'DELETE_MANY':
      const variables = {
        id: params.ids,
      };

      return {
        query: gql`
          mutation deleteAssets($id: [ID]!) {
            deleteAssets(id: $id) {
              id
            }
          }
        `,
        variables,
        parseResponse: response => ({ data: response.data.deleteAssets }),
      };
    default:
      throw new Error('Invalid fetch type');
  }
}
