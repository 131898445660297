import gql from 'graphql-tag';

export default function alertTypeToOrganizationQueries(raFetchType, params) {
  switch (raFetchType) {
    case 'CREATE':
      return {
        query: gql`
          mutation addAlertTypeToOrganization($alert_type_id: ID!, $organization_id: ID!) {
            addAlertTypeToOrganization(
              alert_type_id: $alert_type_id
              organization_id: $organization_id
            ) {
              id
            }
          }
        `,
        variables: {
          ...params.data,
          alert_type_id: params.data.alert_type.id,
          organization_id: params.data.organization.id,
        },
        parseResponse: response => ({ data: response.data.addAlertTypeToOrganization }),
      };
    case 'DELETE':
      return {
        query: gql`
          mutation deleteAlertTypesToOrganizations($id: [ID!]!) {
            deleteAlertTypesToOrganizations(id: $id) {
              id
            }
          }
        `,
        variables: params,
        parseResponse: response => ({ data: response.data.deleteAlertTypesToOrganizations[0] }),
      };
    default:
      throw new Error('Invalid fetch type');
  }
}
