import React, { Component } from 'react';
import gql from 'graphql-tag';
import { withApollo } from 'react-apollo';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Form, Col, Row, Button } from 'reactstrap';
import moment from 'moment-timezone';
import Chart from 'chart.js';

import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import { toast } from '../../../../components/Toast/Toast';
import { parseRoomOccupancyChartData } from '../../../../utils/chart';
import { REQUIRED_FIELD, DATE } from '../../../../utils/validations';
import DatePicker from '../../../../components/DatePicker/DatePicker';
import Loader from '../../../../components/Loader/Loader';
import FormFeedback from '../../../../components/FormFeedback/FormFeedback';
import Autocomplete from '../../../../components/Autocomplete/Autocomplete';
import { getAWSDateTime } from '../../../../utils/js';

const formSchema = yup.object().shape({
  roomId: yup
    .number()
    .test('is-valid', 'Invalid room', value => value && value > 0)
    .typeError(REQUIRED_FIELD.message),
  startTime: yup
    .date()
    .required(REQUIRED_FIELD.message)
    .typeError(DATE.message),
  endTime: yup
    .date()
    .required(REQUIRED_FIELD.message)
    .typeError(DATE.message)
    .test('end-date-check', 'End date must be after start date', function(value) {
      return value > this.parent.startTime;
    }),
});

const GET_INITIAL_ROOM_OCCUPANCY_DATA = gql`
  query getInitialRoomOccupancyData {
    rooms {
      total
      data {
        ... on Room {
          id
          name
        }
      }
    }
  }
`;

const GET_ROOM_OCCUPANCY_PERCENTAGE = gql`
  query room_occupancy_percentage(
    $room_id: [ID]!
    $start_time: AWSDateTime!
    $end_time: AWSDateTime!
  ) {
    room_occupancy_percentage(room_id: $room_id, start_time: $start_time, end_time: $end_time) {
      percentage
      room {
        id
        name
      }
    }
  }
`;

const styles = theme => ({
  formControl: {
    width: '100%',
  },
});

class RoomOccupancy extends Component {
  state = {
    initialized: false,
    loading: false,
    rooms: null,
    selectedStartTime: moment(moment().subtract('day', 7)).startOf('day'),
    selectedEndTime: moment(),
    chart: null,
    chartElement: null,
  };

  componentDidMount() {
    const { client } = this.props;
    const chartElement = document.querySelector('canvas#chart');

    client
      .query({
        query: GET_INITIAL_ROOM_OCCUPANCY_DATA,
        fetchPolicy: 'network-only',
      })
      .then(res => {
        this.setState({
          rooms: res.data.rooms.data,
          initialized: true,
          chartElement,
        });
      })
      .catch(({ message: initUtilizationError }) => {
        toast.error(initUtilizationError || 'Error getting initial room occupancy data');
      });
  }

  handleSubmit = values => {
    const { client } = this.props;
    const { rooms, chartElement, chart } = this.state;

    if (!rooms) {
      toast.error('Please select a room and time period');
      return false;
    }

    const { roomId, startTime, endTime } = values;
    const room = rooms.find(room => room.id === roomId);

    if (!room) {
      toast.error('Could not find room');
      return false;
    }

    this.setState({
      loading: true,
    });

    // use selected start/end dates
    const startTimeQueryVariable = getAWSDateTime(startTime);
    const endTimeQueryVariable = getAWSDateTime(endTime);

    const query = {
      query: GET_ROOM_OCCUPANCY_PERCENTAGE,
      fetchPolicy: 'network-only',
      variables: {
        room_id: [room.id],
        start_time: startTimeQueryVariable,
        end_time: endTimeQueryVariable,
      },
    };

    client
      .query(query)
      .then(res => {
        const parsedData =
          res.data &&
          res.data.room_occupancy_percentage &&
          res.data.room_occupancy_percentage.length &&
          parseRoomOccupancyChartData(res.data.room_occupancy_percentage[0], 'pie');

        if (parsedData) {
          chart && chart.destroy();
          this.setState({
            loading: false,
            selectedStartTime: startTimeQueryVariable,
            selectedEndTime: endTimeQueryVariable,
            chart: new Chart(chartElement, {
              type: 'pie',
              data: parsedData,
              options: {
                responsive: true,
                maintainAspectRatio: false,
                tooltips: {
                  callbacks: {
                    label: (item, data) => {
                      const { index } = item;
                      const { datasets } = data;

                      if (datasets && datasets.length) {
                        const [{ data }] = datasets;

                        return `${data[index].toFixed(2)}%`;
                      }
                    },
                  },
                },
                legend: {
                  onClick: e => e.stopPropagation(),
                },
              },
            }),
          });
        } else {
          toast.info('No room occupancy data found');
          this.setState({
            loading: false,
          });
        }
      })
      .catch(({ message: queryError }) => {
        toast.error(queryError || 'Error getting room occupancy data');
        this.setState({
          loading: false,
        });
      });
  };

  render() {
    const {
      props: { classes },
      state: { initialized, selectedStartTime, selectedEndTime, rooms, loading },
    } = this;

    return (
      <div className="report-page--content__container">
        {loading && <Loader fullscreen transparent />}
        <h1>Room Occupancy</h1>
        {initialized && (
          <div className="room-occupancy-form mb-3">
            <Formik
              onSubmit={this.handleSubmit}
              validationSchema={formSchema}
              initialValues={{
                roomId: null,
                startTime: selectedStartTime,
                endTime: selectedEndTime,
              }}
            >
              {({ handleSubmit, handleChange, setFieldValue, values, errors }) => (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col className="mb-3 align-self-end" lg={6}>
                      <FormControl className={classes.formControl}>
                        <Autocomplete
                          choices={rooms}
                          renderLabel={option => option.name}
                          onChange={value => setFieldValue('roomId', value)}
                          fluid
                          required
                          meta={{ touched: true, error: errors.roomId }}
                        />
                      </FormControl>
                    </Col>
                  </Row>
                  <Row className="date-range-wrapper">
                    <Col className="mb-3">
                      <InputLabel shrink htmlFor="startTime">
                        Start Time
                      </InputLabel>
                      <div className="flex-box start-date-wrapper">
                        <DatePicker
                          defaultValue={new Date(values.startTime)}
                          name="startTime"
                          onChange={value => setFieldValue('startTime', value)}
                          onKeyUp={({ target: { value } }) => setFieldValue('startTime', value)}
                          max={new Date()}
                          min={
                            new Date(
                              moment()
                                .subtract('months', 6)
                                .format(),
                            )
                          }
                        />
                      </div>
                      <FormFeedback visible={errors.startTime} message={errors.startTime} />
                    </Col>
                    <Col className="mb-3">
                      <InputLabel shrink htmlFor="endTime">
                        End Time
                      </InputLabel>
                      <div className="flex-box start-date-wrapper">
                        <DatePicker
                          defaultValue={new Date(values.endTime)}
                          name="endTime"
                          onChange={value => setFieldValue('endTime', value)}
                          max={new Date()}
                          min={
                            new Date(
                              moment()
                                .subtract('months', 6)
                                .format(),
                            )
                          }
                        />
                      </div>
                      <FormFeedback visible={errors.endTime} message={errors.endTime} />
                    </Col>
                  </Row>
                  <Button type="submit" color="primary">
                    Submit
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        )}
        <div className="chart-wrapper">
          <div className="chart-container">
            <canvas id="chart" width="400" height="400" />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(withApollo(RoomOccupancy));
