import React, { Component } from 'react';
import { Switch, Redirect, Link } from 'react-router-dom';
import { Query } from 'react-apollo';
import { Alert } from 'reactstrap';
import gql from 'graphql-tag';
import cx from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListIcon from '@material-ui/icons/ViewList';

import Can from '../../components/Can/Can';
import Loader from '../../components/Loader/Loader';
import Sidebar from '../../components/Sidebar/Sidebar';
import Landing from './Landing';
import PropsRoute from '../../components/PropsRoute/PropsRoute';
import AssetUtilization from './reports/AssetUtilization/AssetUtilization';
import FallHistory from './reports/FallHistory/FallHistory';
import Heatmap from './reports/Heatmap/Heatmap';
import LastSeen from './reports/LastSeen/LastSeen';
import Playback from './reports/Playback/Playback';
import RoomOccupancy from './reports/RoomOccupancy/RoomOccupancy';
import TimeInBed from './reports/TimeInBed/TimeInBed';
import {
  LAST_SEEN_REPORT_ROUTE,
  PLAYBACK_REPORT_ROUTE,
  ASSET_UTILIZATION_REPORT_ROUTE,
  FALL_HISTORY_REPORT_ROUTE,
  HEATMAP_REPORT_ROUTE,
  ROOM_OCCUPANCY_REPORT_ROUTE,
  TIME_IN_BED_REPORT_ROUTE,
} from '../../utils/routes';

const GET_REPORT_TYPES_LIST = gql`
  query getReportTypesList {
    report_types {
      data {
        ... on ReportType {
          id
          component_id
          display_name
        }
      }
    }
  }
`;

const styles = theme => ({
  listItem: {
    '&.selected': {
      backgroundColor: 'rgba(0, 0, 0, 0.15)',
    },
  },
});

class Report extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div className="report-page">
        <Can perform="report-page:visit" no={<Redirect to="/locate" />}>
          <Sidebar noPadding collapsable>
            <Query query={GET_REPORT_TYPES_LIST} fetchPolicy="network-only">
              {({
                data: reportsListData,
                loading: reportsListLoading,
                error: reportsListError,
              }) => {
                if (reportsListError) {
                  return <div>{reportsListError.message}</div>;
                }

                if (reportsListLoading) {
                  return <Loader />;
                }

                const {
                  report_types: { data: reportMappings },
                } = reportsListData;

                return (
                  <List>
                    {reportMappings && reportMappings.length > 0 ? (
                      reportMappings.map(item => (
                        <Link to={`/report/${item.component_id}`} key={item.id}>
                          <ListItem
                            button
                            className={cx(classes.listItem, {
                              selected: window.location.pathname.includes(
                                `/report/${item.component_id}`,
                              ),
                            })}
                          >
                            <ListItemIcon>
                              <ListIcon />
                            </ListItemIcon>
                            <ListItemText>{item.display_name}</ListItemText>
                          </ListItem>
                        </Link>
                      ))
                    ) : (
                      <div className="container">
                        <Alert color="info">No reports found</Alert>
                      </div>
                    )}
                  </List>
                );
              }}
            </Query>
          </Sidebar>

          <div className="report-page--content">
            <Switch>
              <PropsRoute exact path="/report" component={Landing} />
              <PropsRoute path={ASSET_UTILIZATION_REPORT_ROUTE} component={AssetUtilization} />
              <PropsRoute path={FALL_HISTORY_REPORT_ROUTE} component={FallHistory} />
              <PropsRoute path={HEATMAP_REPORT_ROUTE} component={Heatmap} />
              <PropsRoute path={LAST_SEEN_REPORT_ROUTE} component={LastSeen} />
              <PropsRoute path={PLAYBACK_REPORT_ROUTE} component={Playback} />
              <PropsRoute path={ROOM_OCCUPANCY_REPORT_ROUTE} component={RoomOccupancy} />
              <PropsRoute path={TIME_IN_BED_REPORT_ROUTE} component={TimeInBed} />
            </Switch>
          </div>
        </Can>
      </div>
    );
  }
}

export default withStyles(styles)(Report);
