import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { getResources } from 'react-admin';
import { withRouter } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import cx from 'classnames';
import { baseName } from '../../utils/history';
import { MANAGE_ROUTE } from '../../utils/routes';

const MyMenuItemLink = props => {
  const { to, primaryText, onClick, icon, selected } = props;

  return (
    <div className={cx('admin-menu-item', { selected })}>
      <a href={to}>
        <ListItem onClick={onClick} button>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText>{primaryText}</ListItemText>
        </ListItem>
      </a>
    </div>
  );
};

const AdminMenu = ({ resources, onMenuClick, logout }) => (
  <List>
    {resources.map(resource => {
      const {
        name,
        options: { icon, adminEnd, label, show, notAuthorizedPath },
      } = resource;
      const {
        location: { hash },
      } = window;
      let selected;

      if (hash.startsWith(`#/${name}`)) {
        selected = true;
      }

      const MenuItem = props =>
        props.hide ? (
          false
        ) : (
          <MyMenuItemLink
            to={`#/${name}`}
            primaryText={label ? label : `${name.charAt(0).toUpperCase()}${name.slice(1)}`}
            onClick={onMenuClick}
            icon={icon}
            selected={selected}
          />
        );

      if (!show) {
        if (selected) {
          window.location.assign(`${baseName}${MANAGE_ROUTE}#${notAuthorizedPath}`);
        }
        return <MenuItem hide key={name} />;
      }

      return adminEnd ? (
        <Fragment key={name}>
          <MenuItem />
          <hr className="margin-lr" />
        </Fragment>
      ) : (
        <MenuItem key={name} />
      );
    })}
  </List>
);

const mapStateToProps = state => ({
  resources: getResources(state),
});

export default withRouter(connect(mapStateToProps)(AdminMenu));
