import React from 'react';
import { Redirect } from 'react-router-dom';
import { List, Datagrid, SimpleList, Responsive, TextField, EditButton } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

import Can from '../../components/Can/Can';
import PasswordPrompt from '../../components/PasswordPrompt/PasswordPrompt';
import ListActions from '../common/ListActions/ListActions';
import Pagination from '../common/Pagination/Pagination';
import ImpersonateControl from '../../components/ImpersonateControl/ImpersonateControl';
import { redirectUrl } from './index.js';

const styles = {
  tertiaryText: {
    '& span': {
      opacity: 1,
    },
  },
};

export const OrganizationList = withStyles(styles)(({ classes, ...props }) => (
  <Can perform="organizations:list" no={<Redirect to={redirectUrl} />}>
    <PasswordPrompt>
      {({ promptCheck }) => (
        <List
          {...props}
          perPage={15}
          pagination={<Pagination />}
          actions={
            <ListActions
              title="Organizations"
              object="organizations"
              modifyExports={items =>
                items.map(item => ({
                  ...item,
                  parent: item.parent.name,
                }))
              }
            />
          }
          bulkActionButtons={false}
          sort={{ field: 'name', order: 'ASC' }}
        >
          <Responsive
            small={
              <SimpleList
                className={classes.tertiaryText}
                primaryText={record => record.name}
                secondaryText={record => record.parent.name}
                tertiaryText={record => (
                  <div className="d-flex align-items-center">
                    <ImpersonateControl record={record} />
                    <Can perform="organizations:update">
                      <EditButton record={record} basePath="/organizations" />
                    </Can>
                  </div>
                )}
                linkType={false}
              />
            }
            medium={
              <Datagrid>
                <TextField source="name" />
                <TextField source="parent.name" label="Parent" />
                <ImpersonateControl />
                <Can perform="organizations:update">
                  <EditButton basePath="/organizations" />
                </Can>
              </Datagrid>
            }
          />
        </List>
      )}
    </PasswordPrompt>
  </Can>
));
