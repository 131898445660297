import Amplify, { Auth } from 'aws-amplify';
import { onError } from 'apollo-link-error';
import AWSAppSyncClient, { createAppSyncLink } from 'aws-appsync';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { RetryLink } from 'apollo-link-retry';
import { withClientState } from 'apollo-link-state';
import axios from 'axios';
import resolvers from './resolvers';
import listener from './listener';
import log from '../utils/Log';

export const defaults = {
  sidebar: {
    __typename: 'Sidebar',
    open: true,
    view: 'card',
    selectedAsset: null,
    hoverAsset: null,
  },
  locations: [],
};

class Client {
  initialize = async () => {
    const awsExports = await this.getAWSExports();
    try {
      Amplify.configure(awsExports);
    } catch (error) {
      log.error('Failed to configure Amplify.');
    }

    const config = Auth.configure();
    const {
      aws_appsync_graphqlEndpoint,
      aws_appsync_region,
      aws_appsync_authenticationType,
    } = config;

    const authConfig = {
      type: aws_appsync_authenticationType,
      jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
    };

    const onErrorLink = onError(({ graphQLErrors, networkError, operation }) => {
      try {
        if (graphQLErrors)
          graphQLErrors.map(({ message, locations, path }) =>
            log.error(
              `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
                locations,
              )}, Path: ${path}`,
            ),
          );
        if (networkError && operation) {
          console.log(operation);
          console.log(operation.getContext);
          log.error(
            `[Network error]: ${JSON.stringify(networkError)} on operationName: ${
              operation.operationName
            } with variables: ${JSON.stringify(operation.variables)}`,
          );
          // Handle 401 Unauthorized
          try {
            const { statusCode } = JSON.parse(JSON.stringify(networkError));
            if (statusCode === 401) {
              log.pill('Page was reloaded due to a 401.');
              // Unauthorized, refresh page
              window.location.reload();
            }
          } catch (e) {
            log.warn(e);
          }
          // Handle websocket connection errors
          // Note: websocket connections created using the amplify client include x-amz-expires=3600 query string
          // connections close after 1 hour regardless and completely separate from the refresh token from cognito
          // TODO: Research wss reconnection
          try {
            const { errorMessage, uri } = JSON.parse(JSON.stringify(networkError));
            if (errorMessage && uri.includes('wss://')) {
              log.pill('Page was reloaded due to websocket connection errors.');
              // Unauthorized, refresh page
              window.location.reload();
            }
          } catch (e) {
            log.warn(e);
          }
        }
      } catch (e) {
        console.log(e);
      }
    });

    const retryLink = new RetryLink({
      delay: {
        initial: 300,
        max: Infinity,
        jitter: true,
      },
      attempts: {
        max: 2,
        retryIf: (error, _operation) => !!error,
      },
    });

    const cache = new InMemoryCache();
    const stateLink = withClientState({ cache, resolvers, defaults });
    const appSyncLink = createAppSyncLink({
      url: aws_appsync_graphqlEndpoint,
      region: aws_appsync_region,
      auth: authConfig,
    });

    const link = ApolloLink.from([stateLink, onErrorLink, retryLink, appSyncLink]);
    const client = new AWSAppSyncClient({ disableOffline: true }, { link });

    client.onResetStore(stateLink.writeDefaults);

    listener(client);

    return client;
  };

  getAWSExports = async () => {
    const awsexports = await axios.get('/awsmobile.json').catch(error => {
      log.error('Failed to load awsmobile.json file.');
    });
    // Override the sign in and sign out redirects
    const {
      location: { protocol, host },
    } = window;
    awsexports.data.Auth.oauth.redirectSignIn = `${protocol}//${host}`;
    awsexports.data.Auth.oauth.redirectSignOut = `${protocol}//${host}/login`;
    return awsexports.data;
  };
}

export default Client;
