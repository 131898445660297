import React from 'react';
import MediaQuery from 'react-responsive';

// Breakpoint values must match bootstrap settings
// xs: 0,
// sm: 576px,
// md: 768px,
// lg: 992px,
// xl: 1200px

const breakpoints = {
  xs: {
    sortOrder: 0,
    minWidth: null,
    maxWidth: 575,
  },
  sm: {
    sortOrder: 1,
    minWidth: 576,
    maxWidth: 768,
  },
  md: {
    sortOrder: 2,
    minWidth: 768,
    maxWidth: 991,
  },
  lg: {
    sortOrder: 3,
    minWidth: 992,
    maxWidth: 1199,
  },
  xl: {
    sortOrder: 4,
    minWidth: 1200,
    maxWidth: null,
  },
};

const getQuery = (minWidth, maxWidth) => {
  let query;

  if (minWidth && maxWidth) {
    // min and max width defined
    query = `(min-width: ${minWidth}px) and (max-width: ${maxWidth}px)`;
  } else if (minWidth && !maxWidth) {
    // only min width defined
    query = `(min-width: ${minWidth}px)`;
  } else if (!minWidth && maxWidth) {
    // only max width defined
    query = `(max-width: ${maxWidth}px)`;
  }

  return query;
};

const Breakpoint = props => {
  const { size, children } = props;
  const sizeIsArray = Array.isArray(size);
  let query, minWidth, maxWidth;

  if (size && sizeIsArray) {
    const sorted = size.sort((a, b) =>
      breakpoints[a].sortOrder > breakpoints[b].sortOrder ? 1 : -1,
    );

    sorted.forEach((item, index) => {
      const currentBreakpoint = breakpoints[item];

      if (
        currentBreakpoint.minWidth === null ||
        minWidth === undefined ||
        (minWidth !== null && minWidth && currentBreakpoint.minWidth < minWidth)
      ) {
        minWidth = currentBreakpoint.minWidth;
      }

      if (
        currentBreakpoint.maxWidth === null ||
        maxWidth === undefined ||
        (maxWidth !== null && maxWidth && currentBreakpoint.maxWidth > maxWidth)
      ) {
        maxWidth = currentBreakpoint.maxWidth;
      }
    });

    query = getQuery(minWidth, maxWidth);
  } else {
    query = getQuery(breakpoints[size].minWidth, breakpoints[size].maxWidth);
  }

  return (
    <MediaQuery {...props} query={query || '(min-width: 0)'}>
      {children || false}
    </MediaQuery>
  );
};

export default Breakpoint;
