import React, { Component, Fragment } from 'react';
import { Auth } from 'aws-amplify';
import {
  Form,
  FormGroup,
  Label,
  FormFeedback,
  Input,
  Button,
  Row,
  Col,
  Alert,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import buildGraphQLProvider from 'ra-data-graphql';
import { withApollo } from 'react-apollo';
import { UPDATE } from 'react-admin';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import WarningIcon from '@material-ui/icons/Error';
import NumberFormat from 'react-number-format';

import { toast } from '../../components/Toast/Toast';
import PasswordPrompt from '../../components/PasswordPrompt/PasswordPrompt';
import buildQuery from '../../queries/queries';
import Loader from '../../components/Loader/Loader';
import { GET_LOCAL_USER_DATA } from '../../client/queries/app';
import { timezoneOptions } from '../../utils/js';
import { getCurrentTimeFromTimezone } from '../../utils/js';
import PasswordRules from '../../components/PasswordRules/PasswordRules';

import {
  REQUIRED_FIELD,
  PASSWORD_MIN_LENGTH,
  PASSWORD_MATCH,
  NAME_REGEX,
  NAME_MIN_LENGTH,
  NAME_MAX_LENGTH,
  EMAIL,
  PHONE_NUMBER_REGEX,
  TIMEZONE_NAME_REGEX,
} from '../../utils/validations';

const emailVerificationSchema = yup.object().shape({
  code: yup
    .number()
    .required(REQUIRED_FIELD.message)
    .typeError('Invalid code format')
    .integer('Invalid code format'),
});

const infoSchema = yup.object().shape({
  firstName: yup
    .string()
    .required(REQUIRED_FIELD.message)
    .min(NAME_MIN_LENGTH.value, NAME_MIN_LENGTH.message)
    .max(NAME_MAX_LENGTH.value, NAME_MAX_LENGTH.message)
    .matches(NAME_REGEX.value, NAME_REGEX.message),
  lastName: yup
    .string()
    .required(REQUIRED_FIELD.message)
    .min(NAME_MIN_LENGTH.value, NAME_MIN_LENGTH.message)
    .max(NAME_MAX_LENGTH.value, NAME_MAX_LENGTH.message)
    .matches(NAME_REGEX.value, NAME_REGEX.message),
  email: yup
    .string()
    .required(REQUIRED_FIELD.message)
    .email(EMAIL.message),
  phoneNumber: yup
    .string()
    .nullable()
    .matches(PHONE_NUMBER_REGEX.value, PHONE_NUMBER_REGEX.message),
  timezone: yup
    .string()
    .required(REQUIRED_FIELD.message)
    .matches(TIMEZONE_NAME_REGEX.value, TIMEZONE_NAME_REGEX.message),
});

const changePasswordSchema = yup.object().shape({
  oldPassword: yup.string().required(REQUIRED_FIELD.message),
  newPassword: yup
    .string()
    .required(REQUIRED_FIELD.message)
    .min(PASSWORD_MIN_LENGTH.value, PASSWORD_MIN_LENGTH.message),
  confirmPassword: yup
    .string()
    .required(REQUIRED_FIELD.message)
    .min(PASSWORD_MIN_LENGTH.value, PASSWORD_MIN_LENGTH.message)
    .oneOf([yup.ref('newPassword')], PASSWORD_MATCH.message),
});

const deactivateAccountSchema = yup.object().shape({
  confirmDeactivate: yup.boolean().oneOf([true], 'You must confirm to deactivate'),
});

const GET_REMOTE_USER_DATA = gql`
  query getSiteUsers($email: AWSEmail) {
    site_users(email: $email) {
      data {
        ... on SiteUser {
          id
          first_name
          last_name
          email
          phone_number
          timezone
        }
      }
    }
  }
`;

const DEACTIVATE_ACCOUNT = gql`
  mutation deactivateSelf {
    deactivateSelf {
      id
    }
  }
`;

class Account extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      dataProvider: false,
      user: null,
      confirmDeactivate: false,
    };

    const { client } = props;

    buildGraphQLProvider({ client, buildQuery })
      .then(result => {
        this.setState({
          dataProvider: result,
          loading: false,
        });
      })
      .catch(error => {
        this.setState({
          dataProvider: false,
          loading: false,
        });
      });
  }

  resendVerificationCode = () => {
    Auth.verifyCurrentUserAttribute('email')
      .then(data => {
        toast.success('A new code has been sent');
      })
      .catch(err => {
        toast.error('Failed to resend code');
      });
  };

  onEmailVerificationSubmit = event => {
    const { code } = event;

    const done = () => {
      this.setState({
        loading: false,
      });
    };

    this.setState({
      loading: true,
    });

    Auth.verifyCurrentUserAttributeSubmit('email', code.toString())
      .then(data => {
        if (data === 'SUCCESS') {
          toast.success('Email verification successful');
          Auth.currentAuthenticatedUser().then(user => {
            user.refreshSession(user.signInUserSession.refreshToken, (err, session) => {
              // TODO: see if this can be optimized
              window.location.reload();
              done();
            });
          });
        }

        done();
      })
      .catch(err => {
        toast.error('Failed to verify email. Please check the code and try again.');
        done();
      });
  };

  onInfoSubmit = event => {
    const { user } = this.state;

    if (!user) {
      return false;
    }

    const done = () => {
      this.setState({
        loading: false,
      });
    };

    this.setState({
      loading: true,
    });

    const { dataProvider } = this.state;
    const { firstName, lastName, email, phoneNumber, timezone } = event;
    const data = {
      email_id: user.email,
      first_name: firstName,
      last_name: lastName,
      email,
      phone_number: phoneNumber,
      timezone,
    };

    dataProvider(UPDATE, 'users', { data })
      .then(res => {
        if (user.email !== email) {
          Auth.currentAuthenticatedUser().then(user => {
            user.refreshSession(user.signInUserSession.refreshToken, (err, session) => {
              // NOTE: add a query string to show a success message?
              window.location.reload();
            });
          });
        } else {
          toast.success('Profile update successful');
          done();
        }
      })
      .catch(err => {
        toast.error(err && err.message ? err.message : `Failed to update settings: ${err}`);
        done();
      });
  };

  onChangePasswordSubmit = event => {
    this.setState({
      loading: true,
    });

    const { oldPassword, newPassword } = event;

    Auth.currentAuthenticatedUser()
      .then(user => Auth.changePassword(user, oldPassword, newPassword))
      .then(data => {
        if (data === 'SUCCESS') {
          toast.success('Password change successful');
        }

        this.setState({
          loading: false,
        });
      })
      .catch(err => {
        toast.error(err && err.message ? err.message : 'Failed to change password');

        this.setState({
          loading: false,
        });
      });
  };

  startConfirmDeactivate = () => {
    this.setState({
      confirmDeactivate: true,
    });
  };

  stopConfirmDeactivate = () => {
    this.setState({
      confirmDeactivate: false,
    });
  };

  onDeactivateSubmit = event => {
    const { client } = this.props;

    client
      .mutate({ mutation: DEACTIVATE_ACCOUNT })
      .then(res => {
        Auth.signOut();
      })
      .catch(err => {
        toast.error(err && (err.message || err));
        this.setState({
          confirmDeactivate: false,
        });
      });
  };

  render() {
    const { loading, user, confirmDeactivate } = this.state;
    const ErrorComponent = () => (
      <Alert color="info">
        There was a problem loading your profile. Refresh the page to try again.
      </Alert>
    );
    const loader = <Loader fullscreen transparent />;

    return loading ? (
      loader
    ) : (
      <div className="account-page">
        <Query
          query={GET_LOCAL_USER_DATA}
          notifyOnNetworkStatusChange={true}
          onCompleted={data => !user && this.setState({ user: data.userData })} //TODO: Consider moving userData to context api, which should serve as the single source of truth instead of componenet-based state
        >
          {({ data: localUserData, loading, error }) => {
            if (loading) {
              return loader;
            }

            if (error) {
              return <ErrorComponent />;
            }

            try {
              const {
                userData: { email, emailVerified, impersonating },
              } = localUserData;

              return (
                user &&
                (impersonating ? (
                  <div className="settings-page--content__alert-container">
                    <Alert color="info">Please return to your account to update your profile</Alert>
                  </div>
                ) : (
                  <Query
                    query={GET_REMOTE_USER_DATA}
                    variables={{ email }}
                    fetchPolicy="network-only"
                  >
                    {({ data: remoteUserData, loading, error }) => {
                      if (loading) {
                        return loader;
                      }

                      if (error) {
                        return <ErrorComponent />;
                      }

                      try {
                        const {
                          site_users: { data: siteUsers },
                        } = remoteUserData;

                        if (!siteUsers || !siteUsers[0]) {
                          return (
                            <div className="settings-page__alert-container">
                              <Alert color="info">There was a problem loading your profile</Alert>
                            </div>
                          );
                        }

                        const {
                          first_name: firstName,
                          last_name: lastName,
                          email,
                          phone_number: phoneNumber,
                          timezone,
                        } = remoteUserData.site_users.data[0];

                        return (
                          <div>
                            {!emailVerified && (
                              <Fragment>
                                <Alert variant="danger">
                                  Your email address is not verified. We've sent a verification code
                                  to {email}. Please enter it below or{' '}
                                  <Button
                                    className="link"
                                    onClick={() => this.resendVerificationCode()}
                                  >
                                    click here to request a new one
                                  </Button>
                                  .
                                </Alert>
                                <h2 className="m-b-30">Email Verification</h2>
                                <Formik
                                  onSubmit={this.onEmailVerificationSubmit}
                                  validationSchema={emailVerificationSchema}
                                  initialValues={{
                                    code: '',
                                  }}
                                >
                                  {({
                                    handleSubmit,
                                    handleChange,
                                    values,
                                    isValid,
                                    touched,
                                    errors,
                                  }) => (
                                    <Form
                                      noValidate
                                      onSubmit={handleSubmit}
                                      className="email-verification-form"
                                    >
                                      <Row>
                                        <Col>Verification Code</Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4">
                                          <FormGroup>
                                            <Label hidden>Verification Code</Label>
                                            <Input
                                              required
                                              type="number"
                                              name="code"
                                              placeholder="6 digit code"
                                              value={values.code}
                                              onChange={handleChange}
                                              valid={touched.code && !errors.code}
                                              invalid={touched.code && !!errors.code}
                                            />
                                            <FormFeedback type="invalid">
                                              {errors.code}
                                            </FormFeedback>
                                          </FormGroup>
                                        </Col>
                                        <Col>
                                          <Button type="submit" disabled={loading} color="primary">
                                            {loading ? 'Submitting...' : 'Submit'}
                                          </Button>
                                        </Col>
                                      </Row>
                                    </Form>
                                  )}
                                </Formik>
                              </Fragment>
                            )}
                            <Row>
                              <Col>
                                <h2 className="m-b-30">Personal Info</h2>
                                <PasswordPrompt>
                                  {({ promptCheck }) => (
                                    <Formik
                                      onSubmit={values =>
                                        promptCheck(() => this.onInfoSubmit(values))
                                      }
                                      validationSchema={infoSchema}
                                      initialValues={{
                                        firstName,
                                        lastName,
                                        email,
                                        phoneNumber,
                                        timezone,
                                      }}
                                    >
                                      {({
                                        handleSubmit,
                                        handleChange,
                                        values,
                                        isValid,
                                        touched,
                                        errors,
                                      }) => (
                                        <Form noValidate onSubmit={handleSubmit}>
                                          <Row>
                                            <FormGroup md={8}>
                                              <Label for="firstName">First Name *</Label>
                                              <Input
                                                required
                                                type="text"
                                                name="firstName"
                                                id="firstName"
                                                placeholder="First name"
                                                value={values.firstName}
                                                onChange={handleChange}
                                                valid={touched.firstName && !errors.firstName}
                                                invalid={touched.firstName && !!errors.firstName}
                                              />
                                              <FormFeedback type="invalid">
                                                {errors.firstName}
                                              </FormFeedback>
                                            </FormGroup>
                                          </Row>

                                          <Row>
                                            <FormGroup md={8}>
                                              <Label for="lastName">Last Name *</Label>
                                              <Input
                                                required
                                                type="text"
                                                name="lastName"
                                                id="lastName"
                                                placeholder="Last name"
                                                value={values.lastName}
                                                onChange={handleChange}
                                                valid={touched.lastName && !errors.lastName}
                                                invalid={touched.lastName && !!errors.lastName}
                                              />
                                              <FormFeedback type="invalid">
                                                {errors.lastName}
                                              </FormFeedback>
                                            </FormGroup>
                                          </Row>

                                          <Row>
                                            <FormGroup md={8}>
                                              <Label for="email">Email *</Label>
                                              <Input
                                                required
                                                type="email"
                                                name="email"
                                                id="email"
                                                placeholder="Email"
                                                value={values.email}
                                                onChange={handleChange}
                                                valid={touched.email && !errors.email}
                                                invalid={touched.email && !!errors.email}
                                              />
                                              <FormFeedback type="invalid">
                                                {errors.email}
                                              </FormFeedback>
                                            </FormGroup>
                                          </Row>

                                          <Row>
                                            <FormGroup md={8}>
                                              <Label for="email">Phone Number</Label>
                                              <NumberFormat
                                                customInput={Input}
                                                format="###-###-####"
                                                mask="_"
                                                type="tel"
                                                name="phoneNumber"
                                                id="phoneNumber"
                                                placeholder="Phone"
                                                value={values.phoneNumber}
                                                onChange={handleChange}
                                                valid={touched.phoneNumber && !errors.phoneNumber}
                                                invalid={
                                                  touched.phoneNumber && !!errors.phoneNumber
                                                }
                                              />
                                              <FormFeedback type="invalid">
                                                {errors.phoneNumber}
                                              </FormFeedback>
                                            </FormGroup>
                                          </Row>

                                          <Row>
                                            <FormGroup md={8}>
                                              <Label for="timezone">Timezone</Label>
                                              <Input
                                                required
                                                type="select"
                                                name="timezone"
                                                id="timezone"
                                                value={values.timezone}
                                                onChange={handleChange}
                                                valid={touched.timezone && !errors.timezone}
                                                invalid={touched.timezone && !!errors.timezone}
                                              >
                                                {timezoneOptions.map(timezone => (
                                                  <option key={timezone.id} value={timezone.id}>
                                                    {timezone.name} (Currently{' '}
                                                    {getCurrentTimeFromTimezone(timezone.id)})
                                                  </option>
                                                ))}
                                              </Input>
                                            </FormGroup>
                                          </Row>

                                          <Row>
                                            <Button
                                              type="submit"
                                              disabled={loading}
                                              color="primary"
                                            >
                                              {loading ? 'Saving...' : 'Save changes'}
                                            </Button>
                                          </Row>
                                        </Form>
                                      )}
                                    </Formik>
                                  )}
                                </PasswordPrompt>
                              </Col>
                              <Col>
                                <h2 className="m-b-30">Change Password</h2>
                                <Formik
                                  onSubmit={this.onChangePasswordSubmit}
                                  validationSchema={changePasswordSchema}
                                  initialValues={{
                                    oldPassword: '',
                                    newPassword: '',
                                    confirmPassword: '',
                                  }}
                                >
                                  {({
                                    handleSubmit,
                                    handleChange,
                                    values,
                                    isValid,
                                    touched,
                                    errors,
                                  }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                      <PasswordRules />
                                      <Row>
                                        <FormGroup md={8}>
                                          <Label for="oldPassword">Current Password *</Label>
                                          <Input
                                            required
                                            type="password"
                                            name="oldPassword"
                                            id="oldPassword"
                                            placeholder="Current password"
                                            value={values.oldPassword}
                                            onChange={handleChange}
                                            valid={touched.oldPassword && !errors.oldPassword}
                                            invalid={touched.oldPassword && !!errors.oldPassword}
                                          />
                                          <FormFeedback type="invalid">
                                            {errors.oldPassword}
                                          </FormFeedback>
                                        </FormGroup>
                                      </Row>
                                      <Row>
                                        <FormGroup md={8}>
                                          <Label for="newPassword">New Password *</Label>
                                          <Input
                                            required
                                            type="password"
                                            name="newPassword"
                                            id="newPassword"
                                            placeholder="New password"
                                            value={values.newPassword}
                                            onChange={handleChange}
                                            valid={touched.newPassword && !errors.newPassword}
                                            invalid={touched.newPassword && !!errors.newPassword}
                                          />
                                          <FormFeedback type="invalid">
                                            {errors.newPassword}
                                          </FormFeedback>
                                        </FormGroup>
                                      </Row>
                                      <Row>
                                        <FormGroup md={8}>
                                          <Label for="confirmPassword">
                                            Confirm New Password *
                                          </Label>
                                          <Input
                                            required
                                            type="password"
                                            name="confirmPassword"
                                            id="confirmPassword"
                                            placeholder="Confirm new password"
                                            value={values.confirmPassword}
                                            onChange={handleChange}
                                            valid={
                                              touched.confirmPassword && !errors.confirmPassword
                                            }
                                            invalid={
                                              touched.confirmPassword && !!errors.confirmPassword
                                            }
                                          />
                                          <FormFeedback type="invalid">
                                            {errors.confirmPassword}
                                          </FormFeedback>
                                        </FormGroup>
                                      </Row>
                                      <Row>
                                        <Button type="submit" disabled={loading} color="primary">
                                          {loading ? 'Saving...' : 'Save changes'}
                                        </Button>
                                      </Row>
                                    </Form>
                                  )}
                                </Formik>
                              </Col>
                            </Row>
                            {confirmDeactivate && (
                              <PasswordPrompt forceCurrent>
                                {({ promptCheck }) => (
                                  <Modal
                                    isOpen
                                    toggle={this.stopConfirmDeactivate}
                                    centered
                                    size="md"
                                    className="confirm-deactivate-account-modal"
                                  >
                                    <ModalHeader>Deactivate Account</ModalHeader>
                                    <ModalBody>
                                      <Alert color="danger">
                                        <WarningIcon />
                                        Are you sure you want to deactivate this account?
                                      </Alert>
                                      <Formik
                                        onSubmit={values =>
                                          promptCheck(() => this.onDeactivateSubmit())
                                        }
                                        validationSchema={deactivateAccountSchema}
                                        initialValues={{
                                          confirmDeactivate: false,
                                        }}
                                      >
                                        {({
                                          handleSubmit,
                                          handleChange,
                                          values,
                                          isValid,
                                          touched,
                                          errors,
                                        }) => (
                                          <Form noValidate onSubmit={handleSubmit}>
                                            <FormGroup check>
                                              <Input
                                                required
                                                type="checkbox"
                                                name="confirmDeactivate"
                                                id="confirmDeactivate"
                                                value={values.confirmDeactivate}
                                                onChange={handleChange}
                                                valid={
                                                  touched.confirmDeactivate &&
                                                  !errors.confirmDeactivate
                                                }
                                                invalid={
                                                  touched.confirmDeactivate &&
                                                  !!errors.confirmDeactivate
                                                }
                                              />
                                              <Label check for="confirmDeactivate">
                                                I'm sure *
                                              </Label>
                                              <FormFeedback type="invalid">
                                                {errors.confirmDeactivate}
                                              </FormFeedback>
                                            </FormGroup>
                                            <div className="modal-actions">
                                              <FormGroup>
                                                <Button
                                                  color="secondary"
                                                  onClick={this.stopConfirmDeactivate}
                                                  className="no-button"
                                                >
                                                  No
                                                </Button>
                                                <Button
                                                  color="primary"
                                                  type="submit"
                                                  className="yes-button"
                                                >
                                                  Yes
                                                </Button>
                                              </FormGroup>
                                            </div>
                                          </Form>
                                        )}
                                      </Formik>
                                    </ModalBody>
                                  </Modal>
                                )}
                              </PasswordPrompt>
                            )}
                            <hr />
                            <Row className="container">
                              <Col>
                                <p>
                                  Once you delete your account, only your account administrator can
                                  reinstate you
                                </p>
                                {/* <h2 className="m-b-30">Danger Zone</h2> */}
                                <Button color="secondary" onClick={this.startConfirmDeactivate}>
                                  Deactivate account
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        );
                      } catch (e) {}
                    }}
                  </Query>
                ))
              );
            } catch (e) {}
          }}
        </Query>
      </div>
    );
  }
}

export default withApollo(Account);
