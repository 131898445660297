import React, { Component } from 'react';
import gql from 'graphql-tag';
import { withApollo } from 'react-apollo';
import { Formik } from 'formik';
import * as yup from 'yup';
import { ButtonToolbar, ButtonGroup, Form, Col, Row, Button } from 'reactstrap';
import moment from 'moment-timezone';
import Chart from 'chart.js';

import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

import { toast } from '../../../../components/Toast/Toast';
import { PIE_CHART_RANGE_OPTIONS, parseUtilizationChartData } from '../../../../utils/chart';
import { REQUIRED_FIELD, DATE } from '../../../../utils/validations';
import DatePicker from '../../../../components/DatePicker/DatePicker';
import Loader from '../../../../components/Loader/Loader';
import FormFeedback from '../../../../components/FormFeedback/FormFeedback';
import { getAWSDate } from '../../../../utils/js';

const formSchema = yup.object().shape({
  startDate: yup
    .date()
    .required(REQUIRED_FIELD.message)
    .typeError(DATE.message),
  endDate: yup
    .date()
    .required(REQUIRED_FIELD.message)
    .typeError(DATE.message)
    .test('end-date-check', 'End date must be after start date', function(value) {
      return value > this.parent.startDate;
    }),
});

const GET_INITIAL_UTILIZATION_DATA = gql`
  query getInitialUtilizationData {
    asset_types {
      total
      data {
        ... on AssetType {
          id
          name
        }
      }
    }
  }
`;

const GET_DWELL_TIME_BY_ROOM_TYPE = gql`
  query dwell_time_by_room_type(
    $asset_type_id: ID
    $start_date: AWSDate!
    $end_date: AWSDate!
    $period: String
  ) {
    dwell_time_by_room_type(
      asset_type_id: $asset_type_id
      start_date: $start_date
      end_date: $end_date
      period: $period
    ) {
      dwell_time_sum
      room_type {
        name
      }
      process_date
    }
  }
`;

const defaultChartOptions = (type = 'pie') =>
  Object.assign(
    {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        callbacks: {
          label: (item, data) => {
            const { index, value } = item;
            const { datasets } = data;

            if (datasets && datasets.length) {
              const [{ _meta }] = datasets;
              const meta =
                _meta &&
                (_meta[0] ||
                  (Object.prototype.hasOwnProperty.call(_meta, Object.keys(_meta)[0]) &&
                    _meta[Object.keys(_meta)[0]]));
              const { type } = meta;

              if (type === 'line') {
                return `${value}%`;
              }

              if (type === 'pie') {
                const [{ data }] = datasets;
                const total = data.reduce((acc, val) => acc + val, 0);

                return `${Math.round((data[index] / total) * 100)}%`;
              }

              return item.label;
            }
          },
        },
      },
    },
    type === 'line' && {
      scales: {
        yAxes: [
          {
            display: true,
            ticks: {
              suggestedMin: 0,
              suggestedMax: 100,
            },
          },
        ],
      },
    },
  );

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    width: '100%',
  },
});

class AssetUtilization extends Component {
  state = {
    initialized: false,
    loading: false,
    assetTypes: null,
    selectedAssetType: null,
    selectedStartDate: moment(moment().subtract('day', 7)).startOf('day'),
    selectedEndDate: moment().startOf('day'),
    selectedStartDateWithTimezone: null,
    selectedRangeOption: '7d',
    selectedChartType: 'pie',
    chart: null,
    showChart: false,
    chartElement: null,
    chartData: null,
    showLineChartOption: false,
  };

  componentDidMount() {
    const { client } = this.props;
    const chartElement = document.querySelector('canvas#chart');

    client
      .query({
        query: GET_INITIAL_UTILIZATION_DATA,
        fetchPolicy: 'network-only',
      })
      .then(res => {
        this.setState({
          assetTypes: res.data.asset_types.data,
          initialized: true,
          chartElement,
        });
      })
      .catch(({ message: initUtilizationError }) => {
        toast.error(initUtilizationError || 'Error getting initial utilization data');
      });
  }

  handleSubmit = values => {
    const { client } = this.props;
    const { assetTypes, chartElement, selectedChartType, selectedRangeOption, chart } = this.state;

    if (!assetTypes) {
      toast.error('Please select an asset type and time period');
      return false;
    }

    const { assetTypeName, startDate, endDate } = values;
    const assetType = assetTypes.find(asset_type => asset_type.name === assetTypeName);

    if (!assetType) {
      toast.error('Could not find asset type');
      return false;
    }

    this.setState({
      loading: true,
    });

    let startDateQueryVariable, endDateQueryVariable;
    if (selectedRangeOption === 'custom') {
      // use selected start/end dates
      startDateQueryVariable = getAWSDate(startDate);
      endDateQueryVariable = getAWSDate(endDate);
    } else {
      // use value from selected range option with today as end date
      startDateQueryVariable = getAWSDate(
        moment().subtract(
          'day',
          PIE_CHART_RANGE_OPTIONS.find(i => i.id === selectedRangeOption).value,
        ),
      );
      endDateQueryVariable = getAWSDate();
    }

    const showLineChartOption =
      moment(startDateQueryVariable).diff(moment(endDateQueryVariable), 'days') !== -1;
    const chartTypeQueryVariable = showLineChartOption ? selectedChartType : 'pie';
    const query = {
      query: GET_DWELL_TIME_BY_ROOM_TYPE,
      variables: Object.assign(
        {
          asset_type_id: assetType.id,
          start_date: startDateQueryVariable,
          end_date: endDateQueryVariable,
        },
        chartTypeQueryVariable === 'line' && { period: 'daily' },
      ),
    };

    client
      .query(query)
      .then(res => {
        const parsedData =
          res.data &&
          res.data.dwell_time_by_room_type &&
          res.data.dwell_time_by_room_type.length &&
          parseUtilizationChartData(res.data.dwell_time_by_room_type, chartTypeQueryVariable);

        if (parsedData) {
          chart && chart.destroy();
          this.setState({
            loading: false,
            chartData: {
              // reset all other chart types in memory
              [chartTypeQueryVariable]: parsedData,
            },
            selectedAssetType: assetType,
            selectedStartDate: startDateQueryVariable,
            selectedEndDate: endDateQueryVariable,
            showChart: true,
            chart: new Chart(chartElement, {
              type: chartTypeQueryVariable,
              data: parsedData,
              options: defaultChartOptions(chartTypeQueryVariable),
            }),
            showLineChartOption,
          });
        } else {
          toast.info('No utilization data found');
          this.setState({
            loading: false,
          });
        }
      })
      .catch(({ message: queryError }) => {
        toast.error(queryError || 'Error get utilization data');
        this.setState({
          loading: false,
        });
      });
  };

  updateRangeOption = event => {
    const {
      target: { id },
    } = event;

    this.setState({
      selectedRangeOption: id,
    });
  };

  updateChartType = type => {
    const { client } = this.props;
    const {
      chart,
      chartElement,
      chartData: stateData,
      selectedAssetType,
      selectedStartDate,
      selectedEndDate,
    } = this.state;
    const query = {
      query: GET_DWELL_TIME_BY_ROOM_TYPE,
      variables: Object.assign(
        {
          asset_type_id: selectedAssetType.id,
          start_date: selectedStartDate,
          end_date: selectedEndDate,
        },
        type === 'line' && { period: 'daily' },
      ),
    };

    const finish = newChartData => {
      chart && chart.destroy();
      this.setState({
        loading: false,
        selectedChartType: type,
        chartData: {
          ...stateData,
          [type]: newChartData,
        },
        chart: new Chart(chartElement, {
          type,
          data: newChartData,
          options: defaultChartOptions(type),
        }),
      });
    };

    // fetch new data only if not already in memory
    if (!stateData[type]) {
      this.setState({
        loading: true,
      });

      client
        .query(query)
        .then(result => {
          finish(
            result.data && parseUtilizationChartData(result.data.dwell_time_by_room_type, type),
          );
        })
        .catch(({ message: queryError }) => {
          toast.error(queryError || 'Error getting periodic utilization data');
          this.setState({
            loading: false,
          });
        });
    } else {
      finish(stateData[type]);
    }
  };

  render() {
    const {
      props: { classes },
      state: {
        initialized,
        selectedStartDate,
        selectedEndDate,
        selectedAssetType,
        assetTypes,
        loading,
        selectedRangeOption,
        selectedChartType,
        showChart,
        showLineChartOption,
      },
    } = this;

    return (
      <div className="report-page--content__container">
        {loading && <Loader fullscreen transparent />}
        <h1>Asset Utilization</h1>
        {initialized && (
          <div className="asset-utilization-form mb-3">
            <Formik
              onSubmit={this.handleSubmit}
              validationSchema={formSchema}
              initialValues={{
                assetTypeName: selectedAssetType
                  ? selectedAssetType.name
                  : assetTypes && assetTypes[0] && assetTypes[0].name,
                startDate: selectedStartDate,
                endDate: selectedEndDate,
              }}
            >
              {({ handleSubmit, handleChange, setFieldValue, values, errors }) => (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col className="mb-3 align-self-end" lg={6}>
                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="asset-type-name">Asset Type</InputLabel>
                        <Select
                          value={values.assetTypeName}
                          name="assetTypeName"
                          id="asset-type-name"
                          onChange={handleChange}
                        >
                          {assetTypes &&
                            assetTypes.map(asset_type => (
                              <MenuItem key={asset_type.id} value={asset_type.name}>
                                {asset_type.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Col>
                    <Col className="mb-3" lg={6}>
                      <InputLabel shrink>Time Range</InputLabel>
                      <ButtonToolbar>
                        <ButtonGroup>
                          {PIE_CHART_RANGE_OPTIONS.map(option => (
                            <Button
                              key={option.id}
                              id={option.id}
                              active={selectedRangeOption === option.id}
                              onClick={this.updateRangeOption}
                            >
                              {option.label}
                            </Button>
                          ))}
                        </ButtonGroup>
                      </ButtonToolbar>
                    </Col>
                  </Row>
                  {selectedRangeOption === 'custom' && (
                    <Row>
                      <Col className="mb-3" lg={6}>
                        <InputLabel shrink htmlFor="startDate">
                          Start Date
                        </InputLabel>
                        <div className="flex-box start-date-wrapper">
                          <DatePicker
                            time={false}
                            defaultValue={new Date(values.startDate)}
                            name="startDate"
                            onChange={value => setFieldValue('startDate', value)}
                            onKeyUp={({ target: { value } }) => setFieldValue('startDate', value)}
                            max={new Date()}
                            min={
                              new Date(
                                moment()
                                  .subtract('months', 6)
                                  .format(),
                              )
                            }
                          />
                        </div>
                        <FormFeedback visible={errors.startDate} message={errors.startDate} />
                      </Col>
                      <Col className="mb-3" lg={6}>
                        <InputLabel shrink htmlFor="endDate">
                          End Date
                        </InputLabel>
                        <div className="flex-box start-date-wrapper">
                          <DatePicker
                            time={false}
                            defaultValue={new Date(values.endDate)}
                            name="endDate"
                            onChange={value => setFieldValue('endDate', value)}
                            max={new Date()}
                            min={
                              new Date(
                                moment()
                                  .subtract('year', 1)
                                  .format(),
                              )
                            }
                          />
                        </div>
                        <FormFeedback visible={errors.endDate} message={errors.endDate} />
                      </Col>
                    </Row>
                  )}
                  <Button type="submit" color="primary">
                    Submit
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        )}
        <div className="chart-wrapper">
          {showChart && showLineChartOption && (
            <ButtonToolbar>
              <ButtonGroup size="sm" className="ml-auto">
                <Button
                  id="pie"
                  onClick={() => this.updateChartType('pie')}
                  active={selectedChartType === 'pie'}
                >
                  <i className="fas fa-chart-pie" />
                </Button>
                <Button
                  id="line"
                  onClick={() => this.updateChartType('line')}
                  active={selectedChartType === 'line'}
                >
                  <i className="fas fa-chart-line" />
                </Button>
              </ButtonGroup>
            </ButtonToolbar>
          )}
          <div className="chart-container">
            <canvas id="chart" width="400" height="400" />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(withApollo(AssetUtilization));
