import moment from 'moment-timezone';

export function getMessageByComponentId(componentId, vars, timezone) {
  switch (componentId) {
    case 'fall_detected':
    case 'fall_confirmed':
      return `%t in ${vars.room.name}`;

    case 'room_entry':
      return `${vars.asset.name} entered ${vars.room.name} at ${moment(vars.device_event_time)
        .tz(timezone || 'US/Mountain')
        .format('MM/DD/YYYY HH:mm z')}`;

    default:
      return '';
  }
}

export const getAlertOptions = snsTopic => {
  let options = {};

  switch (snsTopic) {
    case 'room-entry-alerts':
      options = {
        className: 'notification--room-entry-alerts',
        autoClose: false,
        closeOnClick: false,
        button: {
          copy: 'View Room',
        },
        showDismiss: true,
        dismissAlertOnClose: true,
      };
      break;
    case 'fall-detected-alerts':
      options = {
        className: 'notification--fall-detected-alerts',
        autoClose: false,
        closeOnClick: false,
        button: {
          copy: 'View Room',
        },
      };
      break;
    case 'fall-confirmed-alerts':
      options = {
        className: 'notification--fall-confirmed-alerts',
        autoClose: false,
        closeOnClick: false,
        button: {
          copy: 'View Room',
        },
      };
      break;
    default:
  }

  return options;
};
