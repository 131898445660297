import React from 'react';
import { Redirect } from 'react-router-dom';
import { Create, SimpleForm, TextInput, ReferenceInput } from 'react-admin';

import Can from '../../components/Can/Can';
import CreateToolbar from '../common/CreateToolbar/CreateToolbar';
import FormHeader from '../common/FormHeader/FormHeader';
import PaginatedList from '../common/PaginatedList/PaginatedList';
import { validate } from '../../utils/admin';
import { formSchema, redirectUrl } from './index.js';
import { DEFAULT_PAGINATION_PER_PAGE } from '../../utils/admin';

export const TagCreate = props => (
  <Can perform="tags:create" no={<Redirect to={redirectUrl} />}>
    <Create {...props}>
      <SimpleForm toolbar={<CreateToolbar />} validate={values => validate(formSchema, values)}>
        <FormHeader text="Tag - Create" />
        <TextInput source="serial_number" required placeholder="XX:XX:XXXX" />
        <ReferenceInput
          label="Asset"
          source="asset.id"
          perPage={DEFAULT_PAGINATION_PER_PAGE}
          reference="assets"
          filter={{ noTag: true }}
          allowEmpty
        >
          <PaginatedList optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  </Can>
);
