import React, { Fragment, useState } from 'react';
import { withLocateContext } from '../../../context/LocateContext';
import { getHumanizedTimestamp } from '../../../utils/js';

const getTimestamp = ({ id, latestLocationData, timestamp, setTimestamp }) => {
  if (latestLocationData && latestLocationData.asset && id === latestLocationData.asset.id) {
    const newTimestamp =
      latestLocationData.asset.tag &&
      latestLocationData.asset.tag.location &&
      latestLocationData.asset.tag.location.DeviceEventTime &&
      getHumanizedTimestamp(latestLocationData.asset.tag.location.DeviceEventTime);

    if (timestamp !== newTimestamp) {
      setTimestamp(newTimestamp);
    }

    return newTimestamp;
  }
  return timestamp;
};

const AssetLastSeen = ({ id, initialTimestamp, locateContext }) => {
  const [timestamp, setTimestamp] = useState(initialTimestamp);

  return (
    <Fragment>
      <strong>Last Seen:</strong>{' '}
      {getTimestamp({
        id,
        latestLocationData: locateContext.latestLocationData,
        timestamp,
        setTimestamp,
      })}
    </Fragment>
  );
};

export default withLocateContext(AssetLastSeen);
