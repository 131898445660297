import gql from 'graphql-tag';

export default function roomTypeQueries(raFetchType, params) {
  switch (raFetchType) {
    case 'CREATE':
      return {
        query: gql`
          mutation addRoomType($name: String!, $plural: String!) {
            addRoomType(name: $name, plural: $plural) {
              id
            }
          }
        `,
        variables: params.data,
        parseResponse: response => ({ data: response.data.addRoomType }),
      };
    case 'UPDATE':
      return {
        query: gql`
          mutation updateRoomType($id: ID!, $name: String!, $plural: String!) {
            updateRoomType(id: $id, name: $name, plural: $plural) {
              id
            }
          }
        `,
        variables: params.data,
        parseResponse: response => ({ data: response.data.updateRoomType }),
      };
    case 'GET_ONE':
      return {
        query: gql`
          query getRoomTypes($id: ID, $filter: TypesFilter) {
            room_types(id: $id, filter: $filter) {
              total
              data {
                ... on RoomType {
                  id
                  name
                  plural
                }
              }
            }
          }
        `,
        variables: {
          filter: {
            allOrganizations: true,
          },
          ...params,
        },
        parseResponse: response => ({
          data:
            response.data &&
            response.data.room_types &&
            response.data.room_types.data &&
            response.data.room_types.data.length &&
            response.data.room_types.data[0],
        }),
      };
    case 'GET_MANY':
    case 'GET_LIST':
      return {
        query: gql`
          query getRoomTypes($sort: Sort, $pagination: Pagination, $filter: TypesFilter) {
            room_types(sort: $sort, pagination: $pagination, filter: $filter) {
              total
              data {
                ... on RoomType {
                  id
                  name
                  plural
                }
              }
            }
          }
        `,
        variables: params,
        parseResponse: response => ({
          data: response.data.room_types.data,
          total: response.data.room_types.total,
        }),
      };
    case 'DELETE':
      return {
        query: gql`
          mutation deleteRoomTypes($id: [ID!]!) {
            deleteRoomTypes(id: $id) {
              id
              name
            }
          }
        `,
        variables: params,
        parseResponse: response => ({ data: response.data.deleteRoomTypes[0] }),
      };
    case 'DELETE_MANY':
      const variables = {
        id: params.ids,
      };

      return {
        query: gql`
          mutation deleteRoomTypes($id: [ID]!) {
            deleteRoomTypes(id: $id) {
              id
            }
          }
        `,
        variables,
        parseResponse: response => ({ data: response.data.deleteRoomTypes }),
      };
    default:
      throw new Error('Invalid fetch type');
  }
}
