import React, { Component, Fragment } from 'react';
import { PiService, PiMap } from 'pointinside';
import _ from 'lodash';
import queryString from 'query-string';
import moment from 'moment-timezone';
import svgson from 'svgson';
import cx from 'classnames';

import {
  getDefaultZone,
  getMarkerSVG,
  getGroupMarkerSVG,
  tagIsMissing,
  deviceEventTimeFromPast,
  meterPointToPixels,
  getSVGPattern,
  getOverlayFillColor,
  MARKER_COLOR_HIGHLIGHT,
  MARKER_COLOR_BASE,
  MIN_MARKER_TRANSITION_SPEED_MS,
  MAX_MARKER_TRANSITION_SPEED_MS,
  MARKER_HOVER,
  MARKER_SELECTED,
  MARKER_Z_INDEX,
  MARKER_Z_INDEX_HOVER,
  MARKER_Z_INDEX_SELECTED,
  GROUP_MARKER_RADIUS,
  ALERT_OVERLAY_PATTERNS,
} from '../../../utils/map';
import log from '../../../utils/Log';
import '../../../styles/vendor/pointinside/pointinside.css';
import ResizeDetector from '../../../components/ResizeDetector/ResizeDetector';
import { formatTimestamp, getMin } from '../../../utils/js';
import { toast } from '../../../components/Toast/Toast';
import { withLocateContext } from '../../../context/LocateContext';
import MapKey from './MapKey';
import Maintenance from '../../../components/Maintenance/Maintenance';

class MapContainer extends Component {
  constructor(props) {
    super(props);

    const qs = queryString.parse(window.location.search);
    let mapPosition = [];

    if (qs && qs.mp) {
      const mpArray = qs.mp.split(',');

      if (mpArray && mpArray.length === 3 && mpArray.every(item => !isNaN(item))) {
        mapPosition = mpArray;
      }
    }

    this.state = {
      groupContexts: null,
      groupContextsSelected: null,
      groupSerialElements: null,
      groupSelectedSerialElements: null,
      pimapInitialized: false,
      placedMarkers: null,
      selectedGroupMarkers: null,
      sidebarOpen: null,
      currentPiZone: null,
      mapPosition,
      svgElement: null,
      customLayer: null,
      showFallAlertTooltip: false,
      markerTimes: {},
      showMaintenance: false,
    };

    // start a timer to continuously keep track of how long markers have been on
    // the map so we know when to hide them if they go missing since subscription data will stop
    this.missingTimer = setInterval(() => {
      this.checkMissing();
    }, 3000);
  }

  async componentDidMount() {
    try {
      await this.setupMap();
    } catch (e) {
      log.error(e);
      this.setState({
        showMaintenance: true,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      selectedZone,
      appContext: { alerts: nextAlerts, showingFallRoom: nextShowingFallRoom },
      locateContext: { latestLocationData: newLocationData, roomPresenceData: nextPresenceData },
    } = nextProps;
    const {
      props: {
        currentVenue,
        appContext: { alerts: currentAlerts, showingFallRoom: currentShowingFallRoom },
        locateContext: { roomPresenceData: currentPresenceData },
      },
      state: { currentPiZone, pimapInitialized },
      pimap,
    } = this;

    if (
      pimap &&
      pimapInitialized &&
      selectedZone &&
      currentPiZone &&
      selectedZone.vendor_zone_id !== currentPiZone
    ) {
      // zone has changed, show correct map and place markers
      const mapId =
        currentVenue && currentVenue.zones.find(zone => zone.id === selectedZone.id).vendor_zone_id;

      pimap.displayZone(mapId).then(() => {
        this.zone = selectedZone;
        this.placeInitialMarkers();
        this.forceShowMap(mapId);

        this.setState({
          currentPiZone: mapId,
        });
      });
    }

    if (newLocationData && newLocationData.updatedLocationData) {
      //console.log(newLocationData.updatedLocationData.TagSerialNumber);
      // if device event times do not match then place a marker
      this.placeMarkers(newLocationData.updatedLocationData, newLocationData.asset);
    }

    if (
      !nextAlerts.every(nextAlert =>
        currentAlerts.some(
          currentAlert =>
            currentAlert.device_event_time === nextAlert.device_event_time &&
            currentAlert.room.id === nextAlert.room.id &&
            currentAlert.alert_type.component_id === nextAlert.alert_type.component_id,
        ),
      ) ||
      nextAlerts.length !== currentAlerts.length
    ) {
      // alerts are different, redraw room alert layer with new alerts
      this.renderRoomAlerts({ appContext: nextProps.appContext });
    }

    if (!_.isEqual(_.sortBy(nextPresenceData), _.sortBy(currentPresenceData))) {
      this.renderRoomPresence({ locateContext: nextProps.locateContext });
    }

    if (nextShowingFallRoom && nextShowingFallRoom !== currentShowingFallRoom) {
      this.handleShowFallRoom(nextProps.appContext);
    }
  }

  componentDidUpdate() {
    this.handleMarkerState();
  }

  componentWillUnmount() {
    clearInterval(this.missingTimer);
  }

  async setupMap() {
    const {
      appContext,
      appContext: {
        config: { piApiKey, piApiUrl },
      },
      mapInitComplete,
    } = this.props;

    let mapElement;
    try {
      await this.setMapData();
      mapElement = await this.getMapElement();
    } catch (error) {
      log.error(error);
      this.setState({
        showMaintenance: true,
      });
    }
    const { venue_id } = this.state;

    this.pisvc = new PiService({
      apiKey: piApiKey,
      apiUrl: piApiUrl,
      appName: 'repp health',
      appVersion: '1.0',
    });

    this.pimap = new PiMap({
      element: mapElement,
      zoneChangerControls: false,
    });

    this.pimap.addListener('zoneChange', vendorZoneId => {
      const { currentVenue, updateSelectedZone, clearFilter, clearSelectedAssets } = this.props;
      const newZone =
        currentVenue.zones && currentVenue.zones.find(zone => zone.vendor_zone_id === vendorZoneId);

      updateSelectedZone(newZone);
      clearFilter();
      clearSelectedAssets();
    });

    this.pimap.addListener('positionChange', event => {
      this.handleMarkerState();
      this.handlePIGroupSplit();

      // set the global position object to be used in handleMapPositionChange()
      this.positionTransform = event;

      const { pimapInitialized, mapPosition } = this.state;
      if (pimapInitialized) {
        const eventZoom = parseFloat(event[0]).toFixed(2);
        const stateZoom = parseFloat(mapPosition[0]).toFixed(2);

        if (eventZoom !== stateZoom) {
          // zoom level changed, update URL and state
          this.handleMapPositionChange();
        }
        this.killMarkerTransitions();
      }
    });

    const mapWrapperElement = document.getElementsByClassName('mapSVG_wrapper')[0];
    mapWrapperElement.addEventListener('mouseup', () => {
      // map position may have changed, update the URL with the latest zoom/pan values
      this.handleMapPositionChange();
    });

    const venue = await this.pisvc.getVenueById({ venueId: venue_id });
    const { selectedZone } = this.props;

    await this.pimap.setVenue({ venue });

    let currentPiZone = this.pimap.getCurrentZoneId();

    if (currentPiZone !== selectedZone.vendor_zone_id) {
      currentPiZone = selectedZone.vendor_zone_id;
      await this.pimap.displayZone(selectedZone.vendor_zone_id);
    }

    const { mapPosition } = this.state;
    const svgElement = document.getElementById(`zone_${currentPiZone}`);
    const scale = mapPosition && mapPosition.length && mapPosition.length > 0 && mapPosition[0];
    const svgOffsetX =
      mapPosition && mapPosition.length && mapPosition.length > 0 && mapPosition[1];
    const svgOffsetY =
      mapPosition && mapPosition.length && mapPosition.length > 0 && mapPosition[2];
    const { showingFallRoom } = appContext;
    this.initializeCustomMapLayers();

    if (showingFallRoom) {
      // pan/zoom the map over a room that was/is in alert
      this.handleShowFallRoom(appContext);
    } else if (scale && svgOffsetX && svgOffsetY) {
      // use map position (`mp`) query string parameter to pan/zoom the map
      if (!svgElement || !mapWrapperElement) return;

      // measure map's viewport
      const viewportWidth = mapWrapperElement.offsetWidth * scale;
      const viewportHeight = mapWrapperElement.offsetHeight * scale;

      // measure svg element
      const svgWidth = svgElement.width.baseVal.value;
      const svgHeight = svgElement.height.baseVal.value;

      // convert position offset to center coordinates by calculating two slopes:
      // one for the current width (X) and one for height (Y)
      const slopeX = input => {
        const m = viewportWidth / 2 / -(svgWidth / 2);
        return (input - viewportWidth / 2) / m;
      };

      const slopeY = input => {
        const m = viewportHeight / 2 / -(svgHeight / 2);
        return (input - viewportHeight / 2) / m;
      };

      // construct location object to use for setCenter()
      const centerLocation = {
        // adding 25 pixels per scale for X to account for some loss that occurs from rounding
        x: slopeX(parseInt(svgOffsetX, 10)) + 25 * scale,
        y: slopeY(parseInt(svgOffsetY, 10)),
        zone: selectedZone.vendor_zone_id,
      };

      this.pimap.setCenter(centerLocation, parseFloat(scale), false);
    }

    const initializeGroupMarkers = _.once(this.initializeGroupMarkers);
    this.placeInitialMarkers();
    initializeGroupMarkers();

    // create new layer in pointinside svg to render room alerts
    const customLayer = document.querySelector(`#zone_${currentPiZone} #fall_alerts_svg_layer`);

    this.setState({ pimapInitialized: true, svgElement, customLayer }, () => {
      mapInitComplete();
    });
    this.forceShowMap(currentPiZone);
  }

  initializeCustomMapLayers = () => {
    const svgElements = document.querySelectorAll('svg[id^="zone_"]');

    if (!svgElements || !svgElements.length) {
      return;
    }

    svgElements.forEach(svgElement => {
      const patternId = svgElement.id.replace('zone_', '');
      const roomPresenceLayer = document.createElementNS('http://www.w3.org/2000/svg', 'g');
      const alertLayer = document.createElementNS('http://www.w3.org/2000/svg', 'g');

      roomPresenceLayer.setAttribute('id', 'room_presence_svg_layer');
      alertLayer.setAttribute('id', 'fall_alerts_svg_layer');

      svgElement.appendChild(roomPresenceLayer);
      svgElement.appendChild(alertLayer); // this should always be last so alerts are prioritized during render

      alertLayer.addEventListener('mousemove', event => {
        const ele = document.getElementById(event.target.id);
        const componentId = ele && ele.getAttribute('data-component-id');

        if (componentId && componentId !== 'fall_end') {
          this.updateRoomAlertTooltip(event);
        }
      });

      alertLayer.addEventListener('mouseleave', event => {
        // hide tooltip
        this.setState({
          showFallAlertTooltip: false,
        });
      });

      // animated fill pattern for room alerts
      const defs = document.createElementNS('http://www.w3.org/2000/svg', 'defs');

      defs.innerHTML = ALERT_OVERLAY_PATTERNS.reduce(
        (acc, val) => `${acc}${getSVGPattern(val.componentId, patternId)}`,
        '',
      );
      svgElement.prepend(defs);
    });

    this.renderRoomAlerts();
    this.renderRoomPresence();
  };

  handleShowFallRoom = async appContext => {
    const { showingFallRoom } = appContext;
    const { currentVenue } = this.props;
    const fallRoomZone =
      currentVenue &&
      currentVenue.zones &&
      currentVenue.zones.find(zone => zone.rooms.some(room => room.id === showingFallRoom));

    if (!fallRoomZone) {
      toast.error(`Error finding zone for room ${showingFallRoom}`);
      return;
    }

    // calculate center point of the alert polygon and call setCenter with result
    const fallRoom = fallRoomZone.rooms.find(room => room.id === showingFallRoom);
    const polygonSelector = `polygon#fall-alert-${fallRoom.id}`;
    const polygonElement = document.querySelector(polygonSelector);
    const mapWrapperElement = document.querySelector('.mapSVG_wrapper');

    if (!polygonElement || !mapWrapperElement) return;

    const { vertices } = fallRoom;
    const roomBoundaries = {
      minX: vertices.reduce((acc, val) => (val.x < acc.x ? val : acc), {
        x: vertices[0].x,
      }).x,
      minY: vertices.reduce((acc, val) => (val.y < acc.y ? val : acc), {
        y: vertices[0].y,
      }).y,
      maxX: vertices.reduce((acc, val) => (val.x > acc.x ? val : acc), {
        x: vertices[0].x,
      }).x,
      maxY: vertices.reduce((acc, val) => (val.y > acc.y ? val : acc), {
        y: vertices[0].y,
      }).y,
    };

    const { minX, minY, maxX, maxY } = roomBoundaries;
    const midpoint = {
      x: minX + (maxX - minX) / 2,
      y: minY + (maxY - minY) / 2,
    };

    // convert point to pixels to use for setCenter()
    const midpointPixels = meterPointToPixels(midpoint, fallRoomZone);
    const centerLocation = {
      ...midpointPixels,
      zone: fallRoomZone.vendor_zone_id,
    };

    // calculate zoom level (scale) by measuring the map wrapper and alert polygon
    const padding = 150; // px, amount of space to reserve around the polygon after zooming
    const polygonRect = polygonElement.getBoundingClientRect();
    const { width: polygonWidth, height: polygonHeight } = polygonRect;
    const { clientWidth: mapWrapperWidth, clientHeight: mapWrapperHeight } = mapWrapperElement;

    // get the lowest possible zoom level without going higher than 6 which is a good max value
    const { positionTransform } = this;
    const currentZoomLevel = positionTransform[0];
    const scaleX = getMin((mapWrapperWidth - padding * 2) / (polygonWidth / currentZoomLevel), 6);
    const scaleY = getMin((mapWrapperHeight - padding * 2) / (polygonHeight / currentZoomLevel), 6);

    // get lowest scale value out from X and Y so all shapes and sizes are zoomed correctly
    const scale = getMin(scaleX, scaleY);

    this.pimap.setCenter(centerLocation, scale, true);
    this.positionTransform[0] = scale;
    this.handleMapPositionChange();

    // reset global showingFallRoom state
    appContext.resetShowFallRoom();
  };

  updateRoomAlertTooltip = event => {
    const {
      clientX,
      clientY,
      target: { id },
    } = event;
    const tooltipElement = document.querySelector('.fall-alert-tooltip');
    const mapWrapperElement = document.querySelector('.mapSVG_wrapper');
    const rect = mapWrapperElement.getBoundingClientRect();
    const TOOLTIP_OFFSET = 15;

    tooltipElement.style.left = `${clientX + TOOLTIP_OFFSET - rect.left}px`;
    tooltipElement.style.top = `${clientY + TOOLTIP_OFFSET - rect.top}px`;
    const roomId = id.replace('fall-alert-', '');
    const {
      appContext: { alerts },
      selectedZone,
    } = this.props;
    const alert = alerts.find(alert => alert.room.id === roomId);

    if (this.state.showFallAlertTooltip.roomId !== roomId) {
      // update state with new alert
      this.setState({
        showFallAlertTooltip: {
          roomId,
          room:
            selectedZone &&
            selectedZone.rooms &&
            selectedZone.rooms.find(room => room.id === roomId),
          alert,
        },
      });
    }
  };

  renderRoomAlerts = (options = {}) => {
    const layers = document.querySelectorAll('svg[id^="zone_"] #fall_alerts_svg_layer');
    const {
      props: { selectedZone },
    } = this;

    layers.forEach(layer => {
      const { appContext, currentVenue } = Object.assign({}, this.props, options);
      const { alerts } = appContext;
      const alertRooms = currentVenue.zones
        .reduce((acc, val) => Array.prototype.concat(acc, val.rooms), [])
        .filter(room =>
          alerts.some(
            alert => selectedZone.rooms.some(zr => zr.id === room.id) && alert.room.id === room.id,
          ),
        );
      const alertRoomPixelPoints =
        alertRooms && alertRooms.length && alertRooms.length > 0
          ? alertRooms.map(room => ({
              ...room,
              vertices: room.vertices.map(vertice => meterPointToPixels(vertice, selectedZone)),
              alert: alerts.find(alert => alert.room.id === room.id),
            }))
          : [];

      const supportsSVGAnimateTransform = typeof SVGAnimateTransformElement;
      const polygonObject = {
        name: 'g',
        type: 'element',
        attributes: {
          id: 'injected',
        },
        children: [
          alertRoomPixelPoints.map(room => {
            const componentId = room.alert.alert_type.component_id;

            return {
              name: 'polygon',
              type: 'element',
              attributes: {
                id: `fall-alert-${room.id}`,
                points: room.vertices.map(vertice => `${vertice.x},${vertice.y}`).join(' '),
                fill:
                  supportsSVGAnimateTransform !== 'undefined'
                    ? componentId !== 'fall_end'
                      ? `url(#${componentId}_pattern_${selectedZone.vendor_zone_id})`
                      : 'none'
                    : getOverlayFillColor(componentId),
                class: `fall-alert-polygon fall-alert-polygon--${componentId}`,
                'data-component-id': componentId,
              },
              children: [],
            };
          }),
        ],
      };

      layer.innerHTML = svgson.stringify(polygonObject, {
        selfClose: false,
      });
    });
  };

  renderRoomPresence = (options = {}) => {
    const {
      props: { selectedZone },
    } = this;
    const { locateContext } = Object.assign({}, this.props, options);
    const svgLayer = document.querySelector(
      `svg#zone_${selectedZone.vendor_zone_id} #room_presence_svg_layer`,
    );
    if (!svgLayer) return;
    const { roomPresenceData } = locateContext;
    const presenceRooms = selectedZone.rooms.filter(room =>
      roomPresenceData.find(presenceItem => presenceItem.RoomId === room.id),
    );
    const polygonPixelPoints =
      presenceRooms && presenceRooms.length && presenceRooms.length > 0
        ? presenceRooms.map(room => ({
            ...room,
            vertices: room.vertices.map(vertice => meterPointToPixels(vertice, selectedZone)),
            presence_detected: !!roomPresenceData.find(
              presenceItem => presenceItem.RoomId === room.id,
            ).PresenceDetected,
          }))
        : [];
    const polygonObject = {
      name: 'g',
      type: 'element',
      attributes: {
        id: 'room-presence',
      },
      children: [
        polygonPixelPoints.map(room => ({
          name: 'polygon',
          type: 'element',
          attributes: {
            id: `room-presence-${room.id}`,
            points: room.vertices.map(vertice => `${vertice.x},${vertice.y}`).join(' '),
            class: cx('room-presence-polygon', room.presence_detected && 'occupied'),
            fill: 'none',
            'stroke-width': 25,
          },
          children: [],
        })),
      ],
    };

    svgLayer.innerHTML = svgson.stringify(polygonObject, {
      selfClose: false,
    });
  };

  getMapElement = () => {
    return new Promise((resolve, reject) => {
      try {
        if (
          this.pimapElement &&
          this.pimapElement !== undefined &&
          typeof this.pimapElement !== 'undefined'
        ) {
          resolve(this.pimapElement);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  setMapData = () => {
    const {
      currentVenue,
      currentVenue: { vendor_map_id },
      selectedZone,
    } = this.props;
    return new Promise((resolve, reject) => {
      const defaultZone = getDefaultZone(currentVenue);
      this.zone =
        (currentVenue.zones &&
          currentVenue.zones.find(zone => zone.id === (selectedZone && selectedZone.id))) ||
        defaultZone;
      const originOffsetMetersX = this.zone.origin_offset_x;
      const originOffsetMetersY = this.zone.origin_offset_y;
      const meterToPixelScalerX = this.zone.meter_to_pixel_scaler_x;
      const meterToPixelScalerY = this.zone.meter_to_pixel_scaler_y;
      const offsetX = originOffsetMetersX / meterToPixelScalerX;
      const offsetY = originOffsetMetersY / meterToPixelScalerY;
      try {
        this.setState(
          {
            venue_id: vendor_map_id,
            zone_id: this.zone.vendor_zone_id,
            meter_to_pixel_scaler_x: meterToPixelScalerX,
            meter_to_pixel_scaler_y: meterToPixelScalerY,
            offset_x: offsetX,
            offset_y: offsetY,
            initialized: true,
          },
          () => {
            resolve();
            return true;
          },
        );
      } catch (error) {
        reject(error);
      }
    });
  };

  handleMapPositionChange = () => {
    const { positionTransform } = this;

    // extract only the necessary items from positionTransform and round to nearest 100th
    const newPositionTransform = positionTransform.slice(-3).map(p => parseFloat(p.toFixed(3)));
    const qs = queryString.parse(window.location.search);
    let newRoute;
    const {
      location: { origin, pathname },
    } = window;

    qs.mp = newPositionTransform.join(',');
    newRoute = `${origin}${pathname && pathname.length ? pathname : ''}?${queryString.stringify(
      qs,
    )}`;

    window.history.pushState(document.title, document.title, newRoute);
    this.setState({
      mapPosition: newPositionTransform,
    });
  };

  forceShowMap = currentPiZone => {
    // PointInside sometimes hides the map structural background, this forces it to show
    const mapSvgElement = document.getElementById(`zone_${currentPiZone}`);

    if (!mapSvgElement) return;

    mapSvgElement.style.visibility = 'visible';
    mapSvgElement.style.opacity = '1';
  };

  checkMissing = () => {
    const { markerTimes } = this.state;

    Object.keys(markerTimes).forEach(key => {
      const tagTime = markerTimes[key];
      if (tagIsMissing(tagTime)) {
        log.warn(`\n Tag with key ${key} is missing. \n Tag Time: ${tagTime}`);
        delete markerTimes[key];
        this.pimap.removeMarkers(key);
        this.removeMarkerFromState(key);
      }
    });
  };

  placeInitialMarkers = () => {
    const { zone } = this;
    if (!zone || !zone.assets) {
      return;
    }

    zone.assets.forEach(asset => {
      this.placeMarkers(asset.tag.location, asset);
    });
  };

  placeMarkers = (location, asset) => {
    const serial = location.TagSerialNumber;

    if (location && location.DeviceEventTime && !tagIsMissing(location.DeviceEventTime)) {
      this.addMarker(location, serial, asset);
    }
  };

  /**
   * isMarkerGrouped - tells if a marker is currently inside a group by comparing its position against all other markers placed on map
   * @access private
   *
   * @param marker Object, Marker object added to map
   * @param matrix Matrix, livePos value from PI SDK, accessed from this.pimap Symbol list
   */
  isMarkerGrouped = (marker, matrix) => {
    const {
      state: { currentPiZone },
      props: { selectedZone },
    } = this;
    const zone =
      (selectedZone &&
        currentPiZone &&
        currentPiZone === selectedZone.vendor_zone_id &&
        selectedZone.vendor_zone_id) ||
      this.pimap.getCurrentZoneId();
    const markerList = this.pimap._markers[zone];
    const transformer = this.pimap._svgToContainerXYFn({ matrix });
    const calcNewPos = loc => {
      return transformer(loc.x, loc.y);
    };
    let isGrouped = false;
    if (marker.location.zone === zone) {
      const groupDistanceSquared = Math.pow(GROUP_MARKER_RADIUS, 2);
      const calcDistanceSquared = (a, b) => {
        return Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2);
      };
      const newPos = calcNewPos(marker.location);
      let otherMarker;
      for (otherMarker of markerList) {
        if (
          otherMarker &&
          otherMarker.context &&
          marker &&
          marker.context &&
          otherMarker.context.serial === marker.context.serial
        ) {
          continue;
        }
        const otherPos = calcNewPos(otherMarker.location);
        const distSq = calcDistanceSquared(otherPos, newPos);
        if (distSq <= groupDistanceSquared) {
          isGrouped = true;
          break;
        }
      }
    }
    return isGrouped;
  };

  /**
   * addMarker - adds a marker to the map, if marker is already on map it will move the marker to the new location
   * @access private
   *
   * @param location Object, The location object received from the locationData subscription
   * @param serial String, The serial number of the tag
   * @param asset Object, the asset object from graphql query, can be null
   */
  addMarker = (location, serial, asset) => {
    const {
      selectedZone,
      currentVenue,
      clearOneSelectedAsset,
      selectOneAsset,
      selectedAssets,
      hoverOverAsset,
      clearHoverAsset,
      addOneAssetToFilter,
      clearOneAssetFromFilter,
      history,
    } = this.props;
    const { placedMarkers } = this.state;
    const locationX = this.mmToPixelsX(location.X);
    const locationY = this.mmToPixelsY(location.Y);
    const assetName = asset && asset.name;
    const assetId = asset && asset.id;
    const assetType = asset && asset.asset_type && asset.asset_type.name;
    const assetTypePlural = asset && asset.asset_type && asset.asset_type.plural;
    const defaultZone = getDefaultZone(currentVenue);
    const vendorZoneId =
      currentVenue.zones.find(zone => zone.id === selectedZone.id).vendor_zone_id ||
      defaultZone.vendor_zone_id;

    // PI Hack, This ensures grouped markers that are placed via subscription response have their labels placed properly
    const insertAfter = (el, referenceNode) => {
      try {
        referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
      } catch (e) {}
    };
    // Check to see if marker is already placed on the map
    if (placedMarkers && placedMarkers.has(serial)) {
      try {
        const marker = placedMarkers.get(serial);
        const isDeviceEventTimeFromPast = deviceEventTimeFromPast({
          previous: marker.context.deviceEventTime,
          current: location.DeviceEventTime,
        });
        // Past DeviceEventTime, out of order, ignore
        if (isDeviceEventTimeFromPast) {
          //console.log('DeviceEventTime out of order');
          return;
        }

        //only move the marker if the x or y location is different
        if (marker.location.x !== locationX || marker.location.y !== locationY) {
          // Get the value of livePos, which is a Matrix data type
          const livePosSymbol = Object.getOwnPropertySymbols(this.pimap).find(s => {
            return String(s) === 'Symbol(livePos)';
          });

          const livePos = livePosSymbol ? this.pimap[livePosSymbol] : null;
          // When the transition ends, update markers using pimap function (which breaks from or combines into groups)
          let transitionEnd = () => {
            marker.element.removeEventListener('transitionend', transitionEnd);
            // Clear transition
            marker.element.style.transition = null;
          };

          // currentX, currentY, targetX, targetY
          // Trig function, Euclidean distance
          const getTransitionSpeed = ({ previousTimestamp }) => {
            const msDifference = moment
              .unix(location.DeviceEventTime, 'ss')
              .diff(moment.unix(previousTimestamp, 'ss'));
            return Math.max(
              window.rh_min_transition_speed || MIN_MARKER_TRANSITION_SPEED_MS,
              Math.min(
                msDifference,
                window.rh_max_transition_speed || MAX_MARKER_TRANSITION_SPEED_MS,
              ),
            );
          };

          const transitionSpeed = getTransitionSpeed({
            previousTimestamp: marker.context.deviceEventTime,
          });

          marker.element.addEventListener('transitionend', transitionEnd, false);
          // Add transition
          marker.element.style.transition = `transform ${window.rh_transition_speed ||
            transitionSpeed}ms`;
          marker.label.element.style.transition = `transform ${window.rh_transition_speed ||
            transitionSpeed}ms`;

          // Before we save the new location, check to see if the previous location was grouped
          const isPreviousLocationGrouped = this.isMarkerGrouped(marker, livePos);
          // Manually set the location object on the marker
          marker.location = {
            ...marker.location,
            x: locationX,
            y: locationY,
          };
          // Check to see if new location is grouped
          const isNewLocationGrouped = this.isMarkerGrouped(marker, livePos);
          // Use pimap's logic for transforming locationX and locationY to correct XY (Maths for zoom, pan, rotation)
          // Ensures grouped marker _lastPosition is set, otherwise it's null causing _resetMarkerLocation to fail
          const transformer = this.pimap._svgToContainerXYFn();
          marker._lastPosition = transformer(locationX, locationY);
          // Use pimaps private function to reset a marker's location if it doesn't currently reside in a group
          if (isPreviousLocationGrouped && isNewLocationGrouped) {
            // do nothing since the marker is going to stay in the group
          } else {
            this.pimap._resetMarkerLocation({
              marker,
              location: marker._lastPosition,
              animated: false,
            });
          }
          // call updateMarkerOverlay to manage group joining/breaking
          this.pimap._updateMarkerOverlay(livePos, false, true);
        }

        //Set the deviceEventTime on the marker
        marker.context.deviceEventTime = location.DeviceEventTime;

        this.saveMarkerToState(marker, serial);
      } catch (e) {
        log.warn(e);
      }
    } else {
      // If marker doesn't exist on map, add it
      this.pimap
        .addMarker({
          location: {
            x: locationX,
            y: locationY,
            zone: vendorZoneId,
          },
          tag: serial,
          context: {
            assetId,
            assetName,
            assetType,
            assetTypePlural,
            serial,
            asset,
            deviceEventTime: location && location.DeviceEventTime,
          },
          animated: false,
          labelHtml: `<div class="rhMapLabel">
            <span>${assetName || serial}</span>
      		</div>`,
          maxWidth: 300,
          markerType: getMarkerSVG({
            assetType: assetType,
            color: this.getMarkerColor(serial, assetType),
          }),
        })
        .then(marker => {
          if (marker.label) {
            // PI Hack, This ensures grouped markers that are placed via subscription response have their labels placed properly
            insertAfter(marker.label.element, marker.element);
          }

          const doMouseEnterOrMove = _.throttle((e, context) => {
            const getSelectedAsset = selectedAssets && selectedAssets.get(context.assetId);
            this.updateMarkerZIndex(marker, MARKER_Z_INDEX_HOVER);
            if (!getSelectedAsset) {
              marker.showLabel();
              try {
                marker.changeMarkerType(getMarkerSVG({ type: MARKER_HOVER, assetType }));
              } catch (e) {
                log.warn(e);
              }
              hoverOverAsset(asset);
            }
          }, 50);

          // SINGLE MARKER MOUSE ENTER
          marker.addListener('mouseover', (e, context) => {
            doMouseEnterOrMove(e, context);
          });

          // SINGLE MARKER MOUSE LEAVE
          marker.addListener('mouseleave', (e, context) => {
            const getSelectedAsset = selectedAssets && selectedAssets.get(context.assetId);
            if (getSelectedAsset && getSelectedAsset.id === context.assetId) {
              // this is the selected asset
              this.updateMarkerZIndex(marker, MARKER_Z_INDEX_SELECTED);
            } else {
              if (this.pimap) {
                marker.hideLabel();
                try {
                  marker.changeMarkerType(getMarkerSVG({ assetType }));
                } catch (e) {
                  log.warn(e);
                }
                clearHoverAsset();
                this.updateMarkerZIndex(marker, MARKER_Z_INDEX);
              }
            }
          });

          if (asset && asset.id) {
            // SINGLE MARKER CLICK
            marker.addListener('click', (e, context) => {
              const getSelectedAsset = selectedAssets && selectedAssets.get(context.assetId);
              if (getSelectedAsset && getSelectedAsset.id === context.assetId) {
                marker.hideLabel();
                try {
                  marker.changeMarkerType(getMarkerSVG({ assetType }));
                } catch (e) {
                  log.warn(e);
                }
                this.updateMarkerZIndex(marker, MARKER_Z_INDEX);
                clearOneSelectedAsset(asset.id);
                clearOneAssetFromFilter(context.assetId);
                return;
              }
              clearHoverAsset();
              marker.showLabel();
              try {
                marker.changeMarkerType(getMarkerSVG({ type: MARKER_SELECTED, assetType }));
              } catch (e) {
                log.warn(e);
              }
              this.updateMarkerZIndex(marker, MARKER_Z_INDEX_SELECTED);
              selectOneAsset(asset);
              addOneAssetToFilter(context.assetId);
              history.push(`/locate/${selectedZone.id}`);
            });
          } else {
            // Hack because PI adds a click listener to the element that toggles the label, we don't want that if there is no asset
            marker.element.addEventListener('click', e => {
              if (marker.label) {
                marker.label.element.style.visibility = 'visible';
              }
            });
          }

          this.saveMarkerToState(marker, serial);
          if (selectedAssets && selectedAssets.size > 0) {
            this.showSelectedAsset();
          }
        });
    }
  };

  handleMarkerState = _.throttle(() => {
    const { pimapInitialized } = this.state;
    const { hoverAsset, selectedAssets } = this.props;
    if (!pimapInitialized) {
      return;
    }
    if (hoverAsset) {
      this.showHoverAsset();
    } else {
      this.hidePreviousHoverAssets();
    }

    if (selectedAssets.size > 0) {
      this.showSelectedAsset();
    } else {
      this.hidePreviousSelectedAssets();
    }
  }, 25);

  /**
   * killMarkerTransitions - called when positionChange event occurs from panning or zooming map, Snaps markers to their new location if they're in the middle of transitioning
   * @access private
   */
  killMarkerTransitions = () => {
    const { placedMarkers } = this.state;

    if (placedMarkers) {
      placedMarkers.forEach(marker => {
        try {
          marker.element.style.transition = null;
          marker.label.element.style.transition = null;
        } catch (e) {
          log.warn(e);
        }
      });
    }
  };

  handlePIGroupSplit = () => {
    const { selectedAssets } = this.props;
    try {
      this.markerGroupController._groups.forEach(group => {
        const foundSelectedAssetInGroup = selectedAssets.some(asset =>
          group.groupedMarkers.some(marker => marker.context.assetId === asset.id),
        );
        if (!foundSelectedAssetInGroup) {
          group.selected = false;
          this.hideGroupLabel(group);
        }
      });
    } catch (e) {}
  };

  mmToPixelsX = locationX => {
    const { meter_to_pixel_scaler_x, offset_x } = this.state;
    const mmToM = locationX / 1000;
    const MToPixels = mmToM / meter_to_pixel_scaler_x;
    const val = Math.round(MToPixels + offset_x);
    return val;
  };

  mmToPixelsY = locationY => {
    const { meter_to_pixel_scaler_y, offset_y } = this.state;
    const mmToM = locationY / 1000;
    const MToPixels = mmToM / meter_to_pixel_scaler_y;
    const val = Math.round(MToPixels + offset_y);
    return val;
  };

  getMarkerColor = serial => {
    const { hoverAsset } = this.props;
    return hoverAsset && hoverAsset.tag && hoverAsset.tag.serial_number === serial
      ? MARKER_COLOR_HIGHLIGHT
      : MARKER_COLOR_BASE;
  };

  saveMarkerToState = (marker, serial) => {
    const { placedMarkers } = this.state;
    const markerMap = placedMarkers ? placedMarkers : new Map(); // key/value store
    markerMap.set(serial, marker);
    this.setState({
      placedMarkers: markerMap,
      markerTimes: {
        ...this.state.markerTimes,
        [serial]: moment()
          .unix()
          .valueOf(),
      },
    });
  };

  removeMarkerFromState = serial => {
    const { placedMarkers } = this.state;
    const markerMap = placedMarkers ? placedMarkers : new Map();
    if (markerMap.has(serial)) {
      markerMap.delete(serial);
    }

    this.setState({
      placedMarkers: markerMap,
    });
  };

  showSelectedAsset = () => {
    const { placedMarkers } = this.state;
    const { selectedAssets, hoverAsset } = this.props;
    let exit = false;
    let locationArray = [];

    if (selectedAssets.size > 0) {
      selectedAssets.forEach(asset => {
        if (exit) {
          return;
        }
        const selectedAssetMarker =
          placedMarkers && placedMarkers.get(asset && asset.tag && asset.tag.serial_number);
        const selectedGroupAssetMarker = this.getGroupAssetMarker(asset && asset.id);
        if (selectedAssetMarker) {
          locationArray.push(selectedAssetMarker.location);
        }
        // Check to see if the selected marker is currently in a group marker
        if (selectedGroupAssetMarker && this.pimap) {
          try {
            selectedGroupAssetMarker.changeMarkerType(
              getGroupMarkerSVG(MARKER_HOVER, selectedGroupAssetMarker.groupCount),
            );
          } catch (e) {
            log.warn(e);
          }
          this.showGroupLabel(selectedGroupAssetMarker);
          selectedGroupAssetMarker.selected = true;
        }

        //show the specificlly selected marker
        if (selectedAssetMarker) {
          selectedAssetMarker.showLabel();

          // try/catch because pimap context gets lost somewhere in the pointinside code
          try {
            selectedAssetMarker.changeMarkerType(
              getMarkerSVG({ type: MARKER_SELECTED, assetType: asset.asset_type.name }),
            );
          } catch (e) {
            log.warn(e);
          }

          this.updateMarkerZIndex(selectedAssetMarker, MARKER_Z_INDEX_SELECTED);
        }
        if (
          selectedAssetMarker &&
          selectedGroupAssetMarker &&
          hoverAsset &&
          hoverAsset.id === asset.id
        ) {
          if (
            this.isAssetInsideGroup(selectedGroupAssetMarker, selectedAssetMarker.context.assetId)
          ) {
            this.showGroupLabel(selectedGroupAssetMarker, selectedAssetMarker.context);
          }
          exit = true;
        }
      });
      //TODO: This will reposition the map so the selected markers are centered within the viewport, however, not clear on scaleFactor and scaleMax settings
      // In addition, there seems to be a bug where tags will move around weirdly after a recenter of the map within the viewport, try turning off animation
      // this.pimap.fitViewport(locationArray, {
      //   animated: true,
      //   scaleFactor: 0.1,
      //   scaleMax: '100%',
      // });
    }
  };

  showHoverAsset = () => {
    const { placedMarkers } = this.state;
    const { hoverAsset, selectedAssets } = this.props;
    let previousHoverAssetMarker;

    const getHoverAssetSelected = selectedAssets.get(hoverAsset.id);

    const hoverAssetMarker =
      placedMarkers &&
      placedMarkers.get(hoverAsset && hoverAsset.tag && hoverAsset.tag.serial_number);

    // GROUP MARKER
    const hoverGroupAssetMarker = this.getGroupAssetMarker(hoverAsset && hoverAsset.id);
    if (hoverGroupAssetMarker && this.pimap) {
      try {
        hoverGroupAssetMarker.changeMarkerType(
          getGroupMarkerSVG(MARKER_HOVER, hoverGroupAssetMarker.groupCount),
        );
      } catch (e) {
        log.warn(e);
      }
      this.updateMarkerZIndex(hoverGroupAssetMarker, MARKER_Z_INDEX_HOVER);
      this.showGroupLabel(hoverGroupAssetMarker, hoverAssetMarker && hoverAssetMarker.context);
    } else {
      if (
        this.previousHoverGroupAsset &&
        this.previousHoverAsset &&
        typeof this.getGroupAssetMarker(this.previousHoverAsset.id) !== 'undefined'
      ) {
        this.hideGroupLabel(this.previousHoverGroupAsset);
        this.previousHoverGroupAsset = null;
      }
    }

    // SINGLE MARKER
    if (hoverAssetMarker && this.pimap) {
      hoverAssetMarker.showLabel();
      let markerType;

      //if it's also selected, set the selected svg type
      if (hoverAsset && hoverAsset.id === (getHoverAssetSelected && getHoverAssetSelected.id)) {
        markerType = MARKER_SELECTED;
        this.updateMarkerZIndex(hoverAssetMarker, MARKER_Z_INDEX_SELECTED);
      } else {
        markerType = MARKER_HOVER;
        this.updateMarkerZIndex(hoverAssetMarker, MARKER_Z_INDEX_HOVER);
      }

      try {
        hoverAssetMarker.changeMarkerType(
          getMarkerSVG({ type: markerType, assetType: hoverAsset.asset_type.name }),
        );
      } catch (e) {
        log.warn(e);
      }
    }

    //if the previous hovered asset is the current selectedAsset, do nothing
    if (
      this.previousHoverAsset &&
      getHoverAssetSelected &&
      this.previousHoverAsset.id === getHoverAssetSelected.id
    ) {
      return;
    }

    const getPreviousHoverAssetSelected = selectedAssets.get(
      this.previousHoverAsset && this.previousHoverAsset.id,
    );

    // hide the previously hovered asset
    if (
      this.previousHoverAsset &&
      hoverAsset &&
      this.previousHoverAsset.id !== hoverAsset.id &&
      !getPreviousHoverAssetSelected
    ) {
      previousHoverAssetMarker =
        placedMarkers && placedMarkers.get(this.previousHoverAsset.tag.serial_number);
      if (previousHoverAssetMarker) {
        previousHoverAssetMarker.hideLabel();
        try {
          previousHoverAssetMarker.changeMarkerType(
            getMarkerSVG({ assetType: this.previousHoverAsset.asset_type.name }),
          );
        } catch (e) {
          log.warn(e);
        }
        this.updateMarkerZIndex(previousHoverAssetMarker, MARKER_Z_INDEX, MARKER_HOVER);
      }
    }

    //set the previously hovered asset to the current hovered asset
    this.previousHoverAsset = hoverAsset;
    if (hoverGroupAssetMarker) {
      this.previousHoverGroupAsset = hoverGroupAssetMarker;
    }
  };

  isAssetInsideGroup = (groupMarker, assetId) => {
    const foundInGroup = _.find(groupMarker.groupedMarkers, {
      context: { assetId: assetId },
    });

    return !!foundInGroup;
  };

  getGroupAssetMarker = id => {
    let groupAssetMarker;

    //Check to see if the asset id exists in the groupMarkerController groups
    // If true, then return the group marker that contains the matching marker
    this.markerGroupController._groups.forEach(group => {
      const foundInGroup = _.find(group.groupedMarkers, {
        context: { assetId: id },
      });
      if (foundInGroup) {
        groupAssetMarker = group;
      }
    });

    return groupAssetMarker;
  };

  hidePreviousSelectedAssets = () => {
    const { placedMarkers } = this.state;
    const { previouslyClearedSelectedAssets } = this.props;
    if (previouslyClearedSelectedAssets.size > 0) {
      previouslyClearedSelectedAssets.forEach(asset => {
        // Single Markers
        const previousSelectedAssetMarker =
          placedMarkers && placedMarkers.get(asset && asset.tag && asset.tag.serial_number);
        if (previousSelectedAssetMarker && this.pimap) {
          previousSelectedAssetMarker.hideLabel();
          try {
            previousSelectedAssetMarker.changeMarkerType(
              getMarkerSVG({ assetType: asset.asset_type.name }),
            );
          } catch (e) {
            log.warn(e);
          }
          this.updateMarkerZIndex(previousSelectedAssetMarker, MARKER_Z_INDEX);
        }

        // Group Markers
        const selectedGroupAssetMarker = this.getGroupAssetMarker(asset && asset.id);
        if (selectedGroupAssetMarker && this.pimap) {
          selectedGroupAssetMarker.selected = false;
          try {
            selectedGroupAssetMarker.changeMarkerType(
              getGroupMarkerSVG(null, selectedGroupAssetMarker.groupCount),
            );
          } catch (e) {
            log.warn(e);
          }
          this.hideGroupLabel(selectedGroupAssetMarker);
          this.updateMarkerZIndex(selectedGroupAssetMarker, MARKER_Z_INDEX);
          this.selectedGroupAssetMarker = null;
        }
      });

      previouslyClearedSelectedAssets.clear();
    }
  };

  hidePreviousHoverAssets = () => {
    const { placedMarkers } = this.state;
    const { selectedAssets } = this.props;
    let previousHoverAssetMarker;
    if (
      this.previousHoverGroupAsset &&
      this.previousHoverAsset &&
      typeof this.getGroupAssetMarker(this.previousHoverAsset.id) !== 'undefined'
    ) {
      if (this.previousHoverGroupAsset.selected) {
        this.showGroupLabel(
          this.previousHoverGroupAsset,
          this.previousHoverGroupAsset.groupedMarkers.map(marker => marker.context),
        );
      } else {
        try {
          this.previousHoverGroupAsset.changeMarkerType(
            getGroupMarkerSVG(null, this.previousHoverGroupAsset.groupCount),
          );
        } catch (e) {
          log.warn(e);
        }
        this.hideGroupLabel(this.previousHoverGroupAsset);
        this.previousHoverGroupAsset = null;
      }
    }

    const getPreviousHoverAssetSelected = selectedAssets.get(
      this.previousHoverAsset && this.previousHoverAsset.id,
    );

    if (this.previousHoverAsset) {
      if (!getPreviousHoverAssetSelected) {
        previousHoverAssetMarker =
          placedMarkers &&
          placedMarkers.get(
            this.previousHoverAsset &&
              this.previousHoverAsset.tag &&
              this.previousHoverAsset.tag.serial_number,
          );
        if (previousHoverAssetMarker && this.pimap) {
          previousHoverAssetMarker.hideLabel();
          try {
            previousHoverAssetMarker.changeMarkerType(
              getMarkerSVG({ assetType: this.previousHoverAsset.asset_type.name }),
            );
          } catch (e) {
            log.warn(e);
          }
          this.updateMarkerZIndex(previousHoverAssetMarker, MARKER_Z_INDEX);
        }
        this.previousHoverAsset = null;
      }
    }
  };

  initializeGroupMarkers = () => {
    const {
      addManyAssetsToFilter,
      clearManyAssetsFromFilter,
      selectManyAssets,
      clearManySelectedAssets,
      history,
      clearHoverAsset,
    } = this.props;
    const markerGroupController = this.pimap.enableMarkerGrouping({
      radius: GROUP_MARKER_RADIUS,
      markerType: getGroupMarkerSVG(),
    });

    const mapClass = this;

    const doMouseEnterOrMove = (e, contexts, marker) => {
      mapClass.updateMarkerZIndex(marker, MARKER_Z_INDEX_HOVER, MARKER_HOVER);
      if (marker.selected) {
        return;
      }
      try {
        marker.changeMarkerType(getGroupMarkerSVG(MARKER_HOVER, marker.groupCount));
      } catch (e) {
        log.warn(e);
      }
      mapClass.showGroupLabel(marker, contexts);
    };

    // GROUP MARKER MOUSE ENTER
    markerGroupController.addListener('mouseenter', function(e, contexts) {
      doMouseEnterOrMove(e, contexts, this);
    });

    // GROUP MARKER MOUSE LEAVE
    markerGroupController.addListener('mouseleave', function(e, contexts) {
      if (this.selected) {
        mapClass.updateMarkerZIndex(this, MARKER_Z_INDEX_SELECTED);
        return;
      }
      mapClass.updateMarkerZIndex(this, MARKER_Z_INDEX);
      try {
        this.changeMarkerType(getGroupMarkerSVG(null, this.groupCount));
      } catch (e) {
        log.warn(e);
      }
      mapClass.hideGroupLabel(this);
    });

    // GROUP MARKER CLICK
    markerGroupController.addListener('click', function(e, contexts) {
      const { selectedZone } = mapClass.props;
      const assetIds = contexts.map(context => context.assetId && context.assetId);
      const assets = contexts.map(context => context.asset && context.asset);
      if (this.selected) {
        mapClass.hideGroupLabel(this);
        mapClass.updateMarkerZIndex(this, MARKER_Z_INDEX);
        try {
          this.changeMarkerType(getGroupMarkerSVG(null, this.groupCount));
        } catch (e) {
          log.warn(e);
        }
        clearManyAssetsFromFilter(assetIds);
        clearManySelectedAssets(assetIds);
        this.selected = false;
        return;
      }
      try {
        this.changeMarkerType(getGroupMarkerSVG(MARKER_HOVER, this.groupCount));
      } catch (e) {
        log.warn(e);
      }
      mapClass.updateMarkerZIndex(this, MARKER_Z_INDEX_SELECTED);
      mapClass.showGroupLabel(this, contexts);
      this.selected = true;
      clearHoverAsset();
      addManyAssetsToFilter(assetIds);
      selectManyAssets(assets);
      history.push(`/locate/${selectedZone.id}`);
    });

    this.markerGroupController = markerGroupController;
  };

  showGroupLabel = (groupMarker, contexts) => {
    if (!contexts) {
      contexts = groupMarker.groupedMarkers.map(marker => marker.context);
    }
    if (groupMarker) {
      const labelInnerHTML = this.getLabelInnerHTML(contexts);
      const labelHTML = `<ul>${labelInnerHTML}</ul>`;
      if (!groupMarker.hasLabel) {
        const labelElement = document.createElement('div');
        labelElement.className = 'rhMapLabel rhMapGroupedLabel';
        labelElement.innerHTML = labelHTML;
        groupMarker.element.appendChild(labelElement);
        groupMarker.hasLabel = true;
        groupMarker.labelElement = labelElement;
        this.repositionGroupLabel(groupMarker);
      } else {
        groupMarker.labelElement.innerHTML = labelHTML;
        this.repositionGroupLabel(groupMarker);
      }
    }
  };

  hideGroupLabel = groupMarker => {
    if (groupMarker && groupMarker.hasLabel) {
      groupMarker.labelElement.style.visibility = 'hidden';
    }
  };

  repositionGroupLabel = groupMarker => {
    groupMarker.labelElement.style.left =
      -Math.round(groupMarker.labelElement.offsetWidth / 2) + 20 + 'px'; // Magic number 20 is half the width of the group marker
    groupMarker.labelElement.style.top =
      -Math.round(groupMarker.labelElement.offsetHeight) - 5 + 'px'; // Magic number 5 is a sweetspot number that looks visually appealing
    groupMarker.labelElement.style.visibility = 'visible';
  };

  getLabelInnerHTML = contexts => {
    const contextArray = Array.isArray(contexts) ? contexts : [contexts];
    let listItems = '';
    if (contextArray && contextArray.length <= 5) {
      _.sortBy(contextArray, item => item.assetName, ['asc']).forEach(context => {
        listItems += `<li class="d-block"><span>${context.assetName || context.serial}</span></li>`;
      });
    } else {
      const countedAssetTypes = _.toPairs(
        _.countBy(contextArray, context => {
          return context.assetTypePlural;
        }),
      );

      _.sortBy(countedAssetTypes, assetType => assetType[0], ['asc']).forEach(assetType => {
        const count = assetType[1];
        const context = contextArray.find(context => {
          return context.assetTypePlural === assetType[0];
        });
        const singularAssetType = context ? context.assetType : 'Tag';
        const name = count > 1 ? assetType[0] || 'Tags' : singularAssetType;
        listItems += `<li class="d-block"><span>${count} ${name}</span></li>`;
      });
    }

    return listItems;
  };

  updateMarkerZIndex = (marker, zIndex) => {
    marker.element.style.zIndex = zIndex;
    if (marker.label) {
      marker.label.element.style.zIndex = zIndex;
    } else if (marker.hasLabel) {
      marker.labelElement.style.zIndex = zIndex;
    }
  };

  render() {
    const {
      props: {
        locateContext: { showRoomPresence },
      },
      state: { showFallAlertTooltip, showMaintenance },
    } = this;

    if (showMaintenance) {
      return <Maintenance />;
    }

    return (
      <Fragment>
        <ResizeDetector>
          <div className="mapSVG_wrapper">
            <div
              id="pi-map"
              ref={ref => (this.pimapElement = ref)}
              style={{ height: '100%', width: '100%' }}
            />
            <MapKey showRoomPresence={showRoomPresence} />
          </div>
        </ResizeDetector>
        <div
          className={cx(
            'fall-alert-tooltip',
            Object.assign(
              { show: !!showFallAlertTooltip },
              showFallAlertTooltip && {
                [showFallAlertTooltip.alert.alert_type.component_id]: true,
              },
            ),
          )}
        >
          {showFallAlertTooltip && (
            <Fragment>
              <div>{showFallAlertTooltip.alert.alert_type.name}</div>
              <div>
                {formatTimestamp(showFallAlertTooltip.alert.device_event_time, { seconds: true })}
              </div>
              <div>Room: {showFallAlertTooltip.room.name}</div>
            </Fragment>
          )}
        </div>
      </Fragment>
    );
  }
}

export default withLocateContext(MapContainer);
