import React from 'react';
import { connect } from 'react-redux';
import MUIButton from '@material-ui/core/Button';
import { crudDelete, crudDeleteMany } from 'react-admin';
import { startUndoable as startUndoableAction } from 'ra-core';
import { Alert, Button, FormGroup, Modal, ModalBody, ModalHeader } from 'reactstrap';
import WarningIcon from '@material-ui/icons/Error';
import DeleteIcon from '@material-ui/icons/Delete';

class DeleteButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      confirming: false,
    };
  }

  startConfirming = () => {
    this.setState({
      confirming: true,
    });
  };

  stopConfirming = () => {
    this.setState({
      confirming: false,
    });
  };

  handleDelete = () => {
    const { record, startUndoable, basePath, resource, promptCheck } = this.props;

    promptCheck(() => startUndoable(crudDelete(resource, record.id, null, basePath, basePath)));
  };

  handleDeleteMany = () => {
    const { startUndoable, selectedIds, basePath, resource, promptCheck } = this.props;

    promptCheck(() => startUndoable(crudDeleteMany(resource, selectedIds, basePath)));
  };

  render() {
    const { confirming } = this.state;
    const { modalHeader, bulk = false } = this.props;

    return confirming ? (
      <Modal
        isOpen
        toggle={this.stopConfirming}
        centered
        size="md"
        className="confirm-delete-modal"
      >
        <ModalHeader>{modalHeader}</ModalHeader>
        <ModalBody>
          <Alert color="danger">
            <WarningIcon />
            You will not be able to undelete
          </Alert>
          <FormGroup>
            <Button
              color="secondary"
              className="no-button"
              onClick={this.stopConfirming}
              style={{ width: '75px' }}
            >
              No
            </Button>
            <Button
              color="primary"
              className="yes-button"
              onClick={bulk ? this.handleDeleteMany : this.handleDelete}
              style={{ float: 'right', width: '75px' }}
            >
              Yes
            </Button>
          </FormGroup>
        </ModalBody>
      </Modal>
    ) : (
      <MUIButton onClick={this.startConfirming} className={`${bulk ? 'bulk-' : ''}delete-button`}>
        <DeleteIcon />
        Delete
      </MUIButton>
    );
  }
}

export default connect(
  null,
  { startUndoable: startUndoableAction },
)(DeleteButton);
