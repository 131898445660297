import React from 'react';
import { Redirect } from 'react-router-dom';
import { BooleanInput, Edit, ReferenceInput, SimpleForm, TextInput } from 'react-admin';

import Autocomplete from '../../components/Autocomplete/Autocomplete';
import Can from '../../components/Can/Can';
import EditToolbar from '../common/EditToolbar/EditToolbar';
import FormHeader from '../common/FormHeader/FormHeader';
import LongTextInput from '../common/LongTextInput/LongTextInput';
import { validate } from '../../utils/admin';
import { formSchema, redirectUrl } from './index.js';
import { REQUIRED_FIELD } from '../../utils/validations';

export const RoomAreaEdit = props => {
  return (
    <Can perform="roomAreas:update" no={<Redirect to={redirectUrl} />}>
      <Edit {...props}>
        <SimpleForm
          validate={values => validate(formSchema, values)}
          toolbar={
            <EditToolbar
              object="roomAreas"
              deleteModalHeader="Are you sure you want to delete this Room Area?"
            />
          }
        >
          <FormHeader text="Room Area - Edit" />
          <TextInput source="region_map_id" label="Region map ID" required />
          <LongTextInput source="description" showCounter />
          <BooleanInput source="is_bed" label="Is bed" />
          <ReferenceInput
            label="Room"
            source="room.id"
            reference="rooms"
            validate={value => {
              if (value < 0) {
                // the Autocomplete component updates to an id of -1 if user input doesn't match a room
                return 'Invalid room';
              }

              return !value && REQUIRED_FIELD.message;
            }}
            required
            perPage={1000}
          >
            <Autocomplete renderLabel={choice => choice.name} />
          </ReferenceInput>
        </SimpleForm>
      </Edit>
    </Can>
  );
};
