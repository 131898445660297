import React from 'react';
import { Redirect } from 'react-router-dom';
import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput } from 'react-admin';

import Can from '../../components/Can/Can';
import CreateToolbar from '../common/CreateToolbar/CreateToolbar';
import FormHeader from '../common/FormHeader/FormHeader';
import LongTextInput from '../common/LongTextInput/LongTextInput';
import { validate } from '../../utils/admin';
import { formSchema, redirectUrl } from './index.js';

export const VenueCreate = props => (
  <Can perform="venues:create" no={<Redirect to={redirectUrl} />}>
    <Create {...props}>
      <SimpleForm toolbar={<CreateToolbar />} validate={values => validate(formSchema, values)}>
        <FormHeader text="Venue - Create" />
        <TextInput source="name" required />
        <LongTextInput source="description" showCounter />
        <ReferenceInput label="Default Zone" source="default_zone.id" reference="zones">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="vendor_map_id" />
        <TextInput source="building" />
        <TextInput source="floor" />
      </SimpleForm>
    </Create>
  </Can>
);
