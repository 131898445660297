import React from 'react';
import { Admin as ReactAdmin, Resource } from 'react-admin';

import AssetIcon from '@material-ui/icons/Room';
import TagIcon from '@material-ui/icons/ControlPoint';
import ZoneIcon from '@material-ui/icons/Dashboard';
import VenueIcon from '@material-ui/icons/LocationCity';
import OrganizationIcon from '@material-ui/icons/Business';
import SiteUserIcon from '@material-ui/icons/SupervisorAccount';
import ListIcon from '@material-ui/icons/ViewList';
import EyeIcon from '@material-ui/icons/RemoveRedEye';

import defaultTheme from '../../admin/theme';
import { ParentList, ParentEdit, ParentCreate } from '../../admin/Parents';
import { OrganizationList, OrganizationEdit, OrganizationCreate } from '../../admin/Organizations';
import { RoomList, RoomEdit, RoomCreate } from '../../admin/Rooms';
import { RoomAreaList, RoomAreaEdit, RoomAreaCreate } from '../../admin/RoomAreas';
import { RoomTypeList, RoomTypeEdit, RoomTypeCreate } from '../../admin/RoomTypes';
import { AlertTypeList, AlertTypeEdit, AlertTypeCreate } from '../../admin/AlertTypes';
import { ReportTypeList, ReportTypeEdit, ReportTypeCreate } from '../../admin/ReportTypes';
import { AssetList, AssetEdit, AssetCreate } from '../../admin/Assets';
import { AssetTypeList, AssetTypeEdit, AssetTypeCreate } from '../../admin/AssetTypes';
import { TagList, TagEdit, TagCreate } from '../../admin/Tags';
import { VenueList, VenueEdit, VenueCreate } from '../../admin/Venues';
import { VisionDeviceList, VisionDeviceEdit, VisionDeviceCreate } from '../../admin/VisionDevices';
import { ZoneList, ZoneEdit, ZoneCreate } from '../../admin/Zones';
import { SiteUserList, SiteUserEdit, SiteUserCreate } from '../../admin/SiteUsers';
import AdminLayout from './AdminLayout';
import NotFoundPage from '../../components/NotFound/NotFound';

const Admin = props => {
  const { check, dataProvider } = props;

  return (
    <ReactAdmin
      theme={defaultTheme}
      dataProvider={dataProvider}
      appLayout={AdminLayout}
      catchAll={NotFoundPage}
    >
      <Resource
        name="parents"
        options={{
          show: check('parents:list'),
          notAuthorizedPath: '/users',
          icon: (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path
                d="M22 18v-7h-9v-5h3v-6h-8v6h3v5h-9v7h-2v6h6v-6h-2v-5h7v5h-2v6h6v-6h-2v-5h7v5h-2v6h6v-6z"
                fill="rgba(0, 0, 0, 0.54)"
              />
            </svg>
          ),
        }}
        list={check('parents:list') ? ParentList : null}
        edit={ParentEdit}
        create={ParentCreate}
      />
      <Resource
        name="organizations"
        options={{
          show: check('organizations:list'),
          notAuthorizedPath: '/users',
          icon: <OrganizationIcon />,
        }}
        list={check('organizations:list') ? OrganizationList : null}
        edit={OrganizationEdit}
        create={OrganizationCreate}
      />
      <Resource
        name="assetTypes"
        options={{
          label: 'Asset Types',
          show: check('assetTypes:list'),
          notAuthorizedPath: '/users',
          icon: <ListIcon />,
        }}
        list={check('assetTypes:list') ? AssetTypeList : null}
        edit={AssetTypeEdit}
        create={AssetTypeCreate}
      />
      <Resource
        name="roomTypes"
        options={{
          label: 'Room Types',
          show: check('roomTypes:list'),
          notAuthorizedPath: '/users',
          icon: <ListIcon />,
        }}
        list={check('roomTypes:list') ? RoomTypeList : null}
        edit={RoomTypeEdit}
        create={RoomTypeCreate}
      />
      <Resource
        name="alertTypes"
        options={{
          label: 'Alert Types',
          show: check('alertTypes:list'),
          notAuthorizedPath: '/users',
          icon: <ListIcon />,
        }}
        list={check('alertTypes:list') ? AlertTypeList : null}
        edit={AlertTypeEdit}
        create={AlertTypeCreate}
      />
      <Resource
        name="reportTypes"
        options={{
          label: 'Report Types',
          adminEnd: true,
          show: check('reportTypes:list'),
          notAuthorizedPath: '/users',
          icon: <ListIcon />,
        }}
        list={check('reportTypes:list') ? ReportTypeList : null}
        edit={ReportTypeEdit}
        create={ReportTypeCreate}
      />
      <Resource
        name="users"
        options={{
          label: 'Users',
          show: check('siteUsers:list'),
          icon: <SiteUserIcon />,
        }}
        list={check('siteUsers:list') ? SiteUserList : null}
        edit={SiteUserEdit}
        create={SiteUserCreate}
      />
      <Resource
        name="venues"
        options={{ show: check('venues:list'), icon: <VenueIcon /> }}
        list={check('venues:list') ? VenueList : null}
        edit={VenueEdit}
        create={VenueCreate}
      />
      <Resource
        name="zones"
        options={{ show: check('zones:list'), icon: <ZoneIcon /> }}
        list={check('zones:list') ? ZoneList : null}
        edit={ZoneEdit}
        create={ZoneCreate}
      />
      <Resource
        name="rooms"
        options={{
          show: check('rooms:list'),
          icon: (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path
                d="M14 6v15H3v-2h2V3h9v1h5v15h2v2h-4V6h-3zm-4 5v2h2v-2h-2z"
                fill="rgba(0, 0, 0, 0.54)"
              />
            </svg>
          ),
        }}
        list={check('rooms:list') ? RoomList : null}
        edit={RoomEdit}
        create={RoomCreate}
      />
      <Resource
        name="roomAreas"
        options={{
          label: 'Room Areas',
          show: check('roomAreas:list'),
          icon: (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <g>
                <path d="m 1 1 l 10 0 l 0 12 l -10 0 l 0 -12" fill="rgba(0, 0, 0, 0.54)" />
                <path
                  d="m 13 1 l 10 0 l 0 22 l -22 0 l 0 -8 l 12 0 l 0 -14"
                  fill="rgba(0, 0, 0, 0.54)"
                />
              </g>
            </svg>
          ),
        }}
        list={check('roomAreas:list') ? RoomAreaList : null}
        edit={RoomAreaEdit}
        create={RoomAreaCreate}
      />
      <Resource
        name="visionDevices"
        options={{ label: 'Vision Devices', show: check('visionDevices:list'), icon: <EyeIcon /> }}
        list={check('visionDevices:list') ? VisionDeviceList : null}
        edit={VisionDeviceEdit}
        create={VisionDeviceCreate}
      />
      <Resource
        name="assets"
        options={{ show: check('assets:list'), icon: <AssetIcon /> }}
        list={check('assets:list') ? AssetList : null}
        edit={AssetEdit}
        create={AssetCreate}
      />
      <Resource
        name="tags"
        options={{ show: check('tags:list'), icon: <TagIcon /> }}
        list={check('tags:list') ? TagList : null}
        edit={TagEdit}
        create={TagCreate}
      />
    </ReactAdmin>
  );
};

export default Admin;
