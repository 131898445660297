import React from 'react';
import { Redirect } from 'react-router-dom';
import { Create, SimpleForm, TextInput, SelectInput, ReferenceInput } from 'react-admin';

import Can from '../../components/Can/Can';
import CreateToolbar from '../common/CreateToolbar/CreateToolbar';
import FormHeader from '../common/FormHeader/FormHeader';
import LongTextInput from '../common/LongTextInput/LongTextInput';
import { validate } from '../../utils/admin';
import { formSchema, redirectUrl } from './index.js';
import { REQUIRED_FIELD } from '../../utils/validations';

export const AssetCreate = props => (
  <Can perform="assets:create" no={<Redirect to={redirectUrl} />}>
    <Create {...props}>
      <SimpleForm toolbar={<CreateToolbar />} validate={values => validate(formSchema, values)}>
        <FormHeader text="Asset - Create" />
        <TextInput source="name" required />
        <LongTextInput source="description" showCounter />
        <ReferenceInput
          label="Asset Type"
          source="asset_type.id"
          reference="assetTypes"
          validate={value => !value && REQUIRED_FIELD.message}
          required
          filter={{ allOrganizations: false }}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  </Can>
);
