import React from 'react';
import { Redirect } from 'react-router-dom';
import { List, Datagrid, TextField, EditButton } from 'react-admin';

import Can from '../../components/Can/Can';
import PasswordPrompt from '../../components/PasswordPrompt/PasswordPrompt';
import BulkActionButtons from '../common/BulkActionButtons/BulkActionButtons';
import ListActions from '../common/ListActions/ListActions';
import Pagination from '../common/Pagination/Pagination';
import { redirectUrl } from './index.js';

export const AssetList = props => (
  <Can perform="assets:list" no={<Redirect to={redirectUrl} />}>
    <PasswordPrompt>
      {({ promptCheck }) => (
        <List
          {...props}
          perPage={15}
          pagination={<Pagination />}
          actions={
            <ListActions
              title="Assets"
              object="assets"
              modifyExports={items =>
                items.map(item => ({
                  ...item,
                  asset_type: item.asset_type.name,
                  tag: item.tag && item.tag.serial_number,
                }))
              }
            />
          }
          bulkActionButtons={
            <BulkActionButtons
              object="assets"
              deleteModalHeader="Are you sure you want to delete the selected Assets?"
            />
          }
          sort={{ field: 'name', order: 'ASC' }}
        >
          <Datagrid>
            <TextField source="name" />
            <TextField source="asset_type.name" label="Asset Type" />
            <Can perform="assets:update">
              <EditButton basePath="/assets" />
            </Can>
          </Datagrid>
        </List>
      )}
    </PasswordPrompt>
  </Can>
);
