import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import EnhancedTableHead from './TableHead';

const styles = theme => ({
  root: {
    marginBottom: '1rem',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

function desc(a, b, orderBy) {
  const aSortValue = a[orderBy].sortValue !== undefined ? a[orderBy].sortValue : a[orderBy];
  const bSortValue = b[orderBy].sortValue !== undefined ? b[orderBy].sortValue : b[orderBy];

  if (bSortValue < aSortValue) {
    return -1;
  }

  if (bSortValue > aSortValue) {
    return 1;
  }

  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  const sorted = stabilizedThis.map(el => el[0]);
  return sorted;
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const PaginatedTable = ({ data, orderBy: initialOrderBy, ...props }) => {
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState(initialOrderBy);
  const [selected, setSelected] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handleRequestSort = (event, property) => {
    const newOrderBy = property;
    let newOrder = 'desc';

    if (newOrderBy === property && order === 'desc') {
      newOrder = 'asc';
    }

    setOrder(newOrder);
    setOrderBy(newOrderBy);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
  };

  const isSelected = id => selected.indexOf(id) !== -1;

  const { classes, columns, selectable } = props;

  return (
    <Paper className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table aria-labelledby="tableTitle">
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
            columns={columns}
          />
          <TableBody>
            {stableSort(data, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => {
                const isSelectedValue = isSelected(row.id);
                return (
                  <TableRow
                    onClick={event => selectable && handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isSelectedValue}
                    tabIndex={-1}
                    key={row.id}
                    selected={isSelectedValue}
                  >
                    {columns.map(col => (
                      <TableCell align="right">{row[col.id].label || row[col.id]}</TableCell>
                    ))}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

PaginatedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

PaginatedTable.defaultProps = {
  classes: {},
};

export default withStyles(styles)(PaginatedTable);
