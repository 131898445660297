import React from 'react';
import { Redirect } from 'react-router-dom';
import { Edit, SimpleForm, TextInput, SelectInput } from 'react-admin';

import Can from '../../components/Can/Can';
import EditToolbar from '../common/EditToolbar/EditToolbar';
import FormHeader from '../common/FormHeader/FormHeader';
import { validate } from '../../utils/admin';
import { formSchema, redirectUrl } from './index.js';
import { timezoneOptions } from '../../utils/js';
import { getCurrentTimeFromTimezone } from '../../utils/js';

export const SiteUserEdit = props => (
  <Can perform="siteUsers:update" no={<Redirect to={redirectUrl} />}>
    {({ check }) => (
      <Edit {...props}>
        <SimpleForm
          validate={values => validate(formSchema, values)}
          toolbar={
            <EditToolbar
              object="siteUsers"
              deleteModalHeader="Are you sure you want to delete this User?"
            />
          }
        >
          <FormHeader text="User - Edit" />
          <TextInput source="first_name" required />
          <TextInput source="last_name" required />
          <TextInput source="email" required />
          <TextInput source="phone_number" />
          <SelectInput
            source="role"
            required
            choices={Array.prototype.concat(
              check('siteUsers:create:systemAdministrator')
                ? { id: 'system-administrator', name: 'System Administrator' }
                : [],
              check('siteUsers:create:organizationAdministrator')
                ? { id: 'organization-administrator', name: 'Organization Administrator' }
                : [],
              check('siteUsers:create:organizationUser')
                ? { id: 'organization-user', name: 'Organization User' }
                : [],
            )}
          />
          <SelectInput
            source="timezone"
            required
            choices={timezoneOptions.map(timezone => ({
              ...timezone,
              name: `${timezone.name} (Currently ${getCurrentTimeFromTimezone(timezone.id)})`,
            }))}
          />
        </SimpleForm>
      </Edit>
    )}
  </Can>
);
