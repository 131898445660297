import React, { Component } from 'react';
import { Query } from 'react-apollo';
import queryString from 'query-string';

import AssetCard from './AssetCard';
import SearchResultsAlert from './SearchResultsAlert';
import Loader from '../../../components/Loader/Loader';
import { SEARCH_ASSETS } from '../../../client/queries/map';
import log from '../../../utils/Log';

class SearchResults extends Component {
  constructor(props) {
    super(props);

    const { search } = props.location;
    const { q } = queryString.parse(search.replace('?', ''));
    this.state = {
      query: q,
      searchText: q,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { search } = nextProps.location;

    if (search !== this.props.location.search) {
      const { q } = queryString.parse(search.replace('?', ''));

      this.setState({
        query: q,
        searchText: q,
      });
    }
  }

  render() {
    const { selectOneAsset, clearHoverAsset, hoverOverAsset } = this.props;
    const { query } = this.state;

    return (
      <div className="locate__search">
        <div className="locate__overview__search-container">
          <Query
            query={SEARCH_ASSETS}
            variables={{ search_query: query }}
            fetchPolicy="network-only"
            errorPolicy="all"
          >
            {({ loading, error, data, variables }) => {
              if (loading) {
                return <Loader small />;
              }

              if (error) {
                log.error(error);

                return (
                  <SearchResultsAlert
                    color="danger"
                    message="An error occurred during your search. Please try again."
                  />
                );
              }

              return (
                <div className="cards-list">
                  {data && data.assets && data.assets.data && !!data.assets.data.length ? (
                    data.assets.data.map(asset => {
                      return (
                        <AssetCard
                          key={asset.id}
                          asset={asset}
                          zoneId={asset && asset.tag && asset.tag.zone && asset.tag.zone.id}
                          clearHoverAsset={clearHoverAsset}
                          hoverOverAsset={hoverOverAsset}
                          selectOneAsset={selectOneAsset}
                        />
                      );
                    })
                  ) : (
                    <SearchResultsAlert
                      color="warning"
                      message={`No results found for "${variables.search_query}"`}
                    />
                  )}
                </div>
              );
            }}
          </Query>
        </div>
      </div>
    );
  }
}

export default SearchResults;
