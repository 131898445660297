import React from 'react';
import { withApollo } from 'react-apollo';

import { toast } from '../Toast/Toast';
import { initializePiMap, piMapElement } from '../../utils/map';
import { withAppContext } from '../../context/AppContext';

class PIMap extends React.Component {
  componentDidMount() {
    const {
      vendorMapId,
      vendorZoneId,
      overrides,
      onInitialized,
      onError,
      appContext: { config },
    } = this.props;

    initializePiMap(config, vendorMapId, vendorZoneId, overrides, initResult => {
      const { element, error: initError, piMap } = initResult;

      if (!initError) {
        const { injectChild } = this.props;
        const customLayer = document.createElementNS('http://www.w3.org/2000/svg', 'g');
        const svgElement = element.querySelector(`svg#zone_${vendorZoneId}`);
        const svgContainer = element.querySelector('.pi-main-container');

        customLayer.setAttribute('id', 'room_overlay');
        customLayer.innerHTML = injectChild;
        svgElement.appendChild(customLayer);

        if (onInitialized) {
          onInitialized({
            piMap,
            mapContainer: element,
            customLayer,
            svgElement,
            svgContainer,
          });
        }
      } else {
        if (onError) {
          onError({
            message: initError,
          });
        } else {
          toast.error(initError);
        }
      }
    });
  }

  render() {
    return piMapElement;
  }
}

export default withApollo(withAppContext(PIMap));
