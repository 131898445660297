import React, { Fragment, useState } from 'react';
import { withLocateContext } from '../../../context/LocateContext';
import { getBatteryIcon } from '../../../utils/map';

const getBatteryPercent = ({
  serialNumber,
  latestLocationData,
  batteryPercent,
  setBatteryPercent,
}) => {
  if (
    latestLocationData &&
    latestLocationData.updatedLocationData &&
    serialNumber === latestLocationData.updatedLocationData.TagSerialNumber
  ) {
    const newBatteryPercent =
      latestLocationData.updatedLocationData &&
      latestLocationData.updatedLocationData.BatteryPercent;

    if (batteryPercent !== newBatteryPercent) {
      setBatteryPercent(newBatteryPercent);
    }
    return newBatteryPercent;
  }
  return batteryPercent;
};

const AssetBatteryPercent = ({ serialNumber, initialBatteryPercent, locateContext }) => {
  const [batteryPercent, setBatteryPercent] = useState(initialBatteryPercent);

  return (
    <Fragment>
      {getBatteryIcon(
        getBatteryPercent({
          serialNumber,
          latestLocationData: locateContext.latestLocationData,
          batteryPercent,
          setBatteryPercent,
        }),
      )}
    </Fragment>
  );
};

export default withLocateContext(AssetBatteryPercent);
