import moment from 'moment';

import { pluralize, getAWSDate } from './js';

export const COLOR_SCHEME = [
  '#e6194b',
  '#3cb44b',
  '#ffe119',
  '#4363d8',
  '#f58231',
  '#911eb4',
  '#46f0f0',
  '#f032e6',
  '#bcf60c',
  '#fabebe',
  '#008080',
  '#e6beff',
  '#9a6324',
  '#fffac8',
  '#800000',
  '#aaffc3',
  '#808000',
  '#ffd8b1',
  '#000075',
  '#808080',
  '#ffffff',
  '#000000',
];

export const PIE_CHART_RANGE_OPTIONS = [
  {
    id: '1d',
    label: '1 Day',
    value: 1,
  },
  {
    id: '7d',
    label: '7 days',
    value: 7,
  },
  {
    id: '30d',
    label: '30 days',
    value: 30,
  },
  {
    id: 'custom',
    label: 'Custom',
    value: null,
  },
];

export const DEFAULT_CHART_OPTIONS = {
  utilization: {
    tooltips: {
      callbacks: {
        label: (item, data) => {
          const datasetData = data && data.datasets && data.datasets[0] && data.datasets[0].data;
          const totalDwellTime = datasetData.reduce((acc, val) => acc + val);
          const label = ` ${Math.round((datasetData[item.index] / totalDwellTime) * 100)}%`;

          return label;
        },
      },
    },
    maintainAspectRatio: false,
    legend: {
      labels: {
        usePointStyle: true,
        fontSize: 11,
      },
    },
  },
  distanceTraveled: {
    legend: {
      labels: {
        filter: (item, chart) => {
          return item.text !== null;
        },
      },
    },
    tooltips: {
      displayColors: false,
      callbacks: {
        title: (item, data) => {
          const { labels } = data;
          const timestamp = moment(labels[item[0].index])
            .utc()
            .format('MMMM DD, YYYY');

          return timestamp;
        },
        label: (item, data) => {
          const meterValue = parseInt(item.value, 10);
          const label = `${meterValue} ${pluralize(
            meterValue,
            'meter',
            'meters',
            () => meterValue !== 1,
          )}`;

          return label;
        },
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            callback: (value, index, data) =>
              index === 0
                ? 'Today'
                : moment(value)
                    .utc()
                    .format('dddd'),
          },
        },
      ],
      yAxes: [
        {
          minBarLength: 2, // px
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  },
};

export function parseUtilizationChartData(input, chartType) {
  let returnObj;

  if (!input || !input.length) {
    return false;
  }

  switch (chartType) {
    case 'line': {
      const labels = Array.from(
        new Set(input.map(i => i.process_date && getAWSDate(i.process_date))),
      );
      const roomTypes = Array.from(
        new Set(input.map(i => (i.room_type ? i.room_type.name : 'Unknown'))),
      );

      returnObj = {
        datasets: roomTypes.map((roomType, index) => {
          const lineData = labels.map(label => {
            const inputItem = input.find(
              i =>
                i.process_date &&
                i.process_date.startsWith(label) &&
                i.room_type &&
                i.room_type.name === roomType,
            );

            if (inputItem) {
              const dayTotal = input
                .filter(i => i.process_date && i.process_date.startsWith(label))
                .reduce((acc, val) => acc + val.dwell_time_sum, 0);

              return Math.round((inputItem.dwell_time_sum / dayTotal) * 100);
            } else {
              return 0;
            }
          });

          return {
            data: lineData,
            label: roomType,
            fill: false,
            backgroundColor: COLOR_SCHEME[index],
            borderColor: COLOR_SCHEME[index],
            lineTension: 0,
          };
        }),
        labels,
      };
      break;
    }

    default: {
      const labels = input.map(i => i.room_type && i.room_type.name);

      returnObj = {
        datasets: [
          {
            data: input.map(i => i.dwell_time_sum),
            backgroundColor: COLOR_SCHEME,
          },
        ],
        labels,
      };
    }
  }

  return returnObj;
}

export function parseTimeInBedChartData(input, chartType) {
  let returnObj;

  if (!input || !input.length) {
    return false;
  }

  const labels = Array.from(new Set(input.map(i => i.date && getAWSDate(i.date))));
  const rooms = Array.from(new Set(input.map(i => (i.room ? i.room.name : 'Unknown'))));

  returnObj = {
    datasets: rooms.map((roomType, index) => {
      const lineData = labels.map(label => {
        const inputItem = input.find(
          i => i.date && i.date.startsWith(label) && i.room && i.room.name === roomType,
        );

        return inputItem && inputItem.percentage;
      });

      return {
        data: lineData,
        label: roomType,
        fill: false,
        backgroundColor: COLOR_SCHEME[index],
        borderColor: COLOR_SCHEME[index],
        lineTension: 0,
      };
    }),
    labels,
  };

  return returnObj;
}

export function parseRoomOccupancyChartData(input) {
  const { percentage } = input;

  const difference = 100 - percentage;
  const labels = [`Occupied - ${percentage}%`, `Unoccupied - ${difference}%`];
  const data = [percentage, difference];

  return {
    datasets: [
      {
        data,
        backgroundColor: [COLOR_SCHEME[0], '#A0A0A0'],
      },
    ],
    labels,
  };
}
