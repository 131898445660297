import React from 'react';
import { Redirect } from 'react-router-dom';
import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput } from 'react-admin';

import Can from '../../components/Can/Can';
import CreateToolbar from '../common/CreateToolbar/CreateToolbar';
import FormHeader from '../common/FormHeader/FormHeader';
import LongTextInput from '../common/LongTextInput/LongTextInput';
import { validate } from '../../utils/admin';
import { formSchema, redirectUrl } from './index.js';
import { REQUIRED_FIELD } from '../../utils/validations';

export const ZoneCreate = props => (
  <Can perform="zones:create" no={<Redirect to={redirectUrl} />}>
    <Create {...props}>
      <SimpleForm toolbar={<CreateToolbar />} validate={values => validate(formSchema, values)}>
        <FormHeader text="Zone - Create" />
        <TextInput source="name" required />
        <LongTextInput source="description" showCounter />
        <ReferenceInput
          label="Venue"
          source="venue.id"
          reference="venues"
          validate={value => !value && REQUIRED_FIELD.message}
          required
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="vendor_zone_id" />
        <TextInput source="floor" />
        <TextInput source="origin_offset_x" required />
        <TextInput source="origin_offset_y" required />
        <TextInput source="meter_to_pixel_scaler_x" required />
        <TextInput source="meter_to_pixel_scaler_y" required />
      </SimpleForm>
    </Create>
  </Can>
);
