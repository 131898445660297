import gql from 'graphql-tag';

const Fragments = {
  assetFields: gql`
    fragment AssetFields on Asset {
      id
      name
      asset_type {
        id
        name
        plural
      }
      tag {
        id
        serial_number
        location {
          X
          Y
          TagSerialNumber
          BatteryPercent
          DeviceEventTime
          room {
            name
          }
          zone {
            name
          }
        }
      }
    }
  `,

  zoneFields: gql`
    fragment ZoneFields on Zone {
      id
      name
      vendor_zone_id
      origin_offset_x
      origin_offset_y
      meter_to_pixel_scaler_x
      meter_to_pixel_scaler_y

      rooms {
        id
        name
        vertices {
          x
          y
        }
      }
    }
  `,
};

export default Fragments;
