import React, { Component } from 'react';
import { Query } from 'react-apollo';

import log from '../../utils/Log';
import { GET_LOCAL_USER_DATA } from '../../client/queries/app';

//TODO: Consider moving userData to context api, which should serve as the single source of truth instead of componenet-based state
export function withUserData(WrappedComponent) {
  return class extends Component {
    render() {
      return (
        <Query query={GET_LOCAL_USER_DATA}>
          {({ data: queryData, loading: queryLoading, error: queryError }) => {
            if (queryError) {
              log.error(queryError);
            }
            return <WrappedComponent {...queryData.userData} {...this.props} />;
          }}
        </Query>
      );
    }
  };
}
