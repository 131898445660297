import React from 'react';
import { Toolbar } from 'react-admin';

import PasswordPrompt from '../../../components/PasswordPrompt/PasswordPrompt';
import SaveButton from '../SaveButton/SaveButton';

const CreateToolbar = props => {
  const { additionalSubmitValues, ...rest } = props;

  return (
    <Toolbar {...rest} style={{ padding: '1rem' }}>
      <PasswordPrompt>
        {({ promptCheck }) => <SaveButton promptCheck={promptCheck} {...props} />}
      </PasswordPrompt>
    </Toolbar>
  );
};

export default CreateToolbar;
