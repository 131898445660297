import gql from 'graphql-tag';
import Fragments from './fragments';

export const GET_LOCAL_USER_DATA = gql`
  query {
    userData @client {
      email
      emailVerified
      impersonating
      groups
      organizationName
      organizationId
      colorBlindMode
    }
  }
`;

export const APP_INIT_QUERY = gql`
  query getInitData {
    get_config {
      key
      value
    }

    vision_alerts_for_site_user {
      id
      zone_id
      device_event_time

      alert_type {
        id
        name
        component_id
      }

      room {
        id
        name
      }
    }

    organizations {
      data {
        ... on Organization {
          name
          default_venue {
            id
          }
        }
      }
    }

    venues {
      data {
        ... on Venue {
          id
          name
          vendor_map_id
          default_zone {
            id
          }
          zones {
            ...ZoneFields
            assets {
              ...AssetFields
            }
          }
        }
      }
    }
  }
  ${Fragments.assetFields}
  ${Fragments.zoneFields}
`;
