import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { withApollo } from 'react-apollo';
import { Alert, ListGroup, ListGroupItem, FormGroup, Label, Input, CustomInput } from 'reactstrap';

import Loader from '../../components/Loader/Loader';
import { toast } from '../../components/Toast/Toast';
import { GET_LOCAL_USER_DATA } from '../../client/queries/app';
import history from '../../utils/history';
import { ACCOUNT_SETTINGS_ROUTE } from '../../utils/routes';
import { withAppContext } from '../../context/AppContext';

const GET_INITIAL_DATA = gql`
  query getAlertTypes($email: AWSEmail) {
    alert_types {
      data {
        ... on AlertType {
          id
          name
          plural
          sns_topic
          description
          component_id
        }
      }
    }

    alert_types_to_site_users {
      id
      notify_email
      notify_phone
      notify_web
      alert_type {
        id
        name
        plural
        description
      }
    }

    site_users(email: $email) {
      data {
        ... on SiteUser {
          phone_number
        }
      }
    }
  }
`;

const ENABLE_ALERT_TYPE_TO_SITE_USER = gql`
  mutation addAlertTypeToSiteUser($alert_type_id: ID!) {
    addAlertTypeToSiteUser(alert_type_id: $alert_type_id) {
      id
      notify_email
      notify_phone
      notify_web
    }
  }
`;

const UPDATE_ALERT_TYPE_TO_SITE_USER_EMAIL = gql`
  mutation updateAlertTypeToSiteUser($id: ID!, $notify_email: Boolean) {
    updateAlertTypeToSiteUser(id: $id, notify_email: $notify_email) {
      id
      notify_email
      notify_phone
      notify_web
    }
  }
`;

const UPDATE_ALERT_TYPE_TO_SITE_USER_PHONE = gql`
  mutation updateAlertTypeToSiteUser($id: ID!, $notify_phone: Boolean) {
    updateAlertTypeToSiteUser(id: $id, notify_phone: $notify_phone) {
      id
      notify_email
      notify_phone
      notify_web
    }
  }
`;

const UPDATE_ALERT_TYPE_TO_SITE_USER_WEB = gql`
  mutation updateAlertTypeToSiteUser($id: ID!, $notify_web: Boolean) {
    updateAlertTypeToSiteUser(id: $id, notify_web: $notify_web) {
      id
      notify_email
      notify_phone
      notify_web

      alert_type {
        id
        component_id
        name
        sns_topic
      }
    }
  }
`;

const DISABLE_ALERT_TYPE_TO_SITE_USER = gql`
  mutation deleteAlertTypesToSiteUsers($id: [ID]!) {
    deleteAlertTypesToSiteUsers(id: $id) {
      id
    }
  }
`;

class Alerts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      updating: false,
      initialized: false,
      alertTypes: null,
      userPhoneNumber: null,
    };

    const { client } = props;

    client.query({ query: GET_LOCAL_USER_DATA }).then(userData => {
      const {
        data: { userData: localUserData },
      } = userData;

      client
        .query({
          query: GET_INITIAL_DATA,
          variables: { email: localUserData && localUserData.email },
          fetchPolicy: 'network-only',
        })
        .then(alertTypesResult => {
          const { data } = alertTypesResult;

          const orgAlertTypes = data.alert_types && data.alert_types.data;

          if (!orgAlertTypes || !orgAlertTypes.length) {
            history.push(ACCOUNT_SETTINGS_ROUTE);
            return;
          }

          const siteUserAlerts = data.alert_types_to_site_users;
          const userPhoneNumber =
            data.site_users &&
            data.site_users.data &&
            data.site_users.data[0] &&
            data.site_users.data[0].phone_number;

          const newAlertTypes = orgAlertTypes.map(alertType => {
            const alertTypeToSiteUser =
              siteUserAlerts &&
              siteUserAlerts.find(
                alert => alert.alert_type && alert.alert_type.id === alertType.id,
              );

            return Object.assign(
              {
                ...alertType,
                enabled: alertTypeToSiteUser ? true : false,
              },
              alertTypeToSiteUser && { alertTypeToSiteUser },
            );
          });

          if (orgAlertTypes) {
            this.setState({
              alertTypes: newAlertTypes,
              initialized: true,
              loading: false,
              userPhoneNumber,
            });
          } else {
            toast.error('Error getting organization alert types');
          }
        })
        .catch(err => {
          history.push(ACCOUNT_SETTINGS_ROUTE);
          toast.error('Error getting organization alert types');
        });
    });
  }

  enableAlertTypeToSiteUser = alertTypeId => {
    const { client } = this.props;

    this.setState({
      updating: true,
    });

    client
      .mutate({
        mutation: ENABLE_ALERT_TYPE_TO_SITE_USER,
        variables: { alert_type_id: alertTypeId },
      })
      .then(result => {
        const { alertTypes } = this.state;

        const newAlertTypes = alertTypes.map(alertType =>
          alertType.id === alertTypeId
            ? {
                ...alertType,
                enabled: true,
                alertTypeToSiteUser: {
                  ...result.data.addAlertTypeToSiteUser,
                },
              }
            : alertType,
        );

        this.setState({
          alertTypes: newAlertTypes,
          updating: false,
        });
      })
      .catch(error => {
        toast.error(
          `Failed to update alert type${
            error && error.message ? `: ${error.message.replace('GraphQL error: ', '')}` : ''
          }`,
        );
        this.setState({
          updating: false,
        });
      });
  };

  disableAlertTypeToSiteUser = alertTypeToSiteUserId => {
    const {
      client,
      appContext: { removeSubscription },
    } = this.props;

    this.setState({
      updating: true,
    });

    client
      .mutate({
        mutation: DISABLE_ALERT_TYPE_TO_SITE_USER,
        variables: { id: [alertTypeToSiteUserId] },
      })
      .then(result => {
        const { alertTypes } = this.state;

        const newAlertTypes = alertTypes.map(alertType => {
          const isCurrent =
            alertType.alertTypeToSiteUser &&
            alertType.alertTypeToSiteUser.id === alertTypeToSiteUserId;
          const newAlert = isCurrent ? { ...alertType, enabled: false } : alertType;

          isCurrent && newAlert && delete newAlert.alertTypeToSiteUser;

          return newAlert;
        });

        this.setState(
          {
            alertTypes: newAlertTypes,
            updating: false,
          },
          () => {
            const currentAlertType = alertTypes.find(
              type =>
                type.alertTypeToSiteUser && type.alertTypeToSiteUser.id === alertTypeToSiteUserId,
            );

            if (currentAlertType && currentAlertType.alertTypeToSiteUser.notify_web === true) {
              // unsubscribe current web subscription
              removeSubscription(alertTypeToSiteUserId);
            }
          },
        );
      })
      .catch(error => {
        toast.error('Failed to update alert type');
        this.setState({
          updating: false,
        });
      });
  };

  updateAlertTypeToSiteUser = (alertTypeToSiteUserId, mutation, values) => {
    const {
      props: {
        client,
        appContext: { addSubscription, removeSubscription },
      },
    } = this;
    this.setState({
      updating: true,
    });

    client
      .mutate({
        mutation,
        variables: {
          id: alertTypeToSiteUserId,
          ...values,
        },
      })
      .then(result => {
        const { alertTypes } = this.state;
        const {
          data: { updateAlertTypeToSiteUser },
        } = result;
        const newAlertTypes = alertTypes.map(alertType =>
          alertType.alertTypeToSiteUser &&
          alertType.alertTypeToSiteUser.id === alertTypeToSiteUserId
            ? {
                ...alertType,
                alertTypeToSiteUser: updateAlertTypeToSiteUser,
              }
            : alertType,
        );

        this.setState(
          {
            alertTypes: newAlertTypes,
            updating: false,
          },
          () => {
            if (Object.prototype.hasOwnProperty.call(values, 'notify_web')) {
              // web notification setting changed, update subscription status and global state
              const { notify_web } = values;

              if (notify_web === true) {
                addSubscription(updateAlertTypeToSiteUser);
              } else {
                removeSubscription(alertTypeToSiteUserId);
              }
            }
          },
        );
      })
      .catch(error => {
        toast.error('Failed to update alert type: ', error);
        this.setState({
          updating: false,
        });
      });
  };

  render() {
    const { loading, updating, initialized, alertTypes, userPhoneNumber } = this.state;

    const ErrorComponent = () => (
      <Alert color="info">
        There was a problem loading your alerts. Refresh the page to try again.
      </Alert>
    );

    return loading ? (
      <Loader fullscreen transparent />
    ) : (
      <div className="alerts-page">
        {initialized && (
          <Fragment>
            {!userPhoneNumber && (
              <Alert color="info">
                Phone alerts are available, but we do not have a phone number on file for you.{' '}
                <Link to="/settings/account">Click here</Link> to update your account settings.
              </Alert>
            )}
            <ListGroup flush>
              {alertTypes ? (
                alertTypes.map(alert => {
                  const {
                    id: alertTypeId,
                    name,
                    description,
                    alertTypeToSiteUser,
                    enabled,
                    component_id,
                  } = alert;
                  const { id: alertTypeToSiteUserId, notify_email, notify_phone, notify_web } =
                    alertTypeToSiteUser || {};

                  return (
                    component_id !== 'fall_end' && (
                      <ListGroupItem key={alertTypeId}>
                        <FormGroup>
                          <CustomInput
                            type="switch"
                            disabled={updating}
                            id={alertTypeId}
                            name={alertTypeId}
                            label={name}
                            onChange={() =>
                              enabled
                                ? this.disableAlertTypeToSiteUser(alertTypeToSiteUserId)
                                : this.enableAlertTypeToSiteUser(alertTypeId)
                            }
                            checked={enabled}
                          />
                          {description && <p className="subheading margin-tp">{description}</p>}
                        </FormGroup>
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="checkbox"
                              disabled={updating || !enabled}
                              checked={notify_email !== undefined ? notify_email : false}
                              onChange={(alertTypeId, value) =>
                                this.updateAlertTypeToSiteUser(
                                  alertTypeToSiteUser && alertTypeToSiteUser.id,
                                  UPDATE_ALERT_TYPE_TO_SITE_USER_EMAIL,
                                  {
                                    notify_email: !notify_email,
                                  },
                                )
                              }
                            />{' '}
                            Alert by email
                          </Label>
                        </FormGroup>
                        {userPhoneNumber && (
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="checkbox"
                                disabled={updating || !enabled}
                                checked={notify_phone !== undefined ? notify_phone : false}
                                onChange={(alertTypeId, value) =>
                                  this.updateAlertTypeToSiteUser(
                                    alertTypeToSiteUser && alertTypeToSiteUser.id,
                                    UPDATE_ALERT_TYPE_TO_SITE_USER_PHONE,
                                    {
                                      notify_phone: !notify_phone,
                                    },
                                  )
                                }
                              />{' '}
                              Alert by phone
                            </Label>
                          </FormGroup>
                        )}
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="checkbox"
                              disabled={updating || !enabled}
                              checked={notify_web !== undefined ? notify_web : false}
                              onChange={(alertTypeId, value) =>
                                this.updateAlertTypeToSiteUser(
                                  alertTypeToSiteUser && alertTypeToSiteUser.id,
                                  UPDATE_ALERT_TYPE_TO_SITE_USER_WEB,
                                  {
                                    notify_web: !notify_web,
                                  },
                                )
                              }
                            />{' '}
                            Alert by web
                          </Label>
                        </FormGroup>
                      </ListGroupItem>
                    )
                  );
                })
              ) : (
                <ErrorComponent />
              )}
            </ListGroup>
          </Fragment>
        )}
      </div>
    );
  }
}

export default withApollo(withAppContext(Alerts));
